import React, { useState } from "react";
import { Button } from "../../../../../../components";
import { setAddNewAgent, setAgentDetails } from "../../../../../../redux/slice/modalOverlay";
import { useDispatch, useSelector } from "react-redux";

const AddNewAgent = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    agent_name: "",
    company_name: "",
  });
  const [errorState, setErrorState] = useState({
    agent_nameError: "",
    company_nameError: "",
  });
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const validate = () => {
    let isError = false;
    const errors = {
      agent_nameError: "",
      company_nameError: "",
    };

    if (!details.agent_name) {
      isError = true;
      errors.agent_nameError = "Please enter agent name";
    }
    if (!details.company_name) {
      isError = true;
      errors.company_nameError = "Please enter the comapny name";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };
  const agentDetails = useSelector((state) => state.modalOverlay.states.agentDetails);
console.log(agentDetails)
  const generateUniqueId = () => {
    return Date.now().toString();
  };
  const handleAddAgent = () => {
    const error = validate();
    if (!error) {
      const newAgent = {
        id: generateUniqueId(),
        agent_details: details,
      };
      dispatch(setAgentDetails([...agentDetails, newAgent]));
    }
  };

  const handleClose = () => {
    dispatch(setAddNewAgent(false));
  };
  return (
    <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
      <div className="absolute right-10 top-2 text-2xl hover:cursor-pointer" onClick={handleClose}>
        X
      </div>
      <div className="flex flex-col gap-y-3 px-8">
        <span className={`py-2 text-start text-xl font-semibold text-secondary`}>
          Add New Agent
        </span>
        <div className="flex flex-col">
          <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
            Name
          </label>
          <input
            type="text"
            name="agent_name"
            id="agent_name"
            placeholder="Name the agent"
            onChange={handleChange}
            className="w-full border border-[#AEAEAE] px-2 py-4 outline-none"
          />
          <span className="text-start text-sm text-red-500">{errorState.agent_nameError}</span>
        </div>
        <div className="flex flex-col">
          <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
            Company name
          </label>
          <input
            type="text"
            name="company_name"
            id="company_name"
            placeholder="Name of the company"
            onChange={handleChange}
            className="w-full border border-[#AEAEAE] px-2 py-4 outline-none"
          />
          <span className="text-start text-sm text-red-500">{errorState.company_nameError}</span>
        </div>

        <div className="mt-10 w-full">
          <Button className="w-full" onClick={handleAddAgent}>
            Add advert
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AddNewAgent;

import React, { useState } from "react";
import { Button, SuccessModal } from "../../../../../../components";
import { setAddNewAdvert, setRefreshAd } from "../../../../../../redux/slice/modalOverlay";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Upload } from "../../../../../../constants/images";

const AddNewAdvert = () => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [advertName, setAdvertName] = useState("");
  const [nameError, setNameError] = useState("");
  const [loading, setLoading] = useState(null);
  const [success, setSuccess] = useState(null);
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const [selectedAdvert, setSelectedAdvert] = useState({
    name: advertName,
    image: selectedFile,
  });

  const postAdvert = async () => {
    if (advertName === "") {
      setNameError("Referral name is required.");
      return;
    }

    if (selectedFile === null) {
      setFileError("Image is required.");
      return;
    }

    try {
      setFileError("");
      setLoading(true);
      const nameResponse = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/adverts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ name: selectedAdvert.name }),
      });
      const data = await nameResponse.json();
      const advertId = data.advertId;
      if (advertId !== null) {
        const formData = new FormData();
        formData.append("advert", selectedFile);

        const imageResponse = await fetch(
          `${import.meta.env.VITE_BASE_URL}/admin/adverts-image/${advertId}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          },
        );
        if (imageResponse?.ok && advertId !== null) {
          setFileError(null);
          setLoading(false);
          dispatch(setRefreshAd(true));
          setSuccess(true);
        } else {
          setFileError("Failed to upload image");
          setLoading(false);
        }
      } else {
        console.log("Error in adding advert");
        setLoading(false);
      }
    } catch (error) {
      setFileError("Error in adding advert");
      setLoading(false);
      console.log(error);
    }
  };

  const handleNameChange = (event) => {
    setAdvertName(event.target.value);
    setSelectedAdvert({ ...selectedAdvert, name: event.target.value });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["image/jpeg", "image/png", "image/svg+xml"];

    if (file && allowedFormats.includes(file.type)) {
      setSelectedFile(file);
      setFileName(file.name);
      setFileError("");
    } else {
      setSelectedFile(null);
      setFileName("");
      setFileError("Invalid file format. Please select a JPG, JPEG, PNG, or SVG file.");
    }
  };

  const handleClose = () => {
    dispatch(setAddNewAdvert(false));
    setSuccess(null);
  };
  return (
    <>
      {!success && (
        <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
          <div
            className="absolute right-10 top-2 text-2xl hover:cursor-pointer"
            onClick={handleClose}
          >
            X
          </div>
          <div className="flex flex-col gap-y-3 px-8">
            <span className={`py-2 text-start text-xl font-semibold text-secondary`}>
              Add New Advert
            </span>
            <div className="flex flex-col">
              <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
                Name
              </label>
              <input
                type="text"
                name="advert_name"
                id="advert_name"
                placeholder="Name the advert"
                value={advertName}
                onChange={handleNameChange}
                className="w-full border border-[#AEAEAE] px-2 py-4 outline-none"
              />
              {nameError !== "" && (
                <span className="text-start text-sm text-red-500">{nameError}</span>
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <span className="text-base font-normal text-[#181818]">Image</span>
              <div>
                <label
                  htmlFor="addNew"
                  className="flex flex-col items-center justify-center gap-y-2 text-lightblue transition-all ease-in hover:scale-95 hover:cursor-pointer"
                >
                  <img src={Upload} alt="addNew" />
                  <span>Upload Image</span>
                  <span>{fileName}</span>
                </label>
                <input
                  type="file"
                  name="addNew"
                  id="addNew"
                  className="hidden"
                  accept=".jpg,.jpeg,.png,.svg,.png"
                  onChange={handleFileChange}
                />
              </div>
              {fileError && <span className="text-center text-sm text-red-500">{fileError}</span>}
            </div>
            <div className="mt-10 w-full">
              <Button className="flex w-full items-center justify-center" onClick={postAdvert}>
                {loading ? <img src={Spinner} alt="loading" className="w-[8%]" /> : "Add Advert"}
              </Button>
            </div>
          </div>
        </div>
      )}
      {success && (
        <SuccessModal
          title={"Advert Added Successfully"}
          buttonText={"OK"}
          onClick={handleClose}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default AddNewAdvert;

import React from "react";
import { allNotifications } from "../../../../constants";
import { RoundedBell } from "../../../../constants/images";

const MainContent = () => {
  return (
    <div className="w-full bg-primary shadow-lg">
      <div className="flex  w-full flex-col gap-y-4 py-5">
        {allNotifications.map((value) => (
          <div className="flex  w-full flex-col gap-y-6">
            <span className="px-10 text-xl font-semibold text-secondary">
              {value.day}
            </span>
            <hr color="#aEAEAE" className="w-full" />
            {value.notification.map((notification) => (
              <div>
                <div
                  className="flex w-full items-center justify-between px-16"
                  key={notification.id}
                >
                  <div className="flex w-full items-center gap-x-3">
                    {notification.read && (
                      <div className="h-[8px] w-[8px] rounded-[50%] bg-lightblue" />
                    )}
                    <img src={RoundedBell} alt={notification.title} />
                    <div className="flex w-full flex-col gap-y-2">
                      <span className="text-base font-semibold text-[#181818]">
                        {notification.title}
                      </span>
                      <span className="w-3/4 text-base font-normal text-[#181818]">
                        {notification.content}
                      </span>
                    </div>
                  </div>
                  <div className="w-[100px] justify-end text-sm font-normal text-lightgray">
                    {notification.time}
                  </div>
                </div>
                <hr color="#aEAEAE" className="my-3 w-full" />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainContent;

import "./index.css";
import React, { useState, useEffect } from "react";
import {
  searchicon,
  linkarrow,
  nothingFound,
} from "../../../../../../constants/images";
import { Button, Input } from "../../../../../../components/ui";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { categoryList, availableJobs, stars } from "../../../../data";
import AvailableJob from "../../../../_components/availabe_job";
import { useSelector } from "react-redux";
import JobDetailsDesktop from "../job-details/desktop";
import JobDetailsMobile from "../job-details/mobile";
import ApplicationDetailsDesktop from "../job-details/_components/application-details/desktop";
import ApplicationDetailsMobile from "../job-details/_components/application-details/mobile";
import SubNavbar from "../sub-navbar";
// import Ratings from "../../../../../services/desktop-view/_components/sidebar/_components/ratings";

const SearchContent = () => {
  const searchValue = useSelector((state) => state?.search.vendorSearch);
  const overlayValue = useSelector(
    (state) => state?.overlay.displayStates.overlay,
  );
  const showJob = useSelector((state) => state?.overlay.displayStates.showjob);
  const showForm = useSelector(
    (state) => state?.overlay.displayStates.showApplyForm,
  );
  const showApplication = useSelector(
    (state) => state?.overlay.displayStates.showApplication,
  );

  const [allJobs, setAllJobs] = useState([]);
  const [overlay, setOverlay] = useState();
  const [showCategory, setShowCategory] = useState(false);
  const [isChecked, setIsChecked] = useState(" ");
  const [seledtedRatings, setSelectedRatings] = useState(null);
  const [rangeValues, setRangeValues] = useState([]);
  const [location, setLocation] = useState("");
  const [min, setMin] = useState(2000);
  const [max, setMax] = useState(50000);
  const [seeAllJobs, setSeeAllJobs] = useState(6);
  const [showFilter, setShowFilter] = useState();
  const [showApplied, setShowApplied] = useState(false);
  const [activeFilter, setActiveFilter] = useState();
  const [mobileDetails, setMobileDetails] = useState(false);
  const handleClick = (filter) => {
    setShowFilter(filter);
    setAllJobs(availableJobs);
    setIsChecked("");
    setRangeValues("");
    setLocation("");
    setMax(50000);
    setMin(2000);
  };
  useEffect(() => {
    if (showJob && screen.width <= 500) {
      setMobileDetails(true);
    } else {
      setMobileDetails(false);
    }
  }, [screen.width, showJob]);
  const handleOverlay = (value) => {
    setOverlay(!value);
  };
  useEffect(() => {
    if (activeFilter === "Applied") {
      setShowApplied(true);
    }
    if (activeFilter === "Best matches") {
    }
    if (activeFilter === "All") {
    }
    if (activeFilter === "Latest") {
    }
    if (activeFilter === "Saved") {
    }
  }, [activeFilter]);
  const handleSendFilter = (active) => {
    setActiveFilter(active);
  };

  useEffect(() => {
    setAllJobs(availableJobs);
  }, []);

  // useEffect(() => {
  //   const categoryFilter = allJobs
  //     .filter((job) => job?.applied !== undefined) // Filter jobs based on application
  //
  //   setAllJobs(categoryFilter);
  // }, [showApplied]);

  useEffect(() => {
    if (isChecked) {
      const categoryFilter = allJobs.filter((job) =>
        job.title.includes(isChecked),
      ); // Filter jobs based on searchValue

      setAllJobs(categoryFilter);
    }
  }, [isChecked]);

  useEffect(() => {
    if (location) {
      const locationFilter = allJobs.filter((job) =>
        job.status.includes(location),
      ); // Filter jobs based on searchValue

      setAllJobs(locationFilter);
    }
  }, [location]);

  useEffect(() => {
    if (availableJobs) {
      const priceFilter = allJobs.filter(
        (job) => job?.range >= min && job?.range <= max,
      ); // Filter jobs based on searchValue

      setAllJobs(priceFilter);
    }
  }, [min, max]);

  useEffect(() => {
    if (rangeValues.length !== 0) {
      setMin(rangeValues[0]);
      setMax(rangeValues[1]);
    }
  }, [rangeValues]);

  const handleSeeAllJobs = () => {
    const arrayLength = allJobs.length;
    if (seeAllJobs < arrayLength) {
      setSeeAllJobs(seeAllJobs + 3);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const capitalizedsearchValue = capitalizeFirstLetter(searchValue);

  const handleCategories = () => {
    setShowCategory(!showCategory);
  };

  useEffect(() => {
    if (availableJobs) {
      const filteredJobs = availableJobs.filter(
        (job) =>
          job.description.includes(capitalizedsearchValue) ||
          job.title.includes(capitalizedsearchValue),
      ); // Filter jobs based on searchValue
      // Use slice instead of splice
      setAllJobs(filteredJobs);
    }
  }, [searchValue]);

  return (
    <div className="flex h-full w-full max-w-full items-start justify-center gap-8 py-10 pl-0 md:float-right md:mx-auto md:ml-auto md:mr-4 md:w-[80%] md:pr-10 md:pt-20">
      {showFilter && (
        <div className="hidden w-[35%] animate-slide_up items-center justify-center pt-16 transition-all ease-in md:flex md:flex-col md:pl-4">
          <div className="w-full rounded-[5px] border-[0.5px] border-[#AEAEAE] px-5 py-4">
            <div className="flex items-center justify-between">
              <span className="text-[16px] font-[500] text-[#5F5F5F]">
                Category
              </span>
              <img src={linkarrow} alt="" className="cursor-pointer" />
            </div>
            <div className="mt-6 flex flex-col">
              <div
                className="relative flex cursor-pointer items-center justify-between"
                onClick={handleCategories}
              >
                <span className="w-full  rounded-[5px] border  border-gray-200 bg-primary p-2 text-sm text-lightgray outline-none md:text-base">
                  Select categories
                </span>
                <img
                  src={linkarrow}
                  alt="categories"
                  className={`absolute right-4  ${showCategory && "ease rotate-90 transition-all"}`}
                />
              </div>

              {showCategory && (
                <div className="animate-slide_up rounded-[4px] bg-white px-4 py-5 drop-shadow-xl">
                  <form action="">
                    <div className="relative flex">
                      <Input
                        type="search"
                        className="min-w-[100%] rounded-[5px] px-7 py-[4px]"
                        placeholder="Search for category"
                      />{" "}
                      <img
                        src={searchicon}
                        alt=""
                        className="absolute bottom-2 z-10 w-[2rem] px-2"
                      />
                    </div>
                  </form>
                  <div className="mt-3  h-[10rem] overflow-y-auto bg-white ">
                    <div className="flex cursor-pointer flex-col gap-3 text-[14px] font-normal text-[#5F5F5F]">
                      {categoryList.map((category) => (
                        <label htmlFor="" key={category.id}>
                          <Input
                            type="checkbox"
                            value={category.title}
                            id={category.catId}
                            name={category.catId}
                            className="mr-2"
                            checked={isChecked === category.title}
                            onChange={(e) => setIsChecked(e.target.value)}
                          />
                          {category.title}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full pt-10">
            <div className="space-y-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-5">
              <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
                <span>Location</span>
                <img src={linkarrow} alt="next" className="cursor-pointer" />
              </div>
              <div className=" flex w-full flex-col gap-2">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="location"
                    value="Physical"
                    id="onsite"
                    onChange={(e) => setLocation(e.target.value)}
                    checked={location === "Physical"}
                    className="onsite"
                  />
                  <label
                    htmlFor="onsite"
                    className="text-base font-normal text-lightgray"
                  >
                    On site
                  </label>
                </div>

                <input
                  type="text"
                  placeholder="Type your location"
                  className="border-[0.5px] border-[#AEAEAE] px-[15px] py-[10px] outline-0"
                />
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  value="Remote"
                  name="location"
                  id="remote"
                  checked={location === "Remote"}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label
                  htmlFor="remote"
                  className="text-base font-normal text-lightgray"
                >
                  Remote
                </label>
              </div>
            </div>
          </div>
          <div className="w-full pt-10">
            <div className="space-y-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-5">
              <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
                <span>Price range</span>
                <img src={linkarrow} alt="next" className="cursor-pointer" />
              </div>
              <div className="flex w-full items-center justify-between">
                <span className="text-xs font-normal text-lightgray">
                  &lt; ₦{min}
                </span>
                <span className="text-xs font-normal text-lightgray">
                  ₦{max}
                </span>
              </div>
              <RangeSlider
                min={2000}
                max={50000}
                step={500}
                onInput={(e) => setRangeValues(e)}
              />
            </div>
          </div>
          {/* <div className="w-full pt-10">
      <div className="gap-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-3">
        <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
          <span>Rating</span>
          <img src={linkarrow} alt="next" className="cursor-pointer" />
        </div>
        {stars.map((star) => {
          return (
            <div className="flex flex-col items-start" key={star.id}>
              <Ratings
                star={star}
                value={star.value}
                isSelected={seledtedRatings === star.value}
                onCheckboxChange={(e) =>
                  setSelectedRatings(+e.target.value)
                }
              />
            </div>
          );
        })}
      </div>
    </div> */}
        </div>
      )}
      <div className="relative flex w-full flex-col items-center pt-6">
        {overlayValue && (
          <div className="fixed top-0 z-[50] block h-[160vh] w-full bg-black bg-opacity-25 md:hidden" />
        )}
        {showJob && !showForm && (
          <div className="fixed right-0 top-0 z-[20] ml-auto hidden h-[100%] w-[81%] bg-black bg-opacity-50 md:block" />
        )}
        {showForm && (
          <div className="fixed right-0 top-0 z-[30] ml-auto hidden h-[100%] w-full bg-black bg-opacity-50 md:block" />
        )}
        {showJob && (
          <span className="hidden md:block">
            <JobDetailsDesktop />
          </span>
        )}
        {showJob && (
          <span className="block md:hidden">
            <JobDetailsMobile />
          </span>
        )}
        <div
          className={`w-full ${mobileDetails && showJob ? "hidden" : "block"} ${showApplication ? "hidden" : "block"}`}
        >
          <SubNavbar
            handleSendClick={handleClick}
            handleSendFilter={handleSendFilter}
            sendOverlay={handleOverlay}
          />
        </div>
        <div className={`${showApplication ? "block" : "hidden"}`}>
        <div className={` w-full hidden md:block`}>
          <ApplicationDetailsDesktop />
        </div>
        <div className={`md:hidden block w-full `}>
          <ApplicationDetailsMobile />
        </div>
        </div>
        
        <div
          className={`w-full px-8 py-8 ${mobileDetails && showJob ? "hidden" : "block"} ${showApplication ? "hidden" : "block"}`}
        >
          <div
            className={`grid grid-cols-1 gap-8 md:w-full ${showFilter ? "w-full md:grid-cols-2 lg:grid-cols-2" : "md:grid-cols-2 lg:grid-cols-3 "}`}
          >
            {allJobs.slice(0, seeAllJobs).map((vendor) => (
              <AvailableJob
                key={vendor.id}
                {...vendor}
                // activeFilter={activeFilter?.applied}
              />
            ))}
          </div>
        </div>
        {(availableJobs.length <= seeAllJobs && allJobs.length !== 0) ||
        allJobs.length === 0 ? (
          <div className="mx-auto flex w-full flex-col items-center justify-center gap-4">
            <img src={nothingFound} alt="" />
            <h3 className=" text-[25px] font-semibold text-[#002D3A]">
              Match not found
            </h3>
            <p className="text-center text-[18px] font-normal md:w-[461px]">
              Sorry, the keyword you entered cannot be found, please check again
              or search with another keyword
            </p>
          </div>
        ) : (
          <div
            className={`flex w-full items-center justify-center px-8 py-8 ${mobileDetails && showJob ? "hidden" : "block"} ${showApplication ? "hidden" : "block"}`}
          >
            <Button
              children="Load more jobs"
              backgroundColor="transparent"
              textColor="#0096C1"
              className="w-fit !border !border-[#0096C1]"
              onClick={handleSeeAllJobs}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchContent;

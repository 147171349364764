import React, { useEffect, useState } from "react";
import { Pagination } from "../../../../../../../components";
import TransactionsNavbar from "../transactions-navbar";
// import { transactionData } from "../../../../../../../constants";
import { Dropdown } from "../../../../../../../constants/images";
import { useSelector } from "react-redux";
const entries = [
  { id: 0, entry: 10 },
  { id: 0, entry: 20 },
  { id: 0, entry: 30 },
];
const TransactionTable = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const transaction = useSelector((state) => state.modalOverlay.states.selectedTransaction);
  const transactionSearch = useSelector((state) => state.modalOverlay.states.transactionSearch);
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const transactionDataFiltered =
    transaction && transactionData.length > 0
      ? transactionData.filter((value) => value?.method === transaction)
      : transactionData.filter((value) => {
          const searchTerm = transactionSearch?.toLowerCase();
          const date = value?.transaction_date?.toLowerCase();
          const title = value?.service?.toLowerCase();
          const transationId = value?.transaction_id?.toLowerCase();
          const consumer = value?.consumer_name?.toLowerCase();
          const vendor = value?.vendor_name?.toLowerCase();
          const amount = String(value?.amount)?.toLowerCase();
          return (
            date?.includes(searchTerm) ||
            transationId?.includes(searchTerm) ||
            title?.includes(searchTerm) ||
            consumer?.includes(searchTerm) ||
            vendor?.includes(searchTerm) ||
            amount?.includes(searchTerm)
          );
        });

  const [openSelect, setOpenSelect] = useState(false);

  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/transactions`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setTransactionData(data);
      } catch (error) {
        console.log("Error fecthing data", error);
      }
    };
    fetchData();
  }, []);

  // Pagination logic
  const itemsPerPage = selectedEntry;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = transactionDataFiltered.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(transactionDataFiltered.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className="mx-auto flex w-full flex-col rounded-[10px] bg-primary py-4">
      <div className="w-full">
        <TransactionsNavbar />
        <div className="grid w-full grid-cols-8 bg-[#E8E8E8] px-6 py-4 text-sm font-medium text-[#181818]">
          <span>Transaction ID</span>
          <span>Project ID</span>
          <span>Service Name</span>
          <span>Consumer</span>
          <span>Vendor</span>
          <span>Amount</span>
          <span>Commission</span>
          <span>Date</span>
        </div>

        {currentData.map((value) => (
          <div key={value.id}>
            <div className="grid w-full grid-cols-8 px-6 py-4">
              <span className="">{value.transaction_id}</span>
              <span className="">{value.project_id}</span>
              <span className="">{value.service_name}</span>
              <span className="">{value.consumer_name}</span>
              <span className="">{value.vendor_name}</span>
              <span className="">{value.amount}</span>
              <span className="">{value.commission}</span>
              <span className="">{value.transaction_date.split(0, 5)}</span>
            </div>
            <hr color="#AEAEAE" className="w-full" />
          </div>
        ))}
      </div>

      {transactionDataFiltered.length === 0 && (
        <div className="flex w-full items-center justify-center pt-4 text-xl text-secondary">
          No search results available for query
        </div>
      )}
      <div className="flex w-full justify-between px-6 pt-3">
        <div className="flex w-full">
          <div className="relative flex items-center gap-x-1">
            <span>Showing</span>
            <div
              onClick={handleSelect}
              className="flex w-fit items-center gap-x-4 px-5 text-base font-normal text-[#181818] hover:cursor-pointer"
            >
              <span>{selectedEntry}</span>
              <img
                src={Dropdown}
                alt="open"
                className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
              />
            </div>
            {openSelect && (
              <div className="absolute bottom-[100%] left-[40%] w-[30%] animate-slide_up  rounded-[10px] bg-primary px-3 py-2">
                <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                  {entries.map((entry) => (
                    <span
                      key={entry.id}
                      className="hover:cursor-pointer"
                      onClick={() => setSelectedEntry(entry.entry)}
                    >
                      {entry.entry}
                    </span>
                  ))}
                </div>
              </div>
            )}
            <span>entries</span>
          </div>
        </div>
        <div>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
        </div>
      </div>
    </div>
  );
};

export default TransactionTable;

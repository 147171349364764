import React from 'react';


const GivePartialToVendorAndConsumer = ({ handlePartialChange, percentageToConsumer, percentageToVendor }) => {
  return (
    <div className="mt-2 border-t-[#AEAEAE] border-t-[1px] pt-5">
      <form action="" className="flex flex-row items-center gap-4">
        <div className="flex items-center gap-2">
          <label
            htmlFor="consumer"
            className="text-[18px] font-normal"
          >
            To Consumer :
          </label>
          <input
            id="consumer"
            type="number"
            name="percentageToConsumer"
            value={percentageToConsumer}
            className="outline-none border-[#AEAEAE] border-[1px] p-1 w-10 appearance-none"
            onChange={handlePartialChange}
          />
          %
        </div>

        <div className="flex items-center gap-2">
          <label
            htmlFor="vendor"
            className="text-[18px] font-normal"
          >
            To Vendor :
          </label>
          <input
            id="vendor"
            type="number"
            name="percentageToVendor"
            value={percentageToVendor}
            className="outline-none border-[#AEAEAE] border-[1px] p-1 w-10 appearance-none"
            onChange={handlePartialChange}
          />
          %
        </div>
      </form>
    </div>
  );
};

export default GivePartialToVendorAndConsumer;



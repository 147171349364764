import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { vendorsMobile } from "../../data";
import { mobileBg } from "../../../../constants/images";
import { Button } from "../../../../components/ui";
import Service from "./service";
import Description from "./description";
import Catalog from "./catlog";
import Reviews from "./reviews";
const MobileView = ({}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { id } = useParams();
  const res = vendorsMobile[id];
  return (
    <div className="md:hidden">
      <img src={mobileBg} alt="" className="mt-14 w-full" />
      <div className="">
        <img
          src={res.vendor}
          alt=""
          className="absolute right-40 top-60 w-24"
        />
        <p className="tetx-[18px] mt-16 text-center font-semibold text-[#002D3A]">
          {res.name}
        </p>
        <p className="text-center text-[16px] font-semibold text-[#5F5F5F]">
          {res.service}
        </p>
        <div className="mt-2 flex items-center justify-center gap-1 text-xs">
          <img src="/rate.png" alt="" />
          <span className="font-semibold">{res.rating}</span>
          <span className="font-normal">{res.rating_count}</span>
        </div>
        <div className="mx-auto mt-5 flex w-[80%] gap-5 ">
          <div className="flex h-16 w-full flex-col items-center justify-center bg-white p-3 drop-shadow-lg">
            <h4 className="text-[20px] font-semibold text-[#0096C1]">
              {res.completedJobs}
            </h4>
            <p className="text-center text-[12px] font-normal leading-[14.08px] text-[#002D3A]">
              Completed jobs
            </p>
          </div>
          <div className="flex h-16 w-full flex-col items-center justify-center bg-white p-3 drop-shadow-lg">
            <h4 className="text-[20px] font-semibold text-[#0096C1]">
              {res.ongoingingJobs}
            </h4>
            <p className="text-center text-[12px] font-normal leading-[14.08px] text-[#002D3A]">
              Ongoing jobs
            </p>
          </div>
          <div className="flex h-16 w-full flex-col items-center justify-center bg-white p-3 drop-shadow-lg">
            <h4 className="text-[20px] font-semibold text-[#0096C1]">
              {res.cancelledJobs}
            </h4>
            <p className="text-center text-[12px] font-normal leading-[14.08px] text-[#002D3A]">
              Cancelled jobs
            </p>
          </div>
        </div>
        <div className=" mx-auto mt-6 flex w-[80%] items-center justify-between border-y-[1px] border-[#AEAEAE]">
          <div className="flex flex-col">
            <div
              className={`${activeIndex === 0 && "border-b-4 border-[#0096C1] "}cursor-pointer text-[14px] font-semibold text-[#5F5F5F]`}
              onClick={() => setActiveIndex(0)}
            >
              Service
            </div>
          </div>
          <div className="">
            <div
              className={`${activeIndex === 1 && "border-b-4 border-[#0096C1] "}cursor-pointer text-[14px] font-semibold text-[#5F5F5F]`}
              onClick={() => setActiveIndex(1)}
            >
              Description
            </div>
          </div>
          <div className="">
            <div
              className={`${activeIndex === 2 && "border-b-4 border-[#0096C1] "}cursor-pointer text-[14px] font-semibold text-[#5F5F5F]`}
              onClick={() => setActiveIndex(2)}
            >
              Catalog
            </div>
          </div>
          <div className="">
            <div
              className={`${activeIndex === 3 && "border-b-4 border-[#0096C1] "}cursor-pointer text-[14px] font-semibold text-[#5F5F5F]`}
              onClick={() => setActiveIndex(3)}
            >
              Reviews
            </div>
          </div>
        </div>
        {activeIndex === 0 && <Service {...res} />}
        {activeIndex === 1 && <Description {...res} />}
        {activeIndex === 2 && <Catalog {...res} />}
        {activeIndex === 3 && <Reviews {...res} />}
        <div className="mx-auto mt-2 flex w-[80%] gap-2 mb-28 ">
          <Button
            children="Request service"
            className="mt-3 w-full drop-shadow-xl"
            border="none"
            backgroundColor="white"
            textColor="#0096C1"
          />
          <Button children="Message" className="mt-3 w-full" border="none" />
        </div>
      </div>
    </div>
  );
};

export default MobileView;

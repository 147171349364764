import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobDetails } from "../../../../../data";
import {
  setJobDetails,
  setShowApplyForm,
} from "../../../../../../../features/overlay";
import { Button } from "../../../../../../../components/ui";
import { left, loveoutline, star } from "../../../../../../../constants/images";
import MobileForm from "../_components/form/mobile";
import SuccessModal from "../_components/success-modal/mobile";

const JobDetailsMobile = () => {
  const dispatch = useDispatch();
  const [showReviews, setShowReviews] = useState(false);
  const [allReviews, setReviews] = useState();
  const [showMore, setShowMore] = useState(4);
  const [successful, setSuccessful] = useState(false);
  const JobId = useSelector((state) => state?.overlay.displayStates.jobId);
  const showForm = useSelector(
    (state) => state?.overlay.displayStates.showApplyForm,
  );

  const handleReviews = () => {
    setShowReviews(!showReviews);
  };
  const sendSuccessful = (value) => {
    dispatch(setShowApplyForm(true));
    setSuccessful(value);
  };
  useEffect(() => {
    const reviews = jobDetails
      .filter((value) => value.id === JobId)
      .map((value) => value.reviews.length);
    setReviews(reviews);
  }, []);

  const handleMoreReviews = () => {
    const lenthOfArray = allReviews;
    if (showMore < lenthOfArray) {
      setShowMore(lenthOfArray);
    }
  };
  const handleApply = () => {
    // dispatch(setJobDetails(false));
    dispatch(setShowApplyForm(true));
  };
  const handleJobDetails = () => {
    dispatch(setJobDetails(false));
  };
  return (
    <div className="relative w-full">
      {showForm && (
        <div className="z-[999] block overflow-hidden md:hidden">
          {!successful ? (
            <MobileForm sendSuccessful={sendSuccessful} />
          ) : (
            <SuccessModal />
          )}
        </div>
      )}

      {showForm && successful && (
        <div className="fixed right-0 top-0 z-[30] ml-auto block h-[100%] w-full bg-black bg-opacity-50" />
      )}
      <div
        className={`z-[50] flex h-full w-full flex-col pb-10 bg-primary px-4 ${showForm && "hidden"}`}
      >
        <div className="relative flex items-center justify-center pb-3 text-[20px] font-semibold text-secondary">
          <div
            className="absolute bottom-[45%] left-[2%]  block md:hidden"
            onClick={handleJobDetails}
          >
            <img src={left} alt="back" className="w-[9.78px] rotate-180" />
          </div>
          <span>Job Details</span>
        </div>
        <div className="flex flex-col">
          {jobDetails
            .filter((job) => job.id === JobId)
            .map((value) => (
              <div className="flex flex-col gap-2">
                <span className="text-lg font-semibold text-secondary">
                  {value.title}
                </span>
                <span className="text-xs font-normal text-lightgray">
                  Posted {value.post_duration} mins ago
                </span>
                <hr color="#AEAEAE" className="w-full" />
                <div className="flex flex-col py-2">
                  <span className="text-xs font-normal text-lightgray">
                    Description
                  </span>
                  <span className="text-sm text-[#181818]">
                    {value.description}
                  </span>
                </div>
                <hr color="#AEAEAE" className="w-full" />
                <div className="py-2">
                  <span className="text-xs font-normal text-lightgray">
                    Job requirements
                  </span>
                  {value.requirements.map((requirement) => (
                    <div className="flex gap-1 text-[#181818]">
                      <span>{requirement.id + 1}.</span>
                      <span>{requirement.content}</span>
                    </div>
                  ))}
                </div>
                <hr color="#AEAEAE" className="w-full" />
                <div className="flex flex-col justify-between gap-3 py-2">
                  <div className="flex flex-col py-2">
                    <span className="py-1 text-xs font-normal text-lightgray">
                      Date
                    </span>
                    <div className="flex items-center gap-1">
                      {" "}
                      <span className="text-xs">From: </span>{" "}
                      <span className="text-sm font-medium">
                        {value.start_date}
                      </span>
                    </div>
                    <div className="flex items-center gap-1">
                      {" "}
                      <span className="text-xs">To: </span>{" "}
                      <span className="text-sm font-medium">
                        {value.closing_date}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs font-normal text-lightgray">
                      Location
                    </span>
                    <span className="text-sm font-medium">
                      {value.location}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs font-normal text-lightgray">
                      Budget
                    </span>
                    <span className="text-sm font-medium">{value.budget}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="flex w-full flex-col items-start py-2">
          <hr color="#AEAEAE" className="w-[100%] !px-0" />
          {jobDetails
            .filter((job) => job.id === JobId)
            .map((value) => {
              return (
                <div className="w-full py-2">
                  <span className="text-sm font-semibold text-secondary">
                    About the consumer
                  </span>
                  <div className="flex items-center gap-2 py-2">
                    <img src={value.image} alt="image" className="w-[50px]" />
                    <div className="flex flex-col">
                      <span className="text-base font-semibold text-secondary">
                        {value.name}
                      </span>
                      <div className="flex items-center gap-2">
                        <img src={star} alt="star" />
                        <span className="text-xs font-semibold">
                          {value.rating}
                        </span>
                        <span className="text-xs font-normal">
                          ({value.count})
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr color="#AEAEAE" className="w-[100%] !px-0" />
                  <div className="flex w-full flex-col items-start pt-4">
                    <div className="flex w-full items-center justify-between">
                      <span className="text-sm font-semibold text-secondary">
                        View reviews
                      </span>
                      <img
                        src={left}
                        className={`transition-all ease-in hover:cursor-pointer ${showReviews && "rotate-90"}`}
                        alt="open"
                        onClick={handleReviews}
                      />
                    </div>

                    {showReviews && (
                      <div className="flex animate-slide_up flex-col gap-5 pt-10">
                        {value.reviews.slice(0, showMore).map((reviews) => (
                          <div className="flex-col gap-1">
                            <div className="flex">
                              <span className="text-base font-medium text-[#181818]">
                                {reviews.title}
                              </span>
                            </div>
                            <span className="text-sm font-normal text-lightgray">
                              {reviews.date}
                            </span>
                            <div className="flex items-center gap-2">
                              <img src={star} alt="star" />
                              <span className="text-sm font-semibold">
                                {reviews.rating}
                              </span>
                              <span className="text-sm font-normal text-lightgray">
                                By Vendor:
                              </span>
                              <span className="text-sm font-normal text-lightgray">
                                {value.name}
                              </span>
                            </div>
                            <span className="text-sm font-normal text-[#181818]">
                              {reviews.content}
                            </span>
                          </div>
                        ))}
                        <span
                          className="text-xs text-lightblue underline underline-offset-4"
                          onClick={handleMoreReviews}
                        >
                          See more reviews
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="flex w-full items-center justify-center gap-4 pb-8">
          <Button
            backgroundColor="transparent"
            textColor="#0096C1"
            className="relative"
            width={160}
          >
            <img
              src={loveoutline}
              alt="like"
              className="absolute right-[6rem] top-[0.5rem]"
            />
            Save
          </Button>
          <Button width={160} onClick={handleApply}>
            Apply now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsMobile;

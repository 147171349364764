import React, { useState } from "react";
import Navbar from "../../_components/ui/navbar";
import SideBar from "../../_components/ui/sidebar";
import MainContent from "./_components/main-content";


const VendorDashboardJobs = () => {
  const [showFilter, setShowFilter] = useState();

  const handleClick = (filter) => {
    setShowFilter(filter);
  };
  return (
    <div>
      <Navbar />
      <SideBar />
      <MainContent showFilter={showFilter} />
      
    </div>
  );
};

export default VendorDashboardJobs;

import React from 'react'
import { chatsend, record } from '../../../../../../constants/images'

const ChatInput = () => {
  return (
    <div className='fixed pr-10 py-6 bg-primary md:rounded-tr-[20px] z-20 md:rounded-tl-[20px] bottom-0 w-full'>
    <div className="flex relative items-center space-x-3 px-10">
        <span className='border border-[#AEAEAE] text-3xl font-extralight px-2 h-[34px] cursor-pointer flex items-center'>+</span>
        <input type="text" className='md:w-1/2 py-2 px-5 outline-none border border-[#AEAEAE] h-[34px]' placeholder='Type something...' />
        <img src={chatsend} alt="send" className='bg-lightblue py-2 px-2 h-[34px]' />
        <img src={record} alt="record" className='absolute right-[15%]' />
    </div>
    </div>
  )
}

export default ChatInput
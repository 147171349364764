import React, { useState } from "react";

const StepSixCard = ({ text, image,bgcolor }) => {

  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <div className="">
      <div className={`flex h-[150px] w-full flex-col justify-center gap-y-3 border-[1px] border-[#AEAEAE] rounded-[7px] p-7 cursor-pointer`}
      style={{ backgroundColor: bgcolor }}>
        <div className="flex items-end justify-end">
          <input type="radio" id="click" className="h-4 w-4 bg-inherit" />
        </div>
        <div className="flex flex-col justify-center items-center">
        <div className="mx-auto">
          <img src={image} alt="" />
        </div>
        <span className="w-[130px] text-center text-[18px] font-semibold leading-[21.11px] text-[#002D3A]">
          {text}
        </span>
        </div>
      </div>
    </div>
  );
};

export default StepSixCard;

import React, { useState } from 'react'
import { Button, Input } from '../../../../../../../components/ui'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const StepFive = ({ next }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    firstname: "",
    lastname: "",
    password: "",
    confirmpassword: "",
    phone: "",
    location: "",
    gender: "",
  });
  const [errorState, setErrorState] = useState({
    fnameError: "",
    lnameError: "",
    passwordError: "",
    cpasswordError: "",
    phoneError: "",
    locationError: "",
    genderError: "",
    termsError: "",
  });
  const [check, setChecked] = useState(false);

  const validate = () => {
    let isError = false;
    const errors = {
      fnameError: "",
      lnameError: "",
      passwordError: "",
      cpasswordError: "",
      phoneError: "",
      locationError: "",
      genderError: "",
      checkboxError: "",
    };
    if (!details.firstname) {
      isError = true;
      errors.fnameError = "Please enter your first name";
    }
    if (!details.lastname) {
      isError = true;
      errors.lnameError = "Please enter your last name";
    }
    if (!details.password) {
      isError = true;
      errors.passwordError = "Please enter a valid password";
    }
    if (details.confirmpassword !== details.password) {
      isError = true;
      errors.cpasswordError = "Passwords do not match";
    }
    if (!details.phone) {
      isError = true;
      errors.phoneError = "Please enter your phone number";
    }
    if (!details.location) {
      isError = true;
      errors.locationError = "Please enter your location";
    }

    if (!details.gender) {
      isError = true;
      errors.genderError = "Please select a gender";
    }
    if (!check) {
      isError = true;
      errors.checkboxError =
        "Please agree to the terms and conditions and policy and try again";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleCheckbox = (e) => {
    setChecked(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validate();

    if (!error) {
      // dispatch(formdata({ personal_details: details }));
      next();
    }
  };
  const selectArrow = `
      select{
        -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
      
        background-position: calc(100% - .5rem);
        background-image: url(/dropdown.png);
        background-repeat: no-repeat;     
      }`;

  return (
    <div className="left-0 right-0 top-[6rem] overflow-hidden py-10 md:absolute md:px-20 md:py-0 lg:px-0">
      <div className=" w-full md:mx-auto lg:w-1/2">
        <div className="flex flex-col justify-center rounded-[5px] bg-primary p-7 md:px-10 md:pt-10">
          <span className="mb-8 text-[25px] font-semibold text-secondary md:text-center md:text-[30px]">
            Set up your account
          </span>
          <span className="text-lg font-semibold text-secondary md:text-center md:text-[20px]">
            Personal information
          </span>
          <form className="flex flex-col gap-2 pt-10">
            <div className="flex min-w-full items-center justify-between gap-3 md:flex-col lg:flex-row">
              <Input
                className="w-full rounded-[5px] p-2 text-sm md:text-base lg:min-w-[48%]"
                onChange={handleForm}
                type="text"
                name="firstname"
                placeholder={"First name"}
              />
              <Input
                className="w-full rounded-[5px] p-2 text-sm md:text-base lg:min-w-[48%]"
                onChange={handleForm}
                type="text"
                name="lastname"
                placeholder={"Last name"}
              />
            </div>
            <div className="flex min-w-full items-center justify-between md:flex-col lg:flex-row">
              <span className="text-sm text-[#e62e2e]">
                {errorState.fnameError}
              </span>
              <span className="text-sm text-[#e62e2e]">
                {errorState.lnameError}
              </span>
            </div>
            <Input
              className="min-w-full rounded-[5px] p-2 text-sm md:text-base"
              onChange={handleForm}
              type="password"
              name="password"
              placeholder={"Set password"}
            />
            <span className="text-sm text-[#e62e2e]">
              {errorState.passwordError}
            </span>
            <Input
              className=" min-w-full rounded-[5px] p-2 text-sm md:text-base"
              onChange={handleForm}
              type="password"
              name="confirmpassword"
              placeholder={"Confirm password"}
            />
            <span className="text-sm text-[#e62e2e]">
              {errorState.cpasswordError}
            </span>
            <Input
              className="min-w-full rounded-[5px] p-2 text-sm md:text-base"
              onChange={handleForm}
              type="tel"
              name="phone"
              placeholder={"Phone number"}
            />
            <span className="text-sm text-[#e62e2e]">
              {errorState.phoneError}
            </span>
            <select
              className="rounded-[5px] border border-lightgray bg-primary p-2 text-sm text-lightgray outline-none md:text-base"
              onChange={handleForm}
              name="gender"
            >
              <option value="" selected disabled>
                Gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            <span className="text-sm text-[#e62e2e]">
              {errorState.genderError}
            </span>
            <Input
              className="min-w-full rounded-[5px] p-2 text-sm md:text-base"
              onChange={handleForm}
              type="text"
              name="location"
              placeholder={"Location"}
            />
            <span className="text-sm text-[#e62e2e]">
              {errorState.locationError}
            </span>
            <Input
              className="min-w-full rounded-[5px] p-2 text-sm md:text-base"
              onChange={handleForm}
              type="text"
              name="referralcode"
              placeholder={"Referral code (optional)"}
            />
            <div className="flex items-center gap-2 mt-16 md:my-4">
              <Input
                type="checkbox"
                name="terms"
                value={check}
                onChange={handleCheckbox}
              />
              <span className="md:text-base text-sm font-normal">
                I understand and agree to GYWD{" "}
                <Link to="#" className="font-medium text-lightblue">
                  Terms of service
                </Link>{" "}
                and{" "}
                <Link to="#" className="font-medium text-lightblue">
                  privacy policy
                </Link>
              </span>
            </div>
            <span className="text-sm text-[#e62e2e]">
              {errorState.checkboxError}
            </span>
            <Button
              border="none"
              type="submit"
              children="Proceed"
              className="w-full"
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
      <style>{selectArrow}</style>
    </div>
  );
};

export default StepFive;

import React from "react";
import Sidebar from "./_components/sidebar";
import ServiceLists from "./_components/service-lists";

const ServicesDesktop = () => {
  return (
    <div className="hidden justify-between gap-16 px-36 py-20 md:flex">
      <Sidebar />
      <div className="mx-auto h-[200vh] w-[1px] bg-[#AEAEAE]" />
      <ServiceLists />
    </div>
  );
};

export default ServicesDesktop;

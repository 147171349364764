import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const HomeIcon = () => {
  const location = useLocation();
  const [fill, setFill] = useState();
  const [pathname, setPathname] = useState();

  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  useEffect(() => {
    const path = pathname?.substring(pathname.lastIndexOf("/") + 1);
    if (path === "dashboard") {
      setFill("#fff");
    } else {
      setFill("#5F5F5F");
    }
  }, [pathname]);

  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 20.0001H4C3.44772 20.0001 3 19.5524 3 19.0001V10.0001H0L10.3273 0.611618C10.7087 0.264877 11.2913 0.264877 11.6727 0.611618L22 10.0001H19V19.0001C19 19.5524 18.5523 20.0001 18 20.0001ZM12 18.0001H17V8.15757L11 2.70302L5 8.15757V18.0001H10V12.0001H12V18.0001Z"
      />
    </svg>
  );
};

export default HomeIcon;

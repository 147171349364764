// all constant data

export const heroBgSlides = [
  {
    id: 0,
    background: "/home_bg.svg",
    title: "Find quality service vendors and",
    text: "Get Your Work Done",
    subtext: "...Effortlessly",
  },
  {
    id: 1,
    background: "/home_bg1.svg",
    title: "Find quality service vendors and",
    text: "Get Your Work Done",
    subtext: "...Effortlessly",
  },
];

export const Services = [
  {
    id: 0,
    title: "Repair and maintenance",
    image: "/home/homevendor1.png",
  },
  {
    id: 5,
    title: "IT and Digital Services",
    image: "/home/it.png",
  },

  {
    id: 1,
    title: "Events and entertainment",
    image: "/home/events.png",
  },
  {
    id: 2,
    title: "Beauty services",
    image: "/home/beauty.png",
  },
  {
    id: 3,
    title: " Transport and logistics",
    image: "/home/transport.png",
  },
  {
    id: 4,
    title: "Consulting services",
    image: "/home/consulting.png",
  },
];
export const Provisions = [
  {
    id: 0,
    image: "/provision0.png",
    title: "Flexible",
    text: "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 1,
    image: "/provision1.png",
    title: "Affordable",
    text: "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 2,
    image: "/provision2.png",
    title: "Safe and Secure",
    text: "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 3,
    image: "/provision3.png",
    title: "All-in-one",
    text: "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
];

export const ChooseUs = [
  {
    id: 0,
    image: "/vendor/bestfit.png",
    title: "Earn money your way",
    text: "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 1,
    image: "/vendor/bestfit1.png",
    title: "Be your own boss",
    text: "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 2,
    image: "/vendor/bestfit2.png",
    title: "Diverse customer base",
    text: "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 3,
    image: "/vendor/bestfit3.png",
    title: "Expand your business",
    text: "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
];
export const Experience = [
  {
    image: "/quality.png",
    title: "Quality assurance",
    paragraph:
      "Our rigorous vetting process ensures you connect with reliable and skilled vendors",
  },
  {
    image: "/diverse.png",
    title: "Diverse services",
    paragraph:
      "Explore a wide range of services, both online and offline, all in one place",
  },
  {
    image: "/secure.png",
    title: "Secure payment",
    paragraph:
      "Benefit from a secure payment system that safeguards your transactions",
  },
  {
    image: "/support.png",
    title: "24/7 Support",
    paragraph:
      "We are available to provide assistance, and ensure a smooth experience",
  },
];

export const FAQS = [
  {
    id: 0,
    question: "How does it work?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 1,
    question: "How many services do you offer?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 2,
    question: "Do I have to pay to sign up?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 3,
    question: "Is my money secure?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 4,
    question: "What if I don't like the service?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
];

export const FAQSVendor = [
  {
    id: 0,
    question: "What’s required to become a vendor?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 1,
    question: "How many years of experience do I need?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 2,
    question: "How do I get jobs?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 3,
    question: "How do I get paid?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 4,
    question: "Where does GYWDE operate?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 5,
    question: "What service can I offer on GYWDE?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 6,
    question: "How long does it take for my registration to be processed?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
  {
    id: 7,
    question: "What’s required to become a vendor?",
    answer:
      "Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
  },
];

export const Testimonials = [
  {
    id: 0,
    image: "/profile.png",
    name: "John Mark",
    title: "Marketing manager",
    text: "The diverse range of services on GYWD is impressive. I've used the platform for everything from graphic design to home repairs, and the quality has always exceeded my expectations. It's my go-to for getting work done efficiently and reliably.",
  },
  {
    id: 1,
    image: "/profile.png",
    name: "Salazar Andrew",
    title: "Marketing manager",
    text: "The diverse range of services on GYWD is impressive. I've used the platform for everything from graphic design to home repairs, and the quality has always exceeded my expectations. It's my go-to for getting work done efficiently and reliably.",
  },
  {
    id: 3,
    image: "/profile.png",
    name: "Jason Bourne",
    title: "Marketing manager",
    text: "The diverse range of services on GYWD is impressive. I've used the platform for everything from graphic design to home repairs, and the quality has always exceeded my expectations. It's my go-to for getting work done efficiently and reliably.",
  },
  // {
  //   id: 4,
  //   image: '/profile.png',
  //   name: 'John Mark',
  //   title: 'Marketing manager',
  //   text: "The diverse range of services on GYWD is impressive. I've used the platform for everything from graphic design to home repairs, and the quality has always exceeded my expectations. It's my go-to for getting work done efficiently and reliably."
  // },
  // {
  //   id: 5,
  //   image: '/profile.png',
  //   name: 'John Mark',
  //   title: 'Marketing manager',
  //   text: "The diverse range of services on GYWD is impressive. I've used the platform for everything from graphic design to home repairs, and the quality has always exceeded my expectations. It's my go-to for getting work done efficiently and reliably."
  // }
];

export const Vendors = [
  {
    id: 0,
    image: "/services/service-img1.svg",
    vendor: "/services/vendor1.png",
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 1,
    image: "/services/service-img.svg",
    vendor: "/services/vendor2.png",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 2,
    image: "/services/service-img2.svg",
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 3,
    image: "/services/service-img.svg",
    vendor: "/services/vendor2.png",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 4,
    image: "/services/service-img1.svg",
    vendor: "/services/vendor1.png",
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 5,
    image: "/services/service-img2.svg",
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 6,
    image: "/services/service-img1.svg",
    vendor: "/services/vendor1.png",
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 7,
    image: "/services/service-img.svg",
    vendor: "/services/vendor2.png",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 8,
    image: "/services/service-img2.svg",
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 9,
    image: "/services/service-img2.svg",
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 10,
    image: "/services/service-img.svg",
    vendor: "/services/vendor2.png",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 11,
    image: "/services/service-img1.svg",
    vendor: "/services/vendor1.png",
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 12,
    image: "/services/service-img2.svg",
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
];

export const VendorsMobile = [
  {
    id: 0,
    image: "/services/mobile-services.svg",
    name: "John Doe",
    title: "Repair and Maintenance",
    titlebg: "#FFF9D3",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 1,
    image: "/services/mobile-services1.svg",
    name: "Mary Ojo",
    title: "Events and entertainment",
    titlebg: "#FFF9D3",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 2,
    image: "/services/mobile-services2.svg",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 3,
    image: "/services/mobile-services1.svg",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 4,
    image: "/services/mobile-services.svg",
    name: "John Doe",
    title: "Repair and Maintenance",
    titlebg: "#EFFFD3",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 5,
    image: "/services/mobile-services2.svg",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 6,
    image: "/services/mobile-services.svg",
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 7,
    image: "/services/mobile-services1.svg",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 8,
    image: "/services/mobile-services2.svg",
    name: "Emeka Eze",
    title: "Events and entertainment",
    titlebg: "#FFE3D3",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
];

export const ServiceCategories = [
  {
    id: 0,
    title: "Transport and logistics",
  },
  {
    id: 1,
    title: "Fashion services",
  },
  {
    id: 2,
    title: "Automotive services",
  },
  {
    id: 3,
    title: "Consulting services",
  },
  {
    id: 4,
    title: "Education services",
  },
  {
    id: 5,
    title: "Cleaning services",
  },
  {
    id: 6,
    title: "Photography and video services",
  },
  {
    id: 7,
    title: "Pet services",
  },
  {
    id: 8,
    title: "Manufacturing services",
  },
  {
    id: 9,
    title: "Property services",
  },
  {
    id: 10,
    title: "Food services",
  },
  {
    id: 11,
    title: "Art and sports",
  },
  {
    id: 12,
    title: "Other services",
  },
];

export const AllCategories = [
  {
    id: 15,
    title: "All services",
  },
  {
    id: 0,
    title: "Transport and logistics",
  },
  {
    id: 1,
    title: "Fashion services",
  },
  {
    id: 2,
    title: "Automotive services",
  },
  {
    id: 3,
    title: "Consulting services",
  },
  {
    id: 4,
    title: "Education services",
  },
  {
    id: 5,
    title: "Cleaning services",
  },
  {
    id: 6,
    title: "Photography and video services",
  },
  {
    id: 7,
    title: "Pet services",
  },
  {
    id: 8,
    title: "Manufacturing services",
  },
  {
    id: 9,
    title: "Property services",
  },
  {
    id: 10,
    title: "Food services",
  },
  {
    id: 11,
    title: "Art and sports",
  },
  {
    id: 12,
    title: "Other services",
  },

  {
    id: 16,
    title: "Repair and maintenance",
  },
  {
    id: 17,
    title: "IT and Digital service",
  },
  {
    id: 18,
    title: "Events and entertainment",
  },
  {
    id: 19,
    title: " Beauty services",
  },
];

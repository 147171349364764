import React from 'react'
import TableHead from '../tableHead'
import TableBody from '../tableBody'

const UnresolvedDispute = ({unresolvedDisputes,setCurrentPage,currentPage,itemsPerPage}) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = unresolvedDisputes.slice(indexOfFirstItem, indexOfLastItem);
  const currentDataLength = currentData.length;
  const totalPages = Math.ceil(unresolvedDisputes.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const goToPreviousPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  const goToNextPage = () => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  return (
    <div className="">
    <table className='w-full'>
      <TableHead />

      {
        unresolvedDisputes?.map(data=>{
          const date = data.date_of_complain && data.date_of_complain;
          const ft_date = new Date(date);
          const month = months[ft_date.getMonth()];
          const year = ft_date.getFullYear();
          const day = ft_date.getDate();
          const doc = `${month} ${day} ${year}`;
          return(
        <TableBody key={data.dispute_id} {...data} date_of_complain={doc} to={`disputereview/${data.dispute_id}`} />)})
      }
      
    </table>
    <div className="mt-4 flex justify-between px-5 py-4">
            <div className="text-[18px] text-[#002D3A]">
              <span>Showing</span> <span>{currentDataLength}</span> <span>entries</span>
            </div>
            <div className="flex items-center">
              <button
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
                className={`mx-1 rounded border px-3 py-1 ${
                  currentPage === 1 ? "cursor-not-allowed bg-gray-300 text-gray-500" : "bg-white text-blue-500"
                }`}
              >
                Previous
              </button>
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`mx-1 rounded border px-3 py-1 ${
                    currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-white text-blue-500"
                  }`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
                className={`mx-1 rounded border px-3 py-1 ${
                  currentPage === totalPages ? "cursor-not-allowed bg-gray-300 text-gray-500" : "bg-white text-blue-500"
                }`}
              >
                Next
              </button>
            </div>
    </div>
    </div>
  )
}

export default UnresolvedDispute

import React from "react";
import { accessmessages } from "../../../../../../../constants/images";
import { Link } from "react-router-dom";
import { allProjects } from "../../../../../data";
import ActiveProjects from "../../../_components/active-projects";
import SubNavbar from "../../../_components/sub-navbar";
import { useSelector } from "react-redux";

const ProjectContents = () => {
  const showDetails = useSelector(
    (state) => state?.overlay.displayStates.showProjectDetails,
  );
  console.log(showDetails)
  return (
    <div className="relative h-screen w-full max-w-full px-6 py-20 overflow-auto">
      {!showDetails && <span className="pt-10 text-xl font-bold  text-secondary">Projects</span>}
      {!showDetails && allProjects.length !== 0 && <SubNavbar />}
      {allProjects.length === 0 ? (
        <div className="flex h-[70vh] flex-col items-center justify-center gap-4">
          <img src={accessmessages} alt="no projects" />
          <span className="text-[20px] font-semibold text-secondary">
            No projects
          </span>
          <span className="text-center text-lg font-normal text-[#181818]">
            Browse different jobs and get started on your next project
          </span>
          <Link 
            to="/vendor-dashboard/jobs"
            className="pt-20 text-base font-bold text-lightblue"
          >
            Browse jobs
          </Link>
        </div>
      ) : (
          <ActiveProjects  />
      )}
    </div>
  );
};

export default ProjectContents;

import React, { useState } from "react";
import { Button } from "../../../../../../components";
import { setAddNewDiscount, setDiscountDetails } from "../../../../../../redux/slice/modalOverlay";
import { useDispatch, useSelector } from "react-redux";
import { Upload } from "../../../../../../constants/images";

const AddNewDiscount = () => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const discount = useSelector((state) => state.modalOverlay.states.discountDetails);
  const [details, setDetails] = useState({
    discount_name: "",
    discount_price: "",
    usage: "",
    discount_code: "",
    validity: "",
  });
  const [errorState, setErrorState] = useState({
    discount_nameError: "",
    discount_priceError: "",
    usageError: "",
    discount_codeError: "",
    validityError: "",
  });
  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const validate = () => {
    let isError = false;
    const errors = {
      discount_nameError: "",
      discount_priceError: "",
      usageError: "",
      discount_codeError: "",
      validityError: "",
    };

    if (!details.discount_name) {
      isError = true;
      errors.discount_nameError = "Please enter a discount name";
    }
    if (!details.discount_code) {
      isError = true;
      errors.discount_codeError = "Please enter a discount code";
    }
    if (!details.discount_price) {
      isError = true;
      errors.discount_priceError = "Please enter discount price";
    }
    if (!details.usage) {
      isError = true;
      errors.usageError = "Please enter usage frequency";
    }
    if (!details.validity) {
      isError = true;
      errors.validityError = "Please enter validity";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["image/jpeg", "image/png", "image/svg+xml"];

    if (file && allowedFormats.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result;
        setSelectedFile(dataURL);
        setFileName(file.name);
        setFileError("");
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setFileName("");
      setFileError("Invalid file format. Please select a JPG, JPEG, PNG, or SVG file.");
    }
  };
  const generateUniqueId = () => {
    return Date.now().toString();
  };
  const handleAddDiscount = () => {
    const error = validate();
    const newDisount = {
      id: generateUniqueId(),
      selectedImage: selectedFile,
      details: details,
    };
    if (!selectedFile) {
      setFileError("Please select an image");
      return;
    }
    if (!error) {
      dispatch(setDiscountDetails(newDisount));
      dispatch(setAddNewDiscount(false));
    }
  };

  const handleClose = () => {
    dispatch(setAddNewDiscount(false));
  };

  return (
    <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
      <div className="absolute right-10 top-2 text-2xl hover:cursor-pointer" onClick={handleClose}>
        X
      </div>
      <div className="flex flex-col gap-y-3 px-8">
        <span className={`py-2 text-start text-xl font-semibold text-secondary`}>Add Discount</span>
        <div className="flex flex-col">
          <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
            Name
          </label>
          <input
            type="text"
            name="discount_name"
            id="discount_name"
            placeholder="Name the discount"
            onChange={handleForm}
            className="w-full rounded-[5px] border   border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
          />
          {details.discount_name === "" && (
            <span className="text-start text-sm text-red-500">{errorState.discount_nameError}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
            Price
          </label>
          <input
            type="text"
            name="discount_price"
            id="discount_price"
            placeholder="Input the discount price"
            onChange={handleForm}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
          />
          {details.discount_price === "" && (
            <span className="text-start text-sm text-red-500">
              {errorState.discount_priceError}
            </span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
            Usage frequency
          </label>
          <div className="flex items-center gap-x-2">
            <input
              type="text"
              name="usage"
              id="usage"
              placeholder="Input the usage frequency"
              onChange={handleForm}
              className="w-1/2 rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
            />
            <span className="text-base font-normal text-lightgray">time(s)</span>
          </div>
          {details.usage === "" && (
            <span className="text-start text-sm text-red-500">{errorState.usageError}</span>
          )}
        </div>{" "}
        <div className="flex flex-col">
          <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
            Discount code
          </label>
          <input
            type="text"
            name="discount_code"
            id="discount_code"
            placeholder="Set code"
            onChange={handleForm}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
          />
          {details.discount_code === "" && (
            <span className="text-start text-sm text-red-500">{errorState.discount_codeError}</span>
          )}
        </div>{" "}
        <div className="flex flex-col">
          <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
            Valid until
          </label>
          <input
            type="date"
            name="validity"
            id="validity"
            placeholder="Set validity date"
            onChange={handleForm}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 text-lightgray outline-none"
          />
          {details.validity === "" && (
            <span className="text-start text-sm text-red-500">{errorState.validityError}</span>
          )}
        </div>{" "}
        <div className="flex flex-col gap-y-2">
          <span className="text-base font-normal text-[#181818]">Image</span>
          <div>
            <label
              htmlFor="addNew"
              className="flex flex-col items-center justify-center gap-y-2 rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 text-lightblue transition-all ease-in hover:scale-95 hover:cursor-pointer"
            >
              <img src={Upload} alt="addNew" />
              <span> Upload Image</span>
              <span>{fileName}</span>
            </label>
            <input
              type="file"
              name="addNew"
              id="addNew"
              className="hidden"
              accept=".jpg,.jpeg,.png,.svg,.png"
              onChange={handleFileChange}
            />
          </div>
          {fileError && <span className="text-center text-sm text-red-500">{fileError}</span>}
        </div>
        <div className="mt-10 w-full">
          <Button className="w-full" onClick={handleAddDiscount}>
            Add Discount
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AddNewDiscount;

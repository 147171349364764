import React, { useState } from "react";
import {
  logo,
  threedot,
  roundedimage,
  notification,
  blueLove,
} from "../../../../../constants/images";
import Menu from "../menu";
import InputSearch from "../../../_components/input_search";

const Navbar = () => {
  const [menu, setShomenu] = useState();
  const handleShowMenu = () => {
    setShomenu(!menu);
  };
  return (
    <>
      <div className="z-10 mx-auto flex h-[7vh] w-full items-center justify-between bg-white px-8  drop-shadow-md fixed md:h-[10vh] md:px-10">
        <img src={logo} alt="" className="w-[20%] md:w-[5%]" />

        <div className="hidden w-[40%] md:block">
         <InputSearch />
        </div>

        <div className="flex w-[55%] md:w-[15%] items-center justify-center md:space-x-6 space-x-3">
          <button className="block w-full text-nowrap rounded-[5px] border !border-[#0096C1] px-3  font-medium text-lightblue md:hidden">
            Download App
          </button>
          <img
            src={blueLove}
            alt="like"
            className="w-[40%] hover:cursor-pointer md:w-[10%]"
          />

          <img
            src={notification}
            alt="notification"
            className="hidden w-[40%] hover:cursor-pointer md:block md:w-[10%]"
          />

          <img
            src={roundedimage}
            alt="profile"
            className="w-[40%] hover:cursor-pointer md:w-[10%]"
          />

          <img
            src={threedot}
            alt="options"
            className="w-[40%] hover:cursor-pointer md:w-[2%]"
            onClick={handleShowMenu}
          />
        </div>
      </div>
      {menu && <Menu />}
    </>
  );
};

export default Navbar;

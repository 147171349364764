import React, { useState } from "react";
import { Button, SuccessModal } from "../../../../../../components";
import { setDeleteAdvert, setRefreshAd } from "../../../../../../redux/slice/modalOverlay";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../../../../../constants/images";

const deleteAdvertModal = () => {
  const advertId = useSelector((state) => state.modalOverlay.states.advertId);
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleClose = () => {
    dispatch(setDeleteAdvert(false));
  };

  const handleDeleteAdvert = async () => {
    setError(null);
    setSuccess(null);
    try {
      setLoading(true);
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/advert/${advertId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        setSuccess(true);
        setLoading(false);
        dispatch(setRefreshAd(true));
      } else {
        setError("Error in deleting advert");
        setLoading(false);
        dispatch(setRefreshAd(true));
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      {!success && (
        <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
          <div
            className="absolute right-10 top-2 text-2xl hover:cursor-pointer"
            onClick={handleClose}
          >
            x
          </div>
          <div className="flex flex-col gap-y-3 px-8">
            <span className={`py-2 text-center text-lg font-medium text-secondary`}>
              Are you sure you want to delete this advert?
            </span>
            {error !== "" && <span className="text-center text-sm text-red-500">{error}</span>}
            <div className="mt-10 flex w-full items-center gap-x-2">
              <Button
                className="w-full"
                backgroundColor="transparent"
                textColor="#0096C1"
                onClick={handleClose}
              >
                No
              </Button>
              <Button
                className="flex w-full items-center justify-center"
                onClick={handleDeleteAdvert}
              >
                {loading ? <img src={Spinner} alt="loading" className="w-[12%]" /> : "Yes"}
              </Button>
            </div>
          </div>
        </div>
      )}
      {success && (
        <SuccessModal
          title={"Advert Deleted Successfully"}
          buttonText={"OK"}
          onClick={handleClose}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default deleteAdvertModal;

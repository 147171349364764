import React, { useEffect, useState } from 'react'
import { projectsData } from '../data'
import Card from '../card'
import MobileCard from '../card/moblieCard';
const SearchContent = ({searchParams}) => {
    // const [allProject,setAllProject] = useState(projectsData)
    const capitalizeFirstLetter = (string) => {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
     const capitalizedSearchParams = capitalizeFirstLetter(searchParams);
    //   useEffect(() => {
    //     allProject
    //       .filter((project) => project.status.includes(capitalizedSearchParams)) // Filter vendors based on searchParams   
    //     },[allProject])
        const filteredSearch = projectsData.filter((project) => project.status.includes(capitalizedSearchParams))
        // console.log(allProject);
  
  return (
    <div className=''>
           <div className="md:flex flex-col gap-6 hidden">
                {filteredSearch.map((projectData, index) => (
                  <Card
                    key={projectData.id}
                    to={`/projectCardContent/${index}`}
                    id={projectData.id}
                    title={projectData.title}
                    address={projectData.address}
                    name={projectData.name}
                    ratings={projectData.ratings}
                    date={projectData.date}
                    budget={projectData.budget}
                    image={projectData.image}
                    status={projectData.status}
                  />
                ))}
              </div>
              <div className="md:hidden flex flex-col gap-6 ">
                {filteredSearch.map((projectData, index) => (
                  <MobileCard
                    key={projectData.id}
                    to={`/projectCardContent/${index}`}
                    id={projectData.id}
                    title={projectData.title}
                    address={projectData.address}
                    name={projectData.name}
                    ratings={projectData.ratings}
                    date={projectData.date}
                    budget={projectData.budget}
                    image={projectData.image}
                    status={projectData.status}
                  />
                ))}
              </div>
    </div>
  )
}

export default SearchContent

import React from 'react'
import { Navbar, AdvertCard } from './_components'

const AdvertsPage = () => {
  return (
    <div>
        <Navbar />
        <AdvertCard />
    </div>
  )
}

export default AdvertsPage
import { useDispatch } from "react-redux";
import { setShowNotice } from "../../../../../../../features/overlay";
import { notice } from "../../../../../../../constants/images";

const MobileChatNotice = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setShowNotice(false));
  };
  return (
    <div className="fixed bottom-[0%] left-0 right-0 z-[999] block w-full animate-slide_up rounded-tl-[30px] rounded-tr-[30px] bg-primary px-4 py-8 md:hidden">
      <div
        className=" absolute right-[23px] top-[31px] text-lg font-medium text-[#181818]"
        onClick={handleClose}
      >
        X
      </div>
      <div className="flex items-center gap-2 py-2">
        <img src={notice} alt="notice" />
        <span className="text-[20px] font-semibold text-secondary">
          Important Notice
        </span>
      </div>
      <div className="flex flex-col px-4 gap-3">
        <span className="text-base font-normal text-[#181818]">
          Chats are actively monitored to ensure a secure and trustworthy
          environment that fosters a positive user experience and prevents
          violation of service terms and conditions.
        </span>
        <>
          <span className="text-base font-semibold text-[#181818]">
            For your safety and adherence to our product's terms of use, please
            be aware of the following
          </span>
          <ul className="list-disc px-4">
            <li className="text-sm font-normal text-[#181818]">
              Maintain in-app discussions, transactions, and service agreements
              to prevent scams and track fraudulent activities.
            </li>
            <li className="text-sm font-normal text-[#181818]">
              For transactions, use only the provided in-app features. During
              conversations, never disclose personal or sensitive information
              such as personal contact details, BVN, and passwords.
            </li>
            <li className="text-sm font-normal text-[#181818]">
              Beware! Attempts to move conversations or transactions off-app are
              suspicious and may lead to account suspension. Report immediately
              to our support team.
            </li>
          </ul>
        </>
        <span className="text-base font-medium text-[#181818]">
          By using this chat, you agree to adhere to these guidelines. Your
          safety and security are our top priorities.
        </span>
      </div>
    </div>
  );
};

export default MobileChatNotice;

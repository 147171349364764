import React, { useState } from "react";
import { setShowResponse } from "../../../../../../redux/slice/modalOverlay";
import { useDispatch } from "react-redux";
import { Button } from "../../../../../../components";
import { verificationLogo } from "../../../../../../../constants/images";

const Modal = () => {
  const dispatch = useDispatch();
  const [isSuccessful, setIsSuccessfull] = useState(false);

  const handleCloseModal = () => {
    dispatch(setShowResponse(false));
  };
  const handleSaveResponse = () => {
    setIsSuccessfull(true);
    // save response and show modal
  };

  return (
    <div className="relative z-[99] flex w-full items-center justify-center bg-primary">
      <div
        className="absolute right-[23px] top-[31px] text-lg font-medium text-[#181818] hover:cursor-pointer"
        onClick={handleCloseModal}
      >
        X
      </div>
      {!isSuccessful && (
        <div className="w-full px-8 py-10">
          <h3 className="text-[20px] font-semibold text-[#002D3A]">Request response</h3>
          <div className="flex flex-col">
            <div className="flex flex-col gap-y-3 pt-4">
              <label htmlFor="">Input request</label>
              <textarea
                name="comment"
                id="comment"
                cols="45"
                rows="5"
                className="mt-1 w-fit rounded-[5px] border-[0.5px] border-[#181818] p-3 text-lightgray outline-none"
              ></textarea>
              <div className="flex items-center gap-x-2 py-4">
                <input type="checkbox" name="reason" id="reason" />
                <span className="text-sm text-lightgray">
                  Add the consumer’s reason for raising the dispute
                </span>
              </div>
              <Button onClick={handleSaveResponse}>Send</Button>
            </div>
          </div>
        </div>
      )}
      {isSuccessful && (
        <div className="flex gap-y-10 w-full flex-col items-center justify-center pt-20">
          <img src={verificationLogo} alt="" className="w-[10rem]" />
          <span className="text-[20px] font-semibold text-secondary">
            Request Sent Successfully!
          </span>

          <Button onClick={handleCloseModal} className='w-3/4 my-4'>Done</Button>
        </div>
      )}
    </div>
  );
};

export default Modal;

import React, { useState } from "react";
import NoMessages from "./_components/no-messages";
import { detailedChatHistory } from "../../../data";
import MobileChatNotice from "../_components/newchat-notice/mobile-content";
import ChatListSidebar from "../_components/chatlist-sidebar";
import ChatServiceRequest from "../_components/chat-service_request/mobile";
import Chatbar from "../_components/chat-bar";
import ChatContent from "../_components/chat-content";
import ChatInput from "../_components/chat-input";
import SearchInput from "../_components/chat-search";
import { useDispatch, useSelector } from "react-redux";
import { setHideSidebar } from "../../../../../features/overlay";

const MobileChat = () => {
  const dispatch = useDispatch();
  const [selectedChat, setSelectedChat] = useState(null);
  const showNotice = useSelector(
    (state) => state?.overlay.displayStates.showNotice,
  );
  const popRequest = useSelector(
    (state) => state?.overlay.displayStates.popRequest,
  );
  const handleChatClick = (chat) => {
    dispatch(setHideSidebar(true))
    setSelectedChat(chat);
  };
  const handleBackClick = (value) => {
    setSelectedChat(value);
  };
  return (
    <div className="flex flex-col pt-20 md:hidden md:pt-0">
      {selectedChat === null && !popRequest && (
        <span className="px-8 py-4 text-[20px] font-bold text-secondary">
          Chat
        </span>
      )}
      {showNotice && (
        <div className="fixed left-0 right-0 top-0 z-[99] block h-[160vh] w-full bg-black bg-opacity-25" />
      )}
      {showNotice && (
        <div className="bottom absolute left-[20%] top-[25%] z-[99] h-fit w-[40%] bg-primary">
          <MobileChatNotice />
        </div>
      )}
      {!popRequest ? (
        <div className="flex animate-slide_up flex-col items-center justify-center md:h-[60vh] md:flex-row md:px-0 md:pt-0">
          {selectedChat === null && <SearchInput />}
          {selectedChat === null ? (
            detailedChatHistory.length === 0 ? (
              <NoMessages />
            ) : (
              <ChatListSidebar chats={selectedChat} onClick={handleChatClick} />
            )
          ) : (
            <div className="relative w-full ">
              <Chatbar setBackClick={handleBackClick} chat={selectedChat} />
              {selectedChat && <ChatContent chat={selectedChat} />}
              <ChatInput />
            </div>
          )}
        </div>
      ) : (
        <ChatServiceRequest />
      )}
    </div>
  );
};

export default MobileChat;

import React from "react";
import { allProjects } from "../../../../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { Back, Location } from "../../../../../../../constants/images";
import { setShowProject } from "../../../../../../../redux/slice/modalOverlay";

const ProjectDetails = () => {
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.modalOverlay.states.projectId);

  const handleBackClick = () => {
    dispatch(setShowProject(false));
  };
  return (
    <>
      {allProjects
        .filter((project) => project.id === projectId)
        .map((value) => (
          <div className="flex justify-between rounded-[10px] bg-primary px-10 py-12 pt-10 shadow-lg">
            <div className="flex w-3/4 flex-col gap-y-5">
              <div className="flex items-center justify-start gap-x-2">
                <img
                  src={Back}
                  alt="back"
                  className="hover:cursor-pointer"
                  onClick={handleBackClick}
                />
                <span className="text-base font-medium text-[#181818]">
                  Back
                </span>
              </div>
              <span className="text-sm text-lightgray">
                Status:{" "}
                <span
                  className={`lg:text-base text-sm font-medium ${value.project_status === "Ongoing" ? "text-[#0096C1]" : value.project_status === "Completed" ? "text-[#36C100]" : value.project_status === "Cancelled" ? "text-[#C12B00]" : ""}`}
                >
                  {value.project_status}
                </span>
              </span>
              <div className="flex flex-col">
                <span className="text-sm text-lightgray">Project title</span>
                <span className="text-lg font-medium text-[#181818]">
                  {value.title}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-sm text-lightgray">Location</span>
                <span className="text-base font-medium text-[#181818]">
                  {value.location_status}
                </span>
                <div className="flex items-center gap-x-2">
                  <img src={Location} alt="location" />
                  <span className="text-base font-medium text-[#181818]">
                    {value.location}
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm text-lightgray">Date</span>
                <span className="text-sm text-lightgray">
                  From{" "}
                  <span className="text-sm font-medium text-[#181818]">
                    {value.start_date}
                  </span>
                </span>
                <span className="text-sm text-lightgray">
                  To{" "}
                  <span className="text-sm font-medium text-[#181818]">
                    {value.end_date}
                  </span>
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-sm text-lightgray">Budget</span>
                <span className="text-sm font-medium text-[#181818]">
                  {value.budget}
                </span>
              </div>

              <div className="flex flex-col items-start gap-y-2">
                <span className="text-sm text-lightgray">Document</span>
                <img
                  src={value.document}
                  className="w-[200px]"
                  alt="attachment"
                />
              </div>
              <div className="flex w-3/4 flex-col">
                <span className="text-sm text-lightgray">Note</span>
                <span className="lg:text-lg font-medium text-[#181818]">
                  {value.description}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-y-10 px-6 py-2 ">
              <div className="h-fit w-5/6 border border-[#AEAEAE]">
                {value.status !== "cancelled" && (
                  <div className="flex flex-col gap-y-5 px-10 py-2 pb-12">
                    <span className="text-lg font-semibold text-secondary">
                      Project timeline
                    </span>
                    <div className="flex flex-col gap-y-8">
                      <div className="flex items-center gap-x-3">
                        <img src={""} alt="timeline" />
                        <div className="flex flex-col">
                          <span className="text-sm font-medium text-lightgray">
                            Project funded
                          </span>
                          <span className="text-xs font-normal text-lightgray">
                            18 Dec 2023
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <img src={""} alt="timeline" />
                        <span className="text-sm font-medium text-lightgray">
                          Deliver work
                        </span>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <img src={""} alt="timeline" />
                        <span className="text-sm font-medium text-lightgray">
                          Consumer review and approval
                        </span>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <img src={""} alt="timeline" />
                        <span className="text-sm font-medium text-lightgray">
                          Accepted
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {value.status === "completed" && (
                  <div className="flex flex-col gap-y-5 px-10 py-2 pb-12">
                    <span className="text-lg font-semibold text-secondary">
                      Project timeline
                    </span>
                    <div className="flex flex-col gap-y-8">
                      <div className="flex items-center gap-x-3">
                        <img src={""} alt="timeline" />
                        <div className="flex flex-col">
                          <span className="text-sm font-medium text-lightgray">
                            Project funded
                          </span>
                          <span className="text-xs font-normal text-lightgray">
                            18 Dec 2023
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <img src={""} alt="timeline" />
                        <span className="text-sm font-medium text-lightgray">
                          Deliver work
                        </span>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <img src={""} alt="timeline" />
                        <span className="text-sm font-medium text-lightgray">
                          Consumer review and approval
                        </span>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <img src={""} alt="timeline" />
                        <span className="text-sm font-medium text-lightgray">
                          Accepted
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {value.status === "cancelled" && (
                  <div className="flex flex-col gap-y-5 px-10 py-2 pb-12">
                    <span className="text-lg font-semibold text-secondary">
                      Project timeline
                    </span>
                    <div className="flex flex-col gap-y-8">
                      <div className="flex items-center gap-x-3">
                        <img src={""} alt="timeline" />
                        <div className="flex flex-col">
                          <span className="text-sm font-medium text-lightgray">
                            Vendor confirmation
                          </span>
                          <span className="text-xs font-normal text-lightgray">
                            18 Dec 2023
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <img src={""} alt="timeline" />
                        <div className="flex flex-col">
                          <span className="text-sm font-medium text-lightgray">
                            Cancelled
                          </span>
                          <span className="text-xs font-normal text-lightgray">
                            21 Dec 2023
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {value.status === "cancelled" && (
                <div className="h-fit w-5/6 border border-[#AEAEAE]">
                  <div className="flex flex-col gap-y-2 px-10 py-2 pb-12">
                    <span className="text-sm font-medium text-lightgray">
                      Reason for cancellation
                    </span>
                    <span className="text-lg font-medium text-[#181818]">I’ve got a better deal elsewhere</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
    </>
  );
};

export default ProjectDetails;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { formdata } from "../../../../../../../../../features/formSlice";
import { Button, Input } from "../../../../../../../../../components/ui";
import { useNavigate } from "react-router-dom";

const ServiceDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedDays, setSelectedDays] = useState([]);
  const [details, setDetails] = useState({
    pricerate: "",
    amount: "",
    openingtime: "",
    closingtime: "",
  });

  const [errorState, setErrorState] = useState({
    pricerateError: "",
    amountError: "",
  });

  const validate = () => {
    let isError = false;
    const errors = {
      pricerateError: "",
      amountError: "",
    };
    if (!details.pricerate) {
      isError = true;
      errors.pricerateError = "Please select a base price rate";
    }
    if (!details.amount) {
      isError = true;
      errors.amountError = "Please enter a amount";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const handleSelectedDays = (e) => {
      const isSelected = e.target.checked;
      if (isSelected) {
        const index = selectedDays.indexOf(e.target.value);
        selectedDays.splice(index, 1);

        setSelectedDays(selectedDays);
      }
    };
  }, [selectedDays]);

  const handleSelectedDays = (e) => {
    const isSelected = e.target.checked;
    if (isSelected) {
      setSelectedDays([...selectedDays, e.target.value]);
    } else {
      const index = selectedDays.indexOf(e.target.value);
      selectedDays.splice(index, 1);

      setSelectedDays(selectedDays);
    }
  };
  useEffect(() => {
    setDetails({ ...details, days: selectedDays });
  }, [selectedDays]);


  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validate();

    if (!error) {
      dispatch(formdata({ id: 0, pricing_availability: details }));
      navigate("/signup/catalog&profile");
    }
  };


  const selectArrow = `
      select{
        -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
      
        background-position: calc(100% - .5rem);
        background-image: url(/dropdown.png);
        background-repeat: no-repeat;     
      }`;

  return (
    <div className="h-screen w-[100%] bg-primary md:bg-bgImage">
      <div className="left-0 right-0 top-[6rem] h-[90vh] overflow-auto py-10 md:absolute md:px-20 md:py-0 lg:px-0">
        <div className=" w-full md:mx-auto lg:w-1/2">
          <div className="flex flex-col justify-center rounded-[5px] bg-primary p-7 md:px-10 md:pt-10">
            <span className="mb-8 text-[25px] font-semibold text-secondary md:text-center md:text-[30px]">
              Set up your account
            </span>
            <span className="text-start text-lg font-semibold text-secondary md:text-[20px]">
              Pricing and availability
            </span>
            <form className="flex flex-col gap-3 py-6">
              <div className="">
                <label htmlFor="pricerate" className="py-3 text-[#181818]">
                  Set your base price rate
                </label>
                <div className="flex w-full gap-4">
                  <select
                    className="w-1/2 rounded-[5px] border border-lightgray bg-primary p-2 text-sm text-lightgray outline-none md:text-base"
                    onChange={handleForm}
                    name="pricerate"
                  >
                    <option value="" selected disabled>
                      Frequency
                    </option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                  </select>

                  <Input
                    className="min-w-1/2 w-1/2 rounded-[5px] p-2 text-sm placeholder:text-lightgray md:text-base"
                    onChange={handleForm}
                    type="text"
                    placeholder="Base amount"
                    name="amount"
                  />
                </div>{" "}
                <div className="flex justify-between">
                  {" "}
                  <span className="text-sm text-[#e62e2e]">
                    {errorState.pricerateError}
                  </span>{" "}
                  <span className="text-sm text-[#e62e2e]">
                    {errorState.amountError}
                  </span>
                </div>
              </div>
              <span className="text-sm text-lightgray">
                You can adjust your rate any time you apply for a job
              </span>

              <div className="flex flex-col items-start gap-1">
                <label
                  htmlFor="availability"
                  className="text-sm text-[#181818] md:text-base"
                >
                  Set your business hours (optional)
                </label>
                <div className="flex items-center gap-2">
                  <span className="text-sm text-lightgray md:text-base">
                    I am always available
                  </span>
                  <span className="text-sm text-[#e62e2e]">
                    {errorState.availabilityError}
                  </span>
                </div>
              </div>

              <div className="rounded-[5px] border border-[#AEAEAE] py-3">
                <div className="flex flex-wrap items-center justify-between px-4 text-sm text-[#181818] md:gap-1 md:px-10 md:text-base">
                  <div
                    className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("sunday") ? "bg-secondary text-primary" : "border border-lightgray text-[#181818] "}`}
                  >
                    <Input
                      type="checkbox"
                      onChange={handleSelectedDays}
                      name="day"
                      className="hidden"
                      id="sunday"
                      value="sunday"
                    />
                    <label htmlFor="sunday" className="px-4 py-2">
                      Sun
                    </label>
                  </div>
                  <div
                    className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("monday") ? "bg-secondary text-primary" : "border border-lightgray text-[#181818] "}`}
                  >
                    <Input
                      type="checkbox"
                      onChange={handleSelectedDays}
                      name="day"
                      className="hidden"
                      id="monday"
                      value="monday"
                    />
                    <label htmlFor="monday" className="px-4 py-2">
                      Mon
                    </label>
                  </div>
                  <div
                    className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("tuesday") ? "bg-secondary text-primary" : "border border-lightgray text-[#181818] "}`}
                  >
                    <Input
                      type="checkbox"
                      onChange={handleSelectedDays}
                      name="day"
                      className="hidden"
                      id="tuesday"
                      value="tuesday"
                    />
                    <label htmlFor="tuesday" className="px-4 py-2">
                      Tues
                    </label>
                  </div>
                  <div
                    className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("wednesday") ? "bg-secondary text-primary" : "border border-lightgray text-[#181818] "}`}
                  >
                    <Input
                      type="checkbox"
                      onChange={handleSelectedDays}
                      name="day"
                      className="hidden"
                      id="wednesday"
                      value="wednesday"
                    />
                    <label htmlFor="wednesday" className="px-4 py-2">
                      Wed
                    </label>
                  </div>
                  <div
                    className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("thursday") ? "bg-secondary text-primary" : "border border-lightgray text-[#181818] "}`}
                  >
                    <Input
                      type="checkbox"
                      onChange={handleSelectedDays}
                      name="day"
                      className="hidden"
                      id="thursday"
                      value="thursday"
                    />
                    <label htmlFor="thursday" className="px-4 py-2 ">
                      Thur
                    </label>
                  </div>
                  <div
                    className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("friday") ? "bg-secondary text-primary" : "border border-lightgray text-[#181818] "}`}
                  >
                    <Input
                      type="checkbox"
                      onChange={handleSelectedDays}
                      name="day"
                      className="hidden"
                      id="friday"
                      value="friday"
                    />
                    <label htmlFor="friday" className="px-4 py-2 ">
                      Fri
                    </label>
                  </div>
                  <div
                    className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("saturday") ? "bg-secondary text-primary" : "border border-lightgray text-[#181818] "}`}
                  >
                    <Input
                      type="checkbox"
                      onChange={handleSelectedDays}
                      name="day"
                      className="hidden"
                      id="saturday"
                      value="saturday"
                    />
                    <label htmlFor="saturday" className="px-4 py-2">
                      Sat
                    </label>
                  </div>
                </div>
                <div className="flex relative w-full items-center gap-4 px-4 py-2 pt-6 md:px-10">
                  <div className="flex w-1/2 justify-between border border-[#AEAEAE] px-3 py-2 ">
                  <input
                      aria-label="Time"
                      onChange={handleForm}
                      name="openingtime"
                      defaultValue={'04:00'}
                      id="open"
                      type="time"
                      className="w-auto outline-none"
                    />
                    <span htmlFor="open" className=" text-lightgray">
                      Open
                    </span>
                  </div>

                  <div className="flex w-1/2 justify-between border border-[#AEAEAE] px-3 py-2 ">
                  <input
                      aria-label="Time"
                      onChange={handleForm}
                      name="openingtime"
                      defaultValue={'12:00'}
                      id="open"
                      type="time"
                      className="w-auto outline-none"
                    />
                    <span htmlFor="open" className=" text-lightgray">
                      Close
                    </span>
                  </div>

                  <div className="absolute">
                    <div className="flex flex-col">
                    {/* <input
                      aria-label="Time"
                      onChange={handleForm}
                      name="openingtime"
                      defaultValue={'00:00'}
                      id="open"
                      type="time"
                      className="w-auto border border-[#AEAEAE] p-2 outline-none"
                    /> */}
                    </div>
                  </div>
                </div>
              </div>
              <Button
                border="none"
                // type="submit"
                children="Proceed"
                className="w-full"
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>
        <style>{selectArrow}</style>
      </div>
    </div>
  );
};

export default ServiceDetails;

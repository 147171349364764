import React, { useEffect, useState } from "react";
import ConsumerSide from "./_components/consumer-side";
import VendorSide from "./_components/vendors-side";
// import GivePartialAwardCard from "./_components/cards/givePartialAwardCard";
// import GiveFullAward from "./_components/cards/giveFullAward";
import { ResolvedDisputes } from "../../../../../../constants";

const DisputeDetails = ({ disputeId }) => {
  const dispute_details = `text-xl font-medium text-secondary`;
  const labelStyle = `text-lg text-lightgray`;

  const [activeIndex, setActiveIndex] = useState(0);
  const res = ResolvedDisputes[disputeId];

  return (
    <div>
      <div className="mb-16 flex items-center justify-between bg-white px-10 py-4 drop-shadow-md">
        <div className="text-[25px] font-medium text-[#002D3A]">Disputes</div>
        <div className="">
          <button className="rounded-[5px] bg-white px-3 py-1 font-semibold text-[#0096C1]">
            View decision
          </button>
        </div>
      </div>
      <div className="rounded-[17px] bg-white px-4 pt-8 drop-shadow-md">
        <div className="grid grid-cols-4 items-center justify-between gap-5 border-b-[0.5px] border-[#AEAEAE] px-5 py-6 text-[18px]">
          <div className="">
            <h3 className={labelStyle}>Dispute ID</h3>
            <span className={dispute_details}>GYWDDR101</span>
          </div>
          <div className={labelStyle}>
            <h3 className="text-[18px] text-[#5F5F5F]">Project ID</h3>
            <span className={dispute_details}>{res.ProjectID}</span>
          </div>
          <div className={labelStyle}>
            <h3 className="text-[18px] text-[#5F5F5F]">Project Title</h3>
            <span className={dispute_details}>{res.ProjectTitle}</span>
          </div>
          <div className={labelStyle}>
            <h3 className="text-[18px] text-[#5F5F5F]">Date of Complain</h3>
            <span className={dispute_details}>{res.DateofComplain}</span>
          </div>
          <div className={labelStyle}>
            <h3 className="text-[18px] text-[#5F5F5F]">Date of Resolution</h3>
            <span className={dispute_details}>{res.resolution_date}</span>
          </div>
          <div className={labelStyle}>
            <h3 className="text-[18px] text-[#5F5F5F]">Amount Refunded</h3>
            <span className={dispute_details}>{res.refund}</span>
          </div>
          <div className={labelStyle}>
            <h3 className="text-[18px] text-[#5F5F5F]">Resolution reached</h3>
            <span className={dispute_details}>{res.final_resolution}</span>
          </div>
        </div>
        <div className="flex border-b-[0.5px] border-[#AEAEAE] px-5 py-3">
          <button
            className={`w-[50%] rounded-[5px] px-4 py-2 ${activeIndex === 0 ? "bg-[#0096C1] text-white" : "bg-white text-[#5F5F5F]"}`}
            onClick={() => setActiveIndex(0)}
          >
            Consumer side
          </button>
          <button
            className={`w-[50%] rounded-[5px] px-4 py-2 ${activeIndex === 1 ? "bg-[#0096C1] text-white" : "bg-white text-[#5F5F5F]"}`}
            onClick={() => setActiveIndex(1)}
          >
            Vendor side
          </button>
        </div>
        <div className="">{activeIndex === 0 && <ConsumerSide />}</div>
        <div className="">{activeIndex === 1 && <VendorSide />}</div>
      </div>
      <div className="my-7 rounded-[17px] bg-white px-5 py-8 drop-shadow-md">
        <h3 className={dispute_details}>Resolution Decision</h3>
        <div className="mt-6 flex flex-col">
          <span className="text-lg font-normal text-[#181818]">{res.resolution === 'consumer' ? 'Fully awarded to consumer' : res.resolution === 'vendor' ? 'Fully awarded to vendor' : 'Partially awarded'}</span>
          <div className="flex flex-col gap-y-10 pt-6">
            <span className="text-lg text-lightgray">Reason</span>
            <span className="text-xl font-normal text-[#181818]">
              Yorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et
              velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora
              torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis
              condimentum lobortis.
            </span>
          </div>
        </div>
        {/* {showDecision && selectedAward === "partial" && <GivePartialAwardCard />}
        {showDecision && selectedAward === "consumer" && <GiveFullAward />} 
        {showDecision && selectedAward === "vendor" && <GivePartialAwardCard />} */}
      </div>
    </div>
  );
};

export default DisputeDetails;

import React from 'react'
import { whiteSearch, whitelogo } from '../../../../constants/images'
import { Input } from '../../../../components/ui';
const DisputNavBar = () => {
  return (
    <div className='bg-[#002D3A] flex justify-between py-3 px-10 items-center'>
      <div className=""><img src={whitelogo} alt="" className='w-24' /></div>
      <div className='w-[35%]'>
        <form action="">
            <div className="relative flex w-full">
              <Input
                type="search"
                className="min-w-[100%] rounded-[5px] px-8 py-1 bg-inherit text-[white]"
                placeholder="Search"
              />{" "}
              <img
                src={whiteSearch}
                alt=""
                className="absolute bottom-2 z-10 px-2"
              />
            </div>
          </form>
    </div>
    <div className="text-white flex flex-col items-end">
        <h3 className="font-semibold">Dispute resolution team</h3>
        <span className="text-[12px] font-normal">John Doe</span>
    </div>
    </div>
  )
}

export default DisputNavBar

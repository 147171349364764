import React, { useState } from "react";
import { projectsData } from '../data'; 
import NoProject from '../noProjects';
import Card from '../card';
import MobileCard from "../card/moblieCard";

const DefaultProject = () => {
    const [showProject, setShowProject] = useState(3);
    const FilteredData = projectsData.filter((filterdata)=> filterdata === status)
    console.log("filterdata",FilteredData )
    return (
        <div>
          <div className="">
            
            {showProject === 0 && <NoProject />}
            {showProject > 0 && (
              <div className="">
              <div className="md:flex flex-col gap-6 hidden">
                {projectsData.map((projectData, index) => (
                  <Card
                    key={projectData.id}
                    to={`/projectCardContent/${index}`}
                    id={projectData.id}
                    title={projectData.title}
                    address={projectData.address}
                    name={projectData.name}
                    ratings={projectData.ratings}
                    date={projectData.date}
                    budget={projectData.budget}
                    image={projectData.image}
                    status={projectData.status}
                  />
                ))}
              </div>
              <div className="md:hidden flex flex-col gap-6 ">
                {projectsData.map((projectData, index) => (
                  <MobileCard
                    key={projectData.id}
                    to={`/projectCardContent/${index}`}
                    id={projectData.id}
                    title={projectData.title}
                    address={projectData.address}
                    name={projectData.name}
                    ratings={projectData.ratings}
                    date={projectData.date}
                    budget={projectData.budget}
                    image={projectData.image}
                    status={projectData.status}
                  />
                ))}
              </div>
              </div>
              
            )}
          </div>
        </div>
      );
}

export default DefaultProject

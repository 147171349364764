import React from 'react'

const DefaulDisplay = ({ text, image, className}) => {
  return (
    <div className="">
    <div className={`flex h-[150px] w-full ${className} flex-col justify-center gap-y-3 rounded-[7px] p-7 cursor-pointer border-[1px] border-[#AEAEAE] relative transition-all ease-in-out hover:scale-105`}
    // style={{ backgroundColor: bgcolor }}
    >
      <div className="flex flex-col justify-center items-center gap-3">
      <div className="mx-auto">
        <img src={image} alt="" />
      </div>
      <span className="w-[130px] text-center text-[18px] font-semibold leading-[21.11px] text-[#002D3A]">
        {text}
      </span>
      </div>
    </div>
  </div>
  )
}

export default DefaulDisplay

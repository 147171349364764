import React, { useEffect, useState } from "react";
import { Button } from "../../../../components/ui";
import { useDispatch, useSelector } from "react-redux";
import { setJobDetails, setJobDetailsId } from "../../../../features/overlay";

const AvailableJob = ({
  id,
  status,
  budget,
  description,
  title,
  service,
  location,
  timeline,
  post_duration,
  color,
}) => {
  const dispatch = useDispatch();
  const [jobId, setJobId] = useState();

  useEffect(() => {
    setJobId(id)
  }, [id])

  const handleJobDetails = () => {
    dispatch(setJobDetails(true));
    dispatch(setJobDetailsId(jobId));
  };

  return (
    <div
      onClick={handleJobDetails}
      className={`lg:min-w-auto min-h-[201px] w-auto cursor-pointer rounded-[5px] border border-[#5F5F5F] transition-all ease-in-out hover:scale-105`}
    >
      <div className="flex flex-col gap-2  px-4 py-3">
        <div className="flex  items-center justify-between">
          <span
            className={`px-[25px] py-[3px] text-xs  font-medium text-lightgray `}
            style={{ backgroundColor: `${color}` }}
          >
            {title}
          </span>{" "}
          <img src="/like.png" alt="" className="" />
        </div>
        <span className="text-wrap text-base font-bold">{description}</span>
        <span className="text-xs font-semibold capitalize text-[#181818]">
          {service}
        </span>
        <div className="flex items-center gap-1">
          <img src="/location.png" alt="" />
          <span className="text-sm font-medium text-lightgray">{location}</span>
        </div>
        <div className="h-[0.1px] w-[220px] bg-[#5F5F5F] bg-opacity-50" />
        <div className="flex items-center gap-1">
          <span className="text-xs text-lightgray">Budget:</span>
          <span className="text-sm font-bold text-[#181818]">₦{budget}</span>
        </div>
        <div className="flex flex-wrap justify-between gap-2">
          <div className="flex items-center gap-3">
            <div className="rounded-[20px] bg-[#E6F5F9] px-5 py-[3px] text-center text-sm text-lightblue">
              {status}
            </div>
            <div className="text-nowrap rounded-[20px] bg-[#E6F5F9] px-5 py-[3px] text-center text-sm text-lightblue">
              {timeline}
            </div>
          </div>
          {/* {activeFilter ? ( */}
          <Button
            children="Apply"
            width=""
            className="!px-[19px] !py-[5px] !text-sm"
            border="none"
          />
          {/* ) : ( */}
          {/* <span>{applied}</span> */}
          {/* )} */}
        </div>
        <span className="text-center text-xs font-light text-lightgray">
          Posted {post_duration} mins ago{" "}
        </span>
      </div>
    </div>
  );
};

export default AvailableJob;

import React from 'react'
import Container from '../../container'
import DesktopProjectCardContent from './desktopProject'
import MobileProject from './mobileView'

const ProjectCardContent = () => {
  return (
    <Container>
        <DesktopProjectCardContent/>
        <MobileProject/>
    </Container>
  )
}

export default ProjectCardContent

import React from 'react'


const DisputAndBannedAcctButton = () => {
  return (
    <div className='bg-white drop-shadow-md py-3 px-10 flex justify-between items-center mx-6'>
      <div className="text-[#002D3A] font-medium text-[25px]">
      Disputes
      </div>
      {/* <div className="">
        <button className='bg-white text-[#0096C1] border-[1px] border-[#0096C1] rounded-[5px] px-3 py-1'>View banned accounts</button>
      </div> */}
    </div>
  )
}

export default DisputAndBannedAcctButton

import React, { useRef, useState } from "react";
import { Button } from "../../../../../../../components/ui";
import { userNotImage } from "../../../../../../../constants/images";

const StepSeven = ({next}) => {
   
    const [file, setFile] = useState();
    function handleChange(event) {
        setFile(URL.createObjectURL(event.target.files[0]));
    }
    const fileRef = useRef()
    const handleUpload = () => {
        fileRef.current.click()
    }
    const handleSubmit = () => {
        next();
      };
  return (
    <div className="mx-auto rounded-[5px] bg-[#ffff] p-7 lg:w-[830px] ">
      <div className="flex flex-col justify-center gap-10">
        <h1 className="text-center text-[30px] font-[600] text-[#002D3A]">
          You’re almost done!
        </h1>
        <p className="text-center">Upload your profile picture</p>
        <div  className="h-[11rem] w-[10rem] border-2 border-dotted border-[#002D3A] mx-auto flex flex-col items-center justify-center">
            {
                file ? <img src={file} className="object-contain w-[145px] h-[145px] rounded-full"/> : <img src={userNotImage} alt="" />
            }
            
            <input type="file" onChange={handleChange} className="hidden" ref={fileRef}   />
            <p className="text-[#0096C1] underline underline-offset-2 cursor-pointer" onClick={handleUpload} >Upload image</p>
        </div>
        <div className="flex justify-center">
          <Button
            children="Save and proceed"
            border="none"
            className="w-[70%]"
            onClick={handleSubmit}
          />
        </div>
        <p className="text-center text-[#5F5F5F] text-[16px] cursor-pointer" onClick={handleSubmit}>I’ll do it later</p>
      </div>
    </div>
  );
};

export default StepSeven;

import React from "react";

const AllChatsIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6673 17.7493L9.33398 14.8327H5.00065C4.54042 14.8327 4.16732 14.4596 4.16732 13.9993V4.91815C4.16732 4.45792 4.54042 4.08482 5.00065 4.08482H18.334C18.7942 4.08482 19.1673 4.45792 19.1673 4.91815V13.9993C19.1673 14.4596 18.7942 14.8327 18.334 14.8327H14.0007L11.6673 17.7493ZM13.1996 13.166H17.5007V5.75148H5.83398V13.166H9.33398H10.1351L11.6673 15.0814L13.1996 13.166ZM1.66732 0.666016H15.834V2.33268H2.50065V11.4993H0.833984V1.49935C0.833984 1.03912 1.20708 0.666016 1.66732 0.666016Z"
        fill="#002D3A"
      />
    </svg>
  );
};

export default AllChatsIcon;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AgentIdCard,
  AgentPassport,
  Back,
  Clock,
  LocationTwo,
  Star,
} from "../../../../../../../../constants/images";
import { allVendors } from "../../../../../../../../constants";
import { setShowAgent } from "../../../../../../../../redux/slice/modalOverlay";
import { Button } from "../../../../../../../../components";
import VerificationModal from "../verification-modal";

const AgentTableDetails = () => {
  const [verification, setShowVerification] = useState(false);
  const [confirmationText, setConfirmationText] = useState("Unverified");
  const dispatch = useDispatch();
  const agentId = useSelector((state) => state.modalOverlay.states.selectedAgentId);
  const handleBackClick = () => {
    dispatch(setShowAgent(false));
  };
  const handleConfirmation = () => {
    setConfirmationText("Verified");
  };
  useEffect(() => {
    if (confirmationText === "Verified") {
      setShowVerification(false);
    }
  }, [confirmationText]);
  const handleShowVerification = () => {
    setShowVerification(!verification);
  };
  return (
    <div className="relative">
      {allVendors
        .filter((project) => project.id === agentId)
        .map((value) => (
          <div className="relative flex justify-between rounded-[10px] bg-primary px-10 py-12 pt-10 shadow-lg">
            <div className="flex w-3/4 flex-col gap-y-5">
              <div className="flex items-center justify-start gap-x-2">
                <img
                  src={Back}
                  alt="back"
                  className="hover:cursor-pointer"
                  onClick={handleBackClick}
                />
                <span className="text-base font-medium text-[#181818]">Back</span>
              </div>
              <div className="flex items-center gap-x-4">
                <img src={value.image} className="w-[75px]" alt="" />
                <div className="flex flex-col">
                  <span className="text-lg font-semibold text-secondary">{value.name}</span>
                  <span className="text-base font-semibold text-lightgray">{value.service}</span>
                  <div className="flex items-center gap-x-1 text-sm">
                    <img src={Star} alt="rating" />
                    <span className="font-semibold text-[#181818]">{value.rating}</span>
                    <span className="font-normal text-lightgray">({value.count})</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-xl font-semibold text-secondary">{value.title}</span>
                <span className="text-sm font-medium text-secondary">Business hours</span>
                <div className="flex flex-col gap-y-2 pt-2">
                  <div>
                    <img src={Clock} alt="time" />
                    <span></span>
                    <span></span>
                  </div>
                  <div>
                    <img src={Clock} alt="time" />
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm font-medium text-secondary">Address</span>
                <div className="flex items-center gap-x-1">
                  <img src={LocationTwo} alt="location" />
                  <span className="text-base font-semibold text-secondary">{value.address}</span>
                </div>
              </div>
              <div className="flex w-3/4 flex-col">
                <span className="text-sm text-lightgray">Description</span>
                <span className="font-medium text-[#181818] lg:text-lg">{value.description}</span>
              </div>
              <div className="flex flex-col items-start gap-y-2">
                <span className="text-sm text-lightgray">Catalog</span>
                <img src={value.document} className="w-[200px]" alt="attachment" />
              </div>
              <div className="flex flex-col items-start gap-y-1">
                <span className="text-sm font-medium text-secondary">Referral information</span>
                <span className="text-sm text-secondary">
                  Referred by:{" "}
                  <span className="text-base font-semibold text-secondary">John Agency</span>
                </span>
                <span className="text-sm text-secondary">
                  Work ID:{" "}
                  <span
                    className={`${confirmationText === "Verified" ? "text-[#21A601]" : "text-[#FF0000]"} text-base font-medium`}
                  >
                    {confirmationText === "Verified" ? (
                      <span className="text-[#21A601]">Verified</span>
                    ) : (
                      <span className="text-[#FF0000]">Unverified</span>
                    )}
                  </span>
                </span>
                {confirmationText === "Verified" && <img src={AgentPassport} alt="passport" />}
                {confirmationText === "Unverified" && (
                  <div>
                    <img src={AgentIdCard} alt="id card" />
                  </div>
                )}
                {confirmationText === "Unverified" && (
                  <Button onClick={handleShowVerification}>Verify ID</Button>
                )}
              </div>
            </div>
            <div className="flex w-1/2 flex-col gap-y-10 px-20 py-2 ">
              <div className="h-fit w-full rounded-[5px] border border-[#AEAEAE] px-4">
                <div className="flex flex-col">
                  <div className="flex w-full justify-between py-2">
                    <span className="text-sm font-normal text-lightgray">Join Date</span>
                    <span className="text-base font-semibold text-secondary">
                      {value.join_date}
                    </span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full justify-between py-2">
                    <span className="text-sm font-normal text-lightgray">User ID</span>
                    <span className="text-base font-semibold text-secondary">{value.userId}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full justify-between py-2">
                    <span className="text-sm font-normal text-lightgray">Phone number</span>
                    <span className="text-base font-semibold text-secondary">{value.phone}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full justify-between py-2">
                    <span className="text-sm font-normal text-lightgray">Email</span>
                    <span className="text-base font-semibold text-secondary">{value.email}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full justify-between py-2">
                    <span className="text-sm font-normal text-lightgray">Gender</span>
                    <span className="text-base font-semibold text-secondary">{value.gender}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full justify-between py-2">
                    <span className="text-sm font-normal text-lightgray">Location</span>
                    <span className="text-base font-semibold text-secondary">{value.location}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full justify-between py-2">
                    <span className="text-sm font-normal text-lightgray">Category</span>
                    <span className="text-base font-semibold text-secondary">{value.category}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full justify-between py-2">
                    <span className="text-sm font-normal text-lightgray">Specialty</span>
                    <span className="text-base font-semibold text-secondary">
                      {value.specialty}
                    </span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full justify-between py-2">
                    <span className="text-sm font-normal text-lightgray">Years of experience</span>
                    <span className="text-base font-semibold text-secondary">{value.exp}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full justify-between py-2">
                    <span className="text-sm font-normal text-lightgray">Base price rate</span>
                    <span className="text-base font-semibold text-secondary">
                      ₦{value.base_price}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {verification && (
              <div className="fixed left-[40%] right-[0] z-50">
                <VerificationModal
                  handleConfirmation={handleConfirmation}
                  onClick={handleShowVerification}
                  value={value}
                />
              </div>
            )}
          </div>
        ))}
      {verification && (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-20 w-screen bg-black bg-opacity-25" />
      )}
    </div>
  );
};

export default AgentTableDetails;

import React, { useState } from "react";
import { logo } from "../../../constants/images";
import { useDispatch } from "react-redux";
import { sideLinks } from "../../../constants";
import {
  setActiveTable,
  setShowAdvertModal,
  setShowAgentModal,
  setShowBannedAccounts,
  setShowDiscountModal,
  setShowDispute,
  setShowEditAgent,
  setShowProject,
  setShowVendor,
} from "../../../redux/slice/modalOverlay";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState();

  const handleReset = () => {
    dispatch(setShowProject(false));
    dispatch(setShowVendor(false));
    dispatch(setShowDispute(false));
    dispatch(setActiveTable("transaction"));
    dispatch(setShowBannedAccounts(false));
    dispatch(setShowAgentModal(false));
    dispatch(setShowAdvertModal(false));
    dispatch(setShowDiscountModal(false));
    dispatch(setShowEditAgent(false));
  };

  return (
    <div className="flex w-full flex-col gap-y-6">
      <div className="flex w-full justify-center pt-8">
        <img src={logo} alt="gywde logo" />
      </div>
      <div className="w-full bg-primary bg-opacity-25 p-[0.5px]" />

      <div className="mr-3 flex flex-col gap-y-8 px-2">
        {sideLinks.map((link) => (
          <Link
            to={`/admin/${link.to}`}
            className={`mx-auto flex w-full items-center gap-x-3 rounded-[5px] px-3 py-4 transition-all ease-in hover:cursor-pointer hover:bg-primary hover:bg-opacity-20 ${link.id === activeId && "bg-primary bg-opacity-20"}`}
            key={link.id}
            onClick={() => {
              setActiveId(link.id);
              handleReset();
            }}
          >
            <img src={link.icon} alt={link.link} />
            <span className="text-base font-medium text-primary">{link.link}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;

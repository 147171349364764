import React from "react";

const StarredChatsIcon = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0008 15.216L4.12295 18.5062L5.43573 11.8993L0.490234 7.32585L7.17943 6.53273L10.0008 0.416016L12.8222 6.53273L19.5113 7.32585L14.5659 11.8993L15.8787 18.5062L10.0008 15.216ZM10.0008 13.306L13.5397 15.2869L12.7493 11.3091L15.7269 8.55552L11.6995 8.07797L10.0008 4.3952L8.30211 8.07797L4.27467 8.55552L7.25225 11.3091L6.46185 15.2869L10.0008 13.306Z"
        fill="#002D3A"
      />
    </svg>
  );
};

export default StarredChatsIcon;

import React from "react";
import { dropdown } from "../../../../constants/images";
import { FAQS } from "../../../../constants";

const FAQSSection = () => {
  return (
    <div className="text-center flex flex-col md:max-w-full lg:max-w-[50%]">
      <div className="flex flex-col pb-10 md:pb-4 text-center lg:text-start lg:px-36 md:space-y-2 space-y-1">
        <span className="capitalize text-secondary font-semibold text-[25px] lg:text-[40px]">
          Frequently Asked Questions
        </span>
      </div>

      <div className="m-2 space-y-2">
        {FAQS.map((faq) => (
          <div
            className="group flex flex-col gap-3 rounded-lg md:px-10 lg:px-36 text-white"
            tabindex="1"
          >
            <div className="flex cursor-pointer md:pl-0 items-center md:p-4 py-4 px-8 justify-between">
              <span className="text-secondary md:text-[20px] text-lg font-semibold text-left w-full">
                {faq.question}
              </span>
              <img
                src={dropdown}
                className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
              />
            </div>
            <hr color="#000000" className="md:w-full w-[83%] mx-auto" />
            <div className="md:text-lg text-base text-lightgray p-4 pl-8 md:pl-0 invisible h-auto max-h-0 text-start opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000">
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSSection;

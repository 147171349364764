import React, { useState } from "react";
import { Button, Input } from "../../../../components/ui";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const [showNum, setShowNum] = useState(1);
  const handleNext = (next) => {
    setShowNum(next);
  };

  const validateEmail = (e) => {
    setEmail(e.target.value);
    if (!emailRegex.test(email)) {
      setErrorMessage("Please enter a valid email address");
      setShowError(True);
    } else if (emailRegex.test(email)) {
      setErrorMessage("");
      setShowError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showNum === 1) {
      if (email === "") {
        setErrorMessage("Please enter your email address");
        setShowError(true);
      } else {
        navigate("/verify-otp");
      }
      if (showError === false && emailRegex.test(email)) {
        //   dispatch(formdata({ Email: email }));
        //   next();
      }
    } else {
      navigate("/verify-otp");
    }
  };
  return (
    <div className="h-screen bg-white lg:bg-bgImage">
      <div className="left-0 right-0 top-[5rem] h-[50vh] py-10 md:absolute md:px-20 md:py-0 lg:px-0">
        <div className=" w-full rounded-[5px] bg-primary p-7 md:mx-auto md:px-20 lg:w-1/2">
          <div className="flex flex-col justify-center md:items-center">
            <h1 className="hidden text-center text-[25px] font-semibold text-[#002D3A]  md:flex md:text-[30px]">
              Forgot password
            </h1>
            <span className="text-base font-normal text-[#181818] md:text-center md:text-lg">
              We will send an OTP code to your registered email
            </span>
          </div>
          <div className="">
            <form className="text-center">
              {showNum === 1 ? (
                <Input
                  className="mt-10 min-w-[100%] rounded-[5px] p-2"
                  type="email"
                  onChange={validateEmail}
                  placeholder={"Enter email address or phone number"}
                />
              ) : (
                <Input
                  className="type={tel} mt-10 min-w-[100%] rounded-[5px] p-2"
                  // onChange={validateEmail}
                  placeholder={"Enter Phone number"}
                />
              )}

              {showError && (
                <span className="h-[5px] text-sm text-[#da3939]">
                  {errorMessage}
                </span>
              )}

              <Button
                onClick={handleSubmit}
                children="Send"
                border="none"
                width="100%"
                type="submit"
                className="mt-10"
              />
            </form>
          </div>
          <div className="mb-20 mt-10 text-center ">
            {showNum === 1 ? (
              <span
                className="cursor-pointer text-[#AEAEAE]"
                onClick={() => handleNext(2)}
              >
                Use phone number instead
              </span>
            ) : (
              <span
                className="cursor-pointer text-[#AEAEAE]"
                onClick={() => handleNext(1)}
              >
                Use email instead
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React from 'react'
import { Link } from 'react-router-dom'
import { blueLove } from '../../../constants/images'

const MobileVendors = ({image,name,title,titlebg,service,location,rating,rating_count,price,bgcolor,to,handleLike,isLike,id}) => {
  return (
    <div className=''>
         <div className="w-full cursor-pointer  lg:min-w-[100%] transition-all ease-in-out hover:scale-105">  
            <div className="flex py-2">
              <img src={image} alt="service" className="" />
              <div className="flex w-full flex-col justify-around border border-[#AEAEAE] px-4 py-1 ">
                <div className="flex items-center ">
                  
                  <div
                    className={`text-nowrap px-[19px] py-[3px] text-center text-xs font-medium text-lightgray `} style={{ backgroundColor: bgcolor }} >
                    {title}
                  </div>
                  
                  <span className="" onClick={()=>handleLike()}>
                  { isLike[id]?<img src={blueLove} alt="" className="ml-auto  z-10" />:<img src="/like.png" alt="" className="ml-auto" />}
                  </span>
                </div>
                <Link to={to}>
                  <div className=" flex items-start  flex-col ">
                <span className="text-base font-bold text-lightblue">
                  {name}
                </span>
                <span className="text-xs font-semibold text-[#181818]">
                  {service}
                </span>
                </div>
                <div className="flex gap-1">
                  <img src="/location.png" alt="" />
                  <span className="text-xs font-medium text-lightgray">
                    {location}
                  </span>
                </div>

                <div className="flex justify-between pt-1">
                  <div className="flex items-center gap-1 text-xs">
                    <img src="/rate.png" alt="" />
                    <span className="font-semibold">{rating}</span>
                    <span className="font-normal">{rating_count}</span>
                  </div>
                  <div className="my-auto h-[20px] w-[1px] bg-lightgray" />
                  <div className="flex items-center gap-1 text-[#181818]">
                    <span className="text-xs font-normal">From:</span>
                    <span className="text-base font-semibold">
                      ₦{price}
                    </span>
                  </div>
                </div>
                </Link>
              </div>
            </div>
            </div>
    </div>
  )
}

export default MobileVendors
import React, { useState } from "react";
import Container from "../container";
import { linkarrow } from "../../../constants/images";
import { Link } from "react-router-dom";
const BookingProcess = () => {
  const [showVendor, setShowVendor] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showProceed, setShowProceed] = useState(false);
  const [showRecive, setShowRecive] = useState(false);
  const handleShowVendor = () => {
    setShowVendor(!showVendor);
  };
  const handleShowRequest = () => {
    setShowRequest(!showRequest);
  };
  const handleConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };
  const handleProceed = () => {
    setShowProceed(!showProceed);
  };
  const handleRecive= () => {
    setShowRecive(!showRecive);
  };
  return (
    <Container>
      <div className="mx-auto w-[85%]  py-24 md:w-[75%] ">
        <div className="relative">
        <h1 className="text-center md:text-[30px] text-[18px] font-semibold text-[#002D3A] mb-3">
          Booking Process
        </h1>
        <Link
        to="/requestServices"
        >
        <img src={linkarrow} alt="link arrow" className="md:hidden rotate-180 relative top-[-2rem] cursor-pointer"/>
        </Link>
        </div>
        <p className="md:text-center text-start md:text-[18px] text-[14px] font-normal leading-[16.42px]">
          Here's an easy guide on how to successfully book a service
        </p>
        <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
          <div
            className=" flex  cursor-pointer items-center justify-between  "
            onClick={handleShowVendor}
          >
            <span className="text-center md:text-[20px] text-[16px] font-semibold text-[#002D3A]">
              Find a Vendor
            </span>
            <img
              src={linkarrow}
              alt=""
              className={`${showVendor && "ease rotate-90 transition-all"}`}
            />
          </div>
          {showVendor && (
            <p className="md:text-[18px] text-[14px] font-normal md:leading-[21.11px] leading-[18px]">
              Browse through the platform to discover service providers offering
              the specific service you need.
            </p>
          )}
        </div>
        <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
          <div
            className=" flex  cursor-pointer items-center justify-between  "
            onClick={handleShowRequest}
          >
            <span className="text-center text-[20px] font-semibold text-[#002D3A]">
            Request a Service
            </span>
            <img
              src={linkarrow}
              alt=""
              className={`${showRequest && "ease rotate-90 transition-all"}`}
            />
          </div>
        </div>
        <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
          <div
            className=" flex  cursor-pointer items-center justify-between  "
            onClick={handleConfirmation}
          >
            <span className="text-center md:text-[20px] text-[16px] font-semibold text-[#002D3A]">
            Wait for vendor confirmation
            </span>
            <img
              src={linkarrow}
              alt=""
              className={`${showConfirmation && "ease rotate-90 transition-all"}`}
            />
          </div>
        </div>
        <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
          <div
            className=" flex  cursor-pointer items-center justify-between  "
            onClick={handleProceed}
          >
            <span className="text-center md:text-[20px] text-[16px] font-semibold text-[#002D3A]">
            Proceed to Payment
            </span>
            <img
              src={linkarrow}
              alt=""
              className={`${showProceed && "ease rotate-90 transition-all"}`}
            />
          </div>
        </div>
        <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
          <div
            className=" flex  cursor-pointer items-center justify-between  "
            onClick={handleRecive}
          >
            <span className="text-center md:text-[20px] text-[16px] leading-[18.77px] font-semibold text-[#002D3A]">
            Receive Service and Acceptance
            </span>
            <img
              src={linkarrow}
              alt=""
              className={`${showRecive && "ease rotate-90 transition-all"}`}
            />
          </div>
        </div>

      </div>
    </Container>
  );
};

export default BookingProcess;

import React from 'react'

const Description = ({descrition}) => {
    return (
      <div className='mt-3 w-[80%] mx-auto'>
        <p className="text-[#002D3A] text-[14px] leading-[21px] font-normal">{descrition}</p>
      </div>
    )
  }
  
  export default Description



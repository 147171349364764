import React, { useEffect, useState } from "react";
import { VendorsMobile } from "../../../../../constants";
import { Link } from "react-router-dom";
const servicesPerPage = 2;
const arrayForHoldingServices = [];

const ServiceLists = () => {
  const [servicesToShow, setServicesToShow] = useState([]);
  const [noServices, setNoServices] = useState(false);
  const [fade, setFade] = useState(false);
  const [count, setCount] = useState(1);

  const loopThroughServices = (count) => {
    for (
      let i = count * servicesPerPage - servicesPerPage;
      i < servicesPerPage * count;
      i++
    ) {
      if (VendorsMobile[i] !== undefined) {
        arrayForHoldingServices.push(VendorsMobile[i]);
      }
    }
    setServicesToShow(arrayForHoldingServices);
  };
  // load the first set of posts when the page loads
  // and then set the value of count to 2
  useEffect(() => {
    setCount((prevCount) => prevCount + 1);
    loopThroughServices(count);
  }, []);

  const handleShowMorePosts = () => {
    setCount((prevCount) => prevCount + 1);
    loopThroughServices(count);
    if (Vendors.length === servicesToShow.length) {
      setNoServices(true);
      setFade(!fade);
    }
  };
  return (
    <div className="flex w-full flex-col px-4">
      <hr className="h-[1.5px] w-[90%] mx-auto bg-[#AEAEAE]" />
      <div className=" flex flex-col gap-2 px-4">
        {servicesToShow.map((vendor) => (
          <Link
            to="/signup"
            className="w-full cursor-pointer transition-all ease-in-out hover:scale-105 lg:min-w-[100%]"
            key={vendor.id}
          >
            <div className="flex py-2">
              <img src={vendor.image} alt="service" className="" />
              <div className="flex w-full flex-col justify-around border border-[#AEAEAE] px-4 py-1 ">
                <div className="flex items-center ">
                  <span
                    style={{
                      backgroundColor: `${vendor?.titlebg} ` || "#FFE3D3",
                    }}
                    className="text-nowrap px-[25px] py-[3px] text-center text-xs font-medium text-lightgray"
                  >
                    {vendor.title}
                  </span>
                  <img src="/like.png" alt="" className="ml-auto" />
                </div>
                <span className="text-base font-bold text-lightblue">
                  {vendor.name}
                </span>
                <span className="text-xs font-semibold text-[#181818]">
                  {vendor.service}
                </span>
                <div className="flex gap-1">
                  <img src="/location.png" alt="" />
                  <span className="text-xs font-medium text-lightgray">
                    {vendor.location}
                  </span>
                </div>

                <div className="flex justify-between pt-1">
                  <div className="flex items-center gap-1 text-xs">
                    <img src="/rate.png" alt="" />
                    <span className="font-semibold">{vendor.rating}</span>
                    <span className="font-normal">{vendor.rating_count}</span>
                  </div>
                  <div className="my-auto h-[20px] w-[1px] bg-lightgray" />
                  <div className="flex items-center gap-1 text-[#181818]">
                    <span className="text-xs font-normal">From:</span>
                    <span className="text-base font-semibold">
                      ₦{vendor.price}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {noServices && (
        <div
          className={`pt-10 text-lg font-medium capitalize text-lightgray transition-all duration-200 ${fade ? "opacity-100" : "opacity-0"}`}
        >
          No more services available at this time, please check back later
        </div>
      )}
      <button
        onClick={handleShowMorePosts}
        className="mx-auto my-12 w-fit rounded-[5px] border border-lightblue px-5 py-[5px] text-center text-lightblue"
      >
        Load more services
      </button>
    </div>
  );
};

export default ServiceLists;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Container from "../../container";
import { coverImage } from "../../../../constants/images";
import { vendors } from "../../data";
import SmallCard from "./smallCard";
import SingleCardService from "./service";
import Description from "./description";
import Catalog from "./catlog";
import Reviews from "./reviews";
const DesktopContent = () => {
  const { id } = useParams();
  const res = vendors[id];
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Container>
      <div className="relative w-full pl-4 md:block hidden">
        <img src={coverImage} alt="" className="w-full"/>

        <div className="flex">
          <div className="w-[33%]">
            <div className="absolute left-10 top-48 w-[30%]">
              <SmallCard {...res} />
            </div>
          </div>
          <div className="w-[67%] px-[4rem]">
            <div className=" flex justify-between border-b-[1px] border-[#AEAEAE] mt-6">
              <div className="flex flex-col">
                <div className={`${activeIndex === 0 && "border-b-4 border-[#0096C1] "}cursor-pointer text-[18px] font-semibold text-[#5F5F5F]`} onClick={()=>setActiveIndex(0)}>
                  Service
                </div>
              </div>
              <div className="">
                <div className={`${activeIndex === 1 && "border-b-4 border-[#0096C1] "}cursor-pointer text-[18px] font-semibold text-[#5F5F5F]`} onClick={()=>setActiveIndex(1)}>
                  Description
                </div>
              </div>
              <div className={`${activeIndex === 2 && "border-b-4 border-[#0096C1]"} cursor-pointer text-[18px] font-semibold text-[#5F5F5F]`} onClick={()=>setActiveIndex(2)}>
                <span className="cursor-pointer text-[18px] font-semibold text-[#5F5F5F]">
                  Catalog
                </span>
              </div>
              <div className={`${activeIndex === 3 && "border-b-4 border-[#0096C1]"} cursor-pointer text-[18px] font-semibold text-[#5F5F5F]`} onClick={()=>setActiveIndex(3)}>
                <span className="cursor-pointer text-[18px] font-semibold text-[#5F5F5F]">
                  Reviews
                </span>
              </div>
            </div>
            {
              activeIndex === 0 && <SingleCardService {...res}/>
            }
            {
              activeIndex === 1 && <Description {...res}/>
            }
            {
              activeIndex === 2 && <Catalog {...res}/>
            }
            {
              activeIndex === 3 && <Reviews {...res}/>
            }
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DesktopContent;

import React, { useEffect, useState } from "react";
import {
  ConsumerChart,
  ProjectChart,
  RevenueChart,
  VendorChart,
} from "./_components/charts";
import ProjectCard from "../dashboard/_components/project-card";
import Layout from "../../components/layout";
import Table from "./_components/table";
import { useSelector } from "react-redux";
import ProjectDetails from "./_components/table/_components/project/project-details";
import VendorDetails from "./_components/table/_components/vendor/vendor-details";

const AdminDashboard = () => {
  const [chart, setChart] = useState("");
  const showProject = useSelector(
    (state) => state.modalOverlay.states.showProject,
  );
  const showVendor = useSelector(
    (state) => state.modalOverlay.states.showVendor,
  );
  const activeChart = useSelector(
    (state) => state.modalOverlay.states.activeTable,
  );
  useEffect(() => {
    if (activeChart) {
      setChart(activeChart);
    }
  }, [activeChart]);

  useEffect(() => {
    setChart("transaction");
  }, []);

  return (
    <Layout>
      {showProject && <ProjectDetails />}
      {showVendor && <VendorDetails />}
      {!(showProject || showVendor) && (
        <>
          <ProjectCard />
          {chart === "transaction" ? (
            <RevenueChart />
          ) : chart === "project" ? (
            <ProjectChart />
          ) : chart === "Vendors" ? (
            <VendorChart />
          ) : chart === "Consumers" ? (
            <ConsumerChart />
          ) : null}
          <Table />
        </>
      )}
    </Layout>
  );
};

export default AdminDashboard;

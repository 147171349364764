import React from 'react'
import { time,location } from '../../../../../constants/images'
const Service = ({companyName,monTOfri,satTOsun,address,title,service,yearsOfexperience,price}) => {
  return (
    <div className='mt-3 w-[80%] mx-auto'>
      <h3 className="text-[#002D3A] text-[16px] font-semibold">{companyName}</h3>
      <p className="text-[#002D3A] text-[12px]">Business hours</p>
      <div className="">
      <div className="flex items-center space-x-3">
              <img src={time} alt="time" className="h-4 w-4" />
              <span className="text-[14px] font-normal">Mon - Fri:</span>{" "}
              <span className="text-[16px] font-semibold">{monTOfri}</span>
            </div>
            <div className="flex items-center space-x-3">
              <img src={time} alt="time" className="h-4 w-4" />
              <span className="text-[14px] font-normal">Sat - Sun:</span>{" "}
              <span className="text-[16px] font-semibold">{satTOsun}</span>
            </div>
            <div className="text-[#002D3A]">
            <p className="text-[14px] font-medium text-[#002D3A]">Address</p>
            <div className="flex items-center space-x-3">
              <img src={location} alt="time" className="h-4 w-4" />{" "}
              <p className="text-[16px] font-semibold">{address}</p>
            </div>
          </div>
          <div className="flex flex-col mt-2  gap-1">
        <div className="flex items-center space-x-16 justify-between "><span className="text-[#5F5F5F] text-[14px] font-normal">Category</span> <span className="text-[#002D3A] text-[16px] font-medium">{title}</span></div>
        <div className="flex items-center space-x-16 justify-between "><span className="text-[#5F5F5F] text-[14px] font-normal">Specialty</span><span className="text-[#002D3A] text-[16px] font-medium">{service}</span></div>
        <div className="flex items-center space-x-16 justify-between "><span className="text-[#5F5F5F] text-[14px] font-normal">Years of experience</span><span className="text-[#002D3A] text-[16px] font-medium">{yearsOfexperience}</span></div>
        <div className="flex items-center space-x-16 justify-between "><span className="text-[#5F5F5F] text-[14px] font-normal">Base price rate</span><span className="text-[#002D3A] text-[16px] font-medium">₦{price}</span></div>
      </div>
      </div>
    </div>
  )
}

export default Service

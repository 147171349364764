import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { formdata } from "../../../../../../../../../features/formSlice";
import { Button, Input } from "../../../../../../../../../components/ui";
import { Link, useNavigate } from "react-router-dom";

const Services = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    category: "",
    specialty: "",
    experience: "",
    businessname: "",
    businessaddress: "",
    description: "",
  });
  const [errorState, setErrorState] = useState({
    categoryError: "",
    specialtyError: "",
    experienceError: "",
    businessnameError: "",
    businessaddressError: "",
  });

  const validate = () => {
    let isError = false;
    const errors = {
      categoryError: "",
      specialtyError: "",
      experienceError: "",
      businessnameError: "",
      businessaddressError: "",
    };
    if (!details.category) {
      isError = true;
      errors.categoryError = "Please select a category";
    }
    if (!details.specialty) {
      isError = true;
      errors.specialtyError = "Please enter a specialty";
    }
    if (!details.experience) {
      isError = true;
      errors.experienceError = "Please enter your years of experience";
    }
    if (!details.businessname) {
      isError = true;
      errors.businessnameError = "Please enter your business name";
    }
    if (!details.businessaddress) {
      isError = true;
      errors.businessaddressError = "Please enter your business address";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validate();

    if (!error) {
      dispatch(formdata({ service_details: details }));
      navigate("/signup/pricing-details");
    }
  };
  const selectArrow = `
      select{
        -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
      
        background-position: calc(100% - .5rem);
        background-image: url(/dropdown.png);
        background-repeat: no-repeat;     
      }`;

  return (
    <div className="h-screen w-[100%] bg-primary md:bg-bgImage">
      <div className="left-0 right-0 top-[6rem] h-[90vh] overflow-auto py-10 md:absolute md:px-20 md:py-0 lg:px-0">
        <div className=" w-full md:mx-auto lg:w-1/2">
          <div className="flex flex-col justify-center rounded-[5px] bg-primary p-7 md:px-10 md:py-10">
            <span className="mb-8 text-[25px] font-semibold text-secondary md:text-center md:text-[30px]">
              Set up your account
            </span>
            <span className="text-start text-lg font-semibold text-secondary md:text-[20px]">
              Service details
            </span>
            <form className="flex flex-col gap-3 py-6">
              <div className="flex flex-col">
                <label htmlFor="category">
                  Which category do the service you offer fall under?
                </label>
                <select
                  className="rounded-[5px] border border-lightgray bg-primary p-2 text-sm text-lightgray outline-none md:text-base"
                  onChange={handleForm}
                  name="category"
                >
                  <option value="" selected disabled>
                    Select a category
                  </option>
                  <option value="male">Plumbing</option>
                  <option value="female">Entertainment</option>
                </select>
                <span className="text-sm text-[#e62e2e]">
                  {errorState.categoryError}
                </span>
              </div>
              <div>
                <label htmlFor="category">What is your specialty?</label>
                <Input
                  className="min-w-full rounded-[5px] p-2 text-sm md:text-base"
                  onChange={handleForm}
                  type="specialty"
                  name="specialty"
                  placeholder={"Plumbing, make up, graphic design..."}
                />
                <span className="text-sm text-[#e62e2e]">
                  {errorState.specialtyError}
                </span>
              </div>
              <div>
                <label htmlFor="experience">
                  How many years of experience do you have?
                </label>
                <Input
                  className=" min-w-full rounded-[5px] p-2 text-sm md:text-base"
                  onChange={handleForm}
                  type="number"
                  name="experience"
                  min={1}
                />
                <span className="text-sm text-[#e62e2e]">
                  {errorState.experienceError}
                </span>
              </div>
              <div>
                <label htmlFor="experience">Do you have a business name?</label>
                <Input
                  className="min-w-full rounded-[5px] p-2 text-sm md:text-base"
                  onChange={handleForm}
                  type="text"
                  name="businessname"
                  placeholder={"Enter your business name"}
                />
                <span className="text-sm text-[#e62e2e]">
                  {errorState.businessnameError}
                </span>
              </div>

              <div>
                <label htmlFor="experience">
                  What is your business address? (For physical services)
                </label>
                <Input
                  className="min-w-full rounded-[5px] p-2 text-sm md:text-base"
                  onChange={handleForm}
                  type="text"
                  name="businessaddress"
                  placeholder={""}
                />
                <span className="text-sm text-[#e62e2e]">
                  {errorState.businessaddressError}
                </span>
              </div>
              <div>
                <label htmlFor="experience">
                  Briefly describe your service (optional)
                </label>
                <textarea
                  className="min-w-full rounded-[5px] border border-[#181818] p-2 text-sm outline-none md:text-base"
                  onChange={handleForm}
                  type="text"
                  name="description"
                  placeholder={
                    "Tell potential customers more about yourself and your services. "
                  }
                />
              </div>
              <Button
                border="none"
                type="submit"
                children="Proceed"
                className="w-full"
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>
        <style>{selectArrow}</style>
      </div>
    </div>
  );
};

export default Services;

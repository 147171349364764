import React from 'react'
import { accountCreated } from '../../../../../../../constants/images'
import { Button } from '../../../../../../../components/ui'
import { Link } from 'react-router-dom'
const StepEight = () => {
  return (
    <div className='mx-auto rounded-[5px] bg-[#ffff] p-7 lg:w-[830px]'>
        <div className="mx-auto flex flex-col gap-y-10 justify-center items-center">
        <h1 className="text-[#002D3A] font-semibold text-[30px]">You are all set!</h1>
        <img src={accountCreated} alt="" className='w-[20rem]' />
        <Link to="/dashboard">
        <Button children="Proceed to dashboard" border="none" className="w-[70%]"/>
        </Link>
        </div>
    </div>
  )
}

export default StepEight
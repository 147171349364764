import React, { useEffect, useState } from "react";
import { Delete, Edit } from "../../../../../../constants/images";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeleteDiscount,
  setEditDiscount,
  setDiscountDetails,
} from "../../../../../../redux/slice/modalOverlay";

const DiscountCard = () => {
  const discount = useSelector((state) => state.modalOverlay.states.discountDetails);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const handleEditDiscount = () => {
    dispatch(setEditDiscount(true));
  };

  useEffect(() => {
    setDetails(discount);
  }, [discount]);

  const handleDeleteDiscount = () => {
    dispatch(setDeleteDiscount(true));
  };

  return (
    <div className="flex w-full flex-col">
      {Object.keys(details).length === 0 ? (
        <span className="mx-auto text-secondary lg:text-lg">No discounts available.</span>
      ) : (
        <div className="float-end ml-auto grid w-full grid-cols-5 gap-x-10 pb-14">
          <div
            className={`flex h-fit w-full flex-col gap-y-4 rounded-[10px] px-3 py-8 pb-5 shadow-lg transition-all ease-in hover:scale-105 hover:cursor-pointer`}
          >
            <div className="flex w-full justify-between">
              <div className="w-full text-lg font-medium text-secondary">
                {details?.details.discount_name}
              </div>
              <div className="flex w-full items-center justify-end gap-x-4">
                <img src={Edit} alt="edit" onClick={handleEditDiscount} />
                <img src={Delete} alt="delete" onClick={handleDeleteDiscount} />
              </div>
            </div>
            <img src={details.selectedImage} alt="Discount image" className="w-5/6" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscountCard;

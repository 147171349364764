import React from "react";
import { useDispatch } from "react-redux";
import { setShowNotice } from "../../../../../../features/overlay";

const NewChatNotice = () => {
  const dispatch = useDispatch();

  const handleNoticeClick = () => {
    dispatch(setShowNotice(true));
  };

  return (
    <div className="w-full bg-[#FFFCEB] px-8 py-3 md:px-20">
      <span className="text-secondary">
        Messages are <span className="font-semibold">not</span> end-to-end
        encrypted. Please be aware that we actively monitor conversations for
        quality assurance and compliance purposes.
      </span>{" "}
      <br />
      <span
        className="text-lightblue hover:cursor-pointer"
        onClick={handleNoticeClick}
      >
        Learn more
      </span>
    </div>
  );
};

export default NewChatNotice;

import React, { useState } from "react";
import FilterIcon from "../../../../_components/filter-icon";
import InputSearch from "../../../../_components/input_search";
const SubNavbar = ({ handleSendClick, handleSendFilter }) => {
  const [filter, setFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState();
  const [navId, setNavId] = useState(0);
  const navFilters = [
    {
      id: 0,
      filter: "All",
    },
    {
      id: 1,
      filter: "Best matches",
    },
    {
      id: 2,
      filter: "Latest",
    },
    {
      id: 3,
      filter: "Saved",
    },
    {
      id: 4,
      filter: "Applied",
    },
  ];
  const handleClick = () => {
    setFilter(!filter);
    handleSendClick(filter);
  };

  const handleFilter = () => {
    handleSendFilter(activeFilter);
  };
  return (
    <div className="ml-auto h-auto w-full md:mr-4 relative">
      <div className="flex w-full flex-col items-start justify-between px-8 md:pt-[2rem]">
        <div className="flex items-center justify-between w-full md:py-3">
        <span className="text-[30px] font-semibold">Jobs</span>
        <div
          className="ml-auto hidden cursor-pointer items-center justify-end gap-2 rounded-[5px] bg-[#E6F5F9] px-2 text-secondary md:flex md:text-lg"
          onClick={handleClick}
        >
          <FilterIcon fill="#ffffff" className="w-[40%]" />
          <span className="text-lg font-medium">Filters</span>
        </div>
        </div>
        <div className="block pt-3 md:hidden">
          <InputSearch />
        </div>
      </div>

      <div className="no-scrollbar w-full overflow-auto overflow-x-scroll text-nowrap bg-white px-4 text-primary shadow-xl md:overflow-hidden md:pr-10">
        <div className="flex justify-between py-[2rem]">
          {navFilters.map((filter) => (
            <span
              key={filter.id}
              className={`cursor-pointer px-[15px] py-[5px] text-lightgray ${navId === filter.id && "cursor-default rounded-[5px] bg-lightblue text-primary"}`}
              onClick={() => {
                setNavId(filter.id);
                setActiveFilter(filter.filter);
                handleFilter();
              }}
            >
              {filter.filter}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubNavbar;
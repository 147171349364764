import React, { useState } from "react";
import { Button } from "../../../../../../components/ui";
import { useDispatch } from "react-redux";
import { setExtensionForm, setOverlay } from "../../../../../../features/overlay";

const ExtensionForm = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    currentDate: "",
    newDate: "",
    note: "",
  });
  const [errorState, setErrorState] = useState({
    currentDateError: "",
    newDateError: "",
    noteError: "",
  });

  const validate = () => {
    let isError = false;
    const errors = {
      currentDateError: "",
      newDateError: "",
      noteError: "",
    };

    if (!details.currentDate) {
      isError = true;
      errors.currentDateError = "Please enter the current date";
    }
    if (!details.newDate) {
      isError = true;
      errors.newDateError = "Please enter the new date";
    }
    if (!details.note) {
      isError = true;
      errors.noteError = "Please enter reason for extension";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validate();

    if (!error) {
      dispatch(setOverlay(false));
    }
  };

  const handleClose = () => {
    dispatch(setExtensionForm(false));
  };
  return (
    <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white px-4 py-8 lg:w-[529px] lg:rounded-[5px]">
      <div
        className="absolute right-10 top-2 text-2xl hover:cursor-pointer"
        onClick={handleClose}
      >
        X
      </div>
      <div className="flex flex-col justify-center gap-y-4 lg:px-10 lg:py-8">
        <span className="text-center text-xl font-semibold text-secondary">
          Request Delivery Date Extension
        </span>

        <span className="text-base font-normal text-[#181818]" htmlFor="files">
          Upload documents/files (optional)
        </span>
        <div className="flex flex-col gap-y-2 py-">
          <label
            htmlFor="currentDate"
            className=""
          >
            Current delivery date
          </label>

          <input
            type="text"
            name="currentDate"
            placeholder="23 Dec, 2023"
            id="currentDate"
            onChange={handleForm}
            className="rounded-[5px] border border-[#181818] outline-none bg-[#F4F4F4] py-3 px-2"
          />
          <span className="text-xs text-[#e62e2e]">
            {errorState.currentDateError}
          </span>
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="note"
            className="py-2 text-base font-normal text-[#181818]"
          >
            Reason for extension
          </label>
          <textarea
            name="note"
            placeholder="Why do you need the extension?"
            id="note"
            cols="20"
            onChange={handleForm}
            rows="5"
            className="border border-[#181818] px-4 py-2 outline-none"
          ></textarea>
          <span className="text-xs text-[#e62e2e]">{errorState.noteError}</span>
        </div>
        <div className="flex flex-col gap-y-2 py-">
          <label
            htmlFor="current_date"
            className=""
          >
            Proposed new delivery date
          </label>

          <input
            type="text"
            name="newDate"
            placeholder="23 Dec, 2023"
            id="newDate"
            onChange={handleForm}
            className="rounded-[5px] border border-[#181818] outline-none bg-[#F4F4F4] py-3 px-2"
          />
          <span className="text-xs text-[#e62e2e]">
            {errorState.newDateError}
          </span>
        </div>
        <Button onClick={handleSubmit}>Send request</Button>
      </div>
    </div>
  );
};

export default ExtensionForm;

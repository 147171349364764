import React from 'react'


const PopularServices = ({image,heading}) => {
  return (
    <div className="flex flex-col justify-center items-center gap-3 p-3 bg-[#E6F5F9] mx-2 md:mx-0 w-auto h-[140px] rounded-[5px] cursor-pointer">
        <img src={image} alt="" className="max-w-full h-auto"/>
        <span className="text-[18px] font-[500] w-[160px] leading-[21.11px] text-[#002D3A] text-center">{heading}</span>
    </div>
  )
}

export default PopularServices

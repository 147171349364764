import { vendorRound } from "../../../../constants/images"

export const projectsData  = 
[
{
    id: 1,
    title:"Plumbing service for my bathroom",
    address:"5, Adeniyi close, Yaba, Lagos.",
    name:"John Doe",
    ratings:"4.8",
    date:"23 Dec 2023",
    budget:"25,000 ",
    image:vendorRound,
    status:"Ongoing"
},
{
    id: 2,
    title:"Plumbing service for my bathroom",
    address:"5, Adeniyi close, Yaba, Lagos.",
    name:"John Doe",
    ratings:"4.8",
    date:"23 Dec 2023",
    budget:"25,000 ",
    image:vendorRound,
    status:"Completed"
},
{
    id: 3,
    title:"Plumbing service for my bathroom",
    address:"5, Adeniyi close, Yaba, Lagos.",
    name:"John Doe",
    ratings:"4.8",
    date:"23 Dec 2023",
    budget:"25,000 ",
    image:vendorRound,
    status:"Cancelled"
},
]
import React, { useState } from "react";
import ProjectIcon from "../icons/project";
import ChatViewProject from "../chat-view_project";
import { setHideSidebar, setViewProject } from "../../../../../../features/overlay";
import { useDispatch, useSelector } from "react-redux";
import { left } from "../../../../../../constants/images";

const Chatbar = ({ chat, setBackClick }) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const dispatch = useDispatch()
  const viewProject = useSelector(
    (state) => state?.overlay.displayStates.viewProject,
  );
  
  const handleClick = () => {
    setBackClick(selectedChat)
    dispatch(setHideSidebar(false))
  }

  const handleProjectClick = () => {
    dispatch(setViewProject(true))
  };
  return (
    <div className="relative">
      <div className="fixed top-16 z-20 h-fit w-[430px] max-w-full overflow-x-hidden rounded-bl-[20px] rounded-br-[20px] bg-primary px-8 py-6 shadow-xl md:top-[11%] md:w-[54%] md:pt-10">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center space-x-3">
            <img src={left} className="rotate-180" alt="go back" onClick={handleClick} />
            <img
              src={chat?.avatar}
              className="h-[50px] w-[50px]"
              alt="profile"
            />
            <div className="flex flex-col">
              <span className="text-lg font-semibold text-secondary">
                {chat?.title}
              </span>
              <span className="text-sm font-normal text-[#21A601]">Online</span>
            </div>
          </div>
          <div className="relative">
            <div
              className="flex cursor-pointer items-center space-x-2"
              onClick={handleProjectClick}
            >
              <ProjectIcon />
              <span className="text-xs font-normal text-lightgray">
                View project
              </span>
            </div>
          </div>
        </div>
      </div>
      {viewProject && (
        <div className="absolute overflow-hidden w-fit right-0 mt-[7rem] z-[99]">
          <ChatViewProject />
        </div>
      )}
    </div>
  );
};

export default Chatbar;

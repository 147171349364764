import React from "react";
import { Layout } from "../../components";
import Navbar from "./_components/navbar";
import MainContent from "./_components/main-content";

const Notifications = () => {
  return (
    <Layout>
      <Navbar />
      <MainContent />
    </Layout>
  );
};

export default Notifications;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CancelledIcon = () => {
  const [svgColor, setSvgColor] = useState();
  const activeProject = useSelector((state) => state.modalOverlay.states.activeProject);
  useEffect(() => {
    setSvgColor(activeProject);
  }, [activeProject]);
  return (
    <svg width="70" height="33" viewBox="0 0 70 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1908_22771)">
        <path
          d="M5 22.7143C7.35664 22.2392 12.5139 19.7953 16.6961 13.7518C18.2353 11.5275 19.552 9.11377 21.5096 7.24718C28.8356 0.261657 38.4806 -0.868471 47.8302 10.8137C56.283 21.3755 62.7987 21.1647 65 19.7392"
          stroke={`${svgColor === "Cancelled" ? "#FFFFFF" : "#FF392B"}`}
          stroke-width="3"
        />
      </g>
      <circle
        cx="56.4289"
        cy="18.4269"
        r="3.57143"
        fill={`${svgColor === "Cancelled" ? "#FFFFFF" : "#FF392B"}`}
      />
      <defs>
        <filter
          id="filter0_d_1908_22771"
          x="0.703125"
          y="0.5"
          width="69.1113"
          height="31.6836"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.223529 0 0 0 0 0.168627 0 0 0 0.16 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1908_22771" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1908_22771"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CancelledIcon
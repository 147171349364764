import React from "react";
import { setShowNotice } from "../../../../../../../features/overlay";
import { useDispatch } from "react-redux";
import { notice } from "../../../../../../../constants/images";

const NoticeContent = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setShowNotice(false));
  };
  return (
    <div className="relative bg-primary py-12 px-10 rounded-[5px]">
      <div
        className=" absolute right-[23px] top-[31px] text-lg hover:cursor-pointer font-medium text-[#181818]"
        onClick={handleClose}
      >
        X
      </div>
      <div className="flex gap-2 py-2 items-center">
      <img src={notice} alt="notice" />
      <span className="text-secondary text-[20px] font-semibold">Important Notice</span>
      </div>
      <div className="flex flex-col gap-3">
        <span className="text-[#181818] text-base font-normal">
          Chats are actively monitored to ensure a secure and trustworthy
          environment that fosters a positive user experience and prevents
          violation of service terms and conditions.
        </span>
        <>
          <span className="text-[#181818] text-base font-semibold">
            For your safety and adherence to our product's terms of use, please
            be aware of the following
          </span>
          <ul className="list-disc">
            <li className="text-[#181818] text-sm font-normal">
              Maintain in-app discussions, transactions, and service agreements
              to prevent scams and track fraudulent activities.
            </li>
            <li className="text-[#181818] text-sm font-normal">
              For transactions, use only the provided in-app features. During
              conversations, never disclose personal or sensitive information
              such as personal contact details, BVN, and passwords.
            </li>
            <li className="text-[#181818] text-sm font-normal">
              Beware! Attempts to move conversations or transactions off-app are
              suspicious and may lead to account suspension. Report immediately
              to our support team.
            </li>
          </ul>
        </>
        <span className="text-[#181818] text-base font-medium">
          By using this chat, you agree to adhere to these guidelines. Your
          safety and security are our top priorities.
        </span>
      </div>
    </div>
  );
};

export default NoticeContent;

import React from "react";
import { ChooseUs } from "../../../../constants";
import Card from "./_components/card";

const ChooseUsSection = () => {
  return (
    <div className="py-10 overflow-hidden">
      <div className="flex flex-col items-center gap-2">
        <div className="flex items-end w-full justify-center">
        <hr color="#5f5f5f" className="w-[5%]" />
        <span className="text-lightgray text-sm md:text-lg  font-normal">
         Why Choose Us?
        </span>
        
        </div>
        <span className=" md:text-[40px] text-[25px] text-secondary font-semibold">
        Discover the best fit for you
        </span>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 px-10 lg:px-36 md:px-10 gap-4 lg:gap-2 py-10">
        {ChooseUs.map((data) => (
          <Card data={data} key={data.id} />
        ))}
      </div>
    </div>
  );
};

export default ChooseUsSection;

import React, { useState } from "react";
import { sort } from "../../../../../constants/images";
import Select from "../select";
import Filter from "../filter";

const MobileSearch = ({handleOverlay}) => {
  const [showFilter, setShowFilter] = useState(false)
  const [overlay, setShowOverlay] = useState(false)
  const handleFilter = () => {
    setShowFilter(true)
    handleOverlay(overlay)
  }
  const handleCloseFilter = () => {
    setShowFilter(false)
    handleOverlay(!overlay)
  }
  return (
    <div className=" relative flex justify-between  w-full items-center gap-4 px-8 py-4">
     <Select />
      <img
        src={sort}
        alt="sort"
        className="rounded-[5px] bg-lightblue px-4 py-4"
        onClick={handleFilter}
      />
      {showFilter && <Filter closeOverlay={handleCloseFilter} />}
    </div>
  );
};

export default MobileSearch;

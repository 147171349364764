import React from "react";

const ServicesCard = ({ heading, icon, image, text }) => {
  return (
      <div className="flex border-2 border-gray-100 p-3 rounded-[7px] gap-4 md:items-center cursor-pointer ">
        <div className=""><img src={image} alt="" className="w-[3.5rem]" /></div>
        <div className="">
          <div className="flex justify-between">
            <span className="text-[#002D3A] font-semibold md:text-[20px] tetx-[16px] w-[70%] leading-[18.77px] ">{heading}</span>
            <img src={icon} alt="" className="w-[0.5em] h-[0.8rem]" />
          </div>
          <div className="">
            <p className="md:text-[18px] text-[12px] text-[#181818] md:leading-[27px] leading-[21px]">{text}</p>
          </div>
        </div>
      </div>
  );
};

export default ServicesCard;

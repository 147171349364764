import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterIcon, searchicon } from "../../../../../../constants/images";
// import ChatFilter from "../chat-filter/desktop";
import DesktopSearchFilter from "../chat-filter/desktop";
import MobileSearchFilter from "../chat-filter/mobile";
import { setOverlay } from "../../../../../../features/overlay";

const SearchInput = () => {
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const overlayValue = useSelector(
    (state) => state?.overlay.displayStates.overlay,
  );

  const handleMobileClick = () => {
    dispatch(setOverlay(true));
  };
  const handleDispatch = () => {
    setShowFilter(!showFilter);
  };
  return (
    <div className="relative flex w-full items-center justify-between md:block">
      {showFilter && <DesktopSearchFilter />}
      {overlayValue && <MobileSearchFilter />}
      <form action="" className="w-full">
        <div className="relative px-8 md:px-0 flex w-full items-center justify-between gap-x-2 md:pt-6">
          <div className="w-full">
            <input
              type="text"
              onChange={() => {}}
              className="w-full min-w-[100%] rounded-[5px] border border-[#181818] px-12 py-2 outline-none "
              placeholder="Search for chats"
            />{" "}
          </div>
          <div>
            {" "}
            <img
              src={filterIcon}
              className="hidden w-full cursor-pointer rounded-[5px] bg-lightblue px-3 py-[0.75rem] md:block"
              alt="category"
              onClick={handleDispatch}
            />
            <img
              src={filterIcon}
              className="block w-full cursor-pointer rounded-[5px] bg-lightblue px-3 py-[0.75rem] md:hidden"
              alt="category"
              onClick={handleMobileClick}
            />
          </div>
          <img
            src={searchicon}
            alt=""
            className="bottom-3 hidden px-2 md:block lg:absolute"
          />
          <img
            src={searchicon}
            alt="search"
            className="absolute bottom-2 z-10 block px-2 md:hidden"
          />
        </div>
      </form>
    </div>
  );
};

export default SearchInput;

// import React, { useState } from "react";
// import Summary from "../../../../bookService/summary";
// import { visaLogo } from "../../../../../../constants/images";
// import { Button } from "../../../../../../components/ui";
// import GetReciptModal from "./getReciptModal";
// const PaymentDetails = () => {
//     const [getRecipt,setGetRecipt] = useState(false)
//   return (
//     <div className="">
//       <h3 className="text-[20px] font-medium text-[#002D3A]">Payment</h3>
//       <div className="flex gap-14">
//         <div className="w-[40%]">
//           <Summary />
//         </div>
//         <div className="w-[60%]">
//           <span className="text-[16px] text-[#002D3A]">Paid with</span>
//           <div className="mt-3 rounded-[5px] border-[1px] border-[#AEAEAE] p-2">
//             <div className="flex items-center justify-between">
//               <div className="flex items-center gap-4">
//                 <div className="">
//                   <img src={visaLogo} alt="" />
//                 </div>
//                 <div className="flex flex-col">
//                   <span className="text-[16px] font-medium text-[#5F5F5F]">
//                     Visa
//                   </span>
//                   <div className="flex items-center space-x-1">
//                     <div className="flex space-x-1">
//                       <div className="h-[3px] w-[3px] rounded-full bg-[#181818]"></div>
//                       <div className="h-[3px] w-[3px] rounded-full bg-[#181818]"></div>
//                       <div className="h-[3px] w-[3px] rounded-full bg-[#181818]"></div>
//                       <div className="h-[3px] w-[3px] rounded-full bg-[#181818]"></div>
//                     </div>
//                     <span className="text-[16px] font-medium">6789</span>
//                   </div>
//                 </div>
//               </div>
//               <div className="">
//                 <span className="text-[18px] font-medium text-[#002D3A]">
//                   ₦27,000
//                 </span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="mx-auto w-[50%] py-10">
//       <Button children="Get receipt" className="w-full" onClick={()=>setGetRecipt(true)}/>
//       {
//         getRecipt === true && <GetReciptModal setGetRecipt={setGetRecipt}/>
//       }
//       </div>
//     </div>
//   );
// };

// export default PaymentDetails;

import React, { useState } from "react";
import Summary from "../../../../bookService/summary";
import { visaLogo } from "../../../../../../constants/images";
import { Button } from "../../../../../../components/ui";
import GetReciptModal from "./getReciptModal";

const PaymentDetails = () => {
  const [getReceipt, setGetReceipt] = useState(false);

  const openModal = () => {
    setGetReceipt(true);
  };

  return (
    <div className="mt-3 md:mt-0">
      <h3 className="md:text-[20px] text-[18px] md:text-start text-center md:font-medium font-semibold text-[#002D3A]">Payment</h3>
      <div id="payment-details" className="flex md:flex-row flex-col gap-14 ">
        <div className="md:w-[40%] mt-3 md:mt-0">
          <Summary />
        </div>
        <div className="md:w-[60%]">
          <span className="text-[16px] text-[#002D3A] md:block hidden">Paid with</span>
          <div className="mt-3 rounded-[5px] border-[1px] border-[#AEAEAE] md:p-2 p-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div>
                  <img src={visaLogo} alt="Visa Logo" className="md:w-[3rem] w-[2rem]"/>
                </div>
                <div className="flex flex-col">
                  <span className="md:text-[16px] text-[12px] font-medium text-[#5F5F5F]">Visa</span>
                  <div className="flex items-center space-x-1">
                    <div className="flex space-x-1">
                      <div className="h-[3px] w-[3px] rounded-full bg-[#181818]"></div>
                      <div className="h-[3px] w-[3px] rounded-full bg-[#181818]"></div>
                      <div className="h-[3px] w-[3px] rounded-full bg-[#181818]"></div>
                      <div className="h-[3px] w-[3px] rounded-full bg-[#181818]"></div>
                    </div>
                    <span className="md:text-[16px] text-[14px] font-medium">6789</span>
                  </div>
                </div>
              </div>
              <div>
                <span className="md:text-[18px] text-[16px] font-medium text-[#002D3A]">₦27,000</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto w-[50%] py-10">
        <Button onClick={openModal} className="w-full">Get receipt</Button>
      </div>
      {getReceipt && <GetReciptModal setGetRecipt={setGetReceipt} />}
    </div>
  );
};

export default PaymentDetails;

import React, { useState } from "react";
import Navbar from "../../_components/ui/navbar";
import SideBar from "../../_components/ui/sidebar";
import DesktopChat from "./desktop";
import MobileChat from "./mobile";

const VendorDashboardChat = () => {
  return (
    <div>
      <Navbar />
      <SideBar />
      <DesktopChat />
      <MobileChat />
    </div>
  );
};

export default VendorDashboardChat;

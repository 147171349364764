import React from "react";

const Card = ({ image, title, paragraph }) => {
  return (
    <div className="flex flex-col lg:w-full md:w-full w-full p-[2.1rem] rounded-[10px] bg-[#FFFFFF] justify-center items-center  space-y-5">
      <img src={image} alt="image" className="" />
      <h3 className="text-[20px] font-[Work Sans] font-semibold text-[#002D3A] leading-[23.46px]">
        {title}
      </h3>
      <p className="md:text-[16px]   font-[400px] text-[#5F5F5F] leading-[18.77px] text-center text-[18px] min-w-[230px] gap-[10px]">
        {paragraph}
      </p>
    </div>
  
  );
};

export default Card;
import React from "react";
import { EmptyProject, accessmessages } from "../../../../constants/images";
import { Button } from "../../../../components/ui";
import { Link } from "react-router-dom";

const ChatViewProject = ({ setClicked }) => {
  return (
    <div className="flex justify-center items-center bg-[#001E27] bg-opacity-60 fixed inset-0 z-[99]">
      <div className="relative overflow-hidden w-3/4 lg:w-[40%] animate-slide_right bg-primary p-6">
        <div
          className="absolute right-[23px] top-[31px] text-lg font-medium text-[#181818] hover:cursor-pointer"
          onClick={() => setClicked(false)}
        >
          X
        </div>
        <div className="flex flex-col items-center px-8 py-10">
          <img src={accessmessages} alt="projects" />
          <span className="text-center text-base font-normal text-[#181818] mt-5 leading-[21px]">
            You have no project here with John Doe. Once you start a new project,
            you can access it here
          </span>
        </div>
        <Link to="/requestServices" className="w-full">
          <Button children="Request service" border="none" width="100%" />
        </Link>
      </div>
    </div>
  );
};

export default ChatViewProject;

import React from "react";
import { Button } from "../../../../../../../components/ui";
import { verificationLogo } from "../../../../../../../constants/images";
import { Link } from "react-router-dom";

const StepFour = ({ next }) => {
  const handleSubmit = () => {
    next();
  };
  return (
    <div className="left-0 right-0 top-[15rem] h-[50vh] py-10 md:absolute md:px-20 md:py-0 lg:px-0">
      <div className=" w-full md:mx-auto lg:w-1/2">
        <div className="flex flex-col items-center justify-center rounded-[5px] bg-primary p-7 md:px-10 md:py-20">
          <img src={verificationLogo} alt="verfication" />
          <h1 className="text-center text-[30px] font-[600] text-[#002D3A]">
            Verification Successful
          </h1>
          <p className=" mb-7 mt-3 text-center text-[18px] font-[400] leading-[27px] text-[#181818]">
            Click proceed to continue with your account set up
          </p>
          <Link to='/signup/personal-details'>
          <Button
            children="Proceed"
            border="none"
            width="330px"
            type="submit"
            onClick={handleSubmit}
          />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StepFour;

import React from 'react'
import { nothingFound } from '../../../../../../constants/images'
import { Button } from '../../../../../../components/ui'
const CheckWork = () => {
  return (
    <div className='flex flex-col items-center justify-center py-10 md:w-[50%] mx-auto mt-5' >
        <img src={nothingFound} alt="" className='md:w-[18rem] w-[9rem]' />
        <p className="md:text-[18px] text-[16px] md:leading-[21.11px] leading-[18.77px] text-center mt-2">The vendor has not submitted the project yet. You will be notified once it is submitted</p>
      <Button children="Go to projects" className="w-full mt-10"/>
    </div>
  )
}

export default CheckWork

import React from "react";

const ProjectIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={'#002D3A'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 18H6C5.44772 18 5 18.4477 5 19C5 19.5523 5.44772 20 6 20H21V22H6C4.34315 22 3 20.6569 3 19V4C3 2.89543 3.89543 2 5 2H21V18ZM5 16.05C5.16156 16.0172 5.32877 16 5.5 16H19V4H5V16.05ZM16 9H8V7H16V9Z"
        fill="#002D3A"
      />
    </svg>
  );
};

export default ProjectIcon;

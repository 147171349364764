import React from "react";

const ProjectAlert = ({ bg, icon, text, color, sub }) => {
  return (
    <div style={{backgroundColor: `${bg}`, color: `${color}`}} className={`w-full `}>
      <div className="flex md:flex-row flex-col md:items-start py-3 px-3 gap-x-2">
        <img src={icon} alt="icon" className="pt-2 md:w-[25px] w-[16px]" />
        <div className="flex flex-col">
        <span className="md:text-base text-sm text-[#181818]">{text}</span><br />
        <span className="md:text-base text-sm text-[#181818]">{sub}</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectAlert;

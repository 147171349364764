import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { blackLocation, linkarrow } from "../../../constants/images";
import Container from "../container";
import { Button } from "../../../components/ui";

import SendToVendorSuccefully from "./sendTovendorSuccefully";
import ProjectTimeLine from "../projectTimeline";
// import ProjectTimeLine from "../chats/projectTimeline";
const ServiceRequest = () => {
  const [serviceSent, setServiceSent] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [showProjectTimeline, setShowProjectTimeline] = useState(false);
  const handleShowProjectTimeline = () => {
    setShowProjectTimeline(!showProjectTimeline);
  };
 
  const handleEdit = () => {
    navigate("/requestServices", {
      state: { title, address, from, to, budget, image, option, message },
    });
  };
const handelbudget = () => {
    navigate("/bookservice", {budget})
}
  const { title, address, from, to, budget, image, option, message } =
    location.state || {};
  return (
    <Container>
      <div className="mx-auto  w-[85%]  py-24 md:w-[75%] ">
        <div className="relative">
        <h1 className="mb-3 text-center font-semibold text-[#002D3A] md:text-start md:text-[25px]">
          Service Request
        </h1>
        <Link
        to="/requestServices"
        >
        <img src={linkarrow} alt="link arrow" className="md:hidden rotate-180 relative top-[-1.8rem] cursor-pointer"/>
        </Link>
        </div>
        <div className="md:flex md:justify-between">
          <div className="md:w-[50%]">
            <p className="mb-4 md:text-[14px] text-[12px] font-normal text-[#5F5F5F]">
              Status:{" "}
              <span className="md:text-[16px] text-[14px] font-medium text-[#0096C1]">
                Pending confirmation
              </span>
            </p>
            <p className="md:text-[14px] text-[12px] font-normal text-[#5F5F5F]">
              Project Title
            </p>
            <p className="md:text-[18px] text-[16px] font-medium capitalize">{title}</p>
            <p className="mt-3 md:text-[14px] text-[12px] font-normal text-[#5F5F5F]">
              Location
            </p>
            <p className="md:text-[18px] text-[16px] font-medium capitalize">{option}</p>
            <div className="flex items-center space-x-2">
              <div className="">
                <img src={blackLocation} alt="" />
              </div>
              <span className="md:text-[18px] text-[16px] font-medium capitalize">
                {address}
              </span>
            </div>
            <p className="mt-3 md:text-[14px] text-[12px]font-normal text-[#5F5F5F]">Date</p>
            <p className="md:text-[14px] text-[12px] font-normal text-[#5F5F5F]">
              From:{" "}
              <span className="md:text-[16px] tetx-[14px] font-medium text-[#181818]">
                {from}
              </span>
            </p>
            <p className="md:text-[14px] text-[12px] font-normal text-[#5F5F5F]">
              To:{" "}
              <span className="text-[16px] font-medium text-[#181818]">
                {to}
              </span>
            </p>
            <p className="mt-3 md:text-[14px] text-[12px] font-normal text-[#5F5F5F]">
              Budget
            </p>
            <p className="md:text-[18px] text-[16px] font-medium capitalize">₦{budget}</p>
            <p className="mt-3 md:text-[14px] text-[12px] font-normal text-[#5F5F5F]">
              Document
            </p>
            {image && (
              <div>
                <img
                  src={image}
                  alt="Uploaded"
                  className="h-[133.4px] w-[200px]"
                />
              </div>
            )}
            <p className="mt-3 md:text-[14px] text-[12px] font-normal text-[#5F5F5F]">Note</p>
            <p className="md:text-[18px] text-[16px] md:leading-[21.11px] leading-[16.42px]">{message}</p>
            <div className="md:hidden">
              <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
                <div
                  className=" flex cursor-pointer items-center justify-between  "
                  onClick={handleShowProjectTimeline}
                >
                  <span className=" text-[12px] font-normal text-[#5F5F5F]">
                    View project timeline
                  </span>
                  <img
                    src={linkarrow}
                    alt=""
                    className={`${showProjectTimeline && "ease rotate-90 transition-all"}`}
                  />
                </div>
                {showProjectTimeline && (
                  <div className="w-full  px-4 py-3 ">
                  <h1 className="text-[18px] font-semibold text-[#002D3A]">
                    Project timeline
                  </h1>
                  <div className="mt-4">
                    <div className="flex items-start space-x-2">
                      <div className="flex flex-col items-center justify-center">
                        <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
                          <div className="h-3 w-3 rounded-full  bg-[#0096C1]"></div>
                        </div>
                        <div className="h-14 border-r-2 border-[#AEAEAE] border-dotted"></div>
                      </div>
                      <span className="font-medium text-[#5F5F5F]">
                        Vendor confirmation
                      </span>
                    </div>
                    <div className="flex items-start space-x-2">
                      <div className="flex flex-col items-center justify-center">
                        <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
                          
                        </div>
                        <div className="h-14 border-r-2 border-[#AEAEAE] border-dotted"></div>
                      </div>
                      <span className="font-medium text-[#5F5F5F]">
                      Funded (Escrow protection)
                      </span>
                    </div>
                    <div className="flex items-start space-x-2">
                      <div className="flex flex-col items-center justify-center">
                        <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
                          
                        </div>
                        <div className="h-14 border-r-2 border-[#AEAEAE] border-dotted"></div>
                      </div>
                      <span className="font-medium text-[#5F5F5F]">
                      Delivered
                      </span>
                    </div>
                    <div className="flex items-start space-x-2">
                      <div className="flex flex-col items-center justify-center">
                        <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
                          
                        </div>
                        
                      </div>
                      <span className="font-medium text-[#5F5F5F]">
                      Accepted
                      </span>
                    </div>
                  </div>
                </div>
                )}
              </div>
            </div>
            <div className="my-5 flex gap-2">
              <Button
                children="Edit"
                className="w-full border-2 border-[#0096C1]"
                backgroundColor="white"
                textColor="#0096C1"
                border="1px solid #0096C1"
                onClick={handleEdit}
              />

              <Button
                children="Send to Vendor"
                type="submit"
                onClick={() => setServiceSent(true)}
                className="w-full border-2 border-[#0096C1]"
                backgroundColor="#0096C1"
                textColor="white"
                border="1px solid #0096C1"
              />
            </div>
            {
              serviceSent && <SendToVendorSuccefully setServiceSent={setServiceSent}/>
            }
          </div>
          <div className="hidden md:block">
            <ProjectTimeLine />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ServiceRequest;

// import React from 'react'
// import { downloadIcon,email } from '../../../../../../../constants/images'
// import { captureAndDownload } from '../camputureDownload'
// const GetReciptModal = ({setGetRecipt}) => {
//     const handleDownload = () => {
//         captureAndDownload('payment-details');
//       };
//   return (
//     <div className="flex justify-center items-center bg-[#001E27] bg-opacity-60 fixed inset-0 z-[99]  ">
//     <div className="relative overflow-hidden  w-full md:w-[40%] animate-slide_right md:h-auto md:rounded-none rounded-t-[40px] h-[80%] bottom-[-6rem] md:bottom-0 bg-primary p-6">
//       <div
//         className="absolute right-[23px] top-[31px] text-lg font-medium text-[#181818] hover:cursor-pointer"
//         onClick={() => setGetRecipt(false)}
//       >
//         X
//       </div>
//       <div className="">
//         <h3 className="text-[#002D3A] font-semibold text-[20px] mt-6">Get receipt</h3>
//         <span className="text-[#002D3A] text-[18px] font-normal mt-4">Select one of the available options</span>
//         <div className="flex items-center space-x-3 mt-4 cursor-pointer" onClick={handleDownload}>
//             <div className=""><img src={downloadIcon} alt="" /></div>
//             <span className="text-[#5F5F5F] font-medium text-[18px]">Download receipt</span>
//         </div>
//         <div className="flex items-center space-x-3 mt-10 cursor-pointer">
//             <div className=""><img src={email} alt="" /></div>
//             <span className="text-[#5F5F5F] font-medium text-[18px] ">Send receipt</span>
//         </div>
//       </div>

//     </div>
//   </div>
//   )
// }

// export default GetReciptModal

import React from 'react';
import { downloadIcon, email } from '../../../../../../../constants/images';
import { captureAndDownload } from '../camputureDownload'

const GetReciptModal = ({ setGetRecipt }) => {
  const handleDownload = () => {
    captureAndDownload('payment-details');
  };

  return (
    <div className="flex justify-center items-center bg-[#001E27] bg-opacity-60 fixed inset-0 z-[99]">
      <div className="relative overflow-hidden w-full md:w-[40%] animate-slide_right md:h-auto md:rounded-none rounded-t-[40px] h-[80%] bottom-[-6rem] md:bottom-0 bg-primary p-6">
        <div
          className="absolute right-[23px] top-[31px] text-lg font-medium text-[#181818] hover:cursor-pointer"
          onClick={() => setGetRecipt(false)}
        >
          X
        </div>
        <div>
          <h3 className="text-[#002D3A] font-semibold text-[20px] mt-6">Get receipt</h3>
          <span className="text-[#002D3A] text-[18px] font-normal mt-4">Select one of the available options</span>
          <div className="flex items-center space-x-3 mt-4 cursor-pointer" onClick={handleDownload}>
            <div><img src={downloadIcon} alt="Download Icon" /></div>
            <span className="text-[#5F5F5F] font-medium text-[18px]">Download receipt</span>
          </div>
          <div className="flex items-center space-x-3 mt-10 cursor-pointer">
            <div><img src={email} alt="Email Icon" /></div>
            <span className="text-[#5F5F5F] font-medium text-[18px]">Send receipt</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetReciptModal;

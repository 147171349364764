import React from "react";
import { Button } from "../../../../../../../components/ui";
import { verificationLogo } from "../../../../../../../constants/images";

const StepFour = ({next}) => {
  const handleSubmit = () => {
    next()
  }
  return (
    <div className="flex justify-center items-center bg-black bg-opacity-60 fixed top-[10rem] sm:top-0 z-40 min-h-screen w-full ">
        <div className="rounded-[5px] bg-white p-10 ">
          <div className="flex flex-col items-center justify-center">
            <img src={verificationLogo} alt="" />
            <h1 className="text-center text-[30px] font-[600] text-[#002D3A]">
              Verification Successful
            </h1>
            <p className=" mb-7 mt-3 w-[492px] text-center text-[18px] font-[400] leading-[27px] text-[#181818]">
              Click proceed to continue with your account set up
            </p>
            <Button
              children="Proceed"
              border="none"
              width="330px"
              type="submit"
              onClick={handleSubmit}
            />
          </div>
        </div>
    </div>
  );
};

export default StepFour;

import React from 'react'
import Container from '../container'

import DesktopChat from './desktopView'
import MobileChat from './mobileView'

const Chats = () => {

  return (
        <Container>
          <div className="px-4 py-5">
          <DesktopChat/>
          <MobileChat/>
          </div>
        </Container>
  )
}

export default Chats

import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../../../components";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { Dropdown, Fund } from "../../../../../constants/images";
import { useDispatch } from "react-redux";
import { setModalOverlay } from "../../../../../redux/slice/modalOverlay";

const ProjectChart = () => {
  const dispatch = useDispatch();
  const [selectedWeek, setSelectedWeek] = useState("This week");
  const [openSelect, setOpenSelect] = useState(false);
  const data = [
    {
      name: "12/02",
      xv: 50,
      uv: 118,
      pv: 38,     
      amt: 120,
    },
    {
      name: "13/02",
      xv: 20,
      uv: 60,
      pv: 10,
      amt: 10,
    },
    {
      name: "14/02",
      xv: 20,
      uv: 120,
      pv: 35,
      amt: 10,
    },
    {
      name: "15/02",
      uv: 105,
      pv: 23,
      amt: 120,
    },
    {
      name: "16/02",
      uv: 80,
      pv: 23,
      amt: 10,
    },
    {
      name: "17/02",
      uv: 105,
      pv: 23,
      amt: 10,
    },
    {
      name: "18/02",
      xv: 20,
      uv: 85,
      pv: 23,
      amt: 10,
    },
  ];
  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  return (
    <div className="mx-auto mb-10 flex w-full rounded-[10px] bg-primary py-4">
      <div className="flex w-full flex-col">
        <div className="flex w-full items-center justify-between px-10 py-5">
          <span className="font-medium text-secondary md:text-lg">
            Projects
          </span>
          <div className="relative flex items-center gap-x-2">
            <div
              onClick={handleSelect}
              className="flex w-[190px] items-center justify-between rounded-[5px] border border-[#AEAEAE] px-5 py-2 text-base font-medium text-lightgray hover:cursor-pointer"
            >
              <span>{selectedWeek}</span>
              <img
                src={Dropdown}
                alt="open"
                className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
              />
            </div>
            {openSelect && (
              <div className="absolute left-0 top-[100%] w-full animate-slide_up rounded-[10px] bg-primary px-3 py-4">
                <div className="flex flex-col items-center gap-y-2 text-base font-medium text-lightgray">
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedWeek("This week")}
                  >
                    This week
                  </span>

                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedWeek("This month")}
                  >
                    This month
                  </span>
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedWeek("This year")}
                  >
                    This year
                  </span>
                  <span
                    className="text-lightblue hover:cursor-pointer"
                    onClick={() => setSelectedWeek("Custom range")}
                  >
                    Custom range
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr className="w-full px-0" color="#AEAEAE" />
        <div className="flex w-full gap-x-8 p-10">
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#279F51]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
              Ongoing projects
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
                1134
              </span>
            </div>
          </div>
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#623CEA]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
              Completed projects
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
               920
              </span>
            </div>
          </div>
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#C12B00]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
              Cancelled projects
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
               85
              </span>
            </div>
          </div>
        
        </div>
          <BarChart
            width={1200}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 50,
              left: 50,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip /> <Bar dataKey="xv" barSize={20}  stackId="a" fill="#C12B00" />
            <Bar dataKey="pv" barSize={20}  stackId="a" fill="#279F51" />
            <Bar dataKey="uv" barSize={20}  stackId="a" fill="#623CEA" />
           
          </BarChart>
      </div>
    </div>
  );
};

export default ProjectChart;

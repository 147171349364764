import React from "react";
import ReactStars from "react-rating-stars-component";

const Ratings = ({ star, value, isSelected, onCheckboxChange }) => {
  return (
    <div className="flex items-center gap-[13px]">
      <input
        type="checkbox"
        name="rating"
        id="rating"
        value={value}
        checked={isSelected}
        onChange={onCheckboxChange}
      />
      <ReactStars {...star} />
    </div>
  );
};

export default Ratings;

import React, { useState } from 'react'

const Navbar = () => {
    const [activeIndex, setActiveIndex] = useState(0)
  return (
    <div className='w-full bg-white drop-shadow-md py-3 '>
      <div className="flex justify-around text-[18px]  overflow-x-auto whitespace-nowrap no-scrollbar px-2 cursor-pointer">
        <div className={`${activeIndex === 0 && "bg-[#0096C1] text-white py-1 rounded-[5px] px-4"} py-1 px-4`} onClick={()=>setActiveIndex(0)}>All</div>
        <div className={`${activeIndex === 1 && "bg-[#0096C1] text-white py-1 rounded-[5px] px-4"} py-1 px-4`} onClick={()=>setActiveIndex(1)}>Ongoing</div>
        <div className={`${activeIndex === 2 && "bg-[#0096C1] text-white py-1 rounded-[5px] px-4"} py-1 px-4`} onClick={()=>setActiveIndex(2)}>Completed</div>
        <div className={`${activeIndex === 3 && "bg-[#0096C1] text-white py-1 rounded-[5px] px-4"} py-1 px-4`} onClick={()=>setActiveIndex(3)}>Cancelled</div>
      </div>
    </div>
  )
}

export default Navbar

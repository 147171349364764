import React from "react";

const ProjectAlert = ({ bg, icon, text, color }) => {
  return (
    <div style={{backgroundColor: `${bg}`, color: `${color}`}} className={`lg:absolute right-0 top-0`}>
      <div className="flex items-center py-2 px-3 gap-x-2">
        <img src={icon} alt="icon" className="" />
        <span className="md:text-xs text-[8px]">{text}</span>
      </div>
    </div>
  );
};

export default ProjectAlert;

import React from "react";

const Input = ({ type, name, placeholder, width, className, ...props }) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      className={`${className} border-[1px] border-[#5F5F5F] outline-none`}
      {...props}
      required
    />
  );
};

export default Input;

import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { blueChat, starRate,linkarrow } from "../../../../../constants/images";
import { projectsData } from "../../data";
import SummaryReport from "../desktopProject/summaryReport";
import PaymentDetails from "../desktopProject/paymentDetails";
import CheckWork from "../desktopProject/checkWork";
const MobileProject = () => {
  const { id } = useParams();
  const res = projectsData[id];
  const [showService, setShowService] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showCheckWork, setShowCheckWork] = useState(false);
  const handleShowService = () =>{
    setShowService(!showService)
  }
  const handleshowPaymentDetails= () =>{
    setShowPaymentDetails(!showPaymentDetails)
  }
  const handleshowCheckWork = () =>{
    setShowCheckWork(!showCheckWork)
  }
  return (
    <div className="mx-7 py-24 md:hidden ">
      <h3 className="text-center text-[18px] font-semibold text-[#002D3A]">
        Project details
      </h3>
      <div className="mt-5 flex items-center justify-between">
        <div className="flex space-x-2">
          <div className="">
            <img src={res.image} alt="" />
          </div>
          <div className="">
            <span className="text-[16px] font-semibold text-[#002D3A]">
              {res.name}
            </span>
            <div className="flex items-center space-x-1">
              <div>
                {" "}
                <img src={starRate} alt="" />
              </div>
              <span className="text-[12px] font-semibold">{res.ratings}</span>
              <span className="text-[12px] text-[#5F5F5F] ">(31)</span>
            </div>
          </div>
        </div>
        <Link to="/chat">
          <img src={blueChat} alt="" />
        </Link>
      </div>
      <p className="mt-5 text-[12px] text-[#5F5F5F]">Project number: #783451</p>
      <h4 className="text-[16px] font-medium text-[#002D3A]">{res.title}</h4>
      
      <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3 ">
          <div
            className=" flex  cursor-pointer items-center justify-between  "
            onClick={handleShowService}
          >
            <span className="text-center text-[14px] font-medium text-[#5F5F5F]">
            Service report summary
            </span>
            <img
              src={linkarrow}
              alt=""
              className={`${showService && "ease rotate-90 transition-all"}`}
            />
          </div>
          {showService && (
           <SummaryReport/>
          )}
        </div>
        <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
          <div
            className=" flex  cursor-pointer items-center justify-between  "
            onClick={handleshowPaymentDetails}
          >
            <span className="text-center text-[14px] font-medium text-[#5F5F5F]">
            Payment details
            </span>
            <img
              src={linkarrow}
              alt=""
              className={`${showPaymentDetails && "ease rotate-90 transition-all"}`}
            />
          </div>
          {showPaymentDetails && (
           <PaymentDetails/>
          )}
        </div>
        <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
          <div
            className=" flex  cursor-pointer items-center justify-between  "
            onClick={handleshowCheckWork}
          >
            <span className="text-center text-[14px] font-medium text-[#5F5F5F]">
            Check work
            </span>
            <img
              src={linkarrow}
              alt=""
              className={`${showCheckWork && "ease rotate-90 transition-all"}`}
            />
          </div>
          {showCheckWork && (
           <CheckWork/>
          )}
        </div>
    </div>
  );
};

export default MobileProject;

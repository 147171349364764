import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ChatIcon = () => {
  const location = useLocation();
  const [fill, setFill] = useState("#5F5F5F"); // Initialize with a default value
  const [pathname, setPathname] = useState(location.pathname); // Initialize with location.pathname
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]); // Dependency array should include location.pathname

  useEffect(() => {
    const path = pathname?.substring(pathname.lastIndexOf("/") + 1);
    if (path === "chat" && !isMobile) {
      setFill("#fff");
    }
  else if (path === "chat" && isMobile) {
      setFill("#0096C1");
    } else {
      setFill("#5F5F5F");
    }
    console.log(path === "chat" && !isMobile)
  }, [pathname]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM5.76282 17H20V5H4V18.3851L5.76282 17ZM8 10H16V12H8V10Z"
      />
    </svg>
  );
}

export default ChatIcon;


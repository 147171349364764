import React from "react";
import {
  HeroSection,
  ChooseSection,
  FAQSSection,
  VendorBannerSection,
  WorksSection,
} from "./_components";
import { HowItWorksSection } from "../home/_components";
import { Banner, Footer, Navbar } from "../../components/ui";
import { BannerSection } from "../about/_components/";

const BeAVendor = () => {
  return (
    <>
      <Navbar />
      <HeroSection />
      <ChooseSection />
      <BannerSection />
      <WorksSection />
      <FAQSSection />
      <VendorBannerSection />
      <Banner />
      <Footer />
    </>
  );
};

export default BeAVendor;

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { CircularProgressbar,buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

const CardDisputes = ({
  active,
  percentage,
  length,
  text,
  className,
  id,
  trailColor,
  pathColor,
  textColor,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`flex w-full cursor-pointer rounded-[7px] px-7 py-3 drop-shadow-md ${active===id?"bg-[#0096C1] text-white":"bg-white text-[#002D3A]"}`}
>
      <div className="w-full flex gap-5">
        <div className="">
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          className="h-[7rem]"
          styles={buildStyles({
                            textColor: textColor,
                            pathColor: pathColor,
                            trailColor: trailColor,
                          })}
        />
</div>
        <div className="">
          <h1 className={`${ active === id ?"text-[white] text-[40px] font-medium":"text-[#002D3A] text-[40px] font-medium"}`}>{length}</h1>
          <h2 className={`${ active === id ?"text-[white] text-[18px] font-medium":"text-[#5F5F5F] text-[18px] font-medium"}`}>{text}</h2>
        </div>
      </div>
      <h2 className={`${""}`}></h2>
    </div>
  )
}

export default CardDisputes

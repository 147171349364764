import React from 'react'
import DesktopContent from './desktopView'
import MobileView from './mobileview'

const CardContent = () => {
  return (
    <div className=''>
      <DesktopContent/>
      <MobileView/>
    </div>
  )
}

export default CardContent

import React from "react";
import { Button } from "../../../../../../../../../components/ui";
import { accountCreated } from "../../../../../../../../../constants/images";
import { Link } from "react-router-dom";

const Proceed = () => {

  return (
    <div className="h-screen w-[100%] bg-primary md:bg-bgImage">
      <div className="left-0 right-0 top-[6rem] h-[90vh] overflow-auto py-10 md:absolute md:px-20 md:py-0 lg:px-0">
        <div className="mx-auto rounded-[5px] bg-[#ffff] p-7 lg:w-[830px]">
          <div className="mx-auto flex flex-col items-center justify-center gap-y-10">
            <h1 className="text-[30px] font-semibold text-[#002D3A]">
              You are all set!
            </h1>
            <img src={accountCreated} alt="" className="w-[20rem]" />
            <Link to='/vendor-dashboard/home' className="w-[70%]">
            <Button
              children="Proceed to dashboard"
              border="none"
              className="w-full"
            />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proceed;

import React from 'react'

const Navbar = () => {
  return (
    <div className="shadow-lg bg-primary rounded-[10px] rounded-br-none rounded-bl-none mb-10 w-full">
        <div className="text-secondary py-4 px-12 font-medium text-[25px]">Notifications</div>
    </div>
  )
}

export default Navbar
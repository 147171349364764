import React, { useEffect, useState } from "react";
import ProjectsNavbar from "../projects-navbar";
import { projectData } from "../../../../../../../constants";
import { Dropdown } from "../../../../../../../constants/images";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../../../../../components";
import { setProjectId, setShowProject } from "../../../../../../../redux/slice/modalOverlay";
const entries = [
  { id: 0, entry: 10 },
  { id: 0, entry: 20 },
  { id: 0, entry: 30 },
];
const ProjectTable = () => {
  const dispatch = useDispatch()
  const [selectedEntry, setSelectedEntry] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const project = useSelector(
    (state) => state.modalOverlay.states.selectedProject,
  );

  const projectSearch = useSelector(
    (state) => state.modalOverlay.states.projectSearch,
  );

  const projectDataFiltered = project
    ? projectData.filter((value) => value.status === project)
    : projectData.filter((value) => {
        const searchTerm = projectSearch?.toLowerCase();
        const location = value.location?.toLowerCase();
        const title = value.serviceName?.toLowerCase();
        const projectId = value.projectId?.toLowerCase();
        const consumer = value.consumer?.toLowerCase();
        const vendor = value.vendor?.toLowerCase();
        const status = value.status?.toLowerCase();
        return (
          location.includes(searchTerm) ||
          projectId.includes(searchTerm) ||
          title.includes(searchTerm) ||
          consumer.includes(searchTerm) ||
          vendor.includes(searchTerm) ||
          status.includes(searchTerm)
        );
      });

  const [openSelect, setOpenSelect] = useState(false);

  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  // Pagination logic
  const itemsPerPage = selectedEntry;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = projectDataFiltered.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );
  const totalPages = Math.ceil(projectDataFiltered.length / itemsPerPage);
    const handleProjectId = (e) => {
      dispatch(setProjectId(e))
      dispatch(setShowProject(true))
    }
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className="mx-auto flex w-full flex-col rounded-[10px] bg-primary py-4">
      <ProjectsNavbar />
      <div className="grid w-full grid-cols-9 bg-[#E8E8E8] px-6 py-4 text-sm font-medium text-[#181818]">
        <span>Project ID</span>
        <span>Project Title</span>
        <span>Location</span>
        <span>Consumer</span>
        <span>Vendor</span>
        <span>Budget</span>
        <span>Start Date</span>
        <span>End Date</span>
        <span>Status</span>
      </div>
      {currentData.map((value) => (
        <div key={value.id} className="hover:cursor-pointer hover:bg-[#eceaea] ease-in-out transition-colors" onClick={() => handleProjectId(value.id)}>
          <div className="grid w-full grid-cols-9 px-6 py-4">
            <span className="">{value.projectId}</span>
            <span className="">{value.serviceName}</span>
            <span className="">{value.location}</span>
            <span className="">{value.consumer}</span>
            <span className="">{value.vendor}</span>
            <span className="">{value.amount}</span>
            <span className="">{value.start_date}</span>
            <span className="">{value.end_date}</span>
            <span
              className={`${
                value.status === "Ongoing"
                  ? "text-[#0096C1]"
                  : value.status === "Completed"
                    ? "text-[#30e749]"
                    : value.status === "Cancelled"
                      ? "text-[#FF392B]"
                      : ""
              }`}
            >
              {value.status}
            </span>
          </div>
          <hr color="#AEAEAE" className="w-full" />
        </div>
      ))}
      {projectDataFiltered.length === 0 && (
        <div className="flex w-full items-center justify-center pt-4 text-xl text-secondary">
          No search results available for query
        </div>
      )}
      <div className="flex w-full justify-between px-6 pt-3">
        <div className="flex w-full">
          <div className="relative flex items-center gap-x-1">
            <span>Showing</span>
            <div
              onClick={handleSelect}
              className="flex w-fit items-center gap-x-4 px-5 text-base font-normal text-[#181818] hover:cursor-pointer"
            >
              <span>{selectedEntry}</span>
              <img
                src={Dropdown}
                alt="open"
                className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
              />
            </div>
            {openSelect && (
              <div className="absolute bottom-[100%] left-[40%] w-[30%] animate-slide_up  rounded-[10px] bg-primary px-3 py-2">
                <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                  {entries.map((entry) => (
                    <span
                      key={entry.id}
                      className="hover:cursor-pointer"
                      onClick={() => setSelectedEntry(entry.entry)}
                    >
                      {entry.entry}
                    </span>
                  ))}
                </div>
              </div>
            )}
            <span>entries</span>
          </div>
        </div>
        <div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={paginate}
        />
        </div>
      </div>
    </div>
  );
};

export default ProjectTable;

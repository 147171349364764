import React, { useEffect, useState } from "react";
import { Vendors } from "../../../../../constants";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const servicesPerPage = 3;
const arrayForHoldingServices = [];

const ServiceLists = () => {
  const searchValue = useSelector((state) => state.search);
  const [servicesToShow, setServicesToShow] = useState([]);
  const [noServices, setNoServices] = useState(false);
  const [fade, setFade] = useState(false);
  const [count, setCount] = useState(1);

  const loopThroughServices = (count) => {
    for (
      let i = count * servicesPerPage - servicesPerPage;
      i < servicesPerPage * count;
      i++
    ) {
      if (Vendors[i] !== undefined) {
        arrayForHoldingServices.push(Vendors[i]);
      }
    }
    setServicesToShow(arrayForHoldingServices);
  };

  // filter search 
  useEffect(() => {
    const filtered = Vendors.filter((vendor) => vendor.service === searchValue.search)
    setServicesToShow(filtered)

  }, [searchValue])
  // load the first set of posts when the page loads
  // and then set the value of count to 2
  useEffect(() => {
    setCount((prevCount) => prevCount + 1);
    loopThroughServices(count);
  }, []);

  const handleShowMorePosts = () => {
    setCount((prevCount) => prevCount + 1);
    loopThroughServices(count);
    if (Vendors.length === servicesToShow.length) {
      setNoServices(true);
      setFade(!fade);
    }
  };
  return (
    <div className="flex w-full flex-col items-center ">
      <div className="grid w-full gap-20 mb-20  gap-x-28 py-20 md:grid-cols-2 lg:grid-cols-3">
        {servicesToShow.map((vendor) => (
          <Link
            to={"/signup"}
            className="h-[342px] w-[240px] cursor-pointer transition-all ease-in-out hover:scale-105 lg:min-w-[100%]"
            key={vendor.id}
          >
            <div className=" flex flex-col">
              <img src={vendor.image} alt="service" className="" />
              <div className="flex flex-col gap-1 border border-t-0 border-[#AEAEAE] px-4 py-3">
                <img
                  src={vendor.vendor}
                  alt={vendor.name}
                  className="mt-[-3rem] w-[20%]"
                />
                <img src="/like.png" alt="" className="ml-auto" />
                <span className="mx-auto bg-[#FFE3D3] px-[25px] py-[3px] text-center text-xs font-medium text-lightgray">
                  {vendor.title}
                </span>
                <span className="text-base font-bold text-lightblue">
                  {vendor.name}
                </span>
                <span className="text-xs font-semibold text-[#181818] capitalize">
                  {vendor.service}
                </span>
                <div className="flex gap-1">
                  <img src="/location.png" alt="" />
                  <span className="text-xs font-medium text-lightgray">
                    {vendor.location}
                  </span>
                </div>

                <div className="flex justify-between pt-3">
                  <div className="flex items-center gap-1 text-xs">
                    <img src="/rate.png" alt="" />
                    <span className="font-semibold">{vendor.rating}</span>
                    <span className="font-normal">{vendor.rating_count}</span>
                  </div>
                  <div className="my-auto h-[20px] w-[1px] bg-lightgray" />
                  <div className="flex items-center gap-1 text-[#181818]">
                    <span className="text-xs font-normal">From:</span>
                    <span className="text-base font-semibold">
                      ₦{vendor.price}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {noServices && (
        <div
          className={`pt-10 text-lg font-medium capitalize text-lightgray transition-all duration-200 ${fade ? "opacity-100" : "opacity-0"}`}
        >
          No more services available at this time, please check back later
        </div>
      )}
      <button
        className="mt-12 rounded-[5px] border border-lightblue px-5 py-[5px] text-center text-lightblue transition-all ease-in hover:bg-lightblue hover:text-primary"
        onClick={handleShowMorePosts}
      >
        Load more services
      </button>
    </div>
  );
};

export default ServiceLists;

import React from "react";
import { verificationLogo } from "../../../../../../../../../constants/images";
import { setJobDetails, setShowApplication, setShowApplyForm } from "../../../../../../../../../features/overlay";
import { useDispatch } from "react-redux";

const SuccessModal = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setShowApplyForm(false));
  };
  const handleApplication = () => {
    dispatch(setShowApplyForm(false));
    dispatch(setJobDetails(false));
    dispatch(setShowApplication(true))
  };
  return (
    <div className="fixed left-0 right-0 top-[35%] z-[999] block w-full animate-slide_up rounded-tl-[30px] rounded-tr-[30px] bg-primary px-4 py-8 md:hidden">
      <span
        className="absolute right-10 top-4 cursor-pointer text-2xl font-medium hover:scale-105"
        onClick={handleClose}
      >
        X
      </span>
      <div className="flex flex-col items-center justify-center gap-y-4 pt-20">
        <img src={verificationLogo} alt="" className="w-[10rem]" />
        <span className="text-[20px] font-semibold text-secondary">
          Application sent
        </span>
        <span className="text-center text-lg font-normal text-[#181818]">
          Congratulations, your application has been successfully submitted
        </span>
        <span className="cursor-pointer py-10 text-lightblue" onClick={handleApplication}>
          View application details
        </span>
      </div>
    </div>
  );
};

export default SuccessModal;

import React, { useState } from "react";
import DefaultProject from "./defaultProject";
import Container from "../container";
import { useSelector } from "react-redux";
import SearchContent from "./serchContent";
import Navbar from "./navbar";
import Search from "./search";


const Projects = () => {
  const searchParams = useSelector((state) => state.search.homeSearchInput);
  return (
    <Container>
      <div className="px-5 py-24 md:px-16">
      <p className="text-[20px] font-bold text-[#002D3A] md:hidden">
              Projects
            </p>
            <div className="hidden md:block">
              <div className="pb-7 w-[45%]">
                <Search />
              </div>
              <div className="pb-8">
                <Navbar />
              </div>
            </div>
            <div className=" md:hidden">
              <div className="pb-7 w-full">
                <Search />
              </div>
              <div className="pb-8">
                <Navbar />
              </div>
            </div>
   
     {
      searchParams === "" ? (<DefaultProject/> ):(<SearchContent searchParams={searchParams}/>)
     }
     </div>
    </Container>
  );
};

export default Projects;

import React from "react";
import Card from "./_components/card";

const TestimonialsSection = () => {
  return (
    <div className="lg:pl-36 lg:py-20 md:px-12 p-8">
      <div className="flex items-start md:items-center justify-center flex-col">
        <div className="py-10">
        <div className="md:flex items-end ">
          <hr color="#5f5f5f" className="w-[15%]" />
          <span className="capitalize lg:text-lg md:text-base text-sm font-normal text-lightgray">
            Testimonials
          </span>
        </div>
        <span className="text-secondary md:text-[30px] text-[25px] lg:text-[40px] font-semibold">
          Here is what people are saying about us
        </span>
        </div>
      </div>
      <Card />
    </div>
  );
};

export default TestimonialsSection;

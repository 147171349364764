import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, SuccessModal } from "../../../../components";
import { setModalOverlay } from "../../../../redux/slice/modalOverlay";
import {
  NotFound,
  Spinner,
  Star,
  VendorImage,
} from "../../../../constants/images";

const FundModal = () => {
  const dispatch = useDispatch();
  const [detailsModal, setDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successfull, setSuccessfull] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    vendorId: "",
    vendorName: "",
    amount: "",
    description: "",
  });
  const [errorState, setErrorState] = useState({
    vendorIdError: "",
    vendorNameError: "",
    amountError: "",
    descriptionError: "",
  });

  const validate = () => {
    let isError = false;
    const errors = {
      vendorIdError: "",
      vendorNameError: "",
      amountError: "",
      descriptionError: "",
    };

    if (!details.vendorId) {
      isError = true;
      errors.vendorIdError = "Please enter a vendor's ID";
    }
    if (!details.vendorName) {
      isError = true;
      errors.vendorNameError = "Please enter the vendor's name";
    }
    if (detailsModal && !details.amount) {
      isError = true;
      errors.amountError = "Please enter an amount";
    }
    if (detailsModal && !details.description) {
      isError = true;
      errors.descriptionError = "Please enter a description";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validate();

    if (!error) {
      setLoading(true);
      setErrorMessage("");
      setTimeout(() => {
        if (details.vendorId !== "123456") {
          setErrorMessage("No ID found. Please try again");
        } else {
          setDetailsModal(true);
        }
        setLoading(false);
      }, 2000);
    }
  };
  const handleClose = () => {
    dispatch(setModalOverlay(false));
  };
  const handleRefundClick = () => {
    setSuccessfull(false);
    setDetailsModal(false);
  }
  const handleSendClick = () => {
    // send amount and description and show successfull modal
    setSuccessfull(true);
  };

  return (
    <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
      <div
        className="absolute right-10 top-2 text-2xl hover:cursor-pointer"
        onClick={handleClose}
      >
        X
      </div>
      <span className={`px-5 py-2 text-start text-xl font-semibold text-secondary lg:px-10 ${successfull && 'hidden'}`}>
        Fund Vendor’s Account
      </span>
      {!detailsModal ? (
        <div className="flex flex-col justify-center">
          {errorMessage !== "" && (
            <div className="my-3 flex w-full items-center gap-x-2 bg-[#FFE7E7] px-5 py-3 lg:px-10">
              <img src={NotFound} alt="not found" />
              <span className="text-base font-medium text-[#FF0000]">
                {errorMessage}
              </span>
            </div>
          )}
          <div className="flex flex-col gap-y-2 px-5 py-2 lg:px-10">
            <label htmlFor="vendorId" className="">
              Input vendor’s ID
            </label>

            <input
              type="text"
              name="vendorId"
              id="vendorId"
              onChange={handleForm}
              className="rounded-[5px] border border-[#181818] px-2 py-3 outline-none"
            />
            <span className="text-xs text-[#e62e2e]">
              {errorState.vendorIdError}
            </span>
          </div>
          <div className="flex flex-col gap-y-2 px-5 py-2 lg:px-10">
            <label
              htmlFor="vendorName"
              className="py-2 text-base font-normal text-[#181818]"
            >
              Input vendor’s name
            </label>
            <input
              type="text"
              name="vendorName"
              id="vendorName"
              onChange={handleForm}
              className="rounded-[5px] border border-[#181818] px-2 py-3 outline-none"
            />
            <span className="text-xs text-[#e62e2e]">
              {errorState.vendorNameError}
            </span>
          </div>
          <div className="w-full lg:px-10 lg:py-8">
            <Button
              onClick={handleSubmit}
              className={"flex w-full items-center justify-center"}
              disabled={loading}
            >
              {loading ? (
                <img src={Spinner} alt="loading" className="w-[8%]" />
              ) : (
                "Confirm details"
              )}
            </Button>
          </div>
        </div>
      ) : (
        <>
        <div className={`flex flex-col justify-center ${successfull ? 'hidden' : 'flex'}`}>
          <div className="mx-10 my-4 rounded-[20px] border border-[#AEAEAE]">
            <div className="flex flex-col">
              <div className="flex justify-between px-7 py-4">
                <span className="text-base font-semibold text-[#181818]">
                  Vendor's details
                </span>
                <span
                  className="text-sm font-semibold text-lightblue hover:cursor-pointer"
                  onClick={() => setDetailsModal(false)}
                >
                  Edit
                </span>
              </div>
              <hr className="w-full" color="" />
              <div className="flex items-center gap-x-5 px-7 py-4">
                <img
                  src={VendorImage}
                  className="h-[120px] w-[120px] rounded-[50%]"
                  alt="vendor image"
                />
                <div className="flex flex-col">
                  <span className="text-base font-semibold text-lightgray">
                    ID: 123456
                  </span>
                  <span className="text-[25px] font-semibold text-secondary">
                    Tola Ayo
                  </span>
                  <span className="text-xl font-medium text-[#181818]">
                    Plumber
                  </span>
                  <div className="flex items-center gap-x-1 text-base text-[#181818]">
                    <img src={Star} alt="ratings" />
                    <span className="font-semibold">4.8</span>
                    <span className="font-normal">(31)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2 px-5 py-2 lg:px-10">
            <label htmlFor="vendorId" className="">
              Input amount (₦)
            </label>

            <input
              type="text"
              name="amount"
              id="amount"
              value={details.amount}
              onChange={handleForm}
              className="rounded-[5px] border border-[#181818] px-2 py-3 outline-none"
            />
            <span className="text-xs text-[#e62e2e]">
              {errorState.amountError}
            </span>
          </div>
          <div className="flex flex-col gap-y-2 px-5 py-2 lg:px-10">
            <label
              htmlFor="vendorName"
              className="py-2 text-base font-normal text-[#181818]"
            >
              Description
            </label>
            <input
              type="text"
              name="description"
              id="description"
              value={details.description}
              onChange={handleForm}
              className="rounded-[5px] border border-[#181818] px-2 py-3 outline-none"
            />
            <span className="text-xs text-[#e62e2e]">
              {errorState.descriptionError}
            </span>
          </div>
          <div className="w-full lg:px-10 lg:py-8">
            <Button
              onClick={handleSendClick}
              className={"flex w-full items-center justify-center"}
              disabled={loading}
            >
              {loading ? (
                <img src={Spinner} alt="loading" className="w-[8%]" />
              ) : (
                "Send"
              )}
            </Button>
          </div>
        </div>
        <div className={`${successfull ? 'block' : 'hidden'}`}>
        <SuccessModal onClick={handleRefundClick} title=" Vendor’s account funded successfully" buttonText="Fund another account" />
        </div>
        </>
      )}
      
    </div>
  );
};

export default FundModal;

import { useSelector } from "react-redux";
import { whitelogo } from "../../constants/images";
import { logo } from "../../constants/images";
import CategorySelect from "./_components/steps/category-select";
import ConsumerForm from "./_components/steps/consumer/_components/form";
import VendorForm from "./_components/steps/vendor/_components/form";
import { Link } from "react-router-dom";

const SignUp = ({ currentStep, handleNextStep }) => {
  const category = useSelector((state) => state.category);
  return (
    <div className="h-screen w-[100%] bg-primary md:bg-bgImage">
      <div className="">
        <Link
          to="/"
          className="mb-7 hidden items-center justify-center pt-7 md:flex"
        >
          <img src={whitelogo} alt="gywde" />
        </Link>
        <Link to='/' className="items-center justify-start py-4 flex md:hidden">
          <img src={logo} alt="gywde" className="pl-7" />
        </Link>
        {category.category === "category" && (
          <CategorySelect next={handleNextStep} />
        )}
        {/* render form category based on dispatched state from CategorySelect */}

        {category.category === "consumer" && (
          <ConsumerForm
            currentStep={currentStep}
            handleNextStep={handleNextStep}
          />
        )}
        {category.category === "vendor" && (
          <VendorForm
            currentStep={currentStep}
            handleNextStep={handleNextStep}
          />
        )}
      </div>
    </div>
  );
};

export default SignUp;

import React, { useRef, useState } from "react";
import { PaystackButton, PaystackConsumer } from "react-paystack";
import Container from "../container";
import { Input } from "../../../components/ui";
import Summary from "./summary";


const BookService = () => {
  const fileRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [amount, setAmount] = useState("");

  // const handleCardUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     Tesseract.recognize(file, "eng", {
  //       logger: (m) => console.log(m),
  //     }).then(({ data: { text } }) => {
  //       console.log(text);
  //       parseCardDetails(text);
  //     });
  //   }
  // };

  // const parseCardDetails = (text) => {
  //   const cardNumberMatch = text.match(/\b\d{4} \d{4} \d{4} \d{4}\b/);
  //   const expiryDateMatch = text.match(/\b\d{2}\/\d{2}\b/);
  //   const cvvMatch = text.match(/\b\d{3}\b/);

  //   if (cardNumberMatch) setCardNumber(cardNumberMatch[0].replace(/ /g, ""));
  //   if (expiryDateMatch) setExpiryDate(expiryDateMatch[0]);
  //   if (cvvMatch) setCvv(cvvMatch[0]);
  // };

  const email = "user@example.com";
  const publicKey = "pk_test_859b9fc407e1c35e10048f58843f7fa191dee3ac";
  
  // const paystackAmount = parseInt(amount) * 100; // Convert amount to kobo for Paystack
  const paystackAmount = parseInt(25000) * 100;
  const onSuccess = (reference) => {
    console.log(reference);
    setIsOpen(false);
  };

  const onClose = () => {
    console.log("Transaction was not completed, window closed.");
    setIsOpen(false);
  };

  const componentProps = {
    email,
    amount: paystackAmount,
    publicKey,
    text: "Pay Now",
    onSuccess,
    onClose,
    className: "custom-paystack-button",
  };

  return (
    <Container>
      <div className="px-10 py-24">
        <div className="md:flex justify-around">
          <div className="flex md:w-[50%] w-full flex-col gap-7 md:pr-7">
            <h1 className="font-semibold text-[#002D3A] md:text-[30px] text-[20px]">
              Book Service
            </h1>
            <p className="font-medium text-[#002D3A] md:text-[18px] text-[14px]">
              Make payment into Escrow
            </p>

            <div className="">
              <p className="md:text-[18px] text-[14px]">Promo Code (Optional)</p>
              <Input
                className="w-full rounded-[4px] p-2"
                type="number"
                value={amount}
                // onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="w-full md:hidden">
            <Summary amount={amount} />
          </div>
            <h3 className="md:text-[20px] text-[16px] font-medium text-[#5F5F5F]">
              Payment method
            </h3>
            <div className="">
              <span
                className=" pr-2 md:text-[22px] text-[20px] font-semibold text-[#0096C1]"
                // onClick={() => fileRef.current.click()}
              >
                +
              </span>
              <span className="md:text-[18px] font-medium text-[#5F5F5F]">
                Add Card Details
              </span>
              {/* <input
                type="file"
                onChange={handleCardUpload}
                style={{ display: "none" }}
                ref={fileRef}
              /> */}
            </div>
            <div className="">
              <p className="">Card number</p>
              <Input
                className="w-full p-2"
                type="text"
                // value={cardNumber}
                placeholder="1234 5678 9012 3456"
                // readOnly
              />
            </div>
            <div className="flex w-full gap-4">
              <div className="w-full">
                <p className="">Expiry date</p>
                <Input
                  className="w-full p-2"
                  type="text"
                  // value={expiryDate}
                  placeholder="MM/YY"
                  // readOnly
                />
              </div>
              <div className="w-full">
                <p className="">CVV</p>
                <Input
                  className="w-full p-2"
                  type="text"
                  // value={cvv}
                  placeholder="123"
                  // readOnly
                />
              </div>
            </div>
            <>
              <button
                onClick={() => setIsOpen(true)}
                className="rounded-lg bg-[#0096C1] px-4 py-2 text-white shadow-lg transition duration-300 hover:bg-[#3c91a8]"
              >
                Proceed with Payment
              </button>
              {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#002D3A] bg-opacity-50">
                  <div className="relative flex h-[30%] w-11/12 items-center justify-center rounded-lg bg-white p-6 shadow-lg sm:w-1/2 lg:w-1/3">
                    <PaystackConsumer {...componentProps}>
                      {({ initializePayment }) => (
                        <div className="w-full">
                          <button
                            onClick={() =>
                              initializePayment(onSuccess, onClose)
                            }
                            className="w-full rounded-lg bg-[#0096C1] px-4 py-2 text-white shadow-lg transition duration-300 hover:bg-[#3c91a8]"
                          >
                            Pay Now
                          </button>
                        </div>
                      )}
                    </PaystackConsumer>
                    <span
                      onClick={() => setIsOpen(false)}
                      className="absolute right-5 top-0 mt-4 cursor-pointer text-black"
                    >
                      x
                    </span>
                  </div>
                </div>
              )}
            </>
          </div>
          <div className="w-[37%] md:block hidden">
            <Summary amount={amount} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BookService;



import React, { useState } from "react";
import {
  Dropdown,
  DropdownWhite,
  SearchIcon,
} from "../../../../../../../constants/images";
import { setSelectedTransaction, setTransactionSearch } from "../../../../../../../redux/slice/modalOverlay";
import { useDispatch } from "react-redux";

const TransactionsNavbar = () => {
  const dispatch = useDispatch()
  const [openSelect, setOpenSelect] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [transaction, setTransaction] = useState("Escrow Payments");
  const [selectedSort, setSelectedSort] = useState("");
  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };
  const handleSort = () => {
    setOpenSort(!openSort);
  };
  const handleTransactionSelect = (e) => {
    setTransaction(e);
    dispatch(setSelectedTransaction(e));
  };

  const handleChange  = (e) => {
    dispatch(setTransactionSearch(e.target.value))
  }
  return (
    <div className="mx-auto flex w-full justify-between px-6 py-4">
      <div className="relative flex items-center gap-x-2">
        <span className="font-medium text-secondary md:text-lg">
          Transactions:
        </span>
        <div
          onClick={handleSelect}
          className="flex w-full items-center gap-x-4 border border-[#AEAEAE] px-3 py-2 text-base font-normal text-[#181818] hover:cursor-pointer"
        >
          <span>{transaction}</span>
          <img
            src={Dropdown}
            alt="open"
            className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
          />
        </div>
        {openSelect && (
          <div className="absolute left-[40%] top-[100%] w-[60%] animate-slide_up  rounded-[10px] bg-primary px-3 py-4">
            <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
              <span
                className="hover:cursor-pointer"
                onClick={() => handleTransactionSelect("Escrow Payments")}
              >
                Escrow Payments
              </span>
              <span
                className="hover:cursor-pointer"
                onClick={() => handleTransactionSelect("Funds Released")}
              >
                Funds Released
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center gap-x-2">
        <div className="relative ml-16 flex w-full items-center justify-center">
          <input
            type="text"
            name="search"
            id="search"
            onChange={handleChange}
            className="w-full rounded-[5px] border border-[#AEAEAE] px-10 py-2 placeholder:text-xs placeholder:font-medium placeholder:text-[#AEAEAE] md:placeholder:text-sm "
            placeholder="Search here"
          />{" "}
          <img src={SearchIcon} alt="search" className="absolute left-0 px-2" />
        </div>
        <div className="relative flex w-1/2 items-center justify-between gap-x-4 rounded-[5px] bg-lightblue px-5 py-2 text-sm font-medium text-primary hover:cursor-pointer">
          <span>Sort by</span>
          <img
            src={DropdownWhite}
            alt="open"
            className={`${openSort && "-rotate-90 transition-all ease-in-out"}`}
            onClick={handleSort}
          />
          {openSort && (
            <div className="absolute left-0 top-[100%] w-[180px] animate-slide_up  rounded-[10px] bg-primary px-2 py-6">
              <div className="flex flex-col items-center w-full gap-y-2 text-base font-normal text-[#181818]">
                <span
                  className="hover:cursor-pointer"
                  onClick={() => setSelectedSort("")}
                >
                  Newest -Oldest
                </span>
                <span
                  className="hover:cursor-pointer"
                  onClick={() => setSelectedSort("")}
                >
                  Oldest - Newest
                </span>
                <span
                  className="hover:cursor-pointer"
                  onClick={() => setSelectedSort("")}
                >
                  Location
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionsNavbar;

import { BusinessIcon, DashboardIcon, DisputeIcon, NotificationsIcon } from "./images";

export const sideLinks = [
  { id: 0, link: "Dashboard", to: "dashboard", icon: DashboardIcon },
  {
    id: 1,
    link: "Dispute Resolution",
    to: "dispute&resolution",
    icon: DisputeIcon,
  },
  {
    id: 2,
    link: "Notifications",
    to: "notifications",
    icon: NotificationsIcon,
  },
  { id: 3, link: "Business Suite", to: "business&suite", icon: BusinessIcon },
];

export const allVendors = [
  {
    id: 0,
    userId: "012345",
    name: "Tim Mark",
    phone: "08098765432",
    email: "johndoe@xyz.com",
    gender: "Male",
    open_day: "Mon-Fri",
    join_date: "Jan 12, 2023",
    title: "Johnny Plumbing Service",
    specialty: "Plumbing",
    service: "Plumber",
    rating: "4.9",
    count: "31",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    address: "5, Adeniyi close, Yaba, Lagos.",
    location: "Yaba, Lagos",
    category: "Repair and maintenance",
    exp: "5",
    base_price: "10,000",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    project_status: "Ongoing",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    status: "Verified",
  },
  {
    id: 1,
    userId: "012345",
    name: "Tim Mark",
    phone: "08098765432",
    email: "johndoe@xyz.com",
    gender: "Male",
    open_day: "Mon-Fri",
    join_date: "Jan 12, 2023",
    title: "Johnny Plumbing Service",
    specialty: "Plumbing",
    service: "Plumber",
    rating: "4.9",
    count: "31",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    address: "5, Adeniyi close, Yaba, Lagos.",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    location: "Yaba, Lagos",
    category: "Repair and maintenance",
    exp: "5",
    base_price: "10,000",
    project_status: "Ongoing",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    status: "Unverified",
  },
  {
    id: 2,
    userId: "012345",
    name: "Tim Mark",
    phone: "08098765432",
    email: "johndoe@xyz.com",
    gender: "Male",
    open_day: "Mon-Fri",
    join_date: "Jan 12, 2023",
    title: "Johnny Plumbing Service",
    specialty: "Plumbing",
    service: "Plumber",
    rating: "4.9",
    count: "31",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    address: "5, Adeniyi close, Yaba, Lagos.",
    location: "Yaba, Lagos",
    category: "Repair and maintenance",
    exp: "5",
    base_price: "10,000",
    project_status: "Ongoing",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    status: "Verified",
  },
  {
    id: 3,
    userId: "012345",
    name: "Tim Mark",
    phone: "08098765432",
    email: "johndoe@xyz.com",
    gender: "Male",
    open_day: "Mon-Fri",
    join_date: "Jan 12, 2023",
    title: "Johnny Plumbing Service",
    specialty: "Plumbing",
    service: "Plumber",
    rating: "4.9",
    count: "31",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    address: "5, Adeniyi close, Yaba, Lagos.",
    location: "Yaba, Lagos",
    category: "Repair and maintenance",
    exp: "5",
    base_price: "10,000",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    project_status: "Ongoing",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    status: "Verified",
  },
  {
    id: 4,
    userId: "012345",
    name: "Tim Mark",
    phone: "08098765432",
    email: "johndoe@xyz.com",
    gender: "Male",
    open_day: "Mon-Fri",
    join_date: "Jan 12, 2023",
    title: "Johnny Plumbing Service",
    specialty: "Plumbing",
    service: "Plumber",
    rating: "4.9",
    count: "31",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    address: "5, Adeniyi close, Yaba, Lagos.",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    location: "Yaba, Lagos",
    category: "Repair and maintenance",
    exp: "5",
    base_price: "10,000",
    project_status: "Ongoing",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    status: "Verified",
  },
  {
    id: 5,
    userId: "012345",
    name: "Tim Mark",
    phone: "08098765432",
    email: "johndoe@xyz.com",
    gender: "Male",
    open_day: "Mon-Fri",
    join_date: "Jan 12, 2023",
    title: "Johnny Plumbing Service",
    specialty: "Plumbing",
    service: "Plumber",
    rating: "4.9",
    count: "31",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    address: "5, Adeniyi close, Yaba, Lagos.",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    location: "Yaba, Lagos",
    category: "Repair and maintenance",
    exp: "5",
    base_price: "10,000",
    project_status: "Ongoing",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    status: "Incomplete",
  },
];

export const allNotifications = [
  {
    id: 0,
    day: "Today",
    notification: [
      {
        id: 0,
        title: "New User Registration",
        content:
          "Yorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.",
        time: "4:29 PM",
        read: true,
      },
      {
        id: 1,
        title: "Dispute Resolution",
        content:
          "Yorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan.",
        time: "1:33 PM",
        read: true,
      },
    ],
  },
  {
    id: 1,
    day: "Yesterday",
    notification: [
      {
        id: 0,
        title: "Feedback received",
        content:
          "Yorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus..",
        time: "4:29 PM",
        read: true,
      },
      {
        id: 1,
        title: "Project Update",
        content:
          "Yorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan.",
        time: "1:33 PM",
        read: false,
      },
      {
        id: 1,
        title: "Escrow Funded",
        content:
          "Yorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan.",
        time: "9:45 PM",
        read: false,
      },
    ],
  },
  {
    id: 2,
    day: "This week",
    notification: [
      {
        id: 0,
        title: "Feedback received",
        content:
          "Yorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.",
        time: "15 Dec 2024",
        read: false,
      },
    ],
  },
];
export const allProjects = [
  {
    id: 0,
    project_number: "#783451",
    rating: "4.9",
    due: true,
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Due",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Ongoing",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 1,
    project_number: "#783451",
    rating: "4.9",
    due: false,
    cancelled_date: "21 Dec, 2023",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 2,
    project_number: "#783451",
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Graphic designer for brand design",
    location: "Remote",
    location_status: "Remote",
    hire: "Grace Ola",
    status: "Submitted",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Completed",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Ongoing",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Ongoing",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Ongoing",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 3,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    cancelled_date: "23 Dec, 2023",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
];

export const transactionData = [
  {
    id: 0,
    transationId: "012345",
    projectId: "#783451",
    method: "Escrow Payments",
    serviceName: "Plumbing service for my bathroom",
    consumer: "Tim Mark",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 1,
    transationId: "012348",
    projectId: "#783451",
    method: "Escrow Payments",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 2,
    transationId: "012348",
    projectId: "#783451",
    method: "Escrow Payments",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 3,
    transationId: "012348",
    projectId: "#783451",
    method: "Escrow Payments",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 4,
    transationId: "012348",
    projectId: "#783451",
    method: "Escrow Payments",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 6,
    transationId: "012348",
    projectId: "#783451",
    method: "Escrow Payments",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 5,
    transationId: "012348",
    projectId: "#783451",
    method: "Escrow Payments",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 7,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 8,
    transationId: "012348",
    projectId: "#783451",
    method: "Escrow Payments",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 9,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 10,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 11,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 12,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    method: "Funds Released",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    date: "Jan 12, 2023",
  },
];

export const projectData = [
  {
    id: 0,
    projectId: "#783451",
    serviceName: "Plumbing service for my bathroom",
    consumer: "Tim Mark",
    vendor: "John Doe",
    amount: "30,000",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Ongoing",
  },
  {
    id: 1,
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Cancelled",
  },
  {
    id: 2,
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Completed",
  },
  {
    id: 3,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Ongoing",
  },
  {
    id: 4,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Ongoing",
  },
  {
    id: 6,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Ongoing",
  },
  {
    id: 5,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Ongoing",
  },
  {
    id: 7,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Ongoing",
  },
  {
    id: 8,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Cancelled",
  },
  {
    id: 9,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Cancelled",
  },
  {
    id: 10,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Cancelled",
  },
  {
    id: 11,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Cancelled",
  },
  {
    id: 12,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Completed",
  },
  {
    id: 13,
    transationId: "012348",
    projectId: "#783451",
    serviceName: "Graphic designer for brand design",
    consumer: "Mary Ojo",
    vendor: "John Doe",
    amount: "30,000",
    commission: "1,200",
    location: "Online",
    start_date: "Jan 12, 2023",
    end_date: "Jan 12, 2023",
    status: "Completed",
  },
];

export const consumerData = [
  {
    id: 0,
    userId: "012345",
    name: "Tim Mark",
    phone: "08098765432",
    email: "johndoe@xyz.com",
    location: "Yaba, Lagos",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 1,
    userId: "012348",
    name: "Mary Ojo",
    phone: "08098765432",
    email: "maryojo@xyz.com",
    location: "Iwo, Ibadan",
    gender: "Female",
    join_date: "Jan 12, 2023",
  },
  {
    id: 2,
    userId: "012399",
    name: "Emeka Eze",
    phone: "08098765432",
    email: "Nil",
    location: "Yaba, Lagos",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 3,
    userId: "012389",
    name: "Tim Mark",
    phone: "08098765432",
    email: "timmark@xyz.com",
    location: "Owo, Ibadan",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 4,
    userId: "012345",
    name: "Tim Mark",
    phone: "08098765432",
    email: "johndoe@xyz.com",
    location: "Yaba, Lagos",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 5,
    userId: "012348",
    name: "Mary Ojo",
    phone: "08098765432",
    email: "maryojo@xyz.com",
    location: "Iwo, Ibadan",
    gender: "Female",
    join_date: "Jan 12, 2023",
  },
  {
    id: 6,
    userId: "012399",
    name: "Emeka Eze",
    phone: "08098765432",
    email: "Nil",
    location: "Yaba, Lagos",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 7,
    userId: "012389",
    name: "Tim Mark",
    phone: "08098765432",
    email: "timmark@xyz.com",
    location: "Owo, Ibadan",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 8,
    userId: "012348",
    name: "Mary Ojo",
    phone: "08098765432",
    email: "maryojo@xyz.com",
    location: "Iwo, Ibadan",
    gender: "Female",
    join_date: "Jan 12, 2023",
  },
  {
    id: 9,
    userId: "012399",
    name: "Emeka Eze",
    phone: "08098765432",
    email: "Nil",
    location: "Yaba, Lagos",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 10,
    userId: "012389",
    name: "Tim Mark",
    phone: "08098765432",
    email: "timmark@xyz.com",
    location: "Owo, Ibadan",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 11,
    userId: "012348",
    name: "Mary Ojo",
    phone: "08098765432",
    email: "maryojo@xyz.com",
    location: "Iwo, Ibadan",
    gender: "Female",
    join_date: "Jan 12, 2023",
  },
  {
    id: 12,
    userId: "012399",
    name: "Emeka Eze",
    phone: "08098765432",
    email: "Nil",
    location: "Yaba, Lagos",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 13,
    userId: "012389",
    name: "Tim Mark",
    phone: "08098765432",
    email: "timmark@xyz.com",
    location: "Owo, Ibadan",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 14,
    userId: "012348",
    name: "Mary Ojo",
    phone: "08098765432",
    email: "maryojo@xyz.com",
    location: "Iwo, Ibadan",
    gender: "Female",
    join_date: "Jan 12, 2023",
  },
  {
    id: 15,
    userId: "012399",
    name: "Emeka Eze",
    phone: "08098765432",
    email: "Nil",
    location: "Yaba, Lagos",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
  {
    id: 16,
    userId: "012389",
    name: "Tim Mark",
    phone: "08098765432",
    email: "timmark@xyz.com",
    location: "Owo, Ibadan",
    gender: "Male",
    join_date: "Jan 12, 2023",
  },
];

export const vendorData = [
  {
    id: 0,
    userId: "012345",
    name: "Tim Mark",
    phone: "08098765432",
    email: "johndoe@xyz.com",
    location: "Yaba, Lagos",
    gender: "Male",
    service: "Repair and maintenance",
    join_date: "Jan 12, 2023",
    status: "Incomplete",
  },
  {
    id: 1,
    userId: "012348",
    name: "Mary Ojo",
    phone: "08098765432",
    email: "maryojo@xyz.com",
    location: "Iwo, Ibadan",
    gender: "Female",
    service: "Transport and logistics",
    join_date: "Jan 12, 2023",
    status: "Incomplete",
  },
  {
    id: 2,
    userId: "012399",
    name: "Emeka Eze",
    phone: "08098765432",
    email: "Nil",
    location: "Yaba, Lagos",
    gender: "Male",
    service: "IT and digital services",
    join_date: "Jan 12, 2023",
    status: "Incomplete",
  },
  {
    id: 3,
    userId: "012389",
    name: "Tim Mark",
    phone: "08098765432",
    email: "timmark@xyz.com",
    location: "Owo, Ibadan",
    gender: "Male",
    service: "Education services",
    join_date: "Jan 12, 2023",
    status: "Incomplete",
  },
  {
    id: 4,
    userId: "012345",
    name: "Tim Mark",
    phone: "08098765432",
    email: "johndoe@xyz.com",
    location: "Yaba, Lagos",
    gender: "Male",
    service: "Repair and maintenance",
    join_date: "Jan 12, 2023",
    status: "Incomplete",
  },
  {
    id: 5,
    userId: "012348",
    name: "Mary Ojo",
    phone: "08098765432",
    email: "maryojo@xyz.com",
    location: "Iwo, Ibadan",
    gender: "Female",
    service: "Cleaning services",
    join_date: "Jan 12, 2023",
    status: "Incomplete",
  },
  {
    id: 6,
    userId: "012399",
    name: "Emeka Eze",
    phone: "08098765432",
    email: "Nil",
    location: "Yaba, Lagos",
    gender: "Male",
    service: "Transport and logistics",
    join_date: "Jan 12, 2023",
    status: "Incomplete",
  },
  {
    id: 7,
    userId: "012389",
    name: "Tim Mark",
    phone: "08098765432",
    email: "timmark@xyz.com",
    location: "Owo, Ibadan",
    gender: "Male",
    service: "Repair and maintenance",
    join_date: "Jan 12, 2023",
    status: "Incomplete",
  },
  {
    id: 8,
    userId: "012348",
    name: "Mary Ojo",
    phone: "08098765432",
    email: "maryojo@xyz.com",
    location: "Iwo, Ibadan",
    gender: "Female",
    service: "IT and digital services",
    join_date: "Jan 12, 2023",
    status: "Incomplete",
  },
  {
    id: 9,
    userId: "012399",
    name: "Emeka Eze",
    phone: "08098765432",
    email: "Nil",
    location: "Yaba, Lagos",
    gender: "Male",
    service: "IT and digital services",
    status: "Complete",
    join_date: "Jan 12, 2023",
  },
  {
    id: 10,
    userId: "012389",
    name: "Tim Mark",
    phone: "08098765432",
    email: "timmark@xyz.com",
    location: "Owo, Ibadan",
    gender: "Male",
    service: "IT and digital services",
    status: "Complete",
    join_date: "Jan 12, 2023",
  },
  {
    id: 11,
    userId: "012348",
    name: "Mary Ojo",
    phone: "08098765432",
    email: "maryojo@xyz.com",
    location: "Iwo, Ibadan",
    gender: "Female",
    service: "IT and digital services",
    status: "Complete",
    join_date: "Jan 12, 2023",
  },
  {
    id: 12,
    userId: "012399",
    name: "Emeka Eze",
    phone: "08098765432",
    email: "Nil",
    location: "Yaba, Lagos",
    gender: "Male",
    service: "IT and digital services",
    status: "Complete",
    join_date: "Jan 12, 2023",
  },
  {
    id: 13,
    userId: "012389",
    name: "Tim Mark",
    phone: "08098765432",
    email: "timmark@xyz.com",
    location: "Owo, Ibadan",
    gender: "Male",
    service: "IT and digital services",
    status: "Complete",
    join_date: "Jan 12, 2023",
  },
  {
    id: 14,
    userId: "012348",
    name: "Mary Ojo",
    phone: "08098765432",
    email: "maryojo@xyz.com",
    location: "Iwo, Ibadan",
    gender: "Female",
    service: "IT and digital services",
    status: "Complete",
    join_date: "Jan 12, 2023",
  },
  {
    id: 15,
    userId: "012399",
    name: "Emeka Eze",
    phone: "08098765432",
    email: "Nil",
    location: "Yaba, Lagos",
    gender: "Male",
    service: "IT and digital services",
    status: "Complete",
    join_date: "Jan 12, 2023",
  },
  {
    id: 16,
    userId: "012389",
    name: "Tim Mark",
    phone: "08098765432",
    email: "timmark@xyz.com",
    location: "Owo, Ibadan",
    gender: "Male",
    service: "IT and digital services",
    status: "Complete",
    join_date: "Jan 12, 2023",
  },
];

export const Notifications = [
  {
    id: 0,
    title: "New User Registration",
    content:
      "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit Lorem ipsum dolor sit amet, conse ctetur adipiscing elit",
  },
  {
    id: 1,
    title: "Dispute Resolution",
    content:
      "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit Lorem ipsum dolor sit amet, conse ctetur adipiscing elit",
  },
  {
    id: 2,
    title: "New User Registration",
    content:
      "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit Lorem ipsum dolor sit amet, conse ctetur adipiscing elit",
  },
  {
    id: 3,
    title: "Dispute Resolution",
    content:
      "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit Lorem ipsum dolor sit amet, conse ctetur adipiscing elit",
  },
];

export const allBannedAccounts = [
  {
    id: 0,
    userId: "012389",
    name: "Tim Mark",
    status: "Banned",
    ban_date: "14 June 2024",
    unban_date: "July 4 2024",
  },
  {
    id: 1,
    userId: "012389",
    name: "Mary Ojo",
    status: "Banned",
    ban_date: "14 June 2024",
    unban_date: "July 4 2024",
  },
  {
    id: 2,
    userId: "012389",
    name: "Mary Jane",
    status: "Banned",
    ban_date: "14 June 2024",
    unban_date: "July 4 2024",
  },
  {
    id: 3,
    userId: "012319",
    name: "Emeka Eze",
    status: "Unbanned",
    ban_date: "14 June 2024",
    unban_date: "July 4 2024",
  },
  {
    id: 4,
    userId: "012389",
    name: "Tim Mark",
    status: "Unbanned",
    ban_date: "14 June 2024",
    unban_date: "July 4 2024",
  },
];

export const BannedUserDisputes = [
  {
    id: 0,
    userId: "012389",
    disputes: [
      {
        id: 0,
        project_title: "Electrician for light fixing",
        projectId: "#783451",
        date: "Dec 24 2023",
        type: "Negative",
      },
      {
        id: 1,
        project_title: "Electrician for light fixing",
        projectId: "#783451",
        date: "Dec 19 2023",
        type: "Negative",
      },
      {
        id: 2,
        project_title: "Electrician for light fixing",
        projectId: "#783451",
        date: "Dec 10 2023",
        type: "Positive",
      },
      {
        id: 3,
        project_title: "Electrician for light fixing",
        projectId: "#783451",
        date: "Dec 23 2023",
        type: "Positive",
      },
      {
        id: 4,
        project_title: "Electrician for light fixing",
        projectId: "#783451",
        date: "Oct 12 2023",
        type: "Negative",
      },
    ],
  },
];

export const ResolvedDisputes = [
  {
    id: 1,
    ProjectTitle: "Plumbing service for my bathroom",
    ProjectID: "#783451",
    DateofComplain: "Dec 24 2023",
    Status: "Unresolved",
    resolution_date: "27 Dec 2023",
    refund: "25,000",
    final_resolution: "Awarded to consumer",
    resolution: "consumer"
  },

  {
    id: 2,
    ProjectTitle: "Graphic designer for brand design",
    ProjectID: "#783451",
    DateofComplain: "Dec 24 2023",
    Status: "Resolving",
    resolution_date: "27 Dec 2023",
    refund: "25,000",
    final_resolution: "Awarded to consumer",
    resolution: "consumer"
  },
  {
    id: 3,
    ProjectTitle: "Plumbing service for my bathroom",
    ProjectID: "#783451",
    DateofComplain: "Dec 24 2023",
    Status: "Unresolved",
    resolution_date: "27 Dec 2023",
    refund: "25,000",
    final_resolution: "Awarded to consumer",
    resolution: "consumer"
  },
  {
    id: 4,
    ProjectTitle: "Electrician for light fixing",
    ProjectID: "#783451",
    DateofComplain: "Dec 24 2023",
    Status: "Resolved",
    resolution_date: "27 Dec 2023",
    refund: "25,000",
    final_resolution: "Awarded to consumer",
    resolution: "consumer"
  },
  {
    id: 5,
    ProjectTitle: "Graphic designer for brand design",
    ProjectID: "#783451",
    DateofComplain: "Dec 24 2023",
    Status: "Resolving",
    resolution_date: "27 Dec 2023",
    refund: "25,000",
    final_resolution: "Awarded to consumer",
    resolution: "vendor"
  },
  {
    id: 6,
    ProjectTitle: "Plumbing service for my bathroom",
    ProjectID: "#783451",
    DateofComplain: "Dec 24 2023",
    Status: "Unresolved",
    resolution_date: "27 Dec 2023",
    refund: "25,000",
    final_resolution: "Awarded to consumer",
    resolution: "vendor"

  },
  {
    id: 7,
    ProjectTitle: "Electrician for light fixing",
    ProjectID: "#783451",
    DateofComplain: "Dec 24 2023",
    Status: "Resolved",
    resolution_date: "27 Dec 2023",
    refund: "25,000",
    final_resolution: "Awarded to consumer",
    resolution: "vendor"
  },
  {
    id: 8,
    ProjectTitle: "Plumbing service for my bathroom",
    ProjectID: "#783451",
    DateofComplain: "Dec 24 2023",
    Status: "Unresolved",
    resolution_date: "27 Dec 2023",
    refund: "25,000",
    final_resolution: "Awarded to consumer",
    resolution: "partial"
  },
  {
    id: 9,
    ProjectTitle: "Graphic designer for brand design",
    ProjectID: "#783451",
    DateofComplain: "Dec 24 2023",
    Status: "Resolving",
    resolution_date: "27 Dec 2023",
    refund: "25,000",
    final_resolution: "Awarded to consumer",
    resolution: "partial"
  },
  {
    id: 10,
    ProjectTitle: "Electrician for light fixing",
    ProjectID: "#783451",
    DateofComplain: "Dec 24 2023",
    Status: "Unresolved",
    resolution_date: "27 Dec 2023",
    refund: "25,000",
    final_resolution: "Awarded to consumer",
    resolution: "partial"
  },
];

import React from "react";

const SaveIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0632 0.375C14.4679 0.375 16.4173 2.35417 16.4173 5.125C16.4173 10.6667 10.4798 13.8333 8.50065 15.0208C6.52148 13.8333 0.583984 10.6667 0.583984 5.125C0.583984 2.35417 2.56315 0.375 4.93815 0.375C6.41063 0.375 7.70898 1.16667 8.50065 1.95833C9.29232 1.16667 10.5907 0.375 12.0632 0.375ZM9.23999 12.728C9.93792 12.2884 10.5669 11.8506 11.1566 11.3815C13.5148 9.50529 14.834 7.45527 14.834 5.125C14.834 3.25727 13.6172 1.95833 12.0632 1.95833C11.2114 1.95833 10.2893 2.40888 9.62023 3.07792L8.50065 4.19751L7.38108 3.07792C6.71202 2.40888 5.78991 1.95833 4.93815 1.95833C3.40157 1.95833 2.16732 3.26973 2.16732 5.125C2.16732 7.45527 3.48645 9.50529 5.84472 11.3815C6.4344 11.8506 7.06338 12.2884 7.76131 12.728C7.99763 12.8769 8.23235 13.0202 8.50065 13.1804C8.76895 13.0202 9.00368 12.8769 9.23999 12.728Z"
        fill="#0096C1"
      />
    </svg>
  );
};

export default SaveIcon;

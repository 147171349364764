import React from "react";
import { dropdown } from "../../../../constants/images";
import { FAQSVendor } from "../../../../constants";

const FAQSSection = () => {
  return (
    <div className="flex flex-col py-12 text-center md:max-w-full">
      <div className="flex flex-col space-y-1 px-7 pb-4 md:space-y-2  md:pb-4 lg:px-36 lg:pb-10">
        <span className="text-start text-[25px] font-semibold capitalize text-secondary md:text-center lg:text-[40px]">
          Frequently Asked <br className="inline md:hidden" /> Questions
        </span>
      </div>

      <div className="grid grid-cols-1 space-y-2 md:m-2 md:grid-cols-2 md:py-6 lg:px-36">
        {FAQSVendor.map((faq) => (
          <div
            className="group flex flex-col rounded-lg text-white md:gap-3  md:px-10"
            tabindex="1"
          >
            <div className="flex cursor-pointer items-center justify-between px-8 py-4 md:p-4 md:pl-0">
              <span className="w-full text-left text-lg font-semibold text-secondary md:text-[20px]">
                {faq.question}
              </span>
              <img
                src={dropdown}
                className="h-2 w-3 transition-all duration-500 group-focus:-rotate-180"
              />
            </div>
            <hr color="#000000" className="mx-auto w-[83%] md:w-full" />
            <div className="invisible h-auto max-h-0 p-4 pl-8 text-start text-base text-lightgray opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000 md:pl-0 md:text-lg">
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSSection;

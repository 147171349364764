import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input } from "../../../components/ui"; 
import { handleHomeSearchInput, vendorSearch } from "../../../features/searchSlice";
import { filterIcon, searchicon } from "../../../constants/images"; 
import MobileSearchContent from "../mobileInputSearch";


const InputSearch = ({handleOverlay}) => {
//   const dispatch = useDispatch();

//   const handleInputChange = (e) => {
//     const searchParam = e.target.value;
//     dispatch(vendorSearch(searchParam));
//   };
const dispatch = useDispatch();
const handleInputChange = (e) => {
  const searchParam = e.target.value;
  dispatch(handleHomeSearchInput(searchParam));
};
  const [showFilter, setShowFilter] = useState(false);
  const [overlay, setShowOverlay] = useState(false);
  const handleFilter = () => {
    setShowFilter(true);
    handleOverlay(overlay);
  };
  const handleCloseFilter = () => {
    setShowFilter(false);
    handleOverlay(!overlay);
  };
  return (
    <div className="flex w-full items-center justify-between pt-10 md:block md:pt-0">
      <form action="">
        <div className="relative flex">
          <Input
            type="search"
            onChange={handleInputChange}
            className="min-w-[100%] rounded-[5px] border-[#AEAEAE] px-10 py-2 "
            placeholder="Search for jobs...."
          />{" "}
          <img
            src={searchicon}
            alt=""
            className="bottom-2 z-10 hidden px-2 md:absolute"
          />
          <img
            src={searchicon}
            alt=""
            className="absolute bottom-2 z-10 px-2"
          />
        </div>
      </form>
      <div
        className="block rounded-[5px] bg-[#0096C1] p-2 md:hidden"
        onClick={handleFilter}
      >
        <img src={filterIcon} alt="category" />
      </div>
      {showFilter && <MobileSearchContent closeOverlay={handleCloseFilter} />}
    </div>
  );
};

export default InputSearch;

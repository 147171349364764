import React from "react";
import { commitment } from "../../../../constants/images";

const CommitmentSection = () => {
  return (
    <div>
      <div>
        <div className="flex flex-col md:flex-row justify-between lg:py-20 lg:px-[9rem] px-8 py-12  mx-auto">
          <div className="flex w-full md:w-1/2 justify-end items-start order-last">
            <img
              src={commitment}
              alt="about us"
              className=" md:max-w-full"
            />
          </div>

          <div className="flex lg:gap-0 gap-6 w-full md:w-1/2 flex-col justify-around order-first">
            <div className="flex flex-col">
              <div className="md:flex items-end hidden">
                <hr color="#5f5f5f" className="w-[15%]" />
                <span className="capitalize lg:text-lg md:text-base text-sm font-normal text-lightgray">
                  Our Commitment
                </span>
              </div>
              <span className="text-secondary font-semibold text-[25px] md:text-2xl xl:text-[40px] xl:leading-[4rem]">
                We want to put a stop to the challenges associated with finding
                reliable and top-notch services
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommitmentSection;

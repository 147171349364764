import React, { useState } from "react";
import { Button } from '../../../../components/ui'
import { Link } from 'react-router-dom'
import { verificationLogo } from "../../../../constants/images";
const SendToVendorSuccefully = ({ setServiceSent }) => {
    
  return (
    <div className="flex justify-center items-center bg-[#001E27] bg-opacity-60 fixed inset-0 z-[99]  ">
    <div className="relative overflow-hidden  w-full md:w-[40%] animate-slide_right md:h-auto md:rounded-none rounded-t-[40px] h-[80%] bottom-[-6rem] md:bottom-0 bg-primary p-6">
      <div
        className="absolute right-[23px] top-[31px] text-lg font-medium text-[#181818] hover:cursor-pointer"
        onClick={() => setServiceSent(false)}
      >
        X
      </div>
      <div className="flex flex-col items-center px-8 py-10">
        <img src={verificationLogo} alt="projects" />
        <div className="mt-5 flex flex-col items-center">
            <h3 className="text-[#002D3A] md:text-[20px] text-[15px] font-semibold text-center leading-[21px]">Request Sent Successfully</h3>
        <span className="text-center font-normal text-[#181818] md:leading-[21px] md:text-[18px] tetx-[14px] mt-3">
        We will notify you once the vendor confirms or rejects the request
        </span>
        </div>
      </div>
      <Link to="/bookservice" className="w-full mt-10 pb-6">
        <Button children="Track Project" border="none" width="100%" />
      </Link>
    </div>
  </div>
  )
}

export default SendToVendorSuccefully

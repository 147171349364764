import React from "react";
import { Button } from "../../../../components/ui";
import { search } from "../../../../features/searchSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

const HeroSection = () => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState();

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(search(searchInput));
  };

  return (
    <main className="relative">
      <div>
        <div
          className="relative h-full overflow-hidden bg-cover bg-center bg-no-repeat px-6 py-8 md:px-10 md:py-20 lg:bg-center xl:p-36"
          style={{
            backgroundImage: 'url("/servicebg.svg")',
          }}
        >
          <div
            style={{ backgroundImage: 'url("/services/services-pattern.svg")' }}
            className="absolute bottom-[-1rem] left-0 right-0 top-0"
          />
          <div className="mt-20 flex h-[300px] items-center justify-start md:justify-center md:text-center">
            <div className="flex w-full animate-slide_up flex-col gap-4 font-semibold text-primary md:gap-8 lg:w-5/6">
              <span className="text-center text-3xl text-[30px] leading-tight md:text-[40px] lg:text-[50px]">
                Discover vendors for virtually{" "}
                <br className="hidden lg:inline" /> anything you need
              </span>
              <div className="mx-auto flex w-1/2 flex-col items-center justify-center space-y-4 md:relative md:flex-row md:space-y-0">
                <input
                  type="text"
                  placeholder="What service do you need?"
                  onChange={handleSearchInput}
                  className=" h-[39px] w-[277px] rounded-[5px] px-3 py-4 text-lightgray outline-0 md:h-[59px] md:w-[550px] md:px-5 md:py-8"
                />
                <Button
                  children="Search"
                  border="none"
                  onClick={handleSubmit}
                  className="right-[75px] w-fit md:absolute"
                />
              </div>

              <span className="flex flex-col items-start justify-center gap-4 text-lg leading-tight md:flex-row md:items-end md:gap-0 md:text-[20px]"></span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HeroSection;

import { useState } from "react"
import { Input } from "../../../../../../../components/ui"


const Checkbox = ({handleActive,active}) => {


  return (
    <div className="flex gap-x-28 " >
    <div className="flex items-center gap-2">
      <Input
        checked={active === 1}
        onChange={() => handleActive(1)}
        type="checkbox"
      />
    <label
      htmlFor="Give partial award"
      className="text-[18px] font-normal "
    >
      Give full award to consumer
    </label>
      </div>
      <div className="flex items-center gap-2">
      <Input
        checked={active === 2}
        onChange={() => handleActive(2)}
        type="checkbox"
        label="two"
      />
    <label
      htmlFor="Give partial award"
      className="text-[18px] font-normal "
    >
      Give full award to vendor
    </label>
      </div>
      <div className="flex items-center gap-2">
      <Input
        checked={active === 3}
        onChange={() => handleActive(3)}
        type="checkbox"
        label="three"
      />
    <label
      htmlFor="Give partial award"
      className="text-[18px] font-normal "
    >
      Give partial award
    </label>
      </div>
    </div>
  )
}

export default Checkbox
import React from "react";
import girlPicture from "../../../../assets/images/Maskgroup.png";
import moblieGirlPic from "../../../../assets/images/Maskgroup-mobile.png"
// import Button from '../../../../shared/button/Button'
import { Button } from "../../../../components/ui";
const PostJobSection = () => {
  return (
    <div className="mx-auto">
    <div className="lg:flex lg:flex-row flex flex-col lg:p-[70px] lg:px-auto px-[40px] relative justify-center items-center">
      <div className="bg-[#FFFFFF] lg:w-[589px] lg:h-[410px] w-full h-full rounded-[5px] p-[30px] lg:drop-shadow-md lg:relative  lg:left-[8vw] lg:z-10">
        <h3 className="lg:font-[600] lg:text-[20px]  h-[19px] text-[16px] text-[#002D3A] leading-[23.46px] mb-3">
          Need a Specific Service?
        </h3>
        <h2 className="font-[600] lg:text-[40px] text-[25px] text-[#002D3A] leading-[46.92px] mb-4">
          Post Your Job!
        </h2>
        <p className="font-[400] lg:text-[18px] lg:w-[529px]  text-[16px] text-[#5F5F5F] leading-[27px] mb-4">
          For a personalized approach, post your specific job requirements
          directly on GYWDE. Describe the task, set your preferences, and let
          qualified vendors apply for the job. Engage with vendors, make your
          selection, and track the progress
        </p>
        <p className="font-[400] lg:text-[18px] lg:w-[529px]  text-[16px] text-[#5F5F5F] leading-[27px] mb-4">
          Ready to Get Started? Post Your Job Now!
        </p>
        <div className="">
          <Button children="Post Job" border="none" />
        </div>
      </div>
      <div className="lg:w-[708px] md:w-[90%] w-[330px] md:flex hidden">
        <img src={girlPicture} alt="girl picture"  className=""/>
      </div>
      <div className=" md:hidden">
        <img src={moblieGirlPic} alt="girl picture" className="w-[330px]  "/>
      </div>
    </div>
    
    </div>
  );
};

export default PostJobSection;

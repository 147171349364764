import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: [],
  applicationForm: [],
};
export const formSlice = createSlice({
  name: "formdata",
  initialState: initialState,
  reducers: {
    formdata: (state, action) => {
      state.userData = action.payload
    },
    setApplicationForm: (state, action) => {
      state.applicationForm = action.payload
    },

  },
  
});

export const { formdata, clearForm, setApplicationForm} = formSlice.actions;
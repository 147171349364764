import React, { useEffect, useState } from "react";
import { MessageList } from "react-chat-elements";
import NewChatNotice from "../newchat-notice";
import { useDispatch } from "react-redux";
import { setPopRequest } from "../../../../../../features/overlay";

const ChatContent = ({ chat }) => {
  const dispatch = useDispatch()
  const [dataSource, setDataSource] = useState([]);
  const [messageDate, setMessageDate] = useState("");

  const handleServiceClick = () => {
    dispatch(setPopRequest(true))
  }

  useEffect(() => {
    let messagesArray = [];

    if (Array.isArray(chat)) {
      // Handle an array of chat objects
      messagesArray = chat.flatMap((chatItem) => chatItem.messages);
    } else if (typeof chat === "object" && chat !== null) {
      // Handle a single chat object
      messagesArray = chat.messages;
    }

    const newDataSource = messagesArray.map((message) => {
      const { position, type, text, timestamp } = message;
      const date = formatDate(timestamp);
      return { position, type, text, date: timestamp };
    });

    setDataSource(newDataSource);
  }, [chat]);

  const formatDate = (timestamp) => {
    const today = new Date();
    const messageDate = new Date(timestamp);

    if (
      today.getFullYear() === messageDate.getFullYear() &&
      today.getMonth() === messageDate.getMonth() &&
      today.getDate() === messageDate.getDate()
    ) {
      setMessageDate("Today");
    }

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[messageDate.getDay()];
    setMessageDate(dayOfWeek);
  };

  return (
    <div className="relative mt-24 h-full w-full overflow-y-auto md:px-8 md:py-20">
      <div className="absolute bottom-0 left-0 right-0 top-0 h-[130vh] w-full bg-[#F8F8F8]" />

      <div className="sticky mx-auto mt-2 flex flex-col items-center justify-center gap-y-4 text-sm text-secondary md:mt-0">
        <NewChatNotice />
        {messageDate}
      </div>
      <MessageList
        className="message-list py-6"
        lockable={true}
        toBottomHeight={"100%"}
        dataSource={dataSource}
      />
      <MessageList
      onClick={handleServiceClick}
        className="message-list"
        lockable={true}
        toBottomHeight={"100%"}
        dataSource={[
          {
            position: "left",
            type: "text",
            text: "Service Request",
            date: new Date(),
          },
        ]}
      />
    </div>
  );
};

export default ChatContent;

import React from "react";
import { AllChatsIcon, StarredChatsIcon, UnreadChatsIcon } from "../../icons";

const DesktopSearchFilter = () => {
  return (
    <div className="absolute md:block hidden left-40 z-50 w-1/2 animate-slide_right bg-primary">
      <div className="flex flex-col justify-center gap-y-3 px-8 py-4 font-medium text-secondary">
        <span className="font-semibold text-secondary">Filter</span>
        <hr className="w-full" color="#AEAEAE" />
        <div className="flex items-center gap-2 hover:cursor-pointer">
          <AllChatsIcon />
          <span>All</span>
        </div>
        <hr className="w-full" color="#AEAEAE" />
        <div className="flex items-center gap-2 hover:cursor-pointer">
          <UnreadChatsIcon />
          <span>Unread</span>
        </div>
        <hr className="w-full" color="#AEAEAE" />
        <div className="flex items-center gap-2 hover:cursor-pointer">
          <StarredChatsIcon />
          <span>Starred</span>
        </div>
      </div>
    </div>
  );
};

export default DesktopSearchFilter;

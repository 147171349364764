import React from "react";
import { mission } from "../../../../constants/images";

const MissionSection = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row lg:gap-[22rem] md:gap-10 lg:py-40 lg:pb-20 lg:px-[9rem] px-8 py-12  mx-auto">
        <div className="flex items-start order-last md:order-first md:mt-0 mt-10">
          <img
            src={mission}
            alt="about us"
            className="lg:max-w-[250%] md:max-w-full"
          />
        </div>

        <div className="flex lg:gap-0 gap-6 flex-col justify-around lg:w-full md:w-[70%] order-first md:order-last">
          <div className="flex flex-col">
            <span className="lg:text-[30px] md:text-[20px] text-[25px] text-secondary font-semibold">
              Our Mission
            </span>
            <span className="text-lightgray font-normal lg:text-lg">
              Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos. Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos.
            </span>
          </div>
          <div className="flex flex-col">
            <span className="lg:text-[30px] md:text-[20px] text-[25px] text-secondary font-semibold">
              Our Vison
            </span>
            <span className="text-lightgray font-normal lg:text-lg">
              Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos. Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionSection;

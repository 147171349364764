import "./index.css";
import React, { useState, useEffect } from "react";
import {
  searchicon,
  nothingFound,
  linkarrow,
} from "../../../../../../constants/images";
import { Input } from "../../../../../../components/ui";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { categoryList, availableJobs, stars } from "../../../../data";
// import Ratings from "../../../../../services/desktop-view/_components/sidebar/_components/ratings";
import AvailableJob from "../../../../_components/availabe_job";

const SearchContent = ({ searchValue }) => {
  const [allJobs, setAllJobs] = useState([]);
  const [showCategory, setShowCategory] = useState(false);
  const [isChecked, setIsChecked] = useState(" ");
  const [seledtedRatings, setSelectedRatings] = useState(null);
  const [rangeValues, setRangeValues] = useState([]);
  const [location, setLocation] = useState("");
  const [min, setMin] = useState(2000);
  const [max, setMax] = useState(50000);

  useEffect(() => {
    setAllJobs(availableJobs);
  }, []);
  useEffect(() => {
    const categoryFilter = allJobs
      .filter((job) => job.title.includes(isChecked)) // Filter jobs based on searchValue
      .slice(0, seeAllJobs);
    setAllJobs(categoryFilter);
  }, [isChecked]);

  useEffect(() => {
    const locationFilter = allJobs
      .filter((job) => job.status.includes(location)) // Filter jobs based on searchValue
      .slice(0, seeAllJobs);
    setAllJobs(locationFilter);
  }, [location]);

  useEffect(() => {
    const priceFilter = allJobs
      .filter((job) => job?.range >= min && job?.range <= max) // Filter jobs based on searchValue
      .slice(0, seeAllJobs);
    setAllJobs(priceFilter);
  }, [min, max]);

  useEffect(() => {
    if (rangeValues.length !== 0) {
      setMin(rangeValues[0]);
      setMax(rangeValues[1]);
    }
  }, [rangeValues]);

  const [active, setActive] = useState(0);
  const handleTabSwitch = (index) => {
    setActive(index);
  };
  const [seeAllJobs, setSeeAllJobs] = useState(9);
  const handleSeeAllJobs = () => {
    const lenthOfArray = availableJobs.length;
    if (seeAllJobs < lenthOfArray) {
      setSeeAllJobs(seeAllJobs + 3);
    }
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const capitalizedsearchValue = capitalizeFirstLetter(searchValue);

  const handleCategories = () => {
    setShowCategory(!showCategory);
  };
  useEffect(() => {
    const filteredJobs = availableJobs
      .filter(
        (job) =>
          job.description.includes(capitalizedsearchValue) ||
          job.title.includes(capitalizedsearchValue),
      ) // Filter jobs based on searchValue
      .slice(0, seeAllJobs); // Use slice instead of splice
    setAllJobs(filteredJobs);
  }, [searchValue]);

  return (
    <div className="float-right mx-auto flex h-full md:w-5/6 max-w-full items-start justify-center md:pt-20 gap-8 px-10 py-5 ">
      <div className="hidden h-full w-[25%] items-center justify-center pt-6 md:flex md:flex-col">
        <div className="w-full rounded-[5px] border-[0.5px] border-[#AEAEAE] px-5 py-4">
          <div className="flex items-center justify-between">
            <span className="text-[16px] font-[500] text-[#5F5F5F]">
              Category
            </span>
            <img src={linkarrow} alt="" className="cursor-pointer" />
          </div>
          <div className="mt-6 flex flex-col">
            <div
              className="relative flex cursor-pointer items-center justify-between"
              onClick={handleCategories}
            >
              <span className="w-full  rounded-[5px] border  border-gray-200 bg-primary p-2 text-sm text-lightgray outline-none md:text-base">
                Select categories
              </span>
              <img
                src={linkarrow}
                alt="categories"
                className={`absolute right-4  ${showCategory && "ease rotate-90 transition-all"}`}
              />
            </div>

            {showCategory && (
              <div className="animate-slide_up rounded-[4px] bg-white px-4 py-5 drop-shadow-xl">
                <form action="">
                  <div className="relative flex">
                    <Input
                      type="search"
                      className="min-w-[100%] rounded-[5px] px-7 py-[4px]"
                      placeholder="Search for category"
                    />{" "}
                    <img
                      src={searchicon}
                      alt=""
                      className="absolute bottom-2 z-10 w-[2rem] px-2"
                    />
                  </div>
                </form>
                <div className="mt-3  h-[10rem] overflow-y-auto bg-white ">
                  <div className="flex cursor-pointer flex-col gap-3 text-[14px] font-normal text-[#5F5F5F]">
                    {categoryList.map((category) => (
                      <label htmlFor="" key={category.id}>
                        <Input
                          type="checkbox"
                          value={category.title}
                          id={category.catId}
                          name={category.catId}
                          className="mr-2"
                          checked={isChecked === category.title}
                          onChange={(e) => setIsChecked(e.target.value)}
                        />
                        {category.title}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full pt-10">
          <div className="space-y-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-5">
            <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
              <span>Location</span>
              <img src={linkarrow} alt="next" className="cursor-pointer" />
            </div>
            <div className=" flex w-full flex-col gap-2">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="location"
                  value="Physical"
                  id="onsite"
                  onChange={(e) => setLocation(e.target.value)}
                  checked={location === "Physical"}
                  className="onsite"
                />
                <label
                  htmlFor="onsite"
                  className="text-base font-normal text-lightgray"
                >
                  On site
                </label>
              </div>

              <input
                type="text"
                placeholder="Type your location"
                className="border-[0.5px] border-[#AEAEAE] px-[15px] py-[10px] outline-0"
              />
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                value="Remote"
                name="location"
                id="remote"
                checked={location === "Remote"}
                onChange={(e) => setLocation(e.target.value)}
              />
              <label
                htmlFor="remote"
                className="text-base font-normal text-lightgray"
              >
                Remote
              </label>
            </div>
          </div>
        </div>
        <div className="w-full pt-10">
          <div className="space-y-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-5">
            <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
              <span>Price range</span>
              <img src={linkarrow} alt="next" className="cursor-pointer" />
            </div>
            <div className="flex w-full items-center justify-between">
              <span className="text-xs font-normal text-lightgray">
                &lt; ₦{min}
              </span>
              <span className="text-xs font-normal text-lightgray">₦{max}</span>
            </div>
            <RangeSlider
              min={2000}
              max={50000}
              step={500}
              onInput={(e) => setRangeValues(e)}
            />
          </div>
        </div>
        {/* <div className="w-full pt-10">
      <div className="gap-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-3">
        <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
          <span>Rating</span>
          <img src={linkarrow} alt="next" className="cursor-pointer" />
        </div>
        {stars.map((star) => {
          return (
            <div className="flex flex-col items-start" key={star.id}>
              <Ratings
                star={star}
                value={star.value}
                isSelected={seledtedRatings === star.value}
                onCheckboxChange={(e) =>
                  setSelectedRatings(+e.target.value)
                }
              />
            </div>
          );
        })}
      </div>
    </div> */}
      </div>
      <div className="w-full md:w-[70%]">
        <div className="mb-5 flex justify-start gap-3 text-[16px] text-[#002D3A]  md:text-[30px]">
          Search results for
          <span className=" font-semibold capitalize text-[#002D3A]">
            {" "}
            "{searchValue !== "" && searchValue}"
          </span>
        </div>
        <div className="mx-auto flex gap-2">
          <div
            className={`${active === 0 && "flex justify-center border-[2px] border-[#0096C1] bg-[#E6F5F9] font-[500] text-[#0096C1]"} "flex cursor-pointer justify-center rounded-[10px] border-[1px] px-2 py-1  text-center md:w-[7rem]`}
            onClick={() => handleTabSwitch(0)}
          >
            All
          </div>
          <div
            className={`${active === 1 && "flex justify-center border-[2px] border-[#0096C1] bg-[#E6F5F9] font-[500] text-[#0096C1]"} "flex w-[7rem] cursor-pointer justify-center rounded-[10px] border-[1px] px-2  py-1 text-center`}
            onClick={() => handleTabSwitch(1)}
          >
            Near you
          </div>
          <div
            className={`${active === 2 && "flex justify-center border-[2px] border-[#0096C1] bg-[#E6F5F9] font-[500] text-[#0096C1]"} "flex w-[7rem] cursor-pointer justify-center rounded-[10px] border-[1px] px-2  py-1 text-center`}
            onClick={() => handleTabSwitch(2)}
          >
            Popular
          </div>
          <div
            className={`${active === 3 && "flex justify-center border-[2px] border-[#0096C1] bg-[#E6F5F9] font-[500] text-[#0096C1]"} "flex w-[7rem] cursor-pointer justify-center rounded-[10px] border-[1px] px-2  py-1 text-center`}
            onClick={() => handleTabSwitch(3)}
          >
            Saved
          </div>
        </div>
        <div className=" hidden md:flex md:flex-col">
          <div className="mt-4">
            {allJobs.length === 0 && (
              <div className="mx-auto flex w-full flex-col items-center justify-center gap-4">
                <img src={nothingFound} alt="" />
                <h3 className=" text-[25px] font-semibold text-[#002D3A]">
                  Match not found
                </h3>
                <p className="w-[461px] text-[18px] font-normal">
                  Sorry, the keyword you entered cannot be found, please check
                  again or search with another keyword
                </p>
              </div>
            )}
            {allJobs.length > 0 && (
              <div className=" grid grid-cols-2 gap-10">
                {allJobs.map((job) => (
                  <AvailableJob key={job.id} {...job} />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="md:hidden">
          <div className="mt-4">
            {allJobs.length === 0 && (
              <div className="mx-auto flex w-full flex-col items-center justify-center gap-4">
                <img src={nothingFound} alt="" />
                <h3 className=" text-[25px] font-semibold text-[#002D3A]">
                  Match not found
                </h3>
                <p className="text-center text-[18px] font-normal md:w-[461px]">
                  Sorry, the keyword you entered cannot be found, please check
                  again or search with another keyword
                </p>
              </div>
            )}
            {availableJobs.length > 0 && (
              <div className=" flex flex-col gap-4">
                {allJobs.map((job) => (
                  <AvailableJob key={job.id} {...job} />
                ))}
              </div>
            )}
          </div>
        </div>
        {availableJobs.length <= seeAllJobs && allJobs.length !== 0 ? (
          <span className="flex justify-center py-6 font-medium text-secondary lg:text-xl">
            No more jobs available at this moment.
          </span>
        ) : (
          <div
            className="mx-auto mt-7 flex cursor-pointer justify-center rounded-[7px] border-[2px] border-[#0096C1] bg-[#E6F5F9] py-2 font-[500] text-[#0096C1] transition-all ease-in-out hover:scale-105 md:w-[40%]"
            onClick={handleSeeAllJobs}
          >
            Load more jobs
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchContent;

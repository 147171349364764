import React from 'react'
import { Button } from '../../../../../components/ui'

const SmallCard = ({name,service,completedJobs,cancelledJobs,ongoingingJobs,vendor,rating,rating_count}) => {
  return (
    <div className=" h-fit w-full bg-white p-5 drop-shadow-lg">
    <div className="mx-auto h-16 w-16 rounded-full">
      <img src={vendor} alt="" className='w-28' />
    </div>
    <p className="text-center text-[20px] font-semibold text-[#002D3A]">
      {name}
    </p>
    <p className="text-center text-[18px] font-semibold text-[#5F5F5F]">
      {service}
    </p>
    <div className="flex items-center gap-1 text-xs justify-center mt-2">
                    <img src="/rate.png" alt="" />
                    <span className="font-semibold">{rating}</span>
                    <span className="font-normal">{rating_count}</span>
                  </div>
    <div className="mt-4 flex gap-5">
      <div className="flex h-16 w-full flex-col items-center justify-center bg-white p-3 drop-shadow-lg">
        <h4 className="text-[20px] font-semibold text-[#0096C1]">
          {completedJobs}
        </h4>
        <p className="text-center text-[12px] font-normal leading-[14.08px] text-[#002D3A]">
          Completed jobs
        </p>
      </div>
      <div className="flex h-16 w-full flex-col items-center justify-center bg-white p-3 drop-shadow-lg">
        <h4 className="text-[20px] font-semibold text-[#0096C1]">
          {ongoingingJobs}
        </h4>
        <p className="text-center text-[12px] font-normal leading-[14.08px] text-[#002D3A]">
          Ongoing jobs
        </p>
      </div>
      <div className="flex h-16 w-full flex-col items-center justify-center bg-white p-3 drop-shadow-lg">
        <h4 className="text-[20px] font-semibold text-[#0096C1]">
          {cancelledJobs}
        </h4>
        <p className="text-center text-[12px] font-normal leading-[14.08px] text-[#002D3A]">
          Cancelled jobs
        </p>
      </div>
    </div>
    <div className="mt-5">
      <Button
        children="Request service"
        className="w-full border-2 border-[#0096C1]"
        backgroundColor="white"
        textColor="#0096C1"
        border="1px solid #0096C1"
      />
      <Button
        children="Message"
        className="mt-3 w-full"
        border="none"
      />
    </div>
  </div>
  )
}

export default SmallCard

import React from "react";
import { Provisions } from "../../../../constants";
import Card from "./_components/card";

const ApartSection = () => {
  return (
    <div className="py-10 overflow-hidden">
      <div className="flex flex-col items-center">
        <span className="text-secondary text-[25px] lg:text-[40px] md:text-[30px]  font-semibold">
          What Sets Us Apart
        </span>
        <span className="text-lightgray text-base md:text-lg">
          We provide top-notch services
        </span>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 px-10 lg:px-36 md:px-10 gap-4 lg:gap-2 py-10">
        {Provisions.map((provision) => (
          <Card provision={provision} key={provision.id} />
        ))}
      </div>
    </div>
  );
};

export default ApartSection;

import React from "react";
import { accessmessages } from "../../../../../../../constants/images";
import { Link } from "react-router-dom";
import SubNavbar from "../../../_components/sub-navbar";
import { allProjects } from "../../../../../data";
import ActiveProjects from "../../../_components/active-projects";
import { useSelector } from "react-redux";

const ProjectContents = () => {
  const showDetails = useSelector(
    (state) => state?.overlay.displayStates.showProjectDetails,
  );
  
  return (
    <div className="relative mr-0  h-screen w-full max-w-full items-start justify-center pl-0 pr-0 pt-10 md:float-right md:mx-auto md:ml-auto md:w-[81%] md:pt-20">
      {!showDetails && <SubNavbar />}
      {allProjects.length === 0 ? (
        <div className="flex h-[70vh] flex-col items-center justify-center gap-4">
          <img src={accessmessages} alt="no projects" />
          <span className="text-[20px] font-semibold text-secondary">
            No projects
          </span>
          <span className="text-lg font-normal text-[#181818]">
            Browse different jobs and get started on your next project
          </span>
          <Link
            to="/vendor-dashboard/jobs"
            className="text-base font-bold text-lightblue"
          >
            Browse jobs
          </Link>
        </div>
      ) : (
          <ActiveProjects />
      )}
    </div>
  );
};

export default ProjectContents;

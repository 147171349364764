import React from "react";

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  // const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <div className="flex gap-3">
      <button
        onClick={prevPage}
        className={`flex h-[30px] w-[30px] items-center justify-center rounded-full bg-opacity-20 ${currentPage > 1 && "bg-lightgray"}`}
      >
        <img src="/dropdown.png" alt="back" className="w-[70px] rotate-90" />
      </button>

      <button
        onClick={nextPage}
        className={`flex h-[30px] w-[30px] items-center justify-center rounded-full bg-opacity-20 ${currentPage < nPages && "bg-lightgray"}`}
      >
        <img src="/dropdown.png" alt="foward" className="w-[70px] -rotate-90" />
      </button>
    </div>
  );
};

export default Pagination;

import React from "react";
// import { projectData } from '../data'
import { locationBlue, calendar, starRate } from "../../../../constants/images";
import { Link } from "react-router-dom";
const Card = ({ title, address, name, ratings, date, budget, image, to, status }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "Ongoing":
        return "text-[#0096C1]"; 
      case "Cancelled":
        return "text-[#C12B00]";
      case "Completed":
        return "text-[#21A601]";
      default:
        return "text-gray-500";
    }
  }
  return (
    <Link to={to}>
      <div className="flex  items-end justify-between rounded-[7px] border-[1px] border-[#AEAEAE] px-11 py-5">
        <div className="flex flex-col space-y-2">
          <h3 className="text-[18px] font-medium text-[#002D3A]">{title}</h3>
          <div className="flex items-center space-x-1 ">
            <div className="">
              <img src={locationBlue} alt="" />
            </div>
            <span className="text-[14px] font-medium text-[#5F5F5F]">
              {address}
            </span>
          </div>
          <div className="flex space-x-1">
            <div>
              <img src={image} alt="" />
            </div>
            <div className="">
              <h3 className="text-[16px] font-semibold text-[#002D3A]">
                {name}
              </h3>
              <div className="flex items-center space-x-1">
                <div>
                  <img src={starRate} alt="" />
                </div>
                <span className="text-[14px] font-semibold text-[#002D3A]">
                  {ratings}{" "}
                  <span className="font-normal text-[#5F5F5F]"> (31)</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex items-center space-x-1 mb-2">
            <div>
              <img src={calendar} alt="" />
            </div>{" "}
            <span className="text-[14px] text-[#002D3A]">{date}</span>
          </div>
          <span className="text-[16px] font-medium text-[#002D3A] ">
            ₦{budget}Budget
          </span>
          <p  className={`text-[16px] font-medium mt-1 ${getStatusColor(status)}`}>
            {status}
          </p>
        </div>
        <div className="">
          <Link to="/chat">
            <button className="rounded-[5px] border border-[#0096C1] px-3 py-1 text-[#0096C1]">
              Message vendor
            </button>
          </Link>
        </div>
      </div>
    </Link>
  );
};

export default Card;

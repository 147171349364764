import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownWhite,
  SearchIcon,
} from "../../../../../../../constants/images";
import { useDispatch, useSelector } from "react-redux";
import {
  setProjectSearch,
  setSelectedProject,
} from "../../../../../../../redux/slice/modalOverlay";

const ProjectsNavbar = () => {
  const dispatch = useDispatch();
  const [openSelect, setOpenSelect] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [project, setProject] = useState("Ongoing");
  const [selectedSort, setSelectedSort] = useState("");
  const activeProject = useSelector(
    (state) => state.modalOverlay.states.activeProject,
  );
  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };
  const handleSort = () => {
    setOpenSort(!openSort);
  };
  const handleChange = (e) => {
    dispatch(setProjectSearch(e.target.value));
  };
  const handleProjectSelect = (e) => {
    setProject(e);
    dispatch(setSelectedProject(e));
  };
  const handleProjectChange = () => {
    if (activeProject === "Completed") {
      setProject("Completed");
      dispatch(setSelectedProject("Completed"));
    } else if (activeProject === "Cancelled") {
      setProject("Cancelled");
      dispatch(setSelectedProject("Cancelled"));
    } else if (activeProject === "Ongoing") {
      setProject("Ongoing");
      dispatch(setSelectedProject("Ongoing"));
    }
  };
  useEffect(() => {
    handleProjectChange();
  }, [activeProject]);

  return (
    <div className="mx-auto flex w-full justify-between px-6 py-4">
      <div className="relative flex items-center gap-x-2">
        <span className="font-medium text-secondary md:text-lg">Projects:</span>
        <div
          onClick={handleSelect}
          className="flex w-[200px] items-center justify-between gap-x-4 border border-[#AEAEAE] px-5 py-2 text-base font-normal text-[#181818] hover:cursor-pointer"
        >
          <span>{project === "" ? "All" : project}</span>
          <img
            src={Dropdown}
            alt="open"
            className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
          />
        </div>
        {openSelect && (
          <div className="absolute left-[40%] top-[100%] w-[60%] animate-slide_up  rounded-[10px] bg-primary px-3 py-4">
            <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
              <span
                className="hover:cursor-pointer"
                onClick={() => handleProjectSelect("")}
              >
                All
              </span>
              <span
                className="hover:cursor-pointer"
                onClick={() => handleProjectSelect("Ongoing")}
              >
                Ongoing
              </span>
              <span
                className="hover:cursor-pointer"
                onClick={() => handleProjectSelect("Completed")}
              >
                Completed
              </span>
              <span
                className="hover:cursor-pointer"
                onClick={() => handleProjectSelect("Cancelled")}
              >
                Cancelled
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center gap-x-2">
        <div className="relative ml-16 flex w-full items-center justify-center">
          <input
            type="text"
            name="search"
            id="search"
            onChange={handleChange}
            className="w-full rounded-[5px] border border-[#AEAEAE] px-10 py-2 placeholder:text-xs placeholder:font-medium placeholder:text-[#AEAEAE] md:placeholder:text-sm "
            placeholder="Search here"
          />{" "}
          <img src={SearchIcon} alt="search" className="absolute left-0 px-2" />
        </div>
        <div className="relative flex w-1/2 items-center justify-between gap-x-4 rounded-[5px] bg-lightblue px-5 py-2 text-sm font-medium text-primary hover:cursor-pointer">
          <span>Sort by</span>
          <img
            src={DropdownWhite}
            alt="open"
            className={`${openSort && "-rotate-90 transition-all ease-in-out"}`}
            onClick={handleSort}
          />
          {openSort && (
            <div className="absolute left-0 top-[100%] w-[180px] animate-slide_up  rounded-[10px] bg-primary px-2 py-6">
              <div className="flex w-full flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                <span
                  className="hover:cursor-pointer"
                  onClick={() => setSelectedSort("")}
                >
                  Newest - Oldest
                </span>
                <span
                  className="hover:cursor-pointer"
                  onClick={() => setSelectedSort("")}
                >
                  Oldest - Newest
                </span>
                <span
                  className="hover:cursor-pointer"
                  onClick={() => setSelectedSort("")}
                >
                  Location
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectsNavbar;

import React from "react";
import { line } from "../../../../constants/images";
import { Experience } from "../../../../constants";
import Card from "./_components/card";
import { Button } from "../../../../components/ui";

const ServicesSection = () => {
  return (
    <div className="bg-[#E6F5F9] lg:px-30 px-20 py-11">
      <div className="">
        <div className="">
            <span className="text-[18px]  font-[400] text-[#5F5F5F]  text-center  text-lg md:text-[20px] flex  md:gap-0 md:flex-row md:items-end gap-0 flex-row items-end justify-center leading-tight mb-5">
            <hr className="w-16 h-[1px] border-0 dark:bg-[#5F5F5F] "  />
              Why Choose Us?
            </span>
          <h3 className="lg:text-[40px] text-[25px]  lg:w-[629px]  mx-auto w-[306px] font-[Work Sans] font-semibold text-[#002D3A] lg:leading-[46.92px] leading-[29.33px] text-center mb-9">
            Elevate Your Service Experience
          </h3>
          <div className=" grid  gap-7 gap-x-7 py-7 md:grid-cols-2 lg:grid-cols-4 ">
            {Experience.map((cardData, i) => (
              <Card key={i} {...cardData} />
            ))}
          </div>
          <div className="flex justify-center pt-2">
            <Button border='none' children="Get Started" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;

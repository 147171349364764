import React, { useState } from "react";
import SearchInput from "../chat-search";
import { accessmessages } from "../../../../constants/images";
 import NoMessages from "../../../vendor-dashboard/_pages/chat/desktop/_components/no-messages";
import ChatListSidebar from "../chatlist-sidebar";
import { detailedChatHistory } from "../../../vendor-dashboard/data";
import Chatbar from "../chatbar";
import ChatContent from "../chat-content";
import ChatInput from "../chat-inputs";
import { useSelector } from "react-redux";
import NoticeContent from "../../../vendor-dashboard/_pages/chat/_components/newchat-notice/desktop-content";
import ChatServiceRequest from "../../../vendor-dashboard/_pages/chat/_components/chat-service_request/desktop";

const DesktopChat = () => {
  const [selectedChat, setSelectedChat] = useState(null);

  const showNotice = useSelector(
    (state) => state?.overlay.displayStates.showNotice,
  );
  const popRequest = useSelector(
    (state) => state?.overlay.displayStates.popRequest,
  );
  const viewProject = useSelector(
    (state) => state?.overlay.displayStates.viewProject,
  );
  
  const handleChatClick = (chat) => {
    setSelectedChat(chat);
  };

  return (
    <div className="mr-0 hidden h-screen w-full max-w-full items-start justify-center pl-0 pr-0 pt-10 md:float-right md:mx-auto md:ml-auto md:flex md:w-[81%] md:pt-20 relative">
      {showNotice && (
        <div className="fixed left-0 right-0 top-0 z-[99] block h-[160vh] w-full bg-black bg-opacity-25" />
      )}
      {showNotice && (
        <div className="absolute left-[20%] top-[25%] w-[40%] h-fit bg-primary z-[99] bottom">
          <NoticeContent />
        </div>
      )}
       {popRequest && (
        <div className="fixed left-0 right-0 top-0 z-[99] block h-[160vh] w-full bg-black bg-opacity-25" />
      )}
       {viewProject && (
        <div className="fixed left-0 right-0 top-0 z-[99] block h-[160vh] w-full bg-black bg-opacity-25" />
      )}
      {popRequest && (
        <div className="absolute left-[20%] top-[25%] w-[40%] h-fit bg-primary z-[99] bottom">
          <ChatServiceRequest />
        </div>
      )}
      <div className="fixed left-[20%] px-6 top-20 z-50 h-screen overflow-y-auto pt-12 shadow-xl md:w-[20%] lg:w-[25%]">
        <SearchInput />
        {detailedChatHistory.length === 0 ? (
          <NoMessages />
        ) : (
          <ChatListSidebar chats={selectedChat} onClick={handleChatClick} />
        )}
      </div>

      <div className=" ml-auto hidden w-[80%] animate-slide_up items-center justify-center  transition-all ease-in md:flex md:flex-col">
        {selectedChat === null ? (
          <div className="absolute bottom-0 top-0 flex flex-col items-center justify-center space-y-4 overflow-hidden">
            <img src={accessmessages} alt="no messages" />
            <span>You can access all your conversations here</span>
          </div>
        ) : (
          <div className="h-screen w-full">
            <Chatbar chat={selectedChat} />
            {selectedChat && <ChatContent chat={selectedChat} />}
            <ChatInput />
          </div>
        )}
      </div>
    </div>
  );
};

export default DesktopChat;

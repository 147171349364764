import React, { useEffect, useState } from "react";
import {
  banner,
  blackApp,
  blackPlay,
  insight,
  left,
  request,
} from "../../../../../../constants/images";
import {
  availableJobs,
  notifications,
  serviceRequests,
} from "../../../../data";
import AvailableJob from "../../../../_components/availabe_job";
import ProgressBar from "../../_components/progress_bar";
import MoreJobsContent from "../morejobs_content";
import ServiceRequest from "../service-request";
import { useDispatch, useSelector } from "react-redux";
import { setSeeMoreJobs, setShowServiceRequest } from "../../../../../../features/overlay";

const HomeContent = () => {
  const dispatch = useDispatch();
  const overlayValue = useSelector(
    (state) => state?.overlay.displayStates.overlay,
  );
  const serviceRequestValue = useSelector(
    (state) => state?.overlay.displayStates.servicerequest,
  );
  const seeMoreValue = useSelector(
    (state) => state?.overlay.displayStates.morejobs,
  );
  const [numberOfRequests, setNumberOfRequests] = useState();

  useEffect(() => {
    setNumberOfRequests(serviceRequests.length);
  }, []);
  // const handleSeeAllJobs = () => {
  //   const lenthOfArray = availableJobs.length;
  //   if (seeAllJobs < lenthOfArray) {
  //     setSeeAllJobs(lenthOfArray);
  //   }
  // };

  // const handleSeeAllNotifications = () => {
  //   const lenthOfArray = notifications.length;
  //   if (allNotifications < lenthOfArray) {
  //     setAllNotifications(lenthOfArray);
  //   }
  // };

  // const handleMobileJobs = () => {
  //   const lenthOfArray = availableJobs.length;
  //   if (mobileJobs < lenthOfArray) {
  //     setMobileJobs(lenthOfArray);
  //   }
  // };


  const handleSeeMoreJobs = () => {
    dispatch(setSeeMoreJobs(true));
  }
  const handleServiceRequest = () => {
    dispatch(setShowServiceRequest(true));
  };
  return (
    <>
        {seeMoreValue && (
        <MoreJobsContent />
      )}

      {serviceRequestValue && (
        <ServiceRequest />
      )}

      {overlayValue && (
        <div className="fixed top-0 z-[50] block h-[160vh] w-full bg-black bg-opacity-25 md:hidden" />
      )}
      <div
        className={`fixed bottom-[50%] right-0 z-50 flex h-[84px] w-[242px] cursor-pointer items-center justify-center gap-2 rounded-l-[50px] bg-white py-3 pl-4 shadow-2xl ${serviceRequestValue ? "hidden" : "block"}`}
        onClick={handleServiceRequest}
      >
        <img src={request} className="rounded-full bg-[#E6F5F9] p-2" alt="" />
        <span className="font-medium md:text-lg">
          Confirm service request &#40;{numberOfRequests}&#41;
        </span>
      </div>
      <div
        className={`w-full px-8 pt-6 md:float-right md:w-5/6 md:max-w-[100%] md:pt-16 lg:px-20  ${seeMoreValue ? "hidden" : "block"} ${serviceRequestValue ? "hidden" : "block"}`}
      >
        <h1 className="hidden py-4 text-[25px] font-semibold text-secondary md:block">
          Hello, John
        </h1>
        <div className="grid grid-cols-1 gap-5 py-4 md:py-0 lg:grid-cols-2">
          <div className="flex cursor-pointer flex-col gap-4 rounded-[5px] border border-[#AEAEAE] p-3 md:items-start ">
            <div className="flex w-full items-center justify-between">
              <span className="w-full text-base font-semibold text-secondary md:text-[20px] ">
                Complete your profile for better visibility
              </span>
              <img src={left} alt="" className="h-[0.8rem] w-[0.5em]" />
            </div>
            <ProgressBar bgcolor="#0C7134" height={7} progress="80" />
          </div>
          <div className="flex cursor-pointer gap-4 rounded-[5px] border border-[#AEAEAE] p-3 md:items-center ">
            <div className="">
              <img src={insight} alt="" className="w-[3.5rem]" />
            </div>
            <div className="flex w-full flex-col">
              <div className="flex w-full items-center justify-between">
                <span className="text-base font-semibold text-secondary md:text-[20px] ">
                  Track your projects
                </span>
                <img src={left} alt="" className="h-[0.8rem] w-[0.5em]" />
              </div>
              <div className="text-sm text-[#181818] md:text-lg">
                Stay on top of your projects and submit before the deadline
              </div>
            </div>
          </div>
        </div>

        <div className="py-8 md:flex md:flex-col">
          <div className="flex items-center justify-between py-4">
            <span className="text-[20px] font-semibold text-secondary">
              Recommended Jobs
            </span>
            <div
              onClick={handleSeeMoreJobs}
              className="cursor-pointer text-[14px] font-[500] text-[#0096C1]"
            >
              See More
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {availableJobs.slice(0, 6).map((vendor) => (
              <AvailableJob key={vendor.id} {...vendor} />
            ))}
          </div>
        </div>

        <div className="mb-3 flex flex-col gap-2 py-6">
          <div className="flex items-center justify-between">
            <span className="text-[20px] font-semibold text-secondary">
              Recent notifications
            </span>

            <span className="cursor-pointer text-[14px] font-[500] text-[#0096C1]">
              See More
            </span>
          </div>
          <div className="items- flex flex-col rounded border border-[#5F5F5F] ">
            {notifications.slice(0, 3).map((notification) => (
              <div key={notification.id} className="flex flex-col">
                <span className="px-4 py-6 text-base text-lightgray">
                  {notification.text}
                </span>
                <div className="h-[0.1px] w-full bg-[#5F5F5F] bg-opacity-50" />
              </div>
            ))}
          </div>
        </div>

        <div className="">
          <img src={banner} alt="" />
        </div>
        <div className="mt-5">
          <span className="mt-5 text-[18px] font-semibold text-secondary">
            Download the mobile app
          </span>
          <div className="mt-3 flex gap-5">
            <img src={blackPlay} alt="" />
            <img src={blackApp} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeContent;

import React, { useState } from "react";
import { services, popularServices, vendors, vendorsMobile } from "../data";
import { Input } from "../../../components/ui";
import ServicesCard from "../cards/ServicesCard";
import PopularServices from "../cards/PopularServices";
import DesktopVendor from "../cards/DesktopVendor";
import MobileVendors from "../cards/MobileVendors";
import { banner, blackPlay, blackApp } from "../../../constants/images";
import { Link } from "react-router-dom";

const DefaultdashBorad = () => {
  const [isLike, setIsLike] = useState({});
  const [seeAllvendors, setSeeAllVendors] = useState(4);
  const handleSeeAllVendors = () => {
    const lenthOfArray = vendors.length;
    if (seeAllvendors < lenthOfArray) {
      setSeeAllVendors(lenthOfArray);
    }
  };
  const [seeAllMobilevendors, setSeeAllMobileVendors] = useState(2);
  const handleSeeAllMobileVendors = () => {
    const lenthOfArray = vendorsMobile.length;
    if (seeAllMobilevendors < lenthOfArray) {
      setSeeAllMobileVendors(lenthOfArray);
    }
  };

  const handleLike = (id) => {
    setIsLike((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="mx-auto min-h-screen w-full px-10 py-5">
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
        {services.map((service, i) => (
          <ServicesCard key={i} {...service} />
        ))}
      </div>
      <h3 className="mt-5 text-[20px] font-semibold text-[#002D3A]">
        Popular services
      </h3>

      <div className="hide-scrollbar mt-5 grid gap-3 auto-cols-max grid-flow-col overflow-auto md:grid-flow-row md:grid-cols-5 md:gap-5 md:overflow-hidden">
        {popularServices.map((popularService, i) => (
          <PopularServices key={i} {...popularService} />
        ))}
      </div>

      <div className="hidden md:flex md:flex-col ">
        <div className="mb-3 flex items-center justify-between">
          <span className="mt-5 text-[20px] font-semibold text-[#002D3A]">
            Recommended vendors
          </span>
          <Link to="/allRecomendedVendors">
            <span
              className="cursor-pointer text-[14px] font-[500] text-[#0096C1]"
              onClick={handleSeeAllVendors}
            >
              See All
            </span>
          </Link>
        </div>

        <div className="grid grid-cols-4 gap-5">
          {vendors.slice(0, seeAllvendors).map((vendor, index) => (
            <DesktopVendor
              key={vendor.id}
              to={`/cardContent/${index}`}
              id={vendor.id}
              image={vendor.image}
              vendor={vendor.vendor}
              name={vendor.name}
              title={vendor.title}
              service={vendor.service}
              location={vendor.location}
              rating={vendor.rating}
              rating_count={vendor.rating_count}
              price={vendor.price}
              bgcolor={vendor.bgcolor}
              handleLike={() => handleLike(vendor.id)}
              isLike={isLike}
            />
          ))}
        </div>
      </div>

      <div className="md:hidden">
        <div className="mb-3 flex items-center justify-between">
          <span className="mt-5 text-[20px] font-semibold text-[#002D3A]">
            Recommended vendors
          </span>
          <Link to="/allRecomendedVendors">
            <span
              className="cursor-pointer text-[14px] font-[500] text-[#0096C1]"
              onClick={handleSeeAllMobileVendors}
            >
              See All
            </span>
          </Link>
        </div>
        <div className="flex flex-col gap-5">
          {vendorsMobile.slice(0, seeAllMobilevendors).map((mvendor, index) => (
            <MobileVendors
              key={mvendor.id}
              to={`/cardContent/${index}`}
              id={mvendor.id}
              image={mvendor.image}
              vendor={mvendor.vendor}
              name={mvendor.name}
              title={mvendor.title}
              service={mvendor.service}
              location={mvendor.location}
              rating={mvendor.rating}
              rating_count={mvendor.rating_count}
              price={mvendor.price}
              bgcolor={mvendor.bgcolor}
              handleLike={() => handleLike(vendor.id)}
              isLike={isLike}
            />
          ))}
        </div>
      </div>

      <div className="mb-3 flex items-center justify-between">
        <span className="mt-5 text-[20px] font-semibold text-[#002D3A]">
          Recent notifications
        </span>
        <span className="cursor-pointer text-[14px] font-[500] text-[#0096C1]">
          See All
        </span>
      </div>
      <Input
        type="text"
        placeholder="You have received a new message!"
        className="w-full rounded-[5px] p-2"
      />
      <div className="mt-7">
        <img src={banner} alt="" />
      </div>
      <div className="mt-5">
        <span className="mt-5 text-[18px] font-semibold text-[#002D3A]">
          Download the mobile app
        </span>
        <div className="mt-3 flex gap-5">
          <img src={blackPlay} alt="" />
          <img src={blackApp} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DefaultdashBorad;

import {
  specific,
  left,
  explo,
  transport,
  it,
  beauty,
  repair,
  events,
  waves,
  cake,
  family,
} from "../../../constants/images";

export const services = [
  {
    heading: "Explore services",
    icon: left,
    image: explo,
    text: "Easily navigate through various service categories to find what you need",
  },
  {
    heading: "Do you need a specific service?",
    icon: left,
    image: specific,
    text: "Create a job post and get applications from vendors to satisfy your needs",
  },
];

export const popularServices = [
  {
    image: repair,
    heading: "Repair and maintenance",
  },
  {
    image: it,
    heading: "IT and digital services",
  },
  {
    image: beauty,
    heading: "Beauty services",
  },
  {
    image: events,
    heading: "Events and entertainment",
  },
  {
    image: transport,
    heading: "Transport and logistics",
  },
];
export const categoryList = [
  {
    id: 1,
    title: "Repair and Maintenance",
    catId: "repair",
    valcard: "repair",
  },
  {
    id: 2,
    title: "IT and digital services",
    catId: "it",
    valcard: "it",
  },
  {
    id: 3,
    title: "Events and entertainment",
    catId: "event",
    valcard: "event",
  },
  {
    id: 4,
    title: "Beauty services",
    catId: "beauty",
    valcard: "beauty",
  },
  {
    id: 5,
    title: "Transport and logistics",
    catId: "trans",
    valcard: "trans",
  },
  {
    id: 6,
    title: "Fashion services",
    catId: "fashion",
    valcard: "fashion",
  },
];
export const stars = [
  {
    id: 0,
    size: 27,
    value: 5,
    edit: false,
  },
  {
    id: 1,
    size: 27,
    value: 4,
    edit: false,
  },
  { id: 2, size: 27, value: 3, edit: false },

  { id: 3, size: 27, value: 2, edit: false },
  { id: 4, size: 27, value: 1, edit: false },
];
export const availableJobs = [
  {
    id: 0,
    name: "John Doe",
    title: "Repair and Maintenance",
    color: "#FFE3D3",
    description: "Plumber needed to repair bathroom faucet",
    location: "Yaba, Lagos",
    budget: "10,000",
    range: 10000,
    status: "Physical",
    timeline: "1 day",
    post_duration: 30,
    applied: "Pending",
  },

  {
    id: 1,
    name: "John Doe",
    title: "Repair and Maintenance",
    color: "#FFE3D3",
    description: "Electrician for light fixing",
    location: "Yaba, Lagos",
    budget: "10,000",
    range: 10000,
    status: "Physical",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 2,
    name: "John Doe",
    title: "IT and digital services",
    color: "#EFFFD3",
    description: "Graphic designer for brand design",
    location: "Remote",
    budget: "30,000",
    range: 30000,
    status: "Remote",
    timeline: "3 days",
    applied: "Pending",
    post_duration: 45,
  },

  {
    id: 3,
    name: "John Doe",
    title: "Beauty services",
    color: "#FFF9D3",
    description: "Make up artist needed for photoshoot",
    location: "Yaba, Lagos",
    budget: "15,000",
    range: 15000,
    status: "Physical",
    applied: "Declined",
    timeline: "1 day",
    post_duration: "1 hour",
  },
  {
    id: 4,
    name: "John Doe",
    title: "Repair and Maintenance",
    color: "#FFE3D3",
    description: "Plumber needed to repair bathroom faucet",
    location: "Yaba, Lagos",
    budget: "10,000",
    range: 10000,
    status: "Physical",
    applied: "Declined",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 5,
    name: "John Doe",
    title: "Repair and Maintenance",
    color: "#FFE3D3",
    description: "Electrician for light fixing",
    location: "Yaba, Lagos",
    budget: "10,000",
    range: 10000,
    status: "Physical",
    timeline: "1 day",
    applied: "Pending",
    post_duration: 30,
  },

  {
    id: 6,
    name: "John Doe",
    title: "IT and digital services",
    color: "#EFFFD3",
    description: "Graphic designer for brand design",
    location: "Remote",
    applied: "Pending",
    budget: "30,000",
    range: 30000,
    status: "Remote",
    timeline: "3 days",
    post_duration: 45,
  },

  {
    id: 7,
    name: "John Doe",
    title: "Beauty services",
    color: "#FFF9D3",
    description: "Make up artist needed for photoshoot",
    location: "Yaba, Lagos",
    budget: "15,000",
    applied: "Accepted",
    range: 15000,
    status: "Physical",
    timeline: "1 day",
    post_duration: "1 hour",
  },
  {
    id: 8,
    name: "John Doe",
    title: "Repair and Maintenance",
    color: "#FFE3D3",
    description: "Plumber needed to repair bathroom faucet",
    location: "Yaba, Lagos",
    budget: "10,000",
    range: 10000,
    status: "Physical",
    applied: "Accepted",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 9,
    name: "John Doe",
    title: "Repair and Maintenance",
    color: "#FFE3D3",
    description: "Electrician for light fixing",
    location: "Yaba, Lagos",
    budget: "10,000",
    applied: "Accepted",
    range: 10000,
    status: "Physical",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 10,
    name: "John Doe",
    title: "IT and digital services",
    color: "#EFFFD3",
    description: "Graphic designer for brand design",
    location: "Remote",
    budget: "30,000",
    range: 30000,
    status: "Remote",
    timeline: "3 days",
    post_duration: 45,
  },

  {
    id: 11,
    name: "John Doe",
    title: "Beauty services",
    color: "#FFF9D3",
    description: "Make up artist needed for photoshoot",
    location: "Yaba, Lagos",
    budget: "15,000",
    range: 15000,
    status: "Physical",
    timeline: "1 day",
    post_duration: "1 hour",
  },

  {
    id: 12,
    name: "John Doe",
    title: "Beauty services",
    color: "#FFF9D3",
    description: "Make up artist needed for photoshoot",
    location: "Yaba, Lagos",
    budget: "15,000",
    range: 15000,
    status: "Physical",
    timeline: "1 day",
    post_duration: "1 hour",
  },

  {
    id: 13,
    name: "John Doe",
    title: "Beauty services",
    color: "#FFF9D3",
    description: "Make up artist needed for photoshoot",
    location: "Yaba, Lagos",
    budget: "15,000",
    range: 15000,
    status: "Physical",
    timeline: "1 day",
    post_duration: "1 hour",
  },

  {
    id: 14,
    name: "John Doe",
    title: "Beauty services",
    color: "#FFF9D3",
    description: "Make up artist needed for photoshoot",
    location: "Yaba, Lagos",
    budget: "15,000",
    range: 15000,
    status: "Physical",
    timeline: "1 day",
    post_duration: "1 hour",
  },
];

export const jobDetails = [
  {
    id: 0,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 1,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Electrician for light fixing",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },
  {
    id: 2,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Graphic designer for brand design",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },
  {
    id: 3,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Make up artist needed for photoshoot",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },
  {
    id: 4,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },
  {
    id: 5,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },
  {
    id: 6,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 7,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 8,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 9,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 10,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 11,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 12,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 13,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },

  {
    id: 14,
    name: "John Doe",
    rating: "4.9",
    count: "24",
    image: "/profile.png",
    title: "Plumber needed to repair bathroom faucet",
    description:
      "I need a plumber to work in my bathroom, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    requirements: [
      {
        id: 0,
        content:
          "Minimum of 5 years experience Resident of Yaba Have no ongoing work",
      },
      {
        id: 1,
        content: "Resident of Yaba",
      },
      {
        id: 2,
        content: "Have no ongoing work",
      },
    ],
    start_date: "20, Dec 2023",
    closing_date: "23 Dec 2023",
    reviews: [
      {
        id: 0,
        title: "Plumbing service for my bathroom",
        date: "29 Nov, 2023",
        rating: "5.0",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 1,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
      {
        id: 2,
        title: "Electrician for light fixing",
        date: "30 Nov, 2023",
        rating: "4.8",
        content:
          "Great working with Tim. Clear requirements and clear discussion",
      },
    ],
    location: "Yaba, Lagos",
    budget: "10,000",
    timeline: "1 day",
    post_duration: 30,
  },
];

export const serviceRequests = [
  {
    id: 0,
    name: "Tim Mark",
    title: "Plumbing service for my bathroom",
    date: "26 April 2024",
  },
  {
    id: 1,
    name: "Grace Ola",
    title: "Electrician for light fixing",
    date: "27 April 2024",
  },
  {
    id: 2,
    name: "Tim Mark",
    title: "Graphic designer for brand design",
    date: "29 April 2024",
  },
  {
    id: 3,
    name: "Tim Mark",
    title: "Make up artist needed",
    date: "30 April 2024",
  },
];

export const chatNotifications = [
  {
    id: 0,
    name: "John Doe",
    image: "/profile.png",
    timestamp: "11:45",
    notifications_length: 2,
    online: true,
    content:
      "Do you know that the planning Do you know that the planning Do you know that the planning...",
  },
  {
    id: 1,
    name: "Grace Ola",
    image: "/profile.png",
    timestamp: "11:41",
    notifications_length: 1,
    online: true,
    content:
      "I’m sorry, I didn’t get the mess I’m sorryI didn’t get the messI’m sorry, I didn’t get the mess...",
  },

  {
    id: 2,
    name: "John Doe",
    image: "/profile.png",
    timestamp: "11:48",
    notifications_length: 0,
    online: false,
    content: "Thank you!",
  },
  {
    id: 3,
    name: "Mercy Frank",
    image: "/profile.png",
    timestamp: "10:30",
    notifications_length: 0,
    online: true,
    content:
      "Do you know that the planning Do you know that the planning Do you know that the planning...",
  },
  {
    id: 4,
    name: "John Doe",
    image: "/profile.png",
    timestamp: "11:48",
    notifications_length: 2,
    online: false,
    content:
      "Do you know that the planning Do you know that the planning Do you know that the planning...",
  },
];
export const vendors = [
  {
    id: 0,
    image: waves,
    vendor: "/services/vendor1.png",
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },

  {
    id: 1,
    image: cake,
    vendor: "/services/vendor2.png",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 2,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 3,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 4,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 5,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 6,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 7,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 8,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 9,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 10,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 11,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
];
export const vendorsMobile = [
  {
    id: 0,
    image: "/services/mobile-services.svg",
    name: "John Doe",
    title: "Repair and Maintenance",
    titlebg: "#FFF9D3",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },

  {
    id: 1,
    image: "/services/mobile-services1.svg",
    name: "Mary Ojo",
    title: "Events and entertainment",
    titlebg: "#FFF9D3",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 2,
    image: "/services/mobile-services2.svg",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 3,
    image: "/services/mobile-services1.svg",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 4,
    image: "/services/mobile-services.svg",
    name: "John Doe",
    title: "Repair and Maintenance",
    titlebg: "#EFFFD3",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 5,
    image: "/services/mobile-services2.svg",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
  {
    id: 6,
    image: "/services/mobile-services.svg",
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
  },
  {
    id: 7,
    image: "/services/mobile-services1.svg",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
  },
  {
    id: 8,
    image: "/services/mobile-services2.svg",
    name: "Emeka Eze",
    title: "Events and entertainment",
    titlebg: "#FFE3D3",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
  },
];

export const notifications = [
  {
    id: 0,
    text: "Your job on “Electrician for a day” is due in three days",
  },
  {
    id: 1,
    text: "You have received a new message!",
  },
  {
    id: 1,
    text: "You completed two jobs this week. Well done!",
  },
  {
    id: 2,
    text: "Your job on Plumber for a day” is due in one days",
  },
  {
    id: 3,
    text: "You have received a new message from a client!",
  },
  {
    id: 4,
    text: "You completed ten jobs this week. Well done!!",
  },
];

export const detailedChatHistory = [
  {
    id: 1,
    avatar: "https://avatars.githubusercontent.com/u/80540635?v=4",
    alt: "kursat_avatar",
    title: "Kursat",
    subtitle: "Why don't we go to the No Way Home movie this weekend?",
    messages: [
      {
        id: 1,
        position: "left",
        type: "text",
        text: "Hello, how can I assist you?",
        timestamp: new Date("2024-06-01T10:00:00"),
      },
      {
        id: 2,
        position: "right",
        type: "text",
        text: "I have a question about your product.",
        timestamp: new Date("2024-06-01T10:05:00"),
      },
      {
        id: 3,
        position: "left",
        type: "text",
        text: "Sure, I'm here to help. What's your question?",
        timestamp: new Date("2024-06-01T10:10:00"),
      },
      {
        id: 4,
        position: "right",
        type: "text",
        text: "I'm having trouble with the login process.",
        timestamp: new Date("2024-06-01T10:15:00"),
      },
      {
        id: 5,
        position: "left",
        type: "text",
        text: "I can assist you with that. Could you please provide more details about the issue?",
        timestamp: new Date("2024-06-01T10:20:00"),
      },
      {
        id: 6,
        position: "right",
        type: "text",
        text: "Whenever I try to login, it gives me an 'Invalid credentials' error.",
        timestamp: new Date("2024-06-01T10:25:00"),
      },
      {
        id: 7,
        position: "left",
        type: "text",
        text: "That error usually occurs when the username or password is incorrect. Have you double-checked the credentials you're using?",
        timestamp: new Date("2024-06-01T10:30:00"),
      },
      {
        id: 8,
        position: "right",
        type: "text",
        text: "Yes, I've double-checked, and I'm sure the credentials are correct.",
        timestamp: new Date("2024-06-01T10:35:00"),
      },
      {
        id: 9,
        position: "left",
        type: "text",
        text: "In that case, please try resetting your password. You can click on the 'Forgot Password' link on the login page to initiate the password reset process.",
        timestamp: new Date("2024-06-01T10:40:00"),
      },
      {
        id: 10,
        position: "left",
        type: "text",
        text: "Alright, I'll give that a try. Thank you for your help!",
        timestamp: new Date("2024-06-01T10:45:00"),
      },
    ],
    date: new Date("2024-06-01"),
    unread: 2,
  },
  {
    id: 2,
    avatar: "https://avatars.githubusercontent.com/u/12345678?v=4",
    alt: "john_avatar",
    title: "John",
    subtitle: "Hey, did you watch the latest episode of the show?",
    messages: [
      {
        id: 1,
        position: "left",
        type: "text",
        text: "No, I haven't. What happened in the latest episode?",
        timestamp: new Date("2024-06-02T18:00:00"),
      },
      {
        id: 2,
        position: "right",
        type: "text",
        text: "There was a major plot twist! The main character turned out to be a spy.",
        timestamp: new Date("2024-06-02T18:05:00"),
      },
    ],
    date: new Date("2024-06-02"),
    unread: 0,
  },

  {
    id: 4,
    avatar: "https://avatars.githubusercontent.com/u/80540635?v=4",
    alt: "kursat_avatar",
    title: "Kursat",
    subtitle: "Why don't we go to the No Way Home movie this weekend?",
    messages: [
      {
        id: 1,
        position: "left",
        type: "text",
        text: "Hello, how can I assist you?",
        timestamp: new Date("2024-06-01T10:00:00"),
      },
      {
        id: 2,
        position: "right",
        type: "text",
        text: "I have a question about your product.",
        timestamp: new Date("2024-06-01T10:05:00"),
      },
      {
        id: 3,
        position: "left",
        type: "text",
        text: "Sure, I'm here to help. What's your question?",
        timestamp: new Date("2024-06-01T10:10:00"),
      },
      {
        id: 4,
        position: "right",
        type: "text",
        text: "I'm having trouble with the login process.",
        timestamp: new Date("2024-06-01T10:15:00"),
      },
      {
        id: 5,
        position: "left",
        type: "text",
        text: "I can assist you with that. Could you please provide more details about the issue?",
        timestamp: new Date("2024-06-01T10:20:00"),
      },
      {
        id: 6,
        position: "right",
        type: "text",
        text: "Whenever I try to login, it gives me an 'Invalid credentials' error.",
        timestamp: new Date("2024-06-01T10:25:00"),
      },
      {
        id: 7,
        position: "left",
        type: "text",
        text: "That error usually occurs when the username or password is incorrect. Have you double-checked the credentials you're using?",
        timestamp: new Date("2024-06-01T10:30:00"),
      },
      {
        id: 8,
        position: "right",
        type: "text",
        text: "Yes, I've double-checked, and I'm sure the credentials are correct.",
        timestamp: new Date("2024-06-01T10:35:00"),
      },
      {
        id: 9,
        position: "left",
        type: "text",
        text: "In that case, please try resetting your password. You can click on the 'Forgot Password' link on the login page to initiate the password reset process.",
        timestamp: new Date("2024-06-01T10:40:00"),
      },
      {
        id: 10,
        position: "right",
        type: "text",
        text: "Alright, I'll give that a try. Thank you for your help!",
        timestamp: new Date("2024-06-01T10:45:00"),
      },
    ],
    date: new Date("2024-06-01"),
    unread: 0,
  },
  {
    id: 2,
    avatar: "https://avatars.githubusercontent.com/u/12345678?v=4",
    alt: "john_avatar",
    title: "John",
    subtitle: "Hey, did you watch the latest episode of the show?",
    messages: [
      {
        id: 1,
        position: "left",
        type: "text",
        text: "No, I haven't. What happened in the latest episode?",
        timestamp: new Date("2024-06-02T18:00:00"),
      },
      {
        id: 2,
        position: "right",
        type: "text",
        text: "There was a major plot twist! The main character turned out to be a spy.",
        timestamp: new Date("2024-06-02T18:05:00"),
      },
      // More messages for ID 2...
    ],
    date: new Date("2024-06-02"),
    unread: 0,
  },
  // Add more chat histories for different IDs...
];

export const allProjects = [
  {
    id: 0,
    project_number: "#783451",
    rating: "4.9",
    due: true,
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Due",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Ongoing",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 4,
    project_number: "#783451",
    rating: "4.9",
    due: false,
    cancelled_date: '21 Dec, 2023',
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Due",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 1,
    project_number: "#783451",
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Graphic designer for brand design",
    location: "Remote",
    location_status: "Remote",
    hire: "Grace Ola",
    status: "Submitted",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Completed",
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
  {
    id: 2,
    project_number: "#783451",
    passed: true,
    rating: "4.9",
    count: "5",
    image: "/profile.png",
    description:
      "I want it to be a good work, Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim",
    title: "Plumbing service for my bathroom",
    location: "5, Adeniyi close, Yaba, Lagos.",
    location_status: "Physical",
    hire: "Tim Mark",
    status: "Cancelled",
    start_date: "23 Dec 2023",
    end_date: "23 Dec 2023",
    budget: "₦25,000",
    project_status: "Cancelled",
    cancelled_date: '23 Dec, 2023',
    document: "/project_doc.png",
    submitted: "/submitted.png",
    note: "I have finished the work. This is the picture. Make sure you keep in mind what I told you about how to manage it",
  },
];

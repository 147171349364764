import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
import { allProjects } from "../../../../../data";
import { Button } from "../../../../../../../components/ui";
import {
  cancel,
  due,
  projectchat,
  projectdoc,
  projectlocation,
  star,
  submitted,
  timeline1,
  timeline2,
} from "../../../../../../../constants/images";
import {
  setExtensionForm,
  setOverlay,
  setRateConsumer,
} from "../../../../../../../features/overlay";
import ProjectForm from "../../../_components/project-form";
import RatingForm from "../../../_components/ratings-form";
import ProjectAlert from "../../../_components/alert";
import ExtensionForm from "../../../_components/extension-form";

const DesktopProjectDetails = () => {
  const [activeItem, setActiveItem] = useState("summary");
  const [overall, setOverall] = useState();
  const dispatch = useDispatch();
  const projectId = useSelector(
    (state) => state?.overlay.displayStates.projectId,
  );
  const overallRatings = useSelector(
    (state) => state?.overlay.displayStates.ratings,
  );
  const overlayValue = useSelector(
    (state) => state?.overlay.displayStates.overlay,
  );
  const rateConsumer = useSelector(
    (state) => state?.overlay.displayStates.rateConsumer,
  );
  const extensionForm = useSelector(
    (state) => state?.overlay.displayStates.extensionForm,
  );
  const handleSubmit = () => {
    dispatch(setOverlay(true));
  };
  const handleOverallChange = (value) => {
    setOverall(value);
  };
  const handleItemClick = (item) => {
    setActiveItem(item);
  };
  const handleRatingForm = () => {
    dispatch(setRateConsumer(true));
  };
  const handleExtensionForm = () => {
    dispatch(setExtensionForm(true));
  };
  return (
    <div className="relative mx-auto hidden w-full px-10 pt-20 md:block">
      {overlayValue && (
        <div className="fixed left-0 right-0 top-0 z-20 block h-[160vh] w-full bg-black bg-opacity-25 " />
      )}
      {rateConsumer && (
        <div className="fixed left-0 right-0 top-0 z-20 block h-[160vh] w-full bg-black bg-opacity-25 " />
      )}
      {extensionForm && (
        <div className="fixed left-0 right-0 top-0 z-20 block h-[160vh] w-full bg-black bg-opacity-25 " />
      )}
      {overlayValue && (
        <div className="absolute top-0 z-50 lg:left-[20%] lg:top-[10%]">
          <ProjectForm />
        </div>
      )}
      {rateConsumer && (
        <div className="absolute top-0 z-50 lg:left-[20%] lg:top-[10%]">
          <RatingForm sendValue={handleOverallChange} />
        </div>
      )}
      {extensionForm && (
        <div className="fixed top-0 z-50 lg:left-[40%] lg:top-[20%]">
          <ExtensionForm />
        </div>
      )}
      {allProjects
        .filter((project) => project.id === projectId)
        .map((value) => (
          <div>
            <div className="flex flex-col gap-y-4">
              <div className="flex justify-between">
                <div className="flex items-center gap-x-2">
                  <img
                    src={value.image}
                    alt="profile image"
                    className="w-[60px]"
                  />
                  <div className="flex flex-col">
                    <span>{value.hire}</span>
                    <div className="flex items-center gap-x-2">
                      <img src={star} alt="" />
                      <span>{value.rating}</span>
                      <span>({value.count})</span>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-x-6">
                  <img
                    src={projectchat}
                    alt="chat"
                    className="rounded-[5px] border border-lightblue p-2"
                  />
                  <Button
                    width="200px"
                    className="h-[50px]"
                    onClick={handleSubmit}
                  >
                    Submit project
                  </Button>
                </div>
              </div>
              <span className="text-lg text-lightgray">
                Project number: {value.project_number}
              </span>
              <div
                className={`text-[25px] ${value.project_status === "Cancelled" && "flex w-full justify-between"} font-medium text-secondary`}
              >
                {value.title}
                {value.project_status === "Cancelled" && (
                  <div className="space-x-2">
                    <span className="text-[#C12B00] text-sm"  >Cancelled:</span>
                    <span className="text-base text-lightgray">{value.cancelled_date}</span>
                  </div>
                )}
              </div>
              {value?.due && (
                <ProjectAlert
                  bg="#FFF8D1"
                  text="This project is due within the next 24 hours. If you've completed the project, please ensure to submit it for review. If you need more time, you can request an extension but note that extensions are subject to approval by the customer"
                  sub="Keep in mind that if the delivery date passes without submission or approved extension, the project will not be considered complete, and you risk not receiving payment."
                  icon={due}
                />
              )}
              {value?.passed && (
                <ProjectAlert
                  bg="#FFE7E7"
                  text="The submission deadline for this project has passed, and no submission can be made at this time, which means you risk not being paid"
                  sub="The consumer will decide on whether to extend or end the project and you will be notified of the decision."
                  icon={cancel}
                />
              )}
              <div className="my-10 flex flex-col border border-[#AEAEAE] px-10 py-8 pb-10">
                <div className="flex flex-col">
                  <div className="flex w-full justify-between py-4 text-lg font-medium text-lightgray">
                    <div
                      className={`cursor-pointer ${
                        activeItem === "summary"
                          ? "text-secondary underline decoration-secondary decoration-4 underline-offset-[18px]"
                          : ""
                      }`}
                      onClick={() => handleItemClick("summary")}
                    >
                      Service report summary
                    </div>
                    <div
                      className={`cursor-pointer ${
                        activeItem === "work"
                          ? "text-secondary underline decoration-secondary decoration-4 underline-offset-[18px]"
                          : ""
                      }`}
                      onClick={() => handleItemClick("work")}
                    >
                      Submitted work
                    </div>
                    <div
                      className={`cursor-pointer ${
                        activeItem === "feedback"
                          ? "text-secondary underline decoration-secondary decoration-4 underline-offset-[18px]"
                          : ""
                      }`}
                      onClick={() => handleItemClick("feedback")}
                    >
                      Job feedback
                    </div>
                  </div>
                  <hr color="#AEAEAE" className="w-full" />
                </div>

                {/* summary */}
                {activeItem === "summary" && (
                  <div className="flex justify-between pt-10">
                    <div className="flex w-3/4 flex-col gap-y-3">
                      <span className="text-sm text-lightgray">
                        Status:{" "}
                        <span
                          className={`text-sm ${value.project_status === "Ongoing" ? "text-[#0096C1]" : value.project_status === "Completed" ? "text-[#36C100]" : value.project_status === "Cancelled" ? "text-[#C12B00]" : ""}`}
                        >
                          {value.project_status}
                        </span>
                      </span>
                      <div className="flex flex-col">
                        <span className="text-sm text-lightgray">Location</span>
                        <span className="text-base font-medium text-[#181818]">
                          {value.location_status}
                        </span>
                        <div className="flex items-center gap-x-2">
                          <img src={projectlocation} alt="location" />
                          <span className="text-base font-medium text-[#181818]">
                            {value.location}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm text-lightgray">Date</span>
                        <span className="text-sm text-lightgray">
                          From{" "}
                          <span className="text-sm font-medium text-[#181818]">
                            {value.start_date}
                          </span>
                        </span>
                        <span className="text-sm text-lightgray">
                          To{" "}
                          <span className="text-sm font-medium text-[#181818]">
                            {value.end_date}
                          </span>
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-sm text-lightgray">Budget</span>
                        <span className="text-sm font-medium text-[#181818]">
                          {value.budget}
                        </span>
                      </div>
                      <div className="flex w-3/4 flex-col">
                        <span className="text-sm text-lightgray">Note</span>
                        <span className="text-base font-normal text-lightgray">
                          {value.description}
                        </span>
                      </div>
                      <div className="flex flex-col items-start gap-y-2">
                        <span className="text-sm text-lightgray">Document</span>
                        <img
                          src={value.document}
                          className="w-[200px]"
                          alt="attachment"
                        />
                      </div>

                      <Button
                        backgroundColor="transparent"
                        textColor="#0096C1"
                        className="w-fit !font-medium"
                        onClick={handleExtensionForm}
                      >
                        Request delivery date extension
                      </Button>
                    </div>
                    <div className="w-1/2 border border-[#AEAEAE]">
                      <div className="flex flex-col gap-y-5 px-10 py-5">
                        <span className="text-lg font-semibold text-secondary">
                          Project timeline
                        </span>
                        <div className="flex flex-col gap-y-8">
                          <div className="flex items-center gap-x-3">
                            <img src={timeline1} alt="timeline" />
                            <div className="flex flex-col">
                              <span className="text-sm font-medium text-lightgray">
                                Project funded
                              </span>
                              <span className="text-xs font-normal text-lightgray">
                                18 Dec 2023
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-x-3">
                            <img src={timeline2} alt="timeline" />
                            <span className="text-sm font-medium text-lightgray">
                              Deliver work
                            </span>
                          </div>
                          <div className="flex items-center gap-x-3">
                            <img src={timeline2} alt="timeline" />
                            <span className="text-sm font-medium text-lightgray">
                              Consumer review and approval
                            </span>
                          </div>
                          <div className="flex items-center gap-x-3">
                            <img src={timeline2} alt="timeline" />
                            <span className="text-sm font-medium text-lightgray">
                              Accepted
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* submmited work */}
                {activeItem === "work" && (
                  <div className="flex flex-col gap-y-12 py-10">
                    <div className="flex flex-col">
                      <span className="text-[25px] font-medium text-secondary">
                        {value.title}
                      </span>
                      <span className="text-sm text-lightgray ">
                        Submitted{" "}
                        <span className="text-base font-medium text-[#181818]">
                          {value.start_date}
                        </span>
                      </span>
                    </div>

                    <div className="flex flex-col">
                      <span className="text-sm text-lightgray ">Note </span>
                      <span className="text-base font-medium text-[#181818]">
                        {value.description}
                      </span>
                    </div>

                    <div className="flex flex-col items-start gap-y-2">
                      <span className="text-sm text-lightgray ">Document</span>
                      <img
                        src={value.submitted}
                        className="w-[200px]"
                        alt="attachment"
                      />
                    </div>

                    <Button
                      backgroundColor="transparent"
                      textColor="#0096C1"
                      className="w-[40%]"
                    >
                      Update
                    </Button>
                  </div>
                )}

                {/* feedback */}
                {activeItem === "feedback" && (
                  <div className="flex w-3/4 flex-col gap-y-10 py-10">
                    <div className="flex flex-col">
                      <span className="text-lg font-medium text-[#181818]">
                        Consumer’s feedback to you
                      </span>
                      <div className="flex w-1/2 justify-between">
                        <span className="text-lg font-medium text-lightgray">
                          Overall rating
                        </span>
                        <div className="flex items-center gap-x-2">
                          <ReactStars
                            color={"#ffd700"}
                            size={20}
                            count={value.count}
                          />
                          <span className="text-base font-medium text-[#181818]">
                            5.0
                          </span>
                        </div>
                      </div>
                    </div>

                    <span className="text-lg font-normal text-lightgray">
                      I hired John for a plumbing issue at my home, and I
                      couldn't be more satisfied with his service. He responded
                      promptly to my request, arrived on time, and quickly
                      identified and fixed the problem.
                    </span>
                    <hr className="w-full" color="#AEAEAE" />
                    <div className="flex flex-col">
                      <span className="text-lg font-medium text-[#181818]">
                        Your feedback to consumer
                      </span>
                      {!overallRatings ? (
                        <span className="text-lg font-medium text-lightgray">
                          No feedback given
                        </span>
                      ) : (
                        <div className="flex w-1/2 items-center justify-between gap-x-2">
                          <span className="text-lg font-medium text-lightgray">
                            Overall ratings
                          </span>
                          <div className="flex items-center gap-x-2">
                            <ReactStars
                              key={overallRatings}
                              count={5}
                              name="overall"
                              value={overallRatings}
                              size={20}
                              activeColor="#FFE234"
                            />
                            <span className="text-base font-medium text-[#181818]">
                              {overallRatings}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    <Button
                      backgroundColor="transparent"
                      textColor="#0096C1"
                      className="w-[40%]"
                      onClick={handleRatingForm}
                    >
                      Rate consumer
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DesktopProjectDetails;

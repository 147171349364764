import React from "react";
import {
  locationBlue,
  calendar,
  starRate,
} from "../../../../../constants/images";
import { Link } from "react-router-dom";
const MobileCard = ({
  title,
  address,
  name,
  ratings,
  date,
  budget,
  image,
  to,
  status,
}) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "Ongoing":
        return "text-[#0096C1]";
      case "Cancelled":
        return "text-[#C12B00]";
      case "Completed":
        return "text-[#21A601]";
      default:
        return "text-gray-500";
    }
  };
  return (
    <Link to={to}>
    <div className="rounded-[7px] border-[1px] border-[#AEAEAE] px-3 py-4 ">
      <div className="flex justify-between border-b-[1px] border-b-[#AEAEAE] pb-3">
        <div className="flex space-x-1">
          <div>
            <img src={image} alt="" />
          </div>
          <div className="">
            <h3 className="text-[14px] font-semibold text-[#002D3A]">{name}</h3>
            <div className="flex items-center space-x-1">
              <div>
                <img src={starRate} alt="" />
              </div>
              <span className="text-[10px] font-semibold text-[#002D3A]">
                {ratings}{" "}
                <span className="font-normal text-[#5F5F5F]"> (31)</span>
              </span>
            </div>
          </div>
        </div>
        <p className={`mt-1 text-[14px] font-medium ${getStatusColor(status)}`}>
          {status}
        </p>
      </div>
      <div className="mt-2">
        <span className="text-[14px] font-medium text-[#002D3A]">{title}</span>
        <div className="flex items-center space-x-1 ">
          <div className="">
            <img src={locationBlue} alt="" />
          </div>
          <span className="text-[12px] font-medium text-[#5F5F5F]">
            {address}
          </span>
        </div>
        <div className="flex justify-between mt-2">
        <div className="flex items-center space-x-1 mb-2">
            <div>
              <img src={calendar} alt="" />
            </div>{" "}
            <span className="text-[12px] text-[#002D3A]">{date}</span>
          </div>
          <div className="">
          <span className="text-[12px] font-medium text-[#002D3A] ">
            ₦{budget}Budget
          </span>
         </div>
        </div>
      </div>
    </div>
    </Link>
  );
};

export default MobileCard;

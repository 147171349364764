import React from "react";
import { setViewProject } from "../../../../../../features/overlay";
import { useDispatch } from "react-redux";
import { accessmessages } from "../../../../../../constants/images";

const ChatViewProject = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setViewProject(false));
  };

  return (
    <div className="relative overflow-hidden w-3/4 animate-slide_right bg-primary">
      <div
        className=" absolute right-[23px] top-[31px] text-lg font-medium text-[#181818] hover:cursor-pointer"
        onClick={handleClose}
      >
        X
      </div>
      <div className="flex flex-col items-center px-8 py-10">
        <img src={accessmessages} alt="projects" />
        <span className="text-center text-base font-normal text-[#181818]">
          You have no project here with John Doe. Once you start a new project,
          you can access it here
        </span>
      </div>
    </div>
  );
};

export default ChatViewProject;

import React, { useRef, useState } from "react";
import { useNavigate,useLocation, Link } from 'react-router-dom';
import Container from "../../container";
import { learnMore,addFile,linkarrow } from "../../../../constants/images";
import { Button, Input } from "../../../../components/ui";

const Request = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const goToBookingProcess = () => {
    navigate("/bookingProcess", { state: { from: "requestServices" } });
  };
        const [formData, setFormData] = useState(location.state ||{
          title: "",
          address: "",
          from: "",
          to: "",
          budget: "",
          image: null,
          option: "",
          message: "",
        });
        const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({ ...formData, [name]: value });
        };
        // const [file, setFile] = useState();
        const fileRef = useRef()
        const handleUpload = () => {
            fileRef.current.click()
        }
        const handleImageChange = (e) => {
          setFormData({ ...formData, image: URL.createObjectURL(e.target.files[0]) });
        };
        const handleSubmit = (e) => {
          e.preventDefault();
          navigate('/serviceRequest', { state: formData });
        };
  return (
    <Container>
      <div className="mx-auto  w-[85%]  py-24 md:w-[75%]">
        <div className="relative">
        <h1 className="text-center text-[18px] font-semibold text-[#002D3A] md:text-start md:text-[25px]">
          Request Service
        </h1>
        <Link
        to="/chat"
        >
        <img src={linkarrow} alt="link arrow" className="md:hidden rotate-180 relative top-[-1.2rem] cursor-pointer"/>
        </Link>
        </div>
        <div className="flex items-start space-x-2 md:hidden ">
          <div className="">
            <img src={learnMore} alt="" />
          </div>
          <span className="text-[14px] leading-[21.11px] text-[#5F5F5F]">
            Learn how the booking process works{" "}
            <span className="cursor-pointer font-semibold text-[#0096C1]" onClick={goToBookingProcess}>
              here
            </span>
          </span>
        </div>
        <div className="mt-3 w-full gap-5 md:flex">
          <div className="rounded-[9px] border-[1px] border-[#AEAEAE] p-4 md:w-[60%]">
            <div className="">
              <h3 className="text-[18px] font-semibold text-[#181818]">
                Project details
              </h3>
              <form action="" className="mt-3 flex flex-col gap-4" onSubmit={handleSubmit}>
                <div className="">
                  <span className="text-[16px] font-normal">
                    Give your project a title
                  </span>
                  <Input
                    placeholder="e.g plumbing service for my bathroom"
                    type="text"
                    name="title"
                    value={formData.title} 
                    onChange={handleChange}
                    className="w-full rounded-[4px] px-2 py-2 placeholder:text-[14px] "
                  />
                </div>
                <div className="">
                  <span className="text-[16px] font-normal">Location</span>

                  <select
                    name="option"
                    id="option"
                    value={formData.option} 
                    onChange={handleChange}
                    className="w-full  rounded-[4px] border border-lightgray bg-primary p-2 px-2 py-2 text-sm text-lightgray outline-none md:text-base "
                  >
                    <option value="" selected disabled ></option>
                    <option value="physical">Physical</option>
                    <option value="online">Online</option>
                  </select>
                </div>
                <div className="">
                  <span className="text-[16px] font-normal">
                    Address? (For physical services)
                  </span>
                  <Input
                    type="text"
                    name="address"
                    value={formData.address} 
                    onChange={handleChange}
                    className="w-full rounded-[4px] px-2 py-2 placeholder:text-[14px] "
                  />
                </div>
                <div className="">
                  <span className="text-[16px] font-normal">Date</span>
                  <Input
                    type="text"
                    name="from"
                    value={formData.from} 
                    onChange={handleChange}
                    placeholder="From"
                    className="w-full  rounded-[4px] bg-[#F0F0F0] px-2 placeholder:text-[14px] placeholder:text-[#181818]"
                  />
                  <Input
                    type="text"
                    name="to"
                    value={formData.to} 
                    onChange={handleChange}
                    placeholder="To"
                    className="mt-2  w-full rounded-[4px] bg-[#F0F0F0] px-2 placeholder:text-[14px] placeholder:text-[#181818]"
                  />
                </div>
                <div className="">
                  <span className="text-[16px] font-normal">
                    What is your budget?
                  </span>
                  <Input
                    type="text"
                    name="budget"
                    value={formData.budget} 
                    onChange={handleChange}
                    className="w-full rounded-[4px] px-2 py-2 placeholder:text-[14px] "
                  />
                </div>
                <div className="">
                  
                  <span className="text-[16px] font-normal">
                    Upload documents or images related to your project
                  </span>
                  <div className="flex space-x-1 items-center">
                  <div className="">
                  <img src={addFile} alt="" onClick={handleUpload} className="cursor-pointer"/>
                  </div>
                  <span className="text-[#0096C1]">Add files</span>
                  </div>
              
                   
            <input type="file" onChange={handleImageChange} className="hidden" ref={fileRef}   />
       
                </div>
                <div className="">
                  <span className="text-[16px] font-normal">
                    Add relevant notes (optional)
                  </span>
                  <textarea
                    id="message"
                    name="message"
                    rows="3"
                    cols="50"
                    value={formData.message} 
                    onChange={handleChange}
                    placeholder="Briefly describe your specific service needs"
                    className="w-full rounded-[5px] border-[1px] border-[#181818] px-2 py-2 outline-none placeholder:text-[14px]"
                  ></textarea>
                </div>
                <div className="mb-9 mt-5 flex gap-2">
                  <Button
                    children="Cancel"
                    className="w-full border-2 border-[#0096C1]"
                    backgroundColor="white"
                    textColor="#0096C1"
                    border="1px solid #0096C1"
                  />
                  <Button
                    children="Next"
                    type="submit"
                    className="w-full border-2 border-[#0096C1]"
                    backgroundColor="#0096C1"
                    textColor="white"
                    border="1px solid #0096C1"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="hidden w-[40%] items-start space-x-1 md:flex ">
            <div className="">
              <img src={learnMore} alt="" />
            </div>
            <span className="text-[18px] leading-[21.11px] text-[#5F5F5F]">
              Learn how the booking process works{" "}
              <span className="cursor-pointer font-semibold text-[#0096C1]" onClick={goToBookingProcess}>
                here
              </span>
            </span>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Request;



import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRouteDispute = () => {
  const token = useSelector((state) => state?.login.states.accessToken);

  // return token ? <Outlet /> : <Navigate to="/dispute" exact />;
  return token ? <Outlet /> : <Navigate to="/dispute" exact />;
};

export default ProtectedRouteDispute;
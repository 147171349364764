import React from "react";
import AvailableJob from "../../../../_components/availabe_job";
import { availableJobs } from "../../../../data";

const MoreJobsContent = () => {
  return (
    <div
      className={`md:h-[160vh] items-center justify-center py-8 md:float-right md:w-[78%] md:max-w-[100%] md:flex-col md:px-0 px-8 md:pr-6 md:pt-24 md:gap-2`}
    >
      <span className="mr-auto md:py-4 space-y-10 text-lg md:text-[30px] font-semibold text-secondary">
        Recommended Jobs
      </span>
      <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3   ">
        {availableJobs.map((vendor) => (
          <AvailableJob key={vendor.id} {...vendor} />
        ))}
      </div>
    </div>
  );
};

export default MoreJobsContent;

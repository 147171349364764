import React, { useEffect, useState } from "react";
import Ratings from "../../../desktop-view/_components/sidebar/_components/ratings";
import RangeSlider from "react-range-slider-input";
// import "react-range-slider-input/dist/style.css";
import { Button } from "../../../../../components/ui";

const stars = [
  {
    id: 0,
    size: 20,
    value: 5,
    edit: false,
  },
  {
    id: 1,
    size: 20,
    value: 4,
    edit: false,
  },
  { id: 2, size: 20, value: 3, edit: false },

  { id: 3, size: 20, value: 2, edit: false },
  { id: 4, size: 20, value: 1, edit: false },
];
const Filter = ({ closeOverlay }) => {
  // filter values
  const [closeFilter, setCloseFilter] = useState(false);
  const [seledtedRatings, setSelectedRatings] = useState(null);
  const [rangeValues, setRangeValues] = useState([]);
  const [location, setLocation] = useState("");
 
  const [min, setMin] = useState(2000);
  const [max, setMax] = useState(50000);

  useEffect(() => {
    if (rangeValues.length !== 0) {
      setMin(rangeValues[0]);
      setMax(rangeValues[1]);
    }
  }, [rangeValues]);

  const handleClose = () => {
    closeOverlay(!closeFilter);
  };
  return (
    <div className="absolute right-[22px] top-[85px] z-[999] w-[390px] animate-slide_up rounded-tl-[30px] rounded-tr-[30px] bg-primary py-8">
      <div className="flex w-full items-center justify-center">
        <span className="text-lg font-semibold">Filter by</span>
      </div>
      <div
        className=" absolute right-[23px] top-[31px] text-lg font-medium text-[#181818]"
        onClick={handleClose}
      >
        X
      </div>
      <div className="space-y-4 px-4 py-8">
        <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
          <span>Location</span>
        </div>
        <div className=" flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="location"
              value="onsite"
              id="onsite"
              onChange={(e) => setLocation(e.target.value)}
              checked={location === "onsite"}
              className="onsite"
            />
            <label
              htmlFor="onsite"
              className="text-base font-normal text-lightgray"
            >
              On site
            </label>
          </div>

          <input
            type="text"
            placeholder="Type your location"
            className="border-[0.5px] border-[#AEAEAE] px-[15px] py-[10px] outline-0"
          />
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            value="remote"
            name="location"
            id="remote"
            checked={location === "remote"}
            onChange={(e) => setLocation(e.target.value)}
          />
          <label
            htmlFor="remote"
            className="text-base font-normal text-lightgray"
          >
            Remote
          </label>
        </div>
      </div>
      <div className="w-full">
        <div className="space-y-4 px-4">
          <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
            <span>Price range</span>
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-xs font-normal text-lightgray">
              &lt; ₦{min}
            </span>
            <span className="text-xs font-normal text-lightgray">₦{max}</span>
          </div>
          <RangeSlider
            min={2000}
            max={50000}
            step={500}
            onInput={(e) => setRangeValues(e)}
          />
        </div>
      </div>{" "}
      <div className="space-y-2 px-4 py-8">
        <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
          <span>Location</span>
        </div>
        {stars.map((star) => {
          return (
            <div className="flex flex-col items-start" key={star.id}>
              <Ratings
                star={star}
                value={star.value}
                isSelected={seledtedRatings === star.value}
                onCheckboxChange={(e) => setSelectedRatings(+e.target.value)}
              />
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-center gap-6">
        <Button
          children="Clear"
          backgroundColor={"transparent"}
          textColor={"#0096C1"}
          className="hover:!bg-lightblue hover:!text-primary"
          border={"none"}
        />
        <Button children="Apply" className="" border={"none"} />
      </div>
    </div>
  );
};

export default Filter;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ServiceIcon = () => {
  const location = useLocation();
  const [fill, setFill] = useState();
  const [pathname, setPathname] = useState();

  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  useEffect(() => {
    const path = pathname?.substring(pathname.lastIndexOf("/") + 1);
    if (path === "service") {
      setFill("#ffff");
    } else {
      setFill("#5F5F5F");
    }
  }, [pathname]);

  return (
    <svg
    width="22"
    height="20" 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill={fill}>
      <path d="M11.9998 1L18 11H6L11.9998 1ZM13 13.5H21V21.5H13V13.5ZM6.75 22C9.37335 22 11.5 19.8734 11.5 17.25C11.5 14.6266 9.37335 12.5 6.75 12.5C4.12665 12.5 2 14.6266 2 17.25C2 19.8734 4.12665 22 6.75 22Z"></path>
    </svg>
  );
};

export default ServiceIcon;

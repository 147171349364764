import React from 'react'
// import Container from '../container'

const NameHolder = () => {
  return (
    <div className="">
      <h1 className="text-[#002D3A] font-semibold text-[25px]">Hello, John</h1>
    </div>
  
  )
}

export default NameHolder

import React from 'react'

const Card = ({data}) => {
  return (
    <div className='w-full'>
        <div className="flex items-start flex-col bg-primary shadow-xl p-10 gap-3 min-w-full max-w-full h-full hover:scale-105 hover:cursor-pointer transition-all ease-in">
            <img src={data.image} alt={data.title} className='w-auto' />
            <span className='text-secondary text-[20px] md:text-[25px] font-semibold text-wrap'>{data.title}</span>
            <span className='text-lightgray text-base font-normal'>{data.text}</span>
        </div>
    </div>
  )
}

export default Card
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setOverlay } from '../../../../../../../features/overlay'
import { AllChatsIcon, StarredChatsIcon, UnreadChatsIcon } from "../../icons";

const MobileSearchFilter = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setOverlay(false));
  };
  return (
    <div className="fixed left-0 right-0 bottom-[10%] z-[999] block w-full animate-slide_up rounded-tl-[30px] rounded-tr-[30px] bg-primary px-4 py-8 md:hidden">
      <div
        className=" absolute right-[23px] top-[31px] text-lg font-medium text-[#181818]"
        onClick={handleClose}
      >
        X
      </div>
      <div className="">
        <div className="flex flex-col justify-center gap-y-3 py-4 font-medium text-secondary">
          <span className="font-semibold text-secondary px-8">Filter</span>
          <hr className="w-full mx-0 px-0" color="#AEAEAE" />
          <div className="flex px-8 items-center gap-2 hover:cursor-pointer">
            <AllChatsIcon />
            <span>All</span>
          </div>
          <hr className="w-full" color="#AEAEAE" />
          <div className="flex px-8 items-center gap-2 hover:cursor-pointer">
            <UnreadChatsIcon />
            <span>Unread</span>
          </div>
          <hr className="w-full" color="#AEAEAE" />
          <div className="flex px-8 items-center gap-2 hover:cursor-pointer">
            <StarredChatsIcon />
            <span>Starred</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSearchFilter;

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const captureAndDownload = async (elementId) => {
  const element = document.getElementById(elementId);
  if (!element) {
    console.error(`Element with id ${elementId} not found`);
    return;
  }

  // Capture the element as a canvas
  const canvas = await html2canvas(element);

  // Convert the canvas to an image
  const imgData = canvas.toDataURL('image/png');

  // Create a PDF and add the image to it
  const pdf = new jsPDF();
  pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);

  // Save the PDF
  pdf.save('receipt.pdf');
};

import React from 'react'
import { chatsend,record } from '../../../../constants/images'

const ChatInput = () => {
  return (
    <div className='fixed pr-10 bg-primary rounded-tr-[20px] z-20 rounded-tl-[20px] py-6 bottom-0 w-[57%]'>
    <div className="flex relative items-center space-x-3 px-10">
        <span className='border border-[#AEAEAE] text-3xl font-extralight px-2 h-[34px] cursor-pointer flex items-center'>+</span>
        <input type="text" className='w-full py-2 px-5 outline-none border border-[#AEAEAE] h-[34px]' placeholder='Type something...' />
        <img src={chatsend} alt="send" className='bg-lightblue py-2 px-2 h-[34px]' />
        <img src={record} alt="record" className='absolute left-[83%]' />
    </div>
    </div>
  )
}

export default ChatInput
import React from "react";
import { Link } from "react-router-dom";
import { accessmessages } from "../../../../../../../constants/images";

const NoMessages = () => {
  return (
    <div className="flex h-[80vh] flex-col items-center justify-center space-y-10">
      <img src={accessmessages} alt="no messages" />
      <span className="text-base font-semibold text-secondary">
        No messages yet
      </span>
      <span className="text-center text-base font-normal text-[#181818]">
        Start connecting with consumers and discussing potential projects
      </span>
      <Link
        to="/vendor-dashboard/jobs"
        className="pt-16 text-base font-bold text-lightblue"
      >
        Browse jobs
      </Link>
    </div>
  );
};

export default NoMessages;

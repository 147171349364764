import ProjectContents from "./_components/project-contents";

const VendorDashboardProjectsDesktop = () => {
  return (
    <div className="hidden md:block">
      <ProjectContents />
    </div>
  );
};

export default VendorDashboardProjectsDesktop;

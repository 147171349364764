import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddNewDiscount } from "../../../../../../redux/slice/modalOverlay";

const Navbar = () => {
  const dispatch = useDispatch();
  const discount = useSelector((state) => state.modalOverlay.states.discountDetails);

  const handleAddNewDiscount = () => {
    dispatch(setAddNewDiscount(true));
  };

  return (
    <div className="mb-10 w-full rounded-[10px] rounded-bl-none rounded-br-none bg-primary shadow-lg">
      <div className="flex w-full items-center  justify-between px-12 py-4">
        <div className="text-[25px] font-medium text-secondary">Discounts</div>
        {Object.keys(discount).length === 0 && (
          <span className="cursor-pointer text-base text-lightblue" onClick={handleAddNewDiscount}>
            Add new
          </span>
        )}
      </div>
    </div>
  );
};

export default Navbar;

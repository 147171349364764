import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setShowApplyForm } from "../../../../../../../../../features/overlay";
import { setApplicationForm } from "../../../../../../../../../features/formSlice";
import { Button } from "../../../../../../../../../components/ui";
import { addfile } from "../../../../../../../../../constants/images";

const DesktopForm = ({ sendSuccessful }) => {
  const dispatch = useDispatch();
  const [successful, setSuccessful] = useState(false);
  const [servicefee, setServiceFee] = useState("-");
  const [amount, setAmount] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState();

  const [details, setDetails] = useState({
    letter: "",
    bid: 0,
    file: "",
    availability: "",
  });
  const [errorState, setErrorState] = useState({
    letterError: "",
    bidError: "",
    fileError: "",
    availabilityError: "",
  });

  const calulateBid = () => {
    if (details.bid) {
      const fee = details.bid * 0.04;
      setServiceFee(fee);
    }
    if (details.bid && servicefee) {
      const amount = parseInt(details.bid) - +servicefee;
      setAmount(amount);
    }
  };
  
  useEffect(() => {
    calulateBid();
  }, [details.bid, servicefee]);

  const validate = () => {
    let isError = false;
    const errors = {
      letterError: "",
      bidError: "",
      fileError: "",
      availabilityError: "",
    };

    if (!details.letter) {
      isError = true;
      errors.letterError = "Please enter your cover letter";
    }
    if (!fileName) {
      isError = true;
      errors.fileError = "Please attach a sample of your previous work";
    }
    if (!details.bid) {
      isError = true;
      errors.bidError = "Please enter a bid amount";
    }
    if (!details.availability) {
      isError = true;
      errors.availabilityError = "Please tick the checkbox if you're available";
    }

    setErrorState((prevState) => ({ ...prevState, ...errors }));
    return isError;
  };

  const handleForm = (e) => {
    setDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileUpload = (e) => {
    setFileName(e.target.files[0].name);
    setSelectedFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validate();

    if (!error) {
      dispatch(
        setApplicationForm({
          application_details: details,
          service_fee: servicefee,
          actual_amount: amount,
          attachment: selectedFile,
        }),
      );
      sendSuccessful(!successful);
    }
  };

  const handleCloseForm = () => {
    dispatch(setShowApplyForm(false));
  };
  return (
    <div className="absolute -right-36 z-[999] h-[689px] max-h-[80vh] w-[584px] overflow-y-auto rounded-[5px] bg-primary">
      <span
        className="absolute right-6 cursor-pointer text-2xl"
        onClick={handleCloseForm}
      >
        x
      </span>
      <form className="w-full space-y-4 px-10 py-8">
        <span className="text-[20px] font-medium text-secondary">
          Apply to Job
        </span>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label
              htmlFor="cover_letter"
              className="text-base font-normal text-[#181818]"
            >
              Cover letter
            </label>
            <textarea
              name="letter"
              id="cover_letter"
              cols="30"
              rows="10"
              onChange={handleForm}
              className="rounded-[5px] border-[0.5px] border-[#181818] p-3 text-lightgray outline-none"
              placeholder="Explain why you are a good fit for the job"
            ></textarea>
            <span className="text-sm text-[#e62e2e]">
              {errorState.letterError}
            </span>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-base font-normal text-[#181818]">
              Attachments{" "}
              <span className="text-sm font-normal text-lightgray">
                (Attach samples of your previous works)
              </span>{" "}
            </div>
            <div className="flex flex-col items-center justify-center gap-y-2 border border-dashed border-[#181818] py-16">
              <label
                htmlFor="previous_work"
                className="mx-auto flex w-fit cursor-pointer items-center justify-center gap-2 bg-[#E6F5F9] px-3 py-4"
              >
                <img src={addfile} alt="add file" />
                <span className="text-sm text-lightblue">Add Files</span>
              </label>
              <span className="text-center text-sm text-lightgray">
                {fileName}
              </span>
              <input
                type="file"
                name="file"
                id="previous_work"
                onChange={handleFileUpload}
                className="hidden"
              />
            </div>
            <span className="text-sm text-[#e62e2e]">
              {errorState.fileError}
            </span>
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="cover_letter"
              className="text-base font-normal text-[#181818]"
            >
              Set your bid
            </label>
            <input
              type="text"
              onChange={handleForm}
              name="bid"
              id="bid"
              className="rounded-[5px] border-[0.5px] border-[#181818] p-3 text-[#181818] outline-none"
            />
            <span className="text-sm text-[#e62e2e]">
              {errorState.bidError}
            </span>
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="cover_letter"
              className="text-base font-normal text-[#181818]"
            >
              4% vendor service fee
            </label>
            <input
              type="text"
              className="rounded-[5px] border-[0.5px] border-[#181818] bg-lightgray bg-opacity-15 p-3 text-lightgray outline-none"
              placeholder="0"
              disabled={true}
              value={`-₦${servicefee}`}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="cover_letter"
              className="text-base font-normal text-[#181818]"
            >
              You will get
            </label>
            <input
              type="text"
              value={amount}
              className="rounded-[5px] border-[0.5px] border-[#181818] bg-lightgray bg-opacity-15 p-3 text-lightgray outline-none"
              disabled={true}
              placeholder="0"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="availability"
              className="text-base font-normal text-[#181818]"
            >
              Availability confirmation
            </label>
            <div className="flex flex-col items-start gap-1">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="availability"
                  id="availability"
                  onChange={handleForm}
                />
                <span>I am available on the scheduled date and time</span>
              </div>
              <span className="text-sm text-[#e62e2e]">
                {errorState.availabilityError}
              </span>
            </div>
          </div>
        </div>
        <Button className="!w-full" onClick={handleSubmit}>
          Apply
        </Button>
      </form>
    </div>
  );
};

export default DesktopForm;

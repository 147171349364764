import React from 'react'
import { Link } from 'react-router-dom'
import { blueLove } from '../../../constants/images'
const DesktopVendor = ({image,vendor,name,title,service,location,rating,rating_count,price,bgcolor,to,handleLike,isLike,id }) => {
  return (
    
    <div>
       <div className="h-full w-full cursor-pointer relative  lg:min-w-[100%]  transition-all ease-in-out hover:scale-105">
            <div className=" flex flex-col">
            <Link to={to}>
              <img src={image} alt="service" className="w-full rounded-t-[5px]" />
              </Link>
              <div className="flex flex-col  border border-t-0 border-[#AEAEAE] px-4 py-2">
              <Link to={to}>
                <img
                  src={vendor}
                  alt={name}
                  className="mt-[-2rem] w-[20%]"
                />
                 </Link>
                <span className="" onClick={()=>handleLike()}>
              { isLike[id]?<img src={blueLove} alt="" className="ml-auto  z-10" />: <img src="/like.png" alt="" className="ml-auto  z-10" />}
                </span>
                <Link to={to}>
                  <div className="">
                <div className={`mx-auto w-full py-[3px] my-3 text-center text-xs font-medium text-lightgray `} style={{ backgroundColor: bgcolor }}>
                  {title}
                </div>
                
                <span className="text-base font-bold text-lightblue">
                  {name}
                </span>
                </div>
                <span className="text-xs font-semibold text-[#181818] capitalize mt-2">
                  {service}
                </span>
                <div className="flex gap-1 mt-2">
                  <img src="/location.png" alt="" />
                  <span className="text-xs font-medium text-lightgray">
                    {location}
                  </span>
                </div>

                <div className="flex justify-between pt-3">
                  <div className="flex items-center gap-1 text-xs">
                    <img src="/rate.png" alt="" />
                    <span className="font-semibold">{rating}</span>
                    <span className="font-normal">{rating_count}</span>
                  </div>
                  <div className="my-auto h-[20px] w-[1px] bg-lightgray" />
                  <div className="flex items-center gap-1 text-[#181818]">
                    <span className="text-xs font-normal">From:</span>
                    <span className="text-base font-semibold">
                      ₦{price}
                    </span>
                  </div>
                </div>
                </Link>
              </div>
            </div>
            </div>
            
    </div>
   
  )
}

export default DesktopVendor

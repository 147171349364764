import React from "react";

const EarningsIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0039 4H19.0039C19.5562 4 20.0039 4.44771 20.0039 5V17C20.0039 17.5523 19.5562 18 19.0039 18H1.00391C0.451626 18 0.00390625 17.5523 0.00390625 17V1C0.00390625 0.44771 0.451626 0 1.00391 0H16.0039V4ZM2.00391 6V16H18.0039V6H2.00391ZM2.00391 2V4H14.0039V2H2.00391ZM13.0039 10H16.0039V12H13.0039V10Z"
        fill="#5F5F5F"
      />
    </svg>
  );
};

export default EarningsIcon;

import React from 'react'

const Card = ({provision}) => {
  return (
    <div className='w-full'>
        <div className="flex items-start flex-col bg-primary shadow-xl p-10 gap-3 min-w-full max-w-full h-full hover:scale-105 hover:cursor-pointer transition-all ease-in">
            <img src={provision.image} alt={provision.title} className='w-auto' />
            <span className='text-secondary text-[20px] md:text-[25px] font-semibold text-wrap'>{provision.title}</span>
            <span className='text-lightgray text-base font-normal'>{provision.text}</span>
        </div>
    </div>
  )
}

export default Card
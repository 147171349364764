import React, { useState } from "react";
import {
  blackLocation,
  ChatRequest,
  linkarrow,
} from "../../../../../../constants/images";
import ProjectTimeLine from "../../../../projectTimeline";

const SummaryReport = (props) => {
  const [showProjectTimeline, setShowProjectTimeline] = useState(false);
  const handleShowProjectTimeline = () => {
    setShowProjectTimeline(!showProjectTimeline);
  };
  return (
    <div className="md:px-11 md:py-5 px-4 md:flex md:justify-between md:mt-10 mt-2 ">
      <div className="md:w-[50%] w-full">
        <p className="mb-4 text-[12px] font-normal text-[#5F5F5F] md:text-[14px]">
          Status:{" "}
          <span className="text-[14px] font-medium text-[#0096C1] md:text-[16px]">
            
            {props?.res?.status}
          </span>
        </p>
        <p className="text-[12px] font-normal text-[#5F5F5F] md:text-[14px]">
          Project Title
        </p>
        <p className="text-[14px] font-medium capitalize md:text-[18px]">
          Plumbing service for my bathroom
        </p>
        <p className="mt-3 text-[12px] font-normal text-[#5F5F5F] md:text-[14px]">
          Location
        </p>
        <p className="text-[14px] font-medium capitalize md:text-[18px]">
          Physical
        </p>
        <div className="flex items-center space-x-2">
          <div className="">
            <img src={blackLocation} alt="" />
          </div>
          <span className="text-[14px] font-medium capitalize md:text-[18px]">
            5, Adeniyi close, Yaba, Lagos.
          </span>
        </div>
        <p className="text-[12px]font-normal mt-3 text-[#5F5F5F] md:text-[14px]">
          Date
        </p>
        <p className="text-[12px] font-normal text-[#5F5F5F] md:text-[14px]">
          From:{" "}
          <span className="tetx-[14px] font-medium text-[#181818] md:text-[16px]">
            20 Dec 2023
          </span>
        </p>
        <p className="text-[12px] font-normal text-[#5F5F5F] md:text-[14px]">
          To:{" "}
          <span className="text-[14px] font-medium text-[#181818]">
            23 Dec 2023
          </span>
        </p>
        <p className="mt-3 text-[12px] font-normal text-[#5F5F5F] md:text-[14px]">
          Budget
        </p>
        <p className="text-[14px] font-medium capitalize md:text-[18px]">
          ₦25,000
        </p>
        <p className="mt-3 text-[12px] font-normal text-[#5F5F5F] md:text-[14px]">
          Document
        </p>

        <div>
          <img
            src={ChatRequest}
            alt="Uploaded"
            className="h-[133.4px] w-[200px]"
          />
        </div>

        <p className="mt-3 text-[12px] font-normal text-[#5F5F5F] md:text-[14px]">
          Note
        </p>
        <p className="text-[14px] leading-[16.42px] md:text-[18px] md:leading-[21.11px]">
          I want it to be a good work, Horem ipsum dolor sit amet, consectetur
          adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis
          tellus. Sed dignissim
        </p>
        <div className="md:hidden">
              <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
                <div
                  className=" flex cursor-pointer items-center justify-between  "
                  onClick={handleShowProjectTimeline}
                >
                  <span className=" text-[12px] font-normal text-[#5F5F5F]">
                    View project timeline
                  </span>
                  <img
                    src={linkarrow}
                    alt=""
                    className={`${showProjectTimeline && "ease rotate-90 transition-all"}`}
                  />
                </div>
                {showProjectTimeline && (
                  <div className="w-full  px-4 py-3 ">
                  <h1 className="text-[18px] font-semibold text-[#002D3A]">
                    Project timeline
                  </h1>
                  <div className="mt-4">
                    <div className="flex items-start space-x-2">
                      <div className="flex flex-col items-center justify-center">
                        <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
                          <div className="h-3 w-3 rounded-full  bg-[#0096C1]"></div>
                        </div>
                        <div className="h-14 border-r-2 border-[#AEAEAE] border-dotted"></div>
                      </div>
                      <span className="font-medium text-[#5F5F5F]">
                        Vendor confirmation
                      </span>
                    </div>
                    <div className="flex items-start space-x-2">
                      <div className="flex flex-col items-center justify-center">
                        <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
                          
                        </div>
                        <div className="h-14 border-r-2 border-[#AEAEAE] border-dotted"></div>
                      </div>
                      <span className="font-medium text-[#5F5F5F]">
                      Funded (Escrow protection)
                      </span>
                    </div>
                    <div className="flex items-start space-x-2">
                      <div className="flex flex-col items-center justify-center">
                        <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
                          
                        </div>
                        <div className="h-14 border-r-2 border-[#AEAEAE] border-dotted"></div>
                      </div>
                      <span className="font-medium text-[#5F5F5F]">
                      Delivered
                      </span>
                    </div>
                    <div className="flex items-start space-x-2">
                      <div className="flex flex-col items-center justify-center">
                        <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
                          
                        </div>
                        
                      </div>
                      <span className="font-medium text-[#5F5F5F]">
                      Accepted
                      </span>
                    </div>
                  </div>
                </div>
                )}
              </div>
            </div>
      </div>
      <div className="md:w-[35%] md:block hidden ">
      <ProjectTimeLine/>
      </div>
    </div>
  );
};

export default SummaryReport;

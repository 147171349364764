import React, { useEffect, useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { linkarrow } from "../../../../../constants/images";
import { ServiceCategories } from "../../../../../constants";
import Ratings from "./_components/ratings";

const stars = [
  {
    id: 0,
    size: 27,
    value: 5,
    edit: false,
  },
  {
    id: 1,
    size: 27,
    value: 4,
    edit: false,
  },
  { id: 2, size: 27, value: 3, edit: false },

  { id: 3, size: 27, value: 2, edit: false },
  { id: 4, size: 27, value: 1, edit: false },
];

const initialCategories = [
  {
    id: 15,
    title: "All services",
  },
  {
    id: 16,
    title: "Repair and maintenance",
  },
  {
    id: 17,
    title: "IT and Digital service",
  },
  {
    id: 18,
    title: "Events and entertainment",
  },
  {
    id: 19,
    title: " Beauty services",
  },
];

const Sidebar = () => {
  const [showServices, setShowServices] = useState(false);

  // filter values
  const [category, setCategory] = useState("");
  const [seledtedRatings, setSelectedRatings] = useState(null);
  const [rangeValues, setRangeValues] = useState([]);
  const [location, setLocation] = useState("");
  const [min, setMin] = useState(2000);
  const [max, setMax] = useState(50000);
  const handleShowAll = () => {
    setShowServices(!showServices);
  };
  useEffect(() => {
    if (rangeValues.length !== 0) {
      setMin(rangeValues[0]);
      setMax(rangeValues[1]);
    }
  }, [rangeValues]);

  return (
    <div className="w-[30%] ">
      <div className="w-full">
        <ul className=" rounded-[5px] border-[0.5px]  border-[#AEAEAE]">
          <li className=" bg-secondary p-5 py-[15px] text-[25px] font-semibold text-primary">
            Service and Categories
          </li>
          {initialCategories.map((service) => (
            <div key={service.id}>
              <li
                className="cursor-pointer p-5 text-lg  font-normal text-secondary transition-all ease-in hover:bg-[#E6F5F9]"
                onClick={() => setCategory(service.title)}
              >
                {service.title}
              </li>
              <hr className="h-[.5px] w-full bg-[#AEAEAE]" />
            </div>
          ))}
          {ServiceCategories.map(
            (service) =>
              showServices && (
                <div key={service.id}>
                  <li
                    className="cursor-pointer p-5 text-lg  font-normal text-secondary transition-all ease-in hover:bg-[#E6F5F9]"
                    onClick={() => setCategory(service.title)}
                  >
                    {service.title}
                  </li>
                  <hr className="h-[.5px] w-full bg-[#AEAEAE]" />
                </div>
              ),
          )}

          <hr className="h-[1px] w-full bg-[#AEAEAE]" />
          <li
            className="cursor-pointer px-5 py-2 text-base font-normal text-lightblue"
            onClick={handleShowAll}
          >
            {showServices ? `Show less` : `Show all 16`}
          </li>
        </ul>
      </div>
      <div className="w-full pt-20">
        <div className="space-y-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-5">
          <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
            <span>Location</span>
            <img src={linkarrow} alt="next" className="cursor-pointer" />
          </div>
          <div className=" flex w-[290px] flex-col gap-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="location"
                value="onsite"
                id="onsite"
                onChange={(e) => setLocation(e.target.value)}
                checked={location === "onsite"}
                className="onsite"
              />
              <label
                htmlFor="onsite"
                className="text-base font-normal text-lightgray"
              >
                On site
              </label>
            </div>

            <input
              type="text"
              placeholder="Type your location"
              className="border-[0.5px] border-[#AEAEAE] px-[15px] py-[10px] outline-0"
            />
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              value="remote"
              name="location"
              id="remote"
              checked={location === "remote"}
              onChange={(e) => setLocation(e.target.value)}
            />
            <label
              htmlFor="remote"
              className="text-base font-normal text-lightgray"
            >
              Remote
            </label>
          </div>
        </div>
      </div>
      <div className="w-full pt-10">
        <div className="space-y-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-5">
          <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
            <span>Price range</span>
            <img src={linkarrow} alt="next" className="cursor-pointer" />
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-xs font-normal text-lightgray">
              &lt; ₦{min}
            </span>
            <span className="text-xs font-normal text-lightgray">₦{max}</span>
          </div>
          <RangeSlider
            min={2000}
            max={50000}
            step={500}
            onInput={(e) => setRangeValues(e)}
          />
        </div>
      </div>{" "}
      <div className="w-full pt-10">
        <div className="gap-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-3">
          <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
            <span>Rating</span>
            <img src={linkarrow} alt="next" className="cursor-pointer" />
          </div>
          {stars.map((star) => {
            return (
              <div className="flex flex-col items-start" key={star.id}>
                <Ratings
                  star={star}
                  value={star.value}
                  isSelected={seledtedRatings === star.value}
                  onCheckboxChange={(e) => setSelectedRatings(+e.target.value)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

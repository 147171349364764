import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  appleicon,
  facebookicon,
  googleicon,
} from "../../../../../../../constants/images";
import { formdata } from "../../../../../../../features/formSlice";
import { Button, Input } from "../../../../../../../components/ui";
import { Link } from "react-router-dom";

const StepTwo = ({ next }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  // let regEmail =
  //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleConsumerRedirect = () => {
    dispatch(category("consumer"));
  }

  const validateEmail = (e) => {
    setEmail(e.target.value);
    if (!emailRegex.test(email)) {
      setErrorMessage("Please enter a valid email address");
      setShowError(true);
    } else if (emailRegex.test(email)) {
      setErrorMessage("");
      setShowError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      setErrorMessage("Please enter your email address");
      setShowError(true);
    }
    if (showError === false && emailRegex.test(email)) {
      dispatch(formdata({ Email: email }));
      next();
    }
  };

  return (
    <>
      <Link className="right-16 top-8 hidden text-primary transition-all ease-in hover:scale-95 md:absolute md:inline" onClick={handleConsumerRedirect}>
      Here to offer a service? 
        <span className="font-bold">Apply as a vendor</span>
      </Link>

      <div className="left-0 right-0 top-[15rem] h-[50vh] py-10 md:absolute md:px-20 md:py-0 lg:px-0">
        <div className=" w-full md:mx-auto lg:w-1/2">
          <div className="rounded-[5px] bg-primary p-7 md:px-20 ">
            <div className="flex flex-col justify-center md:items-center">
              <div className="text-center md:hidden pb-6">
                <Link to="/">
                  <img src={'/dropdown.png'} className="float-left rotate-90 w-10" alt="Go back" />
                  </Link>
                <h1 className="text-center text-[25px] font-semibold text-[#002D3A]">
                  Create an account
                </h1>
              </div>
              <h1 className="hidden text-center text-[25px] font-semibold text-[#002D3A]  md:flex md:text-[30px]">
              Sign up to hire vendors
              </h1>
              <span className="text-base font-normal text-[#181818] md:text-center md:text-lg">
              Sign up for free to connect with reliable vendors who can deliver quality service!
              </span>
            </div>
            <form className="text-center">
              <Input
                className="type={text} mt-10 min-w-[100%] rounded-[5px] p-2"
                onChange={validateEmail}
                placeholder={"Enter email address or phone number"}
              />
              {showError && (
                <span className="h-[5px] text-sm text-[#da3939]">
                  {errorMessage}
                </span>
              )}
              <Button
                children="Proceed"
                onClick={handleSubmit}
                border="none"
                width="100%"
                type="submit"
                className="mt-10"
              />
            </form>
            <div className="my-7 flex items-center gap-2">
              <hr className="w-1/2" />
              <p className="text-center text-[16px] font-[500] text-[#AEAEAE]">
                OR
              </p>
              <hr className="w-1/2" />
            </div>
            <div className=" flex flex-col gap-3">
              <Link
                to="#"
                className="flex items-center gap-y-3 border border-black py-2"
              >
                <div className="flex items-center justify-start px-2">
                  <img src={googleicon} alt="" className="" />
                </div>

                <div className="flex w-full items-center justify-center font-semibold text-secondary">
                  Sign up with Google
                </div>
              </Link>
              <Link
                to="#"
                className="flex items-center gap-y-3 border border-black py-2"
              >
                <div className="flex items-center justify-start px-2">
                  <img src={facebookicon} alt="" className="" />
                </div>

                <div className="flex w-full items-center justify-center font-semibold text-secondary">
                  Sign up with Facebook
                </div>
              </Link>

              <Link
                to="#"
                className="flex items-center gap-y-3 border border-[#000] py-2"
              >
                <div className="flex items-center justify-start px-2">
                  <img src={appleicon} alt="" className="" />
                </div>

                <div className="flex w-full items-center justify-center font-semibold text-secondary">
                  Sign up with Apple
                </div>
              </Link>
            </div>
            <p className="mt-5 text-center">
              Already have an account?{" "}
              <Link to="/login" className="font-bold text-lightblue">
                Log In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepTwo;
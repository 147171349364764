import React, { useState } from "react";
import { Button } from "../../../../components/ui";
import { provide, receive } from "../../../../constants/images";
import { useDispatch } from "react-redux";
import { category } from "../../../../features/categorySlice";
import { Link } from "react-router-dom";

const CategorySelect = ({}) => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState();
  const [error, setError] = useState("");

  const handleSubmit = () => {
    // dispatch category value to store
    if (selectedCategory !== "consumer" || selectedCategory !== "vendor") {
      dispatch(category("category"));
      setError("Please select a category");
    }
    if (selectedCategory === "consumer" || selectedCategory === "vendor") {
      dispatch(category(selectedCategory));
    }
  };
  return (
    <div className="absolute left-0 right-0 h-[50vh] rounded-[10px] bg-primary py-10 md:top-[15rem] md:mx-10 md:my-20 lg:top-[12rem] lg:mx-auto lg:w-[830px]">
      <div className="flex w-full flex-col px-8">
        <span className="text-[25px] font-semibold text-secondary md:text-center lg:text-[30px]">
          What Category do you belong to?
        </span>
        <div className="flex justify-evenly gap-4 pb-2 pt-10 md:pt-20">
          <div
            className={`flex items-center border border-[#0096C1] transition-all ease-in hover:scale-95 hover:cursor-pointer hover:bg-[#E6F5F9] md:border-[#AEAEAE] md:hover:border-[#0096C1] ${selectedCategory === "consumer" && "border-[#0096C1] bg-[#E6F5F9]"}`}
            onClick={() => setSelectedCategory("consumer")}
          > 
            <img src={receive} alt="consumer" className="p-3 md:px-4 md:py-5" />
            <div className="h-full w-[1px] bg-[#AEAEAE]" />
            <span className="p-3 text-base font-normal md:px-8 md:py-5 lg:text-lg">
              I want to <span className="font-bold">receive</span> <br />a
              service
            </span>
          </div>

          <div
            className={`flex items-center border border-[#0096C1] transition-all ease-in hover:scale-95 hover:cursor-pointer hover:bg-[#E6F5F9] md:border-[#AEAEAE] md:hover:border-[#0096C1] ${selectedCategory === "vendor" && "border-[#0096C1] bg-[#E6F5F9]"}`}
            onClick={() => setSelectedCategory("vendor")}
          >
            <img src={provide} alt="consumer" className="px-4 py-5" />
            <div className="h-full w-[1px] bg-[#AEAEAE]" />
            <span className="p-3 text-base font-normal md:px-8 md:py-5 lg:text-lg">
              I want to <span className="font-bold">provide</span> <br />a
              service
            </span>
          </div>
        </div>
        <span className="my-2 h-[20px] text-center">{error}</span>
        <div className="flex w-full flex-col items-center justify-center gap-16 py-16 md:gap-4 md:py-12">
          <Button
            onClick={handleSubmit}
            border="none"
            children="Proceed"
            className="md:w-1/2 w-full"
          />
          <span className="text-sm font-normal">
            Already have an account?{" "}
            <Link to="/login" className="font-bold text-lightblue">
              Log In
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CategorySelect;

import React, { useState } from "react";
import { Pagination } from "../../../../components";
import { BannedUserDisputes, allBannedAccounts } from "../../../../constants";
import { Dropdown } from "../../../../constants/images";
import DisputeDetails from "./_components/dispute-details";

const entries = [
  { id: 0, entry: 10 },
  { id: 0, entry: 20 },
  { id: 0, entry: 30 },
];
const BannedAccounts = () => {
  const [disputeDetails, setDisputeDetails] = useState(false);
  const [userId, setUserId] = useState("");
  const [disputeId, setDisputeId] = useState("");
  const [accountId, setAccountId] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  //   const bannedDataFiltered = allBannedAccounts.filter((value) => {
  //     const userName = value.name?.toLowerCase();
  //     const userId = value.userId?.toLowerCase();
  //     const accountStatus = value.status?.toLowerCase();
  //     const banDate = value.ban_date?.toLowerCase();
  //     const unbanDate = value.unban_date?.toLowerCase();
  //     return (
  //       userName?.includes(searchTerm) ||
  //       userId?.includes(searchTerm) ||
  //       accountStatus?.includes(searchTerm) ||
  //       banDate?.includes(searchTerm) ||
  //       unbanDate?.includes(searchTerm)
  //     );
  //   });

  const [openSelect, setOpenSelect] = useState(false);

  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  // Pagination logic
  const itemsPerPage = selectedEntry;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = allBannedAccounts.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(allBannedAccounts.length / itemsPerPage);

  const handleBannedId = (id, userId) => {
    setAccountId(id);
    setUserId(userId);
    setShowDetails(true);
  };
  const handleDisputeDetails = (id) => {
    setDisputeDetails(true);
    setDisputeId(id);
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div>
      {" "}
      {!disputeDetails && (
        <div className="mb-10 w-full rounded-[10px] rounded-bl-none rounded-br-none bg-primary shadow-lg">
          <div className="w-full px-12 py-4">
            <div className="text-[25px] font-medium text-secondary">Banned accounts</div>
          </div>
        </div>
      )}
      {!showDetails && (
        <div className="grid w-full grid-cols-5 justify-items-start rounded-[5px] bg-[#E8E8E8] px-6 py-4 pl-12 text-sm font-medium text-[#181818]">
          <span className="text-base font-medium text-secondary">Name</span>
          <span className="text-base font-medium text-secondary">User ID</span>
          <span className="text-base font-medium text-secondary">Account status</span>
          <span className="text-base font-medium text-secondary">Ban date</span>
          <span className="text-base font-medium text-secondary">Unban date</span>
        </div>
      )}
      {!showDetails && (
        <div className="rounded-[5px] bg-primary pb-8 drop-shadow-lg">
          {!showDetails &&
            currentData.map((value) => (
              <div
                key={value.id}
                className="transition-colors ease-in-out hover:cursor-pointer hover:bg-[#eceaea]"
                onClick={() => handleBannedId(value.id, value.userId)}
              >
                <div className="grid w-full grid-cols-5 justify-items-start  px-6 py-4 pl-12">
                  <span className="">{value.name}</span>
                  <span className="">{value.userId}</span>
                  <span
                    className={`${value.status === "Unbanned" ? "text-[#1de73f]" : "text-[#ec2727]"}`}
                  >
                    {value.status}
                  </span>
                  <span className="">{value.ban_date}</span>
                  <span className="">{value.unban_date}</span>
                </div>
                <hr color="#AEAEAE" className="w-full" />
              </div>
            ))}
          {allBannedAccounts.length === 0 && (
            <div className="flex w-full items-center justify-center pt-4 text-xl text-secondary">
              No search results available for query
            </div>
          )}
          {!showDetails && (
            <div className="flex w-full justify-between px-6 pt-3">
              <div className="flex w-full">
                <div className="relative flex items-center gap-x-1">
                  <span>Showing</span>
                  <div
                    onClick={handleSelect}
                    className="flex w-fit items-center gap-x-4 px-5 text-base font-normal text-[#181818] hover:cursor-pointer"
                  >
                    <span>{selectedEntry}</span>
                    <img
                      src={Dropdown}
                      alt="open"
                      className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
                    />
                  </div>
                  {openSelect && (
                    <div className="absolute bottom-[100%] left-[40%] w-[30%] animate-slide_up  rounded-[10px] bg-primary px-3 py-2">
                      <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                        {entries.map((entry) => (
                          <span
                            key={entry.id}
                            className="hover:cursor-pointer"
                            onClick={() => setSelectedEntry(entry.entry)}
                          >
                            {entry.entry}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  <span>entries</span>
                </div>
              </div>
              <div>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={paginate}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {showDetails &&
        !disputeDetails &&
        currentData
          .filter((value) => value.id === accountId)
          .map((value) => (
            <div key={value.id} className="flex w-full flex-col gap-y-6">
              <div className="flex w-5/6 gap-x-14">
                <div className="w-1/2 rounded-[5px] bg-primary">
                  <div className="flex w-full justify-between px-3 py-2">
                    <span className="text-base text-lightgray">Name</span>
                    <span className="text-lg font-medium text-secondary">{value.name}</span>
                  </div>
                  <hr color="" className="w-full" />
                  <div className="flex w-full justify-between px-3 py-2">
                    <span className="text-base text-lightgray">User ID</span>
                    <span className="text-lg font-medium text-secondary">{value.userId}</span>
                  </div>
                  <hr color="" className="w-full" />
                  <div className="flex w-full justify-between px-3 py-2">
                    <span className="text-base text-lightgray">Status</span>
                    <span className="text-lg font-medium text-secondary">{value.status}</span>
                  </div>
                </div>
                <div className="w-1/2 h-fit rounded-[5px] bg-primary">
                  <div className="flex w-full justify-between px-3 py-2">
                    <span className="text-base text-lightgray">Ban Date</span>
                    <span className="text-lg font-medium text-secondary">{value.ban_date}</span>
                  </div>
                  <hr color="" className=" w-full" />
                  <div className="flex w-full justify-between px-3 py-2">
                    <span className="text-base text-lightgray">Unban Date</span>
                    <span className="text-lg font-medium text-secondary">{value.unban_date}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col pt-8">
                <div className="mb- w-full rounded-[10px] rounded-bl-none rounded-br-none bg-[#E8E8E8] shadow-lg">
                  <div className="w-full px-12 py-4">
                    <div className="text-[25px] font-medium text-secondary">Disputes raised</div>
                  </div>
                </div>
                <div className="grid w-full grid-cols-4 justify-items-start bg-primary px-6  py-4 pl-12 text-sm font-medium text-[#181818]">
                  <span className="text-lg font-medium text-secondary">Project Title</span>
                  <span className="text-lg font-medium text-secondary">Project ID</span>
                  <span className="text-lg font-medium text-secondary">Date of Complain</span>
                  <span className="text-lg font-medium text-secondary">Resolution type</span>
                </div>
                {BannedUserDisputes.filter((value) => value.userId === userId).map((value) =>
                  value.disputes.map((dispute) => (
                    <div
                      key={dispute.id}
                      className="bg-primary drop-shadow-lg transition-colors ease-in-out hover:cursor-pointer  hover:bg-[#eceaea]"
                      onClick={() => handleDisputeDetails(value.id)}
                    >
                      <div className="grid w-full grid-cols-4 justify-items-start px-6 py-4 pl-12">
                        <span className="">{dispute.project_title}</span>
                        <span className="">{dispute.projectId}</span>
                        <span className="">{dispute.date}</span>
                        <span
                          className={`${dispute.type === "Positive" ? "text-[#1de73f]" : "text-[#ec2727]"}`}
                        >
                          {dispute.type}
                        </span>
                      </div>
                      <hr color="#AEAEAE" className="w-full" />
                    </div>
                  )),
                )}
              </div>
            </div>
          ))}
      {disputeDetails && <DisputeDetails disputeId={disputeId} />}
    </div>
  );
};

export default BannedAccounts;

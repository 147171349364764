import SearchInput from "./component";
import Container from "./container";
import DefaultdashBorad from "./deafaultDashboard";
import SearchCompnent from "./search";
import { useSelector } from "react-redux";
import NameHolder from "./nameholder";
import InputSearch from "./inputSearch";

const DashBoard = () => {
  const searchParams = useSelector((state) => state.search.homeSearchInput);

  return (
    <Container>
      <div className="px-10 py-5">
        <NameHolder />
        <div className="md:hidden">
        <InputSearch/>
        </div>
        
      </div>
      {searchParams === "" ? (
        <DefaultdashBorad />
      ) : (
        <SearchCompnent searchParams={searchParams} />
      )}
    </Container>
  );
};

export default DashBoard;

import { useDispatch } from "react-redux";
import {
  ChatLocation,
  ChatRequest,
  left,
  verificationLogo,
} from "../../../../../../../constants/images";
import { Button } from "../../../../../../../components/ui";
import { setPopRequest } from "../../../../../../../features/overlay";
import { Link } from "react-router-dom";
import { useState } from "react";

const ChatServiceRequest = () => {
  const [accepted, setAccepted] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setPopRequest(false))
  };
  const handleAcceptRequest = () => {
    setAccepted(true);
  };
  return (
    <div className="px-8 pt-10 relative">
      {accepted && <div className="bg-black absolute z-10 top-[-20rem] left-0 right-0 bg-opacity-25 bottom-0" />}
      <div
        className=" absolute left-[10%] top-[9%] text-lg font-medium text-[#181818]"
        onClick={handleClose}
      >
        <img src={left} className="rotate-180" alt="back" />
      </div>
      <div className="flex justify-center gap-2 py-2">
        <span className="text-[20px] font-semibold text-secondary">
          Service Request
        </span>
      </div>
      <div className="flex flex-col gap-4 pt-4">
        <div className="flex flex-col gap-1">
          <span className="text-sm font-normal text-lightgray">
            Project Title
          </span>
          <span className="text-sm font-medium text-[#181818]">
            Plumbing service for my bathroom
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-normal text-lightgray">Location</span>
          <span className="text-sm font-medium text-[#181818]">Physical</span>
          <div className="flex items-center gap-2">
            <img src={ChatLocation} alt="location" />
            <span className="text-sm font-medium text-[#181818]">
              5, Adeniyi close, Yaba, Lagos.
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <span className="text-sm font-normal text-lightgray">Date</span>
            <div className="flex gap-1">
              <span className="text-sm font-normal text-lightgray">From:</span>
              <span className="text-sm font-medium text-[#181818]">
                20 Dec 2023
              </span>
            </div>
            <div className="flex gap-1">
              <span className="text-sm font-normal text-lightgray">To:</span>
              <span className="text-sm font-medium text-[#181818]">
                23 Dec 2023
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <div className="flex flex-col gap-1">
              <span className="text-sm font-normal text-lightgray">Budget</span>
              <span className="text-sm font-medium text-[#181818]">
                ₦25,000
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <span className="text-sm font-normal text-lightgray">Document</span>
          <img
            src={ChatRequest}
            alt="document"
            className="h-[110px] w-[164px]"
          />
        </div>

        <div className="flex flex-col gap-1">
          <span className="text-sm font-normal text-lightgray">Note</span>
          <span className="text-sm font-medium text-[#181818]">
            I want it to be a good work, Horem ipsum dolor sit amet, consectetur
            adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis
            tellus. Sed dignissim
          </span>
        </div>

        <div className="flex items-center justify-center gap-3 pt-4">
          <Button
            backgroundColor="transparent"
            textColor="#0096C1"
            width={"200"}
            onClick={handleClose}
          >
            Decline
          </Button>
          <Button width={"200"} onClick={handleAcceptRequest}>
            Accept request
          </Button>
        </div>
      </div>

      {accepted && (
        <div className="fixed bottom-[0%] left-0 right-0 z-[999] block w-full animate-slide_up rounded-tl-[30px] rounded-tr-[30px] bg-primary px-4 py-8 md:hidden">
          <div
            className=" absolute right-[23px] top-[31px] text-lg font-medium text-[#181818]"
            onClick={handleClose}
          >
            X
          </div>
          <div className=" w-full">
            <div className="flex justify-center gap-2 py-2">
              <span className="text-[20px] font-semibold text-secondary">
                Service Request
              </span>
            </div>
            <div className="flex flex-col items-center justify-center rounded-[5px] bg-primary px-10 py-10">
              <img src={verificationLogo} alt="verfication" />
              <span className="py-10 text-center text-lg font-normal text-[#181818]">
                Service request successfully accepted. We will notify you when
                the project has been funded so you can start work
              </span>
              <Link to="/vendor-dashboard/projects">
                <Button
                  children="View project"
                  border="none"
                  width="330px"
                  type="submit"
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatServiceRequest;

import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { starRate, blueChat } from "../../../../../constants/images";
import { projectsData } from "../../data";

import SummaryReport from "./summaryReport";
import PaymentDetails from "./paymentDetails";
import CheckWork from "./checkWork";
const DesktopProjectCardContent = () => {
  const { id } = useParams();
  const res = projectsData[id];
  const [activeIndex, setActiveIndex] = useState(0);
  console.log("res", res);
  return (
    <div className="hidden px-16 py-28 md:block">
      <div className="flex justify-between">
        <div className="flex space-x-2">
          <div className="">
            <img src={res.image} alt="" />
          </div>
          <div className="">
            <span className="text-[20px] font-semibold text-[#002D3A]">
              {res.name}
            </span>
            <div className="flex items-center space-x-1">
              <div>
                {" "}
                <img src={starRate} alt="" />
              </div>
              <span className="text-[16px] font-semibold">{res.ratings}</span>
              <span className="text-[#5F5F5F]">(31)</span>
            </div>
          </div>
        </div>
        <Link to="/chat">
          <div className="w-full cursor-pointer rounded-[5px] border-[2px] border-[#0096C1] p-2">
            <img src={blueChat} alt="" />
          </div>
        </Link>
      </div>
      <p className="mt-5 text-[18px] text-[#5F5F5F]">Project number: #783451</p>
      <h4 className="text-[25px] font-medium text-[#002D3A]">{res.title}</h4>
      <div className="mt-7 border-2 border-[#AEAEAE] px-10 py-4">
        <div className="flex justify-around border-b-[1px] text-[#5F5F5F]">
          <div
            className={`${activeIndex === 0 && "cursor-pointer border-b-[4px] border-[#002D3A] pb-4 text-[18px] font-medium text-[#002D3A]"} cursor-pointer text-[18px] font-medium`}
            onClick={() => setActiveIndex(0)}
          >
            Service report summary
          </div>
          {res?.status === 'Cancelled' ? null : (
            <>

              <div
                className={`${activeIndex === 1 && "cursor-pointer border-b-[4px] border-[#002D3A] pb-4 text-[18px] font-medium text-[#002D3A]"} cursor-pointer text-[18px] font-medium`}
                onClick={() => setActiveIndex(1)}
              >
                Payment details
              </div>
              <div
                className={`${activeIndex === 2 && "cursor-pointer border-b-[4px] border-[#002D3A] pb-4 text-[18px] font-medium text-[#002D3A]"} cursor-pointer text-[18px] font-medium`}
                onClick={() => setActiveIndex(2)}
              >
                Check work
              </div>
              <div
                className={`${activeIndex === 3 && "cursor-pointer border-b-[4px] border-[#002D3A] pb-4 text-[18px] font-medium text-[#002D3A]"} cursor-pointer text-[18px] font-medium`}
                onClick={() => setActiveIndex(3)}
              >
                Job feedback
              </div>
            </>
          )}
        </div>
        {activeIndex === 0 && <SummaryReport res={res} />}
        {activeIndex === 1 && <PaymentDetails />}
        {activeIndex === 2 && <CheckWork />}
      </div>
    </div>
  );
};

export default DesktopProjectCardContent;

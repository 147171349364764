import React, { useState } from 'react'
import MobileSearch from './_components/search'
import ServiceLists from './_components/service-lists'

const ServiceMobile = () => {
  const [bg, setBg] = useState(false)

  const handleOverlayClick = (data) => {
    setBg(!data)
  }
  return (
    <div className='md:hidden flex flex-col'>
      <MobileSearch handleOverlay={handleOverlayClick} />
      <ServiceLists />
     {bg && <div className='bg-black z-50 h-[200vh] opacity-50 top-0 left-0 right-0 w-full bottom-0 absolute' />}
    </div>
  )
}

export default ServiceMobile
import React from 'react'
import { resoSuccefully } from '../../../../../../../constants/images'

const GiveFullAward = () => {
  return (
    <div>
    <div className="flex gap-2 items-center mt-2">
      <div className=""><img src={resoSuccefully} alt="" /></div>
      <span className="text-[#21A601] text-[16px]">Resolution sent successfully</span>
    </div>
    <span className="text-[18px] text-[#002D3A] mt-3 font-normal">Fully awarded to consumer</span>

    <h3 className="text-[#5F5F5F] font-normal mt-4">Reason</h3>
    <p className="text-[20px] leading-[30px]">Yorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.</p>
  </div>
  )
}

export default GiveFullAward

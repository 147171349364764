import React from "react";
import Navbar from "../ui/navbar";
import Sidebar from "../ui/sidebar";
import FundModal from "../../pages/dashboard/_components/fund-modal";
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  const openFundModal = useSelector(
    (state) => state.modalOverlay.states.modalOverlay,
  );

  return (
    <div className="relative">
      {openFundModal && (
        <>
          <div className="fixed z-[99] h-screen w-screen bg-black bg-opacity-35" />
          <div className="fixed bottom-[50%] left-[40%] right-[50%] top-[20%] z-[999]">
            <FundModal />
          </div>
        </>
      )}
      <div className="flex h-screen">
        <div className="fixed flex z-20 h-[80px] w-full bg-primary shadow-xl">
          <Navbar />
        </div>
        <div className="z-50 h-full w-[15%] overflow-hidden bg-secondary">
          <Sidebar />
        </div>
        <div className="w-3/4 flex-grow overflow-y-auto bg-[#F8F8F8] p-4 pl-14 pt-36">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;

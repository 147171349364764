import React, { useEffect, useState } from "react";
import { Delete, Edit } from "../../../../../../constants/images";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdvertId,
  setDeleteAdvert,
  setEditAdvert,
  setRefreshAd,
} from "../../../../../../redux/slice/modalOverlay";

const AdvertCard = () => {
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const refresh = useSelector((state) => state.modalOverlay.states.refreshAd);
  const dispatch = useDispatch();
  const [adverts, setAdverts] = useState([]);

  const handleEditAdvert = (value) => {
    dispatch(setEditAdvert(true));
    dispatch(setAdvertId(value));
  };

  const handleDeleteAdvert = (value) => {
    dispatch(setDeleteAdvert(true));
    dispatch(setAdvertId(value));
  };
  const getAdverts = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/adverts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setAdverts(data);
      } else {
        console.log(`Error fetching adverts: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (refresh) {
      getAdverts();
      dispatch(setRefreshAd(false));
    } else {
      getAdverts();
    }
  }, [refresh]);
  return (
    <div className="flex w-full flex-col">
      <div className="float-end ml-auto grid w-full grid-cols-5 gap-x-10 pb-14">
        {adverts.map((detail) => (
          <div
            className={`flex h-fit w-full flex-col gap-y-4 rounded-[10px] px-3 py-8 pb-5 shadow-lg transition-all ease-in hover:scale-105 hover:cursor-pointer`}
            key={detail.id}
          >
            <div className="flex w-full justify-between">
              <div className="w-full text-lg font-medium text-secondary">{detail.name}</div>
              <div className="flex w-full items-center justify-end gap-x-4">
                <img src={Edit} alt="edit" onClick={() => handleEditAdvert(detail?.id)} />
                <img src={Delete} alt="delete" onClick={() => handleDeleteAdvert(detail?.id)} />
              </div>
            </div>
            <img src={detail.advert_image_url} alt="advert image" className="h-[150px] w-5/6" />
          </div>
        ))}
      </div>
      {adverts.length === 0 && (
        <span className="mx-auto text-secondary md:text-lg">No posted adverts yet.</span>
      )}
    </div>
  );
};

export default AdvertCard;

import React, { useState } from 'react'
import Container from '../container'
import { vendors,vendorsMobile } from '../data'
import DesktopVendor from '../cards/DesktopVendor'
import MobileVendors from '../cards/MobileVendors'
const AllReconmmendedVendors = () => {
    const [isLike,setIsLike]=useState({})
    const handleLike=(id)=>{
        setIsLike(prev=>({
          ...prev,
          [id]:!prev[id]
        }))}
  return (
    <Container>
<div className="mx-auto min-h-screen w-full px-10 py-24">

      <div className="md:block hidden">
       <div className="grid grid-cols-4 gap-5">
       {vendors.map((vendor,index) => (
           <DesktopVendor
             key={vendor.id}
             to={`/cardContent/${index}`}
             id={vendor.id}
             image={vendor.image}
             vendor={vendor.vendor}
             name={vendor.name}
             title={vendor.title}
             service={vendor.service}
             location={vendor.location}
             rating={vendor.rating}
             rating_count={vendor.rating_count}
             price={vendor.price}
             bgcolor={vendor.bgcolor}
             handleLike={()=>handleLike(vendor.id)}
             isLike={isLike}
           />
       ))}
     </div>
     </div>
    <div className="md:hidden">
    <div className="flex flex-col gap-5">
          {vendorsMobile.map((mvendor,index) => (
            <MobileVendors key={mvendor.id} 
            to={`/cardContent/${index}`}
            id={mvendor.id}
            image={mvendor.image}
            vendor={mvendor.vendor}
            name={mvendor.name}
            title={mvendor.title}
            service={mvendor.service}
            location={mvendor.location}
            rating={mvendor.rating}
            rating_count={mvendor.rating_count}
            price={mvendor.price}
            bgcolor={mvendor.bgcolor}
            handleLike={()=>handleLike(vendor.id)}
            isLike={isLike} />
          ))}
        </div>
    </div>
    </div>
    </Container>
  )
}

export default AllReconmmendedVendors

import React, { useState } from "react";
import {
  appleicon,
  facebookicon,
  googleicon,
  whitelogo,
} from "../../constants/images";
import { Button, Input } from "../../components/ui";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  const [errorState, setErrorState] = useState({
    emailError: "",
    passwordError: "",
  });
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validate = () => {
    let isError = false;
    const errors = {
      emailError: "",
      passwordError: "",
    };

    if (!details.email) {
      isError = true;
      errors.emailError = "Please enter your email address";
    }
    if (details.email && !emailRegex.test(details.email)) {
      isError = true;
      errors.emailError = "Please enter a valid email address";
    }
    if (!details.password) {
      isError = true;
      errors.passwordError = "Please enter your password";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };
  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validate();

    if (!error) {
      navigate("/dashboard");
    }
  };

  return (
    <>
      <div className="min-h-screen bg-white md:bg-bgImage bg-fixed ">
        <Link to="/">
          <img
            src={whitelogo}
            alt=""
            className="mx-auto flex items-center justify-center py-6"
          />
        </Link>
        <div className="left-0 right-0 top-[5rem]   py-10 md:absolute md:px-20 md:py-0 lg:px-0">
          <div className=" w-full   md:mx-auto lg:w-1/2">
            <div className="rounded-[5px]  bg-primary p-7 md:px-20 ">
              <div className="flex flex-col justify-center md:items-center">
                <div className="pb-6 text-center md:hidden">
                  <Link to="/">
                    <img
                      src={"/dropdown.png"}
                      className="float-left w-10 rotate-90"
                      alt="Go back"
                    />
                  </Link>
                  <h1 className="text-center text-[25px] font-semibold text-[#002D3A]">
                    Welcome back!
                  </h1>
                </div>
                <h1 className="hidden text-center text-[25px] font-semibold text-[#002D3A]  md:flex md:text-[30px]">
                  Welcome back!
                </h1>
                <span className="text-base font-normal text-[#181818] md:text-center md:text-lg">
                  Log in to your GYWD account
                </span>
              </div>
              <form className="text-center">
                <Input
                  className="mt-10 min-w-[100%] rounded-[5px] p-2"
                  type="text"
                  name="email"
                  onChange={handleForm}
                  placeholder={"Enter email address or phone number"}
                />
                <span className="text-sm text-[#e62e2e]">
                  {errorState.emailError}
                </span>
                <Input
                  className="mt-10 min-w-[100%] rounded-[5px] p-2"
                  type="password"
                  name="password"
                  onChange={handleForm}
                  placeholder={"Password"}
                />
                <span className="text-sm text-[#e62e2e]">
                  {errorState.passwordError}
                </span>
                <Button
                  children="Log in"
                  onClick={handleSubmit}
                  border="none"
                  width="100%"
                  type="submit"
                  className="mt-10"
                />
              </form>
              <Link to="/forgot-password" className=" mt-5 flex justify-center">
                <span className="text-[16px]  text-[#AEAEAE]">
                  Forgot password? click here
                </span>
              </Link>
              <div className="my-7 flex items-center gap-2">
                <hr className="w-1/2" />
                <p className="text-center text-[16px] font-[500] text-[#AEAEAE]">
                  OR
                </p>
                <hr className="w-1/2" />
              </div>
              <div className=" flex flex-col gap-3">
                <Link
                  to="#"
                  className="flex items-center gap-y-3 border border-black py-2"
                >
                  <div className="flex items-center justify-start px-2">
                    <img src={googleicon} alt="" className="" />
                  </div>

                  <div className="flex w-full items-center justify-center font-semibold text-secondary">
                    Sign up with Google
                  </div>
                </Link>
                <Link
                  to="#"
                  className="flex items-center gap-y-3 border border-black py-2"
                >
                  <div className="flex items-center justify-start px-2">
                    <img src={facebookicon} alt="" className="" />
                  </div>

                  <div className="flex w-full items-center justify-center font-semibold text-secondary">
                    Sign up with Facebook
                  </div>
                </Link>

                <Link
                  to="#"
                  className="flex items-center gap-y-3 border border-[#000] py-2"
                >
                  <div className="flex items-center justify-start px-2">
                    <img src={appleicon} alt="" className="" />
                  </div>

                  <div className="flex w-full items-center justify-center font-semibold text-secondary">
                    Sign up with Apple
                  </div>
                </Link>
              </div>
              <p className="mt-5 text-center">
                Already have an account?{" "}
                <Link to="/signup" className="font-bold text-lightblue">
                  Sign Up
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import Checkbox from "./ChexBoxes";
import GivePatialToVendorAndConsumer from "./givePartialAward";
import { useSelector } from "react-redux";

const ResolutionDecision = ({ reviewData = {} }) => {
  const [active, setActive] = useState(0);
  const [resolutionDecision, setResolutionDecision] = useState({
    disputeId: reviewData?.dispute_id || "",
    decision: "",
    percentageToConsumer: "",
    percentageToVendor: "",
    reason: "", // optional field and it is not sent to the backend
  });

  useEffect(() => {
    setResolutionDecision((prev) => ({
      ...prev,
      disputeId: reviewData?.dispute_id || "",
    }));
  }, [reviewData]);

  const handleActive = (value) => {
    setActive(value);
  };

  useEffect(() => {
    switch (active) {
      case 1:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "consumer",
        }));
        break;
      case 2:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "vendor",
        }));
        break;
      case 3:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "partial",
        }));
        break;
      default:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "",
        }));
    }
  }, [active]);

  console.log("resolutionDecision",resolutionDecision)
  const handlePartialChange = (e) => {
    const { name, value } = e.target;
    setResolutionDecision((prev) => ({
      ...prev,
      [name]: value ? parseInt(value, 10) : 0,
    }));
  };

  const accessToken = useSelector((state) => state.token.accessToken);

  const fetchResolutionDecision = async (e) => {
    e.preventDefault();

    const { percentageToConsumer, percentageToVendor } = resolutionDecision;

    if (percentageToConsumer + percentageToVendor !== 100 && resolutionDecision.decision === "partial") {
      alert("The sum of percentages for Consumer and Vendor must equal 100%.");
      return;
    }

    // Create a copy of the state without the `reason` field
    const { reason, ...payload } = resolutionDecision;

    try {
      const response = await fetch("http://51.21.130.45:8080/admin/handle-disputes", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        let errorData;
        try {
          errorData = await response.clone().json();
          throw new Error(errorData.message || "Unknown error occurred");
        } catch (jsonError) {
          const text = await response.clone().text();
          console.error("Response text:", text);
          throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
        }
      }

      const responseData = await response.json();
      console.log("response post", responseData);
    } catch (error) {
      console.error("Error:", error.message);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="my-7 rounded-[17px] bg-white px-5 py-8 drop-shadow-md">
      <h3 className="text-[20px] font-medium text-[#002D3A]">Resolution Decision</h3>
      <form action="">
        <div className="mt-4 flex gap-10">
          <Checkbox handleActive={handleActive} active={active} />
        </div>
        <div className="">
          {active === 3 && (
            <GivePatialToVendorAndConsumer
              percentageToConsumer={resolutionDecision.percentageToConsumer}
              handlePartialChange={handlePartialChange}
              percentageToVendor={resolutionDecision.percentageToVendor}
            />
          )}
        </div>
        <h3 className="mt-7 text-[20px]">Reason for resolution (optional)</h3>
        <textarea
          name="reason"
          value={resolutionDecision.reason}
          id=""
          onChange={(e) => setResolutionDecision((prev) => ({ ...prev, reason: e.target.value }))}
          cols="70"
          rows="5"
          className="mt-1 rounded-[5px] border-[0.5px] border-[#181818] p-3 text-lightgray outline-none"
        ></textarea>
        <div className="mt-16">
          <button
            className={`w-[35%] rounded-[5px] ${reviewData?.status==="unresolved"?'gray':'bg-[#0096C1]'} px-4 py-2 text-[18px] text-white`}
            onClick={fetchResolutionDecision}
            disabled={reviewData?.status==="unresolved"?true:false}
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResolutionDecision;

import React from "react";

const ProgressBar = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: height,
    position: "relative",
    width: "80%",
    backgroundColor: "#AEAEAE",
    borderRadius: 40,
    marginTop: 40,
    marginBottom: 20,
    marginLeft: 0,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "start",
  };

  const progresstext = {
    padding: 10,
    paddingLeft: 0,
    paddingBottom: 5,
    color: "#181818",
    fontWeight: 400,
    position: "absolute",
    bottom: 10,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}>{`${progress}% complete`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;

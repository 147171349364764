import React, { useState } from "react";
import { Button, Input } from "../../../../components/ui";
import { Link } from "react-router-dom";
import { verificationLogo } from "../../../../constants/images";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";

const CreatePassword = () => {
  const [modal, setModal] = useState(false);
  const [showToggleEyes, setToggleEyes] = useState(false);
  const [showToggleEyesB, setToggleEyesB] = useState(false);

  const handModal = () => {
    setModal(!modal);
};

  const handToggleEye = () => {
    setToggleEyes(!showToggleEyes);
};
  const handToggleEyeB = () => {
    setToggleEyesB(!showToggleEyesB);
};
  return (
    <div className="h-screen bg-white lg:bg-bgImage">
      {modal && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-20 h-[100vh] bg-black bg-opacity-40" />
      )}
      {modal && (
        <div className="absolute left-[8%] right-0 top-[25%] z-50 flex h-[50vh] w-5/6 animate-zoom flex-col items-center justify-center gap-5 rounded-xl bg-primary p-8 md:left-[30.5%] md:w-[40%]">
          <img src={verificationLogo} alt="" className="w-[6rem]" />
          <span className="flex flex-col items-center justify-center gap-2 text-center text-[20px] font-[700] text-[#002D3A]">
            You have successfully changed your password!{" "}
            <Button
              border="none"
              //   onClick={handModal}
              className="w-fit"
              children="Okay"
            />
          </span>
        </div>
      )}
      <div className="left-0 right-0 top-[5rem] h-[50vh] py-10 md:absolute md:px-20 md:py-0 lg:px-0">
        <div className=" w-full rounded-[5px] bg-primary p-7 md:mx-auto md:px-20 lg:w-1/2">
          <div className="flex flex-col justify-center md:items-center">
            <h1 className="hidden text-center text-[25px] font-semibold text-[#002D3A]  md:flex md:text-[30px]">
              Create new password
            </h1>
            <span className="text-base font-normal text-[#181818] md:text-center md:text-lg">
              Your new password must be different from previously used password
            </span>
          </div>
          <div className="">
            <form className="text-center">
        <div className="flex items-center justify-center relative"> 
              <Input
                className="type={password} mt-10 min-w-[100%] rounded-[5px] p-2"
                // onChange={validateEmail}
                type={showToggleEyes ? "text" : "password"}
                placeholder={"Type new password"}
               
                 
              />
            <div className="flex cursor-pointer absolute bottom-3 right-5" onClick={handToggleEye}>
                  {showToggleEyes ? <FaRegEye /> : <FaRegEyeSlash />}
            </div>
        </div>
              {/* {showError && (
              <span className="h-[5px] text-sm text-[#da3939]">
                {errorMessage}
              </span>
            )} */}

              <div className="flex items-center justify-center relative">
                <Input
                  className="type={password} mt-10 min-w-[100%] rounded-[5px] p-2"
                  // onChange={}
                  type={showToggleEyesB ? "text" : "password"}
                  placeholder={"Confirm new password"}
                />
                <div className="flex cursor-pointer absolute bottom-3 right-5" onClick={handToggleEyeB}>
                  {showToggleEyesB ? <FaRegEye /> : <FaRegEyeSlash />}
                </div>
              </div>

              {/* {showError && (
              <span className="h-[5px] text-sm text-[#da3939]">
                {errorMessage}
              </span>
            )} */}
            <Link to="/dashboard">
              <Button
                onClick={handModal}
                children="Reset"
                border="none"
                width="100%"
                type="submit"
                className="mt-10"
              />
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;

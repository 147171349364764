import React from 'react'
import { Navbar, DiscountCard } from './_components'

const DiscountsPage = () => {
  return (
    <div>
        <Navbar />
        <DiscountCard />
    </div>
  )
}

export default DiscountsPage
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: 'category',
};
export const categorySlice = createSlice({
  name: "category",
  initialState: initialState,
  reducers: {
    category: (state, action) => {
      state.category = action.payload
    },
  },
});

export const { category } = categorySlice.actions;

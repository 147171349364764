import React from "react";

const UnreadChatsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1161_58195)">
        <path
          d="M13.416 2.50065C13.3614 2.76991 13.3327 3.04859 13.3327 3.33398C13.3327 3.61938 13.3614 3.89806 13.416 4.16732H3.7589L10.0509 9.71898L14.2575 5.95183C14.6129 6.39138 15.0559 6.75721 15.56 7.02275L10.0592 11.949L3.33268 6.01393V15.834H16.666V7.4173C16.9353 7.47196 17.2139 7.50065 17.4993 7.50065C17.7848 7.50065 18.0634 7.47196 18.3327 7.4173V16.6673C18.3327 17.1276 17.9596 17.5007 17.4993 17.5007H2.49935C2.03912 17.5007 1.66602 17.1276 1.66602 16.6673V3.33398C1.66602 2.87375 2.03912 2.50065 2.49935 2.50065H13.416ZM17.4993 0.833984C18.8801 0.833984 19.9993 1.95328 19.9993 3.33398C19.9993 4.71469 18.8801 5.83398 17.4993 5.83398C16.1186 5.83398 14.9993 4.71469 14.9993 3.33398C14.9993 1.95328 16.1186 0.833984 17.4993 0.833984Z"
          fill="#002D3A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1161_58195">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UnreadChatsIcon;

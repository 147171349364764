import React from 'react'

const Summary = ({amount}) => {
  return (
    <div className='bg-[#F4FDFF] py-5 px-7 w-full flex flex-col gap-3 '>
      <h3 className="text-[#002D3A] font-medium text-[18px] md:block hidden">Summary</h3>
      <div className="border-[1px] border-t-[#AEAEAE] w-full md:block hidden"></div>
      <div className="flex justify-between"><span className="text-[#5F5F5F] md:text-[16px] text-[12px] font-normal">Project number</span><span className="md:text-[20px] text-[16px] text-[#002D3A] font-medium">#783451</span></div>
      <div className="flex justify-between"><span className="text-[#5F5F5F] md:text-[16px] text-[12px] font-normal">Price </span><span className="md:text-[20px] text-[16px] text-[#002D3A] font-medium">₦{amount}</span></div>
      <div className="flex justify-between"><span className="text-[#5F5F5F] md:text-[16px] text-[12px] font-normal">VAT</span><span className="md:text-[20px] text-[16px] text-[#002D3A] font-medium">₦2,000</span></div>
      <div className="flex justify-between"><span className="text-[#5F5F5F] md:text-[16px] text-[12px] font-normal">Discount</span><span className="md:text-[20px] text-[16px] text-[#002D3A] font-medium">₦0</span></div>
      <div className="flex justify-between"><span className="text-[#5F5F5F] md:text-[16px] text-[12px] font-semibold">Total</span><span className="md:text-[20px] text-[16px] text-[#002D3A] font-medium">₦27,000</span></div>
    </div>
  )
}

export default Summary

import React, { useState } from "react";
import { left } from "../../../../../../../../../constants/images";
import { jobDetails } from "../../../../../../../data";
import { useDispatch, useSelector } from "react-redux";
import { setShowApplication } from "../../../../../../../../../features/overlay";

const ApplicationDetailsMobile = () => {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const JobId = useSelector((state) => state?.overlay.displayStates.jobId);
  const applicationDetails = useSelector(
    (state) => state?.formdata.applicationForm,
  );

  const handleDetails = () => {
    setShowDetails(!showDetails);
  };
  const handleApply = () => {
    dispatch(setShowApplication(false));
  };
  return (
    <div className="bg-primary pb-10">
      <div className="relative flex flex-col px-8 pb-3">
        <div className="relative flex items-center justify-center py-3">
          <div className="absolute bottom-[30%] left-[2%]  block md:hidden">
            <img
              src={left}
              alt="back"
              className="w-[9.78px] rotate-180"
              onClick={handleApply}
            />
          </div>
          <span className="text-[20px] font-semibold text-secondary">
            Application details
          </span>
        </div>
        <div className="w-[370px] border-[0.5px] border-lightgray px-4 py-6">
          <div className="flex w-full items-center justify-between">
            <span className="text-base font-medium text-secondary">
              Job details
            </span>
            <img
              src={left}
              alt="back"
              className="rotate- w-[9.78px]"
              onClick={handleDetails}
            />
          </div>

          {showDetails &&
            jobDetails
              .filter((job) => job.id === JobId)
              .map((value) => (
                <div className="flex animate-slide_up flex-col gap-4 pt-4">
                  <span className="text-[20px] font-semibold text-secondary">
                    {value.title}
                  </span>
                  <span className="text-sm font-normal text-lightgray">
                    Posted {value.post_duration} mins ago
                  </span>
                  <hr color="#AEAEAE" className="w-full" />
                  <div className="flex flex-col">
                    <span className="text-sm font-normal text-lightgray">
                      Description
                    </span>
                    <span>{value.description}</span>
                  </div>
                  <hr color="#AEAEAE" className="w-full" />
                  <div>
                    <span className="text-sm font-normal text-lightgray">
                      Job requirements
                    </span>
                    {value.requirements.map((requirement) => (
                      <div className="flex gap-1">
                        <span>{requirement.id + 1}.</span>
                        <span>{requirement.content}</span>
                      </div>
                    ))}
                  </div>
                  <hr color="#AEAEAE" className="w-full" />
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                      <span className="text-sm font-normal text-lightgray">
                        Date
                      </span>
                      <div className="flex gap-1">
                        {" "}
                        <span>From: </span>{" "}
                        <span className="text-base font-medium">
                          {value.start_date}
                        </span>
                      </div>
                      <div className="flex gap-1">
                        {" "}
                        <span>To: </span>{" "}
                        <span className="text-base font-medium">
                          {value.closing_date}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full items-center justify-between gap-x-4">
                    <div className="flex flex-col">
                      <span className="text-sm font-normal text-lightgray">
                        Location
                      </span>
                      <span className="text-base font-medium">
                        {value.location}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm font-normal text-lightgray">
                        Budget
                      </span>
                      <span className="text-base font-medium">
                        {value.budget}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
        </div>

        <div className="py-10">
          <span className="text-base font-medium text-secondary">
            Your application
          </span>
          {applicationDetails
            ?.map((value) => value.application_details)
            ?.map((details) => (
              <>
                <div className="flex w-full flex-col gap-2">
                  <span className="text-sm font-medium text-secondary">
                    Cover letter
                  </span>
                  <span className="text-sm font-normal text-[#181818]">
                    {details.letter}
                  </span>
                </div>
                <hr color="#AEAEAE" className="my-2 w-full" />

                <div className="flex w-full justify-between gap-3 py-3">
                  <div className="flex flex-col space-y-3">
                    <div className="flex flex-col">
                      <span className="text-xs font-normal text-lightgray">
                        Consumer’s budget
                      </span>
                      {jobDetails
                        ?.filter((job) => job.id === JobId)
                        ?.map((value) => {
                          return (
                            <span className="text-sm font-medium text-[#181818]">
                              ₦{value.budget}
                            </span>
                          );
                        })}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-xs font-normal text-lightgray">
                        Your bid
                      </span>
                      <span className="text-sm font-medium text-[#181818]">
                        ₦{details.bid}
                      </span>
                    </div>
                  </div>{" "}
                  <div className="flex flex-col">
                    {applicationDetails?.map((formdetail) => (
                      <div className="space-y-3">
                        <div className="flex flex-col">
                          <span className="text-xs font-normal text-lightgray">
                            4% Service charge
                          </span>
                          <span className="text-sm font-medium text-[#181818]">
                            ₦{formdetail.service_fee}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-xs font-normal text-lightgray">
                            You’ll receive
                          </span>
                          <span className="text-sm font-medium text-[#181818]">
                            ₦{formdetail.actual_amount}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <hr color="#AEAEAE" className="my-2 w-full" />
                <div className="flex flex-col items-start gap-2 py-2">
                  <span className="text-base text-secondary">Attachment</span>
                  {applicationDetails?.map((value) => {
                    return (
                      <img
                        src={value.attachment}
                        alt="attachment"
                        className="h-[109px] w-[203px]"
                      />
                    );
                  })}
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ApplicationDetailsMobile;

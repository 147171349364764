import { useState, useEffect } from "react";
import HowitWorksCardA from "./howItWorksCards/HowitWorksCardA";
import HowitWorksCard from "./howItWorksCards/HowitWorksCard";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

const HowItWorksSection = () => {
  const [cardSlider, setCardSlider] = useState(1);
  const next = (e) => {
    setCardSlider(1);
  };
  const previous = () => {
    setCardSlider(2);
  };
  return (
  
    <div className="w-full bg-lightblue pb-10 md:pb-20">
      <h1 className="flex items-center justify-center py-10 text-[25px] font-semibold text-primary md:text-[30px] lg:text-[40px]">
        How it works
      </h1>
      <div className="flex gap-2 items-center justify-center mb-[40px]">
        <div
          onClick={next}
          className={`w-[1.1rem] h-[1.1rem] flex justify-center items-center ${
            cardSlider === 1 ? "bg-white" : "bg-white"
          } rounded-full `}
        >
          <div
            className={` w-[0.9rem] h-[0.9rem] ${
              cardSlider === 1 ? "bg-white" : "bg-[#0096C1]"
            }  rounded-full border-2 border-[#0096C1] `}
          >
            {" "}
          </div>
        </div>
        <div
          onClick={previous}
          className={`w-[1.1rem] h-[1.1rem] flex justify-center items-center ${
            cardSlider === 2 ? "bg-white" : "bg-white"
          } rounded-full `}
        >
          <div
            className={` w-[0.9rem] h-[0.9rem] ${
              cardSlider === 2 ? "bg-white" : "bg-[#0096C1]"
            }  rounded-full border-2 border-[#0096C1] `}
          >
            {" "}
          </div>
        </div>
      </div>
      <div className="">
        {cardSlider === 1 && <HowitWorksCardA />}
        {cardSlider === 2 && <HowitWorksCard />}
       
      </div>

      <div className="flex items-center justify-center gap-x-4 mt-9">
        <div className=" cursor-pointer " onClick={next}>
          <BsArrowLeft className={` text-white ${cardSlider === 1 ? "text-[2.5rem]":"" }   `} />
        </div>
        <div className="cursor-pointer" onClick={previous}>
        <BsArrowRight className={` text-white ${cardSlider === 2 ? "text-[2.5rem]":"" }   `} />
        </div>
      </div>

    </div>
  );
};

export default HowItWorksSection;

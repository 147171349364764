import { Banner, Footer, Navbar } from "../../components/ui";
import {
  AboutUsSection,
  CategoriesSection,
  HeroSection,
  HowItWorksSection,
  PostJobSection,
  ServicesSection,
  TestimonialsSection,
} from "./_components";



const Home = () => {
  return (
    <>
    <Navbar />
      {/* import homepage components here */}
      <HeroSection />
      <AboutUsSection />
      <ServicesSection/>
      <CategoriesSection />
      <HowItWorksSection />
      <PostJobSection />
      <TestimonialsSection />
      <Banner />
      <Footer />
    </>
  );
};

export default Home;

import React from 'react'

const Catalog = ({plumImageA,plumImageB,plumImageC,plumImageD }) => {
  return (
    <div className='grid grid-cols-2 mt-8 gap-5'>
      <div className=""><img src={plumImageD} alt="plumbingImage" /></div>
      <div className="row-span-2"><img src={plumImageC} alt="" /></div>
      <div className="row-span-2"><img src={plumImageB} alt="" /></div>
      <div className=""><img src={plumImageA} alt="" /></div>
    </div>
  )
}

export default Catalog

import React from "react";
import { HeroSection } from "./_components";
import ServicesDesktop from "./desktop-view";
import ServicesMobile from "./mobile-view";
import { Banner, Navbar, Footer } from "../../components/ui";

const Services = () => {
  return (
    <>
      <Navbar />
      <HeroSection />
      <ServicesDesktop />
      <ServicesMobile />
      <Banner />
      <Footer />
    </>
  );
};

export default Services;


import React from "react";

const ProjectTimeLine = () => {
  return (
    <div className="w-full border-[1px] border-[#AEAEAE] px-11 py-10">
      <h1 className="text-[18px] font-semibold text-[#002D3A]">
        Project timeline
      </h1>
      <div className="mt-4">
        <div className="flex items-start space-x-2">
          <div className="flex flex-col items-center justify-center">
            <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
              <div className="h-3 w-3 rounded-full bg-[#0096C1]"></div>
            </div>
            <div className="h-14 border-r-2 border-[#AEAEAE] border-dotted"></div>
          </div>
          <span className="font-medium text-[#5F5F5F]">Vendor confirmation</span>
        </div>
        <div className="flex items-start space-x-2">
          <div className="flex flex-col items-center justify-center">
            <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
              <div className="h-3 w-3 rounded-full bg-white"></div>
            </div>
            <div className="h-14 border-r-2 border-[#AEAEAE] border-dotted"></div>
          </div>
          <span className="font-medium text-[#5F5F5F]">Funded (Escrow protection)</span>
        </div>
        <div className="flex items-start space-x-2">
          <div className="flex flex-col items-center justify-center">
            <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
              <div className="h-3 w-3 rounded-full bg-white"></div>
            </div>
            <div className="h-14 border-r-2 border-[#AEAEAE] border-dotted"></div>
          </div>
          <span className="font-medium text-[#5F5F5F]">Delivered</span>
        </div>
        <div className="flex items-start space-x-2">
          <div className="flex flex-col items-center justify-center">
            <div className="flex h-5 w-5 items-center justify-center rounded-full border-2 border-[#0096C1]">
              <div className="h-3 w-3 rounded-full bg-white"></div>
            </div>
          </div>
          <span className="font-medium text-[#5F5F5F]">Accepted</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectTimeLine;

import React from "react";
import { Button } from "../../../../components";
import { useDispatch } from "react-redux";
import { setShowBannedAccounts } from "../../../../redux/slice/modalOverlay";

const Navbar = () => {
  const dispatch = useDispatch();
  const handleBannedAccounts = () => {
    dispatch(setShowBannedAccounts(true));
  };
  return (
    <div className="mb-10 w-full rounded-[10px] rounded-bl-none rounded-br-none bg-primary shadow-lg">
      <div className="flex w-full items-center justify-between px-4 py-2">
        {" "}
        <div className="px-12 py-4 text-[25px] font-medium text-secondary">Disputes</div>
        <Button
          backgroundColor="transparent"
          className="h-[50px]"
          padding="2px 20px"
          textColor="#0096C1"
          onClick={handleBannedAccounts}
        >
          View banned accounts
        </Button>
      </div>
    </div>
  );
};

export default Navbar;

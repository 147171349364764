import {
  specific,
  left,
  explo,
  transport,
  it,
  beauty,
  repair,
  events,
  waves,
  cake,
  family,
  plumImageA,
  plumImageB,
  plumImageC,
  plumImageD,
} from "../../../constants/images";
import Reviews from "../cardContent/desktopView/reviews";

export const services = [
  {
    heading: "Explore services",
    icon: left,
    image: explo,
    text: "Easily navigate through various service categories to find what you need",
  },
  {
    heading: "Do you need a specific service?",
    icon: left,
    image: specific,
    text: "Create a job post and get applications from vendors to satisfy your needs",
  },
];

export const popularServices = [
  {
    image: repair,
    heading: "Repair and maintenance",
  },
  {
    image: it,
    heading: "IT and digital services",
  },
  {
    image: beauty,
    heading: "Beauty services",
  },
  {
    image: events,
    heading: "Events and entertainment",
  },
  {
    image: transport,
    heading: "Transport and logistics",
  },
];

export const vendors = [
  {
    id: 0,
    image: waves,
    vendor: "/services/vendor1.png",
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
    range:10000,
    bgcolor: "#FFE3D3",
    completedJobs:34,
    ongoingingJobs:5,
    cancelledJobs:0,
    companyName:"Johnny Plumbing Service",
    monTOfri:"10:00am - 8:00pm",
    satTOsun:"12:00pm - 9:00pm",
    address:"No 6, Adeniyi close, Yaba, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },

  {
    id: 1,
    image: cake,
    vendor: "/services/vendor2.png",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:32,
    ongoingingJobs:3,
    cancelledJobs:0,
    companyName:"Mary Baking Service",
    monTOfri:"10:00am - 8:00pm",
    satTOsun:"12:00pm - 9:00pm",
    address:"No 6, orita Ape, Oyo",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 2,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 3,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:34,
    ongoingingJobs:5,
    cancelledJobs:0,
    companyName:"Johnny Plumbing Service",
    monTOfri:"10:00am - 8:00pm",
    satTOsun:"12:00pm - 9:00pm",
    address:"No 6, Adeniyi close, Yaba, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 4,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:34,
    ongoingingJobs:5,
    cancelledJobs:0,
    companyName:"Johnny Plumbing Service",
    monTOfri:"10:00am - 8:00pm",
    satTOsun:"12:00pm - 9:00pm",
    address:"No 6, Adeniyi close, Yaba, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 5,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 6,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 7,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 8,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 9,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 10,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 11,
    image: family,
    vendor: "/services/vendor3.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
];
export const vendorsMobile = [
  {
    id: 0,
    image: "/services/mobile-services.svg",
    vendor: "/services/vendor1.png",
    name: "John Doe",
    title: "Repair and Maintenance",
    titlebg: "#FFF9D3",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
    range:10000,
    bgcolor: "#FFE3D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },

  {
    id: 1,
    image: "/services/mobile-services1.svg",
    vendor: "/services/vendor2.png",
    name: "Mary Ojo",
    title: "Events and entertainment",
    titlebg: "#FFF9D3",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 2,
    image: "/services/mobile-services2.svg",
    vendor: "/services/vendor2.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 3,
    image: "/services/mobile-services1.svg",
    vendor: "/services/vendor2.png",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 4,
    image: "/services/mobile-services.svg",
    vendor: "/services/vendor2.png",
    name: "John Doe",
    title: "Repair and Maintenance",
    titlebg: "#EFFFD3",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
    range:15000,
    bgcolor: "#FFE3D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 5,
    image: "/services/mobile-services2.svg",
    vendor: "/services/vendor2.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 6,
    image: "/services/mobile-services.svg",
    vendor: "/services/vendor2.png",
    name: "John Doe",
    title: "Repair and Maintenance",
    service: "Plumbing",
    location: "Yaba, Lagos",
    rating: "4.8",
    rating_count: "(31)",
    price: "10,000",
    range:10000,
    bgcolor: "#FFE3D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 7,
    image: "/services/mobile-services1.svg",
    vendor: "/services/vendor2.png",
    name: "Mary Ojo",
    title: "Events and entertainment",
    service: "Baker",
    location: "Ibadan, Oyo",
    rating: "4.7",
    rating_count: "(25)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
  {
    id: 8,
    image: "/services/mobile-services2.svg",
    vendor: "/services/vendor2.png",
    name: "Emeka Eze",
    title: "Events and entertainment",
    titlebg: "#FFE3D3",
    service: "Photographer",
    location: "Ikorodu, Lagos",
    rating: "4.8",
    rating_count: "(15)",
    price: "15,000",
    range:15000,
    bgcolor: "#FFF9D3",
    completedJobs:37,
    ongoingingJobs:9,
    cancelledJobs:0,
    companyName:"Emek's Event Service",
    monTOfri:"9:00am - 8:00pm",
    satTOsun:"11:00pm - 9:00pm",
    address:"No 6, Balogun close, Ikorodu, Lagos",
    yearsOfexperience:"5 years",
    descrition:"Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    plumImageA:plumImageA,
    plumImageB:plumImageB,
    plumImageC:plumImageC,
    plumImageD:plumImageD,
    reviews:"I recently hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.",
    reviewsDate:"Nov 21, 2023 - Nov 25, 2023",
  },
];

export const catergoryCheckbox = [
  {
    id : 1,
    title :"Repair and Maintenance",
    catId : "repair",
    valcard: "repair",
  },
  {
    id : 2,
    title :"IT and digital services",
    catId : "it",
    valcard: "it",
  },
  {
    id : 3,
    title :"Events and entertainment",
    catId : "event",
    valcard: "event",
  },
  {
    id : 4,
    title :"Beauty services",
    catId : "beauty",
    valcard: "beauty",
  },
  {
    id : 5,
    title :"Transport and logistics",
    catId : "trans",
    valcard: "trans",
  },
  {
    id : 1,
    title :"Fashion services",
    catId : "fashion",
    valcard: "fashion",
  },
]

import React from "react";
import {
  spiral,
  vendorservice,
  vendorservice1,
  vendorservice2,
  vendorservice3,
} from "../../../../constants/images";
const WorksSection = () => {
  return (
    <div className="w-full bg-lightblue pb-10 md:pb-20">
      <div className="flex items-center justify-center py-10 text-[25px] font-semibold text-primary md:text-[30px] lg:text-[40px]">
        How it works
      </div>
      <div className="mx-7 rounded-[20px] bg-primary py-10 lg:mx-48">
        <div className="flex flex-col px-4 md:px-10 lg:px-20">
          <div className="flex items-start lg:gap-14">
            <div>
              <span className="text-lg font-semibold text-secondary md:text-[20px] lg:text-[25px]">
                If you want to offer your service
              </span>
              <div className="mt-2 h-[4px] w-1/2 rounded-r-[10px] bg-lightblue" />
            </div>
            <img src={spiral} alt="spiral" className="-mr-120 mt-4" />
          </div>
          <div className="flex flex-col">
            {/* each item */}
            <div className="flex flex-col items-center justify-between gap-16 py-16 md:flex-row lg:px-20">
              <img src={vendorservice} alt="sign up" />
              <div className="flex flex-col items-start gap-2 md:flex-row md:gap-4 lg:w-[800px]">
                <span className="font-semibold text-lightblue lg:text-[40px]">
                  01
                </span>
                <div className="flex flex-col items-start gap-3">
                  <span className="font-semibold text-secondary md:text-[20px]">
                    Sign up or log in
                  </span>
                  <span className="font-normal text-lightgray md:text-lg">
                    Create your GYWDE account by providing basic information and
                    fill in essential details about your services, background,
                    and expertise or log in if you already have an account.
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-between gap-16 py-3 md:flex-row lg:px-20">
              <div className="order-last flex flex-col items-start gap-2 md:order-first md:flex-row md:gap-4 lg:w-[800px]">
                <span className="font-semibold text-lightblue lg:text-[40px]">
                  02
                </span>
                <div className="flex flex-col items-start gap-3">
                  <span className="font-semibold text-secondary md:text-[20px]">
                    Search for Jobs
                  </span>
                  <span className="font-normal text-lightgray md:text-lg">
                    Explore a variety of job opportunities posted by people
                    seeking your services. Use our search and filtering tools to
                    find jobs that match your skills and preferences.
                  </span>
                </div>
              </div>
              <img
                src={vendorservice1}
                alt="sign up"
                className="order-first md:order-last"
              />
            </div>

            <div className="flex flex-col items-center justify-between gap-16 py-16 md:flex-row lg:px-20">
              <img src={vendorservice2} alt="sign up" />
              <div className="flex flex-col items-start  gap-2 md:flex-row md:gap-4 lg:w-[800px]">
                <span className="font-semibold text-lightblue lg:text-[40px]">
                  03
                </span>
                <div className="flex flex-col items-start gap-3">
                  <span className="font-semibold text-secondary md:text-[20px]">
                    Apply to Jobs
                  </span>
                  <span className="font-normal text-lightgray md:text-lg">
                    Once you find a job that aligns with your expertise, apply
                    to the job in an easy and straightforward process.
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-between gap-16 py-3 md:flex-row lg:px-20">
              <div className="order-last flex flex-col  items-start gap-2 md:order-first md:flex-row md:gap-4 lg:w-[800px]">
                <span className="font-semibold text-lightblue lg:text-[40px]">
                  04
                </span>
                <div className="flex flex-col items-start gap-3">
                  <span className="font-semibold text-secondary md:text-[20px]">
                    Start Earning
                  </span>
                  <span className="font-normal text-lightgray md:text-lg">
                    Start earning. Track your earnings and build a positive
                    reputation on the platform through timely and quality work.
                  </span>
                </div>
              </div>
              <img
                src={vendorservice3}
                alt="sign up"
                className="order-first md:order-last"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorksSection;

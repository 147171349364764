import ProjectContents from "./_components/projects-content";

const VendorDashboardProjectsMobile = () => {
  return (
    <div className="block md:hidden">
      <ProjectContents />
    </div>
  );
};

export default VendorDashboardProjectsMobile;

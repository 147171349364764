import React, { useEffect, useState } from 'react'
import InnerCard from './cards'
import DisputNavBar from '../navBar'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


const DisputeReview = () => {
  const accessToken = useSelector((state) => state.token.accessToken);
  const [reviewData,shoReviewData] = useState(null)
  const {id} = useParams()
  const fetchReview = async () => {
    try {
      const response = await fetch(`http://51.21.130.45:8080/admin/disputes-details/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      console.log("review datas", data);
      shoReviewData(data);
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  useEffect(()=>{
    fetchReview()
  },[])

  return (
    <>
    <div className="sticky top-0 z-40">
        <DisputNavBar />
      </div>
    <div className='mx-10'>
    <div className='bg-white drop-shadow-md py-3 px-10 flex justify-between items-center'>
      <div className="text-[#002D3A] font-medium text-[25px]">
      Disputes
      </div>
      <div className="">
        <button className='bg-white text-[#0096C1]  rounded-[5px] px-3 py-1'>Make a decision</button>
      </div>
    </div>
    <div className="mt-10">
        <InnerCard reviewData={reviewData}/>
    </div>
    </div>
    </>
  )
}

export default DisputeReview

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { jobDetails } from "../../../../../../../data";
import { Button } from "../../../../../../../../../components/ui";

const ApplicationDetailsDesktop = () => {
  const [display, setDisplay] = useState("details");
  const [allDetails, setAllDetails] = useState([]);
  const [budget, setBudget] = useState();
  const [formDetails, setFormDetails] = useState([]);
  const JobId = useSelector((state) => state?.overlay.displayStates.jobId);
  const applicationDetails = useSelector(
    (state) => state?.formdata.applicationForm,
  );

  return (
    <div className="bg-primary px-10 py-8 transition-all ease-in">
      <div className="py-3 text-[25px] font-medium text-secondary">
        Application details
      </div>
      <hr color="#AEAEAE" className="w-full" />
      <div className="flex w-full items-start justify-start gap-x-2 py-3">
        <Button
          width={450}
          textColor={`${display === "details" ? "#ffffff" : "#5F5F5F"}`}
          backgroundColor={`${display === "details" ? "#0096C1" : "#ffffff"}`}
          border="none"
          onClick={() => setDisplay("details")}
        >
          Job details
        </Button>
        <Button
          width={450}
          textColor={`${display === "application" ? "#ffffff" : "#5F5F5F"}`}
          backgroundColor={`${display === "application" ? "#0096C1" : "#ffffff"}`}
          border="none"
          onClick={() => setDisplay("application")}
        >
          Your application
        </Button>
      </div>
      <hr color="#AEAEAE" className="w-full" />
      {display === "details" &&
        jobDetails
          .filter((job) => job.id === JobId)
          .map((value) => (
            <div className="flex flex-col gap-4 pt-4">
              <span className="text-[20px] font-semibold text-secondary">
                {value.title}
              </span>
              <span className="text-sm font-normal text-lightgray">
                Posted {value.post_duration} mins ago
              </span>

              <div className="flex flex-col">
                <span className="text-sm font-normal text-lightgray">
                  Description
                </span>
                <span>{value.description}</span>
              </div>

              <div>
                <span className="text-sm font-normal text-lightgray">
                  Job requirements
                </span>
                {value.requirements.map((requirement) => (
                  <div className="flex gap-1">
                    <span>{requirement.id + 1}.</span>
                    <span>{requirement.content}</span>
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <span className="text-sm font-normal text-lightgray">
                    Date
                  </span>
                  <div className="flex gap-1">
                    {" "}
                    <span>From: </span>{" "}
                    <span className="text-base font-medium">
                      {value.start_date}
                    </span>
                  </div>
                  <div className="flex gap-1">
                    {" "}
                    <span>To: </span>{" "}
                    <span className="text-base font-medium">
                      {value.closing_date}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm font-normal text-lightgray">
                    Location
                  </span>
                  <span className="text-base font-medium">
                    {value.location}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm font-normal text-lightgray">
                    Budget
                  </span>
                  <span className="text-base font-medium">{value.budget}</span>
                </div>
              </div>
            </div>
          ))}
      <div className="mx-auo flex w-full justify-between gap-4 px-10 pt-4">
        {display === "application" &&
          applicationDetails
            ?.map((value) => value.application_details)
            ?.map((details) => (
              <>
                <div className="flex w-1/2 flex-col gap-2">
                  <span className="text-base font-normal text-secondary">
                    Cover letter
                  </span>
                  <span className="text-base font-normal text-[#181818]">
                    {details.letter}
                  </span>
                  <div className="flex flex-col items-start gap-2 py-2">
                    <span className="text-base text-secondary">Attachment</span>
                    {applicationDetails?.map((value) => {
                      return (
                        <img
                          src={value.attachment}
                          alt="attachment"
                          className="h-[109px] w-[203px]"
                        />
                      );
                    })}
                  </div>
                  <div className="flex items-center gap-2 py-2">
                    <span className="text-sm text-[#181818]">
                      Application status:{" "}
                    </span>
                    <span className="text-base text-lightblue">Pending</span>
                  </div>
                </div>
                <div className="flex h-[258px] w-[227px] flex-col justify-center gap-3 border border-lightgray px-8 py-1">
                  <div className="flex flex-col">
                    <span className="text-sm font-normal text-lightgray">
                      Consumer’s budget
                    </span>
                    {jobDetails
                      ?.filter((job) => job.id === JobId)
                      ?.map((value) => {
                        return (
                          <span className="text-base font-medium text-[#181818]">
                            ₦{value.budget}
                          </span>
                        );
                      })}
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-normal text-lightgray">
                      Your bid
                    </span>
                    <span className="text-base font-medium text-[#181818]">
                      ₦{details.bid}
                    </span>
                  </div>{" "}
                  <div className="flex flex-col">
                    {applicationDetails?.map((formdetail) => (
                      <div className="space-y-3">
                        <div className="flex flex-col">
                          <span className="text-sm font-normal text-lightgray">
                            4% Service charge
                          </span>
                          <span className="text-base font-medium text-[#181818]">
                            ₦{formdetail.service_fee}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm font-normal text-lightgray">
                            You’ll receive
                          </span>
                          <span className="text-base font-medium text-[#181818]">
                            ₦{formdetail.actual_amount}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}{" "}
      </div>
    </div>
  );
};

export default ApplicationDetailsDesktop;

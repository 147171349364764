import React from "react";
import {
  profileicon,
  wallet,
  offers,
  settings,
  help,
  legal,
  switchh,
  Logout,
  roundedimage,
} from "../../../../../constants/images";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <div className="fixed right-20 top-20 z-50">
      <div className="h-full w-full   border-2 border-[#B5B5B5] bg-white px-8 py-2 text-[#052538]">
        <div className="">
          <div className="border-b-[1px] border-[#B5B5B5] py-4 ">
            <div className="flex gap-3">
              <img src={roundedimage} alt="" className="w-[4rem]" />
              <div className="flex flex-col">
                <p className="text-[18px] font-semibold text-[#052538]">
                  Tim Mark
                </p>
                <p className="text-[#6D6D6D]">Consumer</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-7 border-b-[1px] border-[#B5B5B5] py-4 text-[16px]">
            <Link to="#">
              <div className="flex items-center gap-x-5">
                <img src={profileicon} alt="" />
                <p className="">Profile</p>
              </div>
            </Link>
            <Link to="#">
              <div className="flex items-center gap-x-5">
                <img src={wallet} alt="" />
                <p className="">Payments</p>
              </div>
            </Link>
            <Link to="#">
              <div className="flex items-center gap-x-5">
                <img src={offers} alt="" />
                <p className="">Offers</p>
              </div>
            </Link>
          </div>
          <div className="flex flex-col gap-y-7 border-b-[1px] border-[#B5B5B5] py-4 text-[16px]">
            <Link to="#">
              <div className="flex items-center gap-x-3">
                <img src={settings} alt="" />
                <p className="">Settings</p>
              </div>
            </Link>
            <Link to="#">
              <div className="flex items-center gap-x-3">
                <img src={help} alt="" />
                <p className="">Help & support</p>
              </div>
            </Link>
            <Link to="#">
              <div className="flex items-center gap-x-3">
                <img src={legal} alt="" />
                <p className="">Legal</p>
              </div>
            </Link>
            <Link to="#">
              <div className="flex items-center gap-x-3">
                <img src={switchh} alt="" />
                <p className="">Switch to vendor</p>
              </div>
            </Link>
          </div>
          <div className="mt-3 flex cursor-pointer flex-col gap-y-7 py-4 text-[16px]">
            <div className="flex items-center gap-x-3">
              <img src={Logout} alt="" />
              <p className="">Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;

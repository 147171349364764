import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const VendorIcon = () => {
  const [svgColor, setSvgColor] = useState();
  const activeProject = useSelector((state) => state.modalOverlay.states.activeProject);
  useEffect(() => {
    setSvgColor(activeProject);
  }, [activeProject]);
  return (
    <svg width="70" height="35" viewBox="0 0 70 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1908_22779)">
        <path
          d="M5 19.6529C6.60991 20.3209 10.9071 20.2723 15.2167 14.7345C20.6037 7.8122 25.6192 9.63385 31.5635 17.4669C37.5077 25.3 41.5944 29.1255 47.7245 18.3778C53.8545 7.63006 54.5975 3.98676 65 1.80078"
          stroke={`${svgColor === "Vendors" ? "#FFFFFF" : "#279F51"}`}
          stroke-width="3"
        />
      </g>
      <circle
        cx="56.1992"
        cy="5"
        r="4"
        fill={`${svgColor === "Vendors" ? "#FFFFFF" : "#279F51"}`}
      />
      <defs>
        <filter
          id="filter0_d_1908_22779"
          x="0.425781"
          y="0.332031"
          width="68.8828"
          height="34.168"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.129412 0 0 0 0 0.588235 0 0 0 0 0.32549 0 0 0 0.16 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1908_22779" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1908_22779"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default VendorIcon;

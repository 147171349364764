import React from "react";
import { AgentIdCard } from "../../../../../../../../constants/images";
import { Button } from "../../../../../../../../components";

const VerificationModal = ({ onClick, handleConfirmation, value }) => {
  const labelStyle = `text-sm font-normal mr-2 text-lightgray`;
  const idContent = `text-base font-medium text-secondary`;
  return (
    <div className="relative bg-primary w-[50%] py-5">
      <div className="absolute right-10 text-xl font-medium hover:cursor-pointer" onClick={onClick}>x</div>
      <div className="pt-10 flex flex-col gap-y-3 items-center justify-center">
        <span className="text-lg font-semibold text-secondary">Verify Work ID</span>
        <img src={AgentIdCard} alt="id_card" />
        <div className="flex gap-x-2">
        <div>
          <span className={labelStyle}>Name:</span>
          <span className={idContent}>{value.name}</span>
        </div>
        <div>
          <span className={labelStyle}>Category:</span>
          <span className={idContent}>{value.category}</span>
        </div>
        <div>
          <span className={labelStyle}>Specialty:</span>
          <span className={idContent}>{value.specialty}</span>
        </div>
        </div>
        <div className="text-lg font-normal text-[#181818]">
          Does the information match the work ID?
        </div>
        <div className="flex gap-x-4">
          <Button backgroundColor="transparent" textColor="#0096C1" onClick={onClick}>
            No
          </Button>
          <Button onClick={handleConfirmation}>Yes</Button>
        </div>
      </div>
    </div>
  );
};

export default VerificationModal;

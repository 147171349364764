import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../../components/ui";
import { vendorSearch } from "../../../../features/searchSlice";
import { setOverlay } from "../../../../features/overlay";
import { filterIcon, searchicon } from "../../../../constants/images";
import MobileSearchContent from "../category_search-mobile";

const InputSearch = () => {
  const dispatch = useDispatch();
  const overlayValue = useSelector(
    (state) => state?.overlay.displayStates.overlay,
  );

  const handleInputChange = (e) => {
    const searchParam = e.target.value;
    dispatch(vendorSearch(searchParam));
  };
  const handleFilter = () => {
    dispatch(setOverlay(true));
  };

  return (
    <div className="flex w-full items-center justify-between md:block ">
      <form action="">
        <div className="relative flex">
          <Input
            type="search"
            onChange={handleInputChange}
            className="min-w-[100%] rounded-[5px] border-[#AEAEAE] px-10 py-2 "
            placeholder="Search for jobs...."
          />{" "}
          <img
            src={searchicon}
            alt=""
            className="bottom-2 z-10 hidden px-2 md:block lg:absolute"
          />
          <img
            src={searchicon}
            alt=""
            className="absolute bottom-2 z-10 px-2"
          />
        </div>
      </form>
      <div
        className="ml-6 block rounded-[5px] bg-[#0096C1] p-2 md:hidden"
        onClick={handleFilter}
      >
        <img src={filterIcon} alt="category" />
      </div>
      {overlayValue && <MobileSearchContent />}
    </div>
  );
};

export default InputSearch;

import React from 'react'
import { EmptyProject } from '../../../../constants/images'
const NoProject = () => {
  return (
    <div className='flex flex-col justify-center items-center gap-7 mt-11'>
      <img src={EmptyProject} alt="" />
      <span className="text-[#002D3A] font-semibold text-[20px] text-center">No projects yet</span>
      <p className="md:text-[18px] text-[14px] md:leading-[21.11px] leading-[16.42px] text-center md:w-[55%] w-full">Explore services and discover a range of services tailored to your needs. Connect with a vendor and start a project easily</p>
      <p className="text-[#0096C1] font-bold text-[16px] cursor-pointer">Browse services</p>
    </div>
  )
}

export default NoProject

import React from "react";
import { StepEight, StepFive, StepFour, StepSeven, StepSix, StepThree, StepTwo } from "../";

const ConsumerForm = ({ currentStep, handleNextStep }) => {
  const steps = [
    <StepTwo next={handleNextStep} />,
    <StepThree next={handleNextStep} />,
    <StepFour next={handleNextStep} />,
    <StepFive next={handleNextStep} />,
    <StepSix next={handleNextStep} />,
    <StepSeven next={handleNextStep} />,
    <StepEight next={handleNextStep} />,
    // create next step and add here, pass next props and dispatch step data
  ];
  return <div>{steps[currentStep]}</div>;
};

export default ConsumerForm;

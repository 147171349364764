import React, { useState } from "react";
import { Button, SuccessModal } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { setCommission } from "../../../../../redux/slice/modalOverlay";
import { Spinner } from "../../../../../constants/images";

const ComissionModal = ({ handleClose }) => {
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [success, setSuccess] = useState("");
  const [successModal, setSuccessModal] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const postCommission = async () => {
    const commission_rate = value;
    if (value === "") {
      setError("Please enter Commission");
      setTimeout(() => setError(""), 3000);
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/update-commission`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ commission_rate }),
      });
      // console.log(response)
      if (response.ok) {
        setError("");
        dispatch(setCommission(value));
        setLoading(false);
        setSuccess("Commission updated successfully");
        setSuccessModal(true);
        setTimeout(() => setSuccess(""), 3000);
      } else {
        setLoading(false);
        setError("Error in updating Commission");
      }

      // const data = await response.json();
      // console.log(data);
    } catch (error) {
      setLoading(false);
      setError("Error posting Commission");
      console.log(error);
    }
  };
  const handleCommission = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      {!successModal && (
        <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
          <div
            className="absolute right-10 top-2 text-2xl hover:cursor-pointer"
            onClick={handleClose}
          >
            X
          </div>
          <div className="flex flex-col gap-y-3 px-8">
            <span className={`py-2 text-start text-xl font-semibold text-secondary`}>
              Edit Commission
            </span>
            <input
              type="text"
              className="w-full border border-[#AEAEAE] px-3 py-2 outline-none"
              onChange={handleCommission}
            />
            {error !== "" && <span className="text-sm font-medium text-[#e03241]">{error}</span>}
            <div className="mt-10 w-full">
              <Button
                className="flex w-full items-center justify-center"
                disabled={value === ""}
                onClick={postCommission}
              >
                {loading ? <img src={Spinner} alt="loading" className="w-[8%]" /> : "Update"}
              </Button>
            </div>
          </div>
        </div>
      )}

      {successModal && (
        <SuccessModal
          title={"Commission Added Successfully"}
          buttonText={"OK"}
          onClick={handleClose}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default ComissionModal;

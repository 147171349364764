import React, { useState } from "react";
import { Button } from "../../components";
import { setAccessToken } from "../../redux/slice/login";
import { Spinner, logo } from "../../constants/images";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    user_type: "super_admin",
    identifier: "",
    password: "",
  });
  const [errorState, setErrorState] = useState({
    usernameError: "",
    passwordError: "",
  });
  const [loginError, setLoginError] = useState();
  const validate = () => {
    let isError = false;
    const errors = {
      usernameError: "",
      passwordError: "",
    };

    if (!details.identifier) {
      isError = true;
      errors.usernameError = "Please enter your username";
    }
    if (!details.password) {
      isError = true;
      errors.passwordError = "Please enter your password";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = validate();
    if (!error) {
      setLoading(true);
      try {
        const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${}`, // This might be needed later for other requests
          },
          body: JSON.stringify(details),
        });

        if (response.ok) {
          const data = await response.json();
          const accessToken = data.accessToken; // Ensure this path matches the structure of the response
          dispatch(setAccessToken(accessToken)); // Correct logging of accessToken
          navigate("dashboard");
          setLoading(false);
        } else if (response.status === 400) {
          setLoginError(
            "Invalid identifier format: Please enter a valid username",
          );
          setLoading(false);
        } else if (response.status === 401) {
          setLoginError("Unauthorized: No matching user found");
          setLoading(false);
        } else if (response.status === 500) {
          setLoginError("Database error, please try again");
          setLoading(false);
        } else {
          console.error("Login failed", response.status);
          setLoading(false);
        }
      } catch (error) {
        console.error("Login failed", error);
      }
    }
  };

  return (
    <div className="relative">
      <div className="absolute left-[7%] top-[5%]">
        <img src={logo} alt="gywde logo" />
      </div>
      <div className="flex h-screen items-center justify-center  bg-secondary">
        <div className="mx-8 flex h-fit w-full flex-col rounded-[5px] bg-primary px-4 py-4 md:mx-auto md:w-1/2 lg:px-6 lg:py-8">
          <span className="text-center font-semibold text-secondary lg:text-[30px]">
            Log in to your dashboard
          </span>
          <form className="space-y-5 pt-3">
            <div className="mx-auto flex w-5/6 flex-col gap-y-1">
              <label
                htmlFor="username"
                className="text-sm font-normal text-[#181818] lg:text-base"
              >
                Username
              </label>
              <input
                type="text"
                name="identifier"
                id="identifier"
                onChange={handleForm}
                className="rounded-[5px] border border-[#181818] px-3 py-4"
              />
              <span className="text-xs text-[#e62e2e]">
                {errorState.usernameError}
              </span>
            </div>
            <div className="mx-auto flex w-5/6 flex-col gap-y-1">
              <label
                htmlFor="password"
                className="text-sm font-normal text-[#181818] lg:text-base"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                onChange={handleForm}
                className="rounded-[5px] border border-[#181818] px-3 py-4"
              />
              <span className="text-xs text-[#e62e2e]">
                {errorState.passwordError}
              </span>
            </div>
            <div className="mx-auto flex w-5/6 flex-col items-center justify-center pt-10">
            <Button
              onClick={handleSubmit}
              className={"flex w-full items-center justify-center"}
              disabled={loading}
            >
              {loading ? (
                <img src={Spinner} alt="loading" className="w-[5%]" />
              ) : (
                "Login"
              )}
            </Button>
              {loginError !== "" && (
                <span className="pt-4 text-sm text-[#e62e2e]">
                  {loginError}
                </span>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;

import {
  it,
  manufacturer,
  pet,
  photo,
  property,
  repair,
  transport,
  arts,
  automative,
  beauty,
  cleaning,
  consulting,
  education,
  events,
  fashions,
  food,
  specific,
} from "../../../../../../../constants/images";

export const datas = 
[
{
    image:repair,
    text:"Repair and maintenance",
   

},
{
    image:it,
    text:"IT and digital services",
    
},
{
    image:events,
    text:"Events and entertainment",
    
},
{
    image:beauty,
    text:"Beauty Services",
    
},
{
    image:transport,
    text:"Transport and logistics",
    
},
{
    image:fashions,
    text:"Fashion Services",
    
},
{
    image:automative,
    text:"Automotive Services",
    
},
{
    image:consulting,
    text:"Consulting Services",
    
},
{
    image:education,
    text:"Education Services",
   
},
{
    image:cleaning,
    text:"Cleaning Services",
    
},
{
    image:photo,
    text:"Photography and video",
    
},
{
    image:pet,
    text:"Pet Services",
    
},
{
    image:manufacturer,
    text:"Manufacturing Services",
    
},
{
    image:property,
    text:"Property Services",
    
},
{
    image:food,
    text:"food Services",
    
},
{
    image:arts,
    text:"Arts and Sprots",
  
    
},
{
    image:specific,
    text:"Post a job",
    className:"bg-[#E6F5F9] border-none"
},
]

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allProjects } from "../../../../data";
import DesktopProjectDetails from "../../desktop/_components/project-details";
import MobileProjectDetails from "../../mobile/_components/project-details";
import {
  setProjectId,
  setShowProjectDetails,
} from "../../../../../../features/overlay";
import { calender, cancel, due, projectlocation } from "../../../../../../constants/images";
import ProjectAlert from "./_components/alert";

const ActiveProjects = () => {
  const [active, setActive] = useState("all");
  const [filterValue, setFilterValue] = useState("");
  const dispatch = useDispatch();
  const showDetails = useSelector(
    (state) => state?.overlay.displayStates.showProjectDetails,
  );
  const projectFilter = useSelector(
    (state) => state?.overlay.displayStates.projectFilter,
  );
  const projectSearch = useSelector(
    (state) => state?.overlay.displayStates.projectSearch,
  );

  useEffect(() => {
    setFilterValue(projectFilter);
  }, [projectFilter]);

  useEffect(() => {
    setFilterValue("All");
  }, []);

  const handleProjectDetails = (id) => {
    dispatch(setShowProjectDetails(true));
    dispatch(setProjectId(id));
  };
  const filteredProjects =
    filterValue === "All"
      ? allProjects
      : allProjects.filter((project) => project.project_status === filterValue);

  const searchedProjects = filteredProjects.filter((project) => {
    const searchValue = projectSearch.toLowerCase();
    const titleMatch = project.title.toLowerCase().includes(searchValue);
    const statusMatch = project.project_status
      .toLowerCase()
      .includes(searchValue);
    return titleMatch || statusMatch;
  });
  return (
    <>
      {!showDetails ? (
        <>
          {searchedProjects.length > 0 ? (
            searchedProjects.map((project) => (
              <div
                className="my-6 relative rounded-[5px] border border-[#AEAEAE] px-4 py-4 lg:mx-20"
                onClick={() => handleProjectDetails(project.id)}
                key={project.id}
              >
               {project?.due && <ProjectAlert bg="#FFF8D1" color='#A7910D' text='This project is due in 24 hours. Submit now!' icon={due} />}
               {project?.passed && <ProjectAlert bg="#FFE7E7" color='#FF0000' text='This project submission deadline has passed' icon={cancel} />}
                <div className="flex flex-col justify-center lg:px-7">
                  <span className="text-lg font-medium text-secondary">
                    {project?.title}
                  </span>
                  <div className="flex flex-col justify-between lg:flex-row">
                    <div className="flex flex-col gap-2 md:w-[300px]">
                      <div className="flex items-center gap-x-2">
                        <img src={projectlocation} alt="location" />
                        <span className="text-xs font-medium text-lightgray md:text-sm">
                          {project?.location}
                        </span>
                      </div>
                      <div className="flex justify-between text-xs font-medium text-lightgray md:block md:text-sm">
                        Hired by {project?.hire}
                        <span className="block text-xs font-medium text-secondary md:text-sm lg:hidden">
                          {project?.budget} Budget
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 md:w-[100px]">
                      <span className="text-xs text-secondary md:text-sm">
                        {project?.status}
                      </span>
                      <div>
                        <div className="flex justify-between text-xs text-secondary md:block md:text-sm">
                          <div className="flex items-center gap-x-2 text-nowrap">
                            <img src={calender} alt="" />{" "}
                            <span>{project?.end_date}</span>
                          </div>
                          <span
                            className={`block text-xs md:text-sm lg:hidden ${
                              project.project_status === "Ongoing"
                                ? "text-[#0096C1]"
                                : project.project_status === "Completed"
                                  ? "text-[#36C100]"
                                  : project.project_status === "Cancelled"
                                    ? "text-[#C12B00]"
                                    : ""
                            }`}
                          >
                            {project?.project_status}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="hidden flex-col gap-2 md:w-[200px] lg:flex">
                      <span className="text-xs font-medium text-secondary md:text-sm">
                        {project?.budget} Budget
                      </span>
                      <span
                        className={`text-xs md:text-sm ${
                          project.project_status === "Ongoing"
                            ? "text-[#0096C1]"
                            : project.project_status === "Completed"
                              ? "text-[#36C100]"
                              : project.project_status === "Cancelled"
                                ? "text-[#C12B00]"
                                : ""
                        }`}
                      >
                        {project?.project_status}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="my-6 rounded-[5px] border border-[#AEAEAE] px-4 py-4 lg:mx-20">
              <span className="text-lg font-medium text-secondary">
                No projects found.
              </span>
            </div>
          )}
        </>
      ) : (
        <>
          <DesktopProjectDetails />
          <MobileProjectDetails />
        </>
      )}
    </>
  );
};

export default ActiveProjects;

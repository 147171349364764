import { useEffect, useState } from "react";
import { Button } from "../../../../components/ui";

const HeroSection = () => {
  const [screenSize, setScreenSize] = useState();

  useEffect(() => {
    setScreenSize(screen.width);
  }, []);

  return (
    <main className="relative">
      <div>
        {" "}
        <div
          className="relative h-[550px] overflow-hidden md:h-[558px] lg:h-[858px] bg-cover bg-no-repeat px-6 py-8 transition-all duration-1000 ease-out md:px-10 md:py-20 md:pb-0 bg-center lg:py-20 xl:p-36 xl:pb-0"
          style={{
            backgroundImage: `${screenSize <= 500 ? "url('/vendor/mobilevendorbg.svg')" : "url('/vendor/vendorbg.svg')"}`,
          }}
        >
          <div className="mt-20 lg:h-[558px]  h-[450px] justify-center flex flex-col md:h-[360px]">
            <div className="flex flex-col text-white transition-all duration-1000 ease-out md:space-y-40 ">
              <div className="flex animate-slide_up gap-4 flex-col lg:w-[58%]">
                <span className="text-3xl font-semibold leading-tight md:text-[40px] lg:text-[50px] ">
                  Discover meaningful work on your terms
                </span>
                <span className="lg:text-[20px] font-semibold md:text-base text-sm">
                  Unlock opportunities, connect with clients, <br />grow your business
                </span>

                <Button
                  children="Be a Vendor"
                  border="none"
                  className="mt-7 w-fit text-sm font-medium md:text-base"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HeroSection;

import React from "react";
import { Button } from "../../../../../../components";
import { setDeleteDiscount, setDiscountDetails } from "../../../../../../redux/slice/modalOverlay";
import { useDispatch } from "react-redux";

const deleteDiscountModal = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setDeleteDiscount(false));
  };

  const handleDeleteAdvert = () => {
    dispatch(setDiscountDetails({}));
    handleClose();
  };

  return (
    <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
      <div className="absolute right-10 top-2 text-2xl hover:cursor-pointer" onClick={handleClose}>
        X
      </div>
      <div className="flex flex-col gap-y-3 px-8">
        <span className={`py-2 text-center text-lg font-medium text-secondary`}>
          Are you sure you want to delete this discount?
        </span>

        <div className="mt-10 flex w-full items-center gap-x-2">
          <Button
            className="w-full"
            backgroundColor="transparent"
            textColor="#0096C1"
            onClick={handleClose}
          >
            No
          </Button>
          <Button className="w-full" onClick={handleDeleteAdvert}>
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
};
export default deleteDiscountModal;

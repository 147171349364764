import React from "react";
import { AgentTable, Navbar } from "./_components";

const AgentsPage = () => {
  return (
    <div>
      <Navbar />
      <AgentTable />
    </div>
  );
};

export default AgentsPage;

import React, { useState } from "react";
import NoMessages from "../../../vendor-dashboard/_pages/chat/mobile/_components/no-messages";
import { detailedChatHistory } from "../../../vendor-dashboard/data";
// import MobileChatNotice from "../_components/newchat-notice/mobile-content";
import MobileChatNotice from "../../../vendor-dashboard/_pages/chat/_components/newchat-notice/mobile-content";
import ChatListSidebar from "../chatlist-sidebar";
import ChatServiceRequest from "../../../vendor-dashboard/_pages/chat/_components/chat-service_request/mobile";
import Chatbar from "../chatbar";
import ChatContent from "../chat-content";
import ChatInput from "../chat-inputs";
import SearchInput from "../chat-search";
import { useSelector } from "react-redux";

const MobileChat = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const showNotice = useSelector(
    (state) => state?.overlay.displayStates.showNotice,
  );
  const popRequest = useSelector(
    (state) => state?.overlay.displayStates.popRequest,
  );
  const handleChatClick = (chat) => {
    setSelectedChat(chat);
  };

  return (
    <div className="flex flex-col pt-20 md:hidden md:pt-0">
      {(selectedChat === null && !popRequest) && (
        <span className="px-8 py-4 text-[20px] font-bold text-secondary">
          Chat
        </span>
      )}
      {showNotice && (
        <div className="fixed left-0 right-0 top-0 z-[99] block h-[160vh] w-full bg-black bg-opacity-25" />
      )}
      {showNotice && (
        <div className="bottom absolute left-[20%] top-[25%] z-[99] h-fit w-[40%] bg-primary">
          <MobileChatNotice />
        </div>
      )}
      {!popRequest ? (
        <div className="flex animate-slide_up flex-col items-center justify-center md:h-[60vh] md:flex-row md:px-0 md:pt-0">
          {selectedChat === null && <SearchInput />}
          {selectedChat === null ? (
            detailedChatHistory.length === 0 ? (
              <NoMessages />
            ) : (
              <ChatListSidebar chats={selectedChat} onClick={handleChatClick} />
            )
          ) : (
            <div className="relative w-full ">
              <Chatbar chat={selectedChat} />
              {selectedChat && <ChatContent chat={selectedChat} />}
              <ChatInput />
            </div>
          )}
        </div>
      ) : (
        <ChatServiceRequest />
      )}
    </div>
  );
};

export default MobileChat;

import React from "react";
import { StepFour, StepThree, StepTwo } from "..";

const VendorForm = ({ currentStep, handleNextStep }) => {
  const steps = [
    <StepTwo next={handleNextStep} />,
    <StepThree next={handleNextStep} />,
    <StepFour next={handleNextStep} />,

  ];
  return <>{steps[currentStep]}</>;
};

export default VendorForm;

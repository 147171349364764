import React, { useState } from "react";
import { searchicon } from "../../../../../../constants/images";
import { useDispatch } from "react-redux";
import { setProjectFilter, setProjectSearch } from "../../../../../../features/overlay";

const SubNavbar = () => {
  const dispatch = useDispatch();
  const [navId, setNavId] = useState(0);
  const navFilters = [
    {
      id: 0,
      filter: "All",
    },
    {
      id: 1,
      filter: "Ongoing",
    },
    {
      id: 2,
      filter: "Completed",
    },
    {
      id: 3,
      filter: "Cancelled",
    },
  ];

  const handleFilter = (value, id) => {
    setNavId(id);
    dispatch(setProjectFilter(value));
  };
  const handleSearchChange = (event) => {
    dispatch(setProjectSearch(event.target.value)); // Update searchValue state with the input value
  };

  return (
    <div className="relative ml-auto h-auto w-full md:mr-4">
      <div className="flex w-full flex-col items-start justify-between pt-5 md:px-8 md:pt-[2rem]">
        <div className="flex w-full items-center md:py-3">
          <input
            type="text"
            onChange={handleSearchChange}
            className="relative w-full border border-lightgray px-10 py-2 outline-none md:w-[40%]"
            placeholder="Search projects..."
          />
          <img
            src={searchicon}
            className="absolute left-2 lg:left-10"
            alt="search"
          />
        </div>
      </div>

      <div className="no-scrollbar w-full overflow-auto overflow-x-scroll text-nowrap bg-white px-4 text-primary shadow-xl md:overflow-hidden md:pr-10">
        <div className="mx-auto flex justify-between py-[2rem] md:w-5/6">
          {navFilters.map((filter) => (
            <span
              key={filter.id}
              className={`cursor-pointer px-[15px] py-[5px] text-lightgray ${navId === filter.id && "cursor-default rounded-[5px] bg-lightblue text-primary"}`}
              onClick={() => {
                handleFilter(filter.filter, filter.id);
              }}
            >
              {filter.filter}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubNavbar;

import React, { useState } from "react";
import { ChatRequest } from "../../../../../../../../constants/images";

const VendorSide = () => {
  const [requestRsponse, setRequestResponse] = useState(!false);

  return (
    <div className="relative">
      <div className="px-5 py-7">
        <div className=" flex gap-20">
          <div className="text-lg font-normal text-[#5F5F5F]">
            <h3 className="">Vendor name</h3>
            <span className="text-xl font-medium text-secondary">John Doe</span>
          </div>
          <div className="text-lg font-normal text-[#5F5F5F]">
            <h3 className="">Email address</h3>
            <span className="text-xl font-medium text-secondary">johndoe@xyz.com</span>
          </div>
        </div>
        <h3 className="mt-5 text-[25px] text-[#5F5F5F]">Submitted Evidences</h3>
        {requestRsponse === false && (
          <h4 className="mt-3 text-lg text-[#5F5F5F]">No response yet</h4>
        )}

        {requestRsponse === true && (
          <>
            <h4 className="mt-3 text-lg text-[#5F5F5F]">Reason for dispute</h4>
            <p className="text-xl text-[#181818]">
              Yorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et
              velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora
              torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis
              condimentum lobortis.
            </p>
            <h4 className="mt-3 text-lg font-normal text-[#5F5F5F]">Document</h4>
            <div className="mt-2 flex gap-7">
              <div className="flex h-fit w-[185px] flex-col items-center justify-center rounded-[5px] bg-[#EAEAEA] p-2">
                <div className="h-[110.06px] w-[165px] overflow-hidden object-contain">
                  <img src={ChatRequest} alt="" className="rounded-[5px]" />
                </div>
                <span className="mt-1 text-[#181818]">xyzimage.jpg</span>
              </div>
              <div className="flex h-fit w-[185px] flex-col items-center justify-center rounded-[5px] bg-[#EAEAEA] p-1">
                <div className="h-[110.06px] w-[165px] overflow-hidden bg-white object-contain p-2">
                  <p className="text-[8px] leading-[9.38px]">
                    Jorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero
                    et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad
                    litora torquent per conubia nostra, per inceptos himenaeos. <br /> <br /> Jorem
                    ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et
                    velit interdum
                  </p>
                </div>
                <span className="mt-1 text-[#181818]">xyzevidence.pdf</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VendorSide;

import React from "react";
import {
  whitelogo,
  whitePlaystore,
  whiteAppatore,
  facebookFotterIcon,
  instaFotterIcon,
  twitterFotterIcon,
  lindlinIconFotter,
} from "../../../constants/images";

const Footer = () => {
  return (
    <div className="mt-20 bg-[#001E27] text-[#FFFFFF]">
      <div className="lg:mx-36 md:mx-10 mx-10 lg:flex lg:flex-row md:flex md:flex-row flex flex-col pt-20 gap-y-10 pb-10 ">
        <div className=" lg:w-[40%] md:w-[38%] flex flex-col gap-y-10 ">
          <img src={whitelogo} alt=""  className="w-32"/>
          <p className="text-[16px] lg:w-[65%] md:w-[65%] ">
            At GYWDE, we aim to empower individuals to seamlessly connect with
            skilled vendors and get their work done with confidence.
          </p>
          <div className="lg:flex md:flex lg:gap-4 md:gap-2 hidden">
           <a href="#" className="cursor-pointer"><img src={twitterFotterIcon} alt="" /></a> 
            <a href="#" className="cursor-pointer"><img src={instaFotterIcon} alt="" /></a>
            <a href="#" className="cursor-pointer"><img src={facebookFotterIcon} alt="" /></a>
           <a href="#" className="cursor-pointer"><img src={lindlinIconFotter} alt="" /></a> 
          </div>
          <div className="lg:flex md:flex lg:gap-4 md:gap-2  hidden">
            <img src={whiteAppatore} alt=""  className="lg:w-[7rem] md:w-[6rem]"/>
            <img src={whitePlaystore} alt="" className="lg:w-[7rem] md:w-[6rem]" />
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-3 md:grid md:grid-cols-3 grid grid-cols-1 gap-5 w-[60%]">
          <div className="flex flex-col gap-y-5">
            <p className="font-semibold">Categories</p>
          <a href="#">Repairs and maintenance</a>
          <a href="#">Digital services</a>
          <a href="#">Events services</a>
          <a href="#">Beauty services</a>
          <a href="#">Transport and logistics</a>
          <a href="#">Consulting services</a>
          </div>
          <div className="flex flex-col gap-y-5">
            <p className="font-semibold">Company</p>
          <a href="#">About Us</a>
          <a href="#">FAQ</a>
          <a href="#">Terms of service</a>
          <a href="#">Privacy policy</a>
          </div>
          <div className="flex flex-col gap-y-5">
            <p className="font-semibold">Contact Us</p>
          <a href="#">Physical address</a>
          <a href="#">Email address</a>
          <a href="#">Phone number</a>
          </div>
        </div>
        <div className="lg:hidden md:hidden gap-2 flex">
           <a href="#" className="cursor-pointer"><img src={twitterFotterIcon} alt="" /></a> 
            <a href="#" className="cursor-pointer"><img src={instaFotterIcon} alt="" /></a>
            <a href="#" className="cursor-pointer"><img src={facebookFotterIcon} alt="" /></a>
           <a href="#" className="cursor-pointer"><img src={lindlinIconFotter} alt="" /></a> 
          </div>
          <div className="lg:hidden md:hidden gap-2 flex">
            <img src={whiteAppatore} alt=""  className="lg:w-[7rem] md:w-[6rem]"/>
            <img src={whitePlaystore} alt="" className="lg:w-[7rem] md:w-[6rem]" />
          </div>
      </div>
      
      <div className="border-t-4 border-[#002D3A] w-full"></div>
      <div className=" flex lg:justify-center lg:items-center md:justify-center md:items-center justify-start py-8 mx-10">
        <p className="">© Gywd 2023, All Right Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;

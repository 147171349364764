import React, { useEffect, useRef, useState } from "react";
import { Testimonials } from "../../../../../../constants";
import {
  leftarrow,
  rightarrow,
  quote,
  quote1,
} from "../../../../../../constants/images";
import Pagination from "../../../../../../components/ui/pagination";

const Card = () => {
  const scrollRef = useRef(null);
  const displayMobile = screen.width <= 768;
  const [currentPage, setCurrentPage] = useState(1);
  const testimonialsPerPage = 1;
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!scrollRef.current) return;
      const scrollContainer = scrollRef.current;

      //Left arrow key
      if (e.key === "ArrowLeft") {
        scrollContainer.scrollLeft -= 50;
      }
      //Right arrow key
      else if (e.key === "ArrowRight") {
        scrollContainer.scrollLeft += 50;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //Handles arrow movement
  const handleLeftArrow = () => {
    if (!scrollRef.current) return;
    const scrollContainer = scrollRef.current;
    scrollContainer.scrollLeft -= 80;
    if (scrollContainer.scrollLeft > 1) {
      setIsLeftArrowVisible(true);
    } else {
      setIsLeftArrowVisible(false);
    }
  };

  const handleRightArrow = () => {
    if (!scrollRef.current) return;
    const scrollContainer = scrollRef.current;
    scrollContainer.scrollLeft += 80;
    if (scrollContainer.scrollLeft > 1) {
      setIsLeftArrowVisible(true);
    } else {
      setIsLeftArrowVisible(false);
    }
  };
  const nPages = Math.ceil(Testimonials.length / testimonialsPerPage);

  // pagination
  const lastTestimonialIndex = currentPage * testimonialsPerPage;
  const firstTestimonialIndex = lastTestimonialIndex - testimonialsPerPage;
  const currentTestimonial = Testimonials.slice(
    firstTestimonialIndex,
    lastTestimonialIndex,
  );

  return (
    <div className="relative">
      <div
        ref={scrollRef}
        className="no-scrollbar flex flex-col items-center justify-between gap-6 overflow-y-hidden overflow-x-scroll  text-primary lg:flex-row lg:gap-[3%]"
      >
        {!displayMobile &&
          Testimonials.map((testimonial) => (
            <div
              className="flex h-full w-full items-end  border border-[#002D3A]"
              key={testimonial.name}
            >
              <div className=" mt-20 h-full w-full rounded-tl-[50px] rounded-tr-[50px] bg-[#002D3A]  p-8 md:px-10 md:py-10">
                <div className="left-0 right-0 top-[30px] mt-[-6rem] flex flex-col items-center px-3 md:px-0">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="rounded-full border-8 border-primary"
                  />
                  <div className="my-2 flex flex-col items-center space-y-1 text-nowrap bg-primary bg-opacity-20 px-6  py-1 text-center">
                    <span className="font-semibold lg:text-lg">
                      {testimonial.name}
                    </span>
                    <span>{testimonial.title}</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex w-full justify-start py-3">
                      <img src={quote1} alt="quote" />
                    </div>

                    <span>{testimonial.text}</span>
                    <div className="flex w-full justify-end py-3">
                      <img src={quote} alt="quote" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {displayMobile &&
          currentTestimonial.map((testimonial) => (
            <div
              className="no-scrollbar flex h-full w-full  animate-slide_right items-end border border-[#002D3A]"
              key={testimonial.name}
            >
              <div className=" mt-20 h-full w-full rounded-tl-[50px] rounded-tr-[50px] bg-[#002D3A]  p-8 md:px-10 md:py-10">
                <div className="left-0 right-0 top-[30px] mt-[-6rem] flex flex-col items-center px-3 md:px-0">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="rounded-full border-8 border-primary"
                  />
                  <div className="my-2 flex flex-col items-center space-y-1 text-nowrap bg-primary bg-opacity-20 px-6  py-1 text-center">
                    <span className="font-semibold lg:text-lg">
                      {testimonial.name}
                    </span>
                    <span>{testimonial.title}</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex w-full justify-start py-3">
                      <img src={quote1} alt="quote" />
                    </div>

                    <span>{testimonial.text}</span>
                    <div className="flex w-full justify-end py-3">
                      <img src={quote} alt="quote" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}{" "}
        <div className="flex lg:hidden">
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <div className="absolute left-[-64px] right-0 top-[45%] z-20 hidden translate-y-[-50%] justify-between lg:flex">
        <div onClick={handleLeftArrow} className="cursor-pointer">
          {<img src={leftarrow} alt="back" />}
        </div>
        <div onClick={handleRightArrow} className="cursor-pointer">
          <img src={rightarrow} alt="foward" />
        </div>
      </div>
    </div>
  );
};

export default Card;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ConsumerIcon = () => {
  const [svgColor, setSvgColor] = useState();
  const activeProject = useSelector((state) => state.modalOverlay.states.activeProject);
  useEffect(() => {
    setSvgColor(activeProject);
  }, [activeProject]);
  return (

<svg width="71" height="36" viewBox="0 0 71 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1908_22787)">
<path d="M6 24.178C10.2677 13.3847 13.2853 5.03185 32.3103 21.4971C34.322 23.2382 36.3994 25.1082 38.953 25.8548C53.4305 30.088 48.6917 -4.95968 66 4.19627" stroke={`${svgColor === "Consumers" ? "#FFFFFF" : "#FFA000"}`} stroke-width="3"/>
</g>
<ellipse cx="56.3428" cy="4.34783" rx="3.44828" ry="4.34783" fill={`${svgColor === "Consumers" ? "#FFFFFF" : "#FFA000"}`}/>
<defs>
<filter id="filter0_d_1908_22787" x="0.605469" y="1.16406" width="70.0957" height="34.5469" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.94902 0 0 0 0 0.6 0 0 0 0 0.290196 0 0 0 0.16 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1908_22787"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1908_22787" result="shape"/>
</filter>
</defs>
</svg>
  )
}
export default ConsumerIcon
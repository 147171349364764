import React, { useState } from "react";
import ProjectIcon from "../../../vendor-dashboard/_pages/chat/_components/icons/project";
import ChatViewProject from "../../../vendor-dashboard/_pages/chat/_components/chat-view_project";
import { setViewProject } from "../../../../features/overlay";
import { requestService } from "../../../../constants/images";
import NoProject from "../../requestService/youhaveNoProject";
import { useDispatch, useSelector } from "react-redux";

const Chatbar = ({ chat }) => {
  const [clicked, setClicked] = useState(false);
  const dispatch = useDispatch();
  const viewProject = useSelector(
    (state) => state?.overlay.displayStates.viewProject,
  );

  const handleProjectClick = () => {
    dispatch(setViewProject(true));
  };
  return (
    <div className="relative">
      <div className="fixed top-16 z-20 h-fit w-[430px] max-w-full overflow-x-hidden rounded-bl-[20px] rounded-br-[20px] bg-primary px-8 py-6 shadow-xl md:top-[11%] md:w-[50%] md:pt-10">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center space-x-2">
            <img
              src={chat?.avatar}
              className="h-[50px] w-[50px]"
              alt="profile"
            />
            <div className="flex flex-col">
              <span className="text-lg font-semibold text-secondary">
                {chat?.title}
              </span>
              <span className="text-sm font-normal text-[#21A601]">Online</span>
            </div>
          </div>
          <div className="flex space-x-5">
            <div className="flex items-center space-x-2">
              <div className="">
                <img
                  src={requestService}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => setClicked(true)}
                />
              </div>
              <span className="w-[48px] text-[12px] leading-[14.08px]">
                Request service
              </span>
            </div>
            <div className="relative">
              <div
                className="flex cursor-pointer items-center space-x-2"
                onClick={handleProjectClick}
              >
                <ProjectIcon />
                <span className="text-xs font-normal text-lightgray">
                  View project
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {clicked && (
        // <div className="absolute right-0 z-[99] mt-[7rem] w-fit overflow-hidden top-[-3.5rem]">
        //   {" "}
          <NoProject setClicked={setClicked} />
        // </div>
      )}
      {viewProject && (
        <div className="absolute right-0 z-[99] mt-[7rem] w-fit overflow-hidden">
          <ChatViewProject />
        </div>
      )}
    </div>
  );
};

export default Chatbar;

import React, { useEffect, useState } from "react";
import ServiceRequestCard from "./_components/card";
import { serviceRequests } from "../../../../data";
import { left } from "../../../../../../constants/images";
import { useDispatch } from "react-redux";
import { setShowServiceRequest } from "../../../../../../features/overlay";

const ServiceRequest = ({  }) => {
  const dispatch = useDispatch()
  const [requestLength, setRequestLength] = useState(0);

  useEffect(() => {
    setRequestLength(serviceRequests.length);
  }, []);
  const handleBackClick = () => {
    dispatch(setShowServiceRequest(false))
  };
  return (
    <div
      className={`flex flex-col items-center justify-center px-8 py-2 md:float-right md:w-[78%] md:max-w-[100%] md:pt-20 md:flex-col md:px-0 md:pr-6`}
    >
      <div className="relative flex items-baseline space-y-10 py-2 text-lg font-semibold text-secondary md:static md:mr-auto md:py-4 md:text-[30px]">
        <div
          className="absolute bottom-[19%] left-[-40%]  block md:hidden"
          onClick={handleBackClick}
        >
          <img src={left} alt="back" className="w-[10px] rotate-180" />
        </div>
        <span>Service Requests &#40;{requestLength}&#41;</span>
      </div>
      <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3   ">
        {serviceRequests.map((service) => (
          <ServiceRequestCard key={service.id} {...service} />
        ))}
      </div>
    </div>
  );
};

export default ServiceRequest;

// import React from 'react'

import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
// /import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Button } from "../../../../components/ui";

const EnterOtp = () => {
//   const dispatch = useDispatch();
const navigate = useNavigate()
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [counter, setCounter] = useState(60);
  const [resend, setResend] = useState(false);

  const handleOtp = (e) => {
    setOtp(e);
    if (otp.length < 4) {
      setErrorMessage(false);
      setShowError(true);
    }
  };

  const handleResend = () => {
    if (counter === 0) {
      setResend(true);
    }
  };

  const handleSubmit = () => {
    if (showError === false && errorMessage === "") {
      navigate('/create-password')
      // dispatch(formdata({ otp: otp }));
    //   next();
    }
  };

  const handleRestartCounter = () => {
    setResend(false);
    setCounter(60);
    setOtp("");
  };

  useEffect(() => {
    if (otp.length === 6) {
      setShowError(false);
      setErrorMessage("");
    }
  }, [otp]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
    <div className="lg:bg-bgImage bg-white min-h-screen">
      {resend && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-20 h-[100vh] bg-black bg-opacity-40" />
      )}
      {resend && (
        <div className="absolute md:left-[30.5%] left-[8%] right-0 top-[40%] z-50 h-[50vh] w-5/6 md:w-[40%] rounded-xl bg-primary animate-zoom">
          <span className="flex h-full flex-col items-center justify-center gap-2">
          Verification code has been resent{" "}
            <Button
              border="none"
              onClick={handleRestartCounter} 
              className="w-fit"
              children="Okay"
            />
          </span>
        </div>
      )}
      <div className="absolute left-0 right-0 top-24 md:top-[1rem] h-[50vh] md:px-20 lg:px-0">
        <div className="mx-auto md:w-full lg:w-1/2">
          <div className="rounded-[5px] bg-primary p-7 ">
            <div className="flex flex-col items-start md:items-center justify-center gap-6 md:gap-10">
              <h1 className="text-[30px] font-semibold text-[#002D3A]">
              Forgot password
              </h1>
              <p className="md:text-center text-lg font-normal text-[#181818]">
              Please enter the 4-digits code we sent to your email
                
              </p>
              <form className="flex w-full flex-col md:items-center gap-8">
                <OtpInput
                  value={otp}
                  skipDefaultStyles={true}
                  inputStyle=" w-full h-[60px] text-center rounded-[5px] font-semibold text-[20px] border border-lightgray outline-none"
                  onChange={handleOtp}
                  containerStyle="gap-5 w-full md:w-3/4"
                  numInputs={4}
                  renderInput={(props) => <input {...props} required />}
                />
                {showError && (
                  <span className="h-[5px] text-sm text-[#da3939]">
                    {errorMessage}
                  </span>
                )}
                <span className="md:text-center text-base font-normal text-lightgray">
                  Didn’t receive code?{" "}
                  <span
                    className={`font-bold ${counter === 0 ? "text-lightblue hover:scale-105 hover:cursor-pointer" : " hover:cursor-wait"}`}
                    onClick={handleResend}
                  >
                    RESEND
                  </span>{" "}
                  {counter > 0 && <span>in {counter}s</span>}
                </span>
                <Button
                  children="Proceed"
                  border="none"
                  type="submit"
                  onClick={handleSubmit}
                  className='md:static absolute top-[80vh] w-[100%]'
                />
              </form>
              <Link to="/forgetPassword" className="md:mt-7 text-center text-[16px] font-[400] text-[#AEAEAE]">
              Entered wrong email?
              </Link>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default EnterOtp;

import React from 'react'

const Description = ({descrition}) => {
  return (
    <div className='mt-8'>
      <p className="text-[#002D3A] text-[18px] leading-[27px] font-normal">{descrition}</p>
    </div>
  )
}

export default Description

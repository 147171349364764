import React from "react";
import { allVendors } from "../../../../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  Back,
  LocationTwo,
  Clock,
  Star,
} from "../../../../../../../constants/images";
import { setShowVendor } from "../../../../../../../redux/slice/modalOverlay";

const VendorDetails = () => {
  const dispatch = useDispatch();
  const vendorId = useSelector((state) => state.modalOverlay.states.vendorId);

  const handleBackClick = () => {
    dispatch(setShowVendor(false));
  };
  console.log(vendorId);
  return (
    <>
      {allVendors
        .filter((project) => project.id === vendorId)
        .map((value) => (
          <div className="flex justify-between rounded-[10px] bg-primary px-10 py-12 pt-10 shadow-lg">
            <div className="flex w-3/4 flex-col gap-y-5">
              <div className="flex items-center justify-start gap-x-2">
                <img
                  src={Back}
                  alt="back"
                  className="hover:cursor-pointer"
                  onClick={handleBackClick}
                />
                <span className="text-base font-medium text-[#181818]">
                  Back
                </span>
              </div>
              <div className="flex items-center gap-x-4">
                <img src={value.image} className="w-[75px]" alt="" />
                <div className="flex flex-col">
                  <span className="text-lg font-semibold text-secondary">
                    {value.name}
                  </span>
                  <span className="text-base font-semibold text-lightgray">
                    {value.service}
                  </span>
                  <div className="flex items-center gap-x-1 text-sm">
                    <img src={Star} alt="rating" />
                    <span className="font-semibold text-[#181818]">
                      {value.rating}
                    </span>
                    <span className="font-normal text-lightgray">
                      ({value.count})
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-xl font-semibold text-secondary">
                  {value.title}
                </span>
                <span className="text-sm font-medium text-secondary">
                  Business hours
                </span>
                <div className="flex flex-col gap-y-2 pt-2">
                  <div>
                    <img src={Clock} alt="time" />
                    <span></span>
                    <span></span>
                  </div>
                  <div>
                    <img src={Clock} alt="time" />
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm font-medium text-secondary">
                  Address
                </span>
                <div className="flex items-center gap-x-1">
                  <img src={LocationTwo} alt="location" />
                  <span className="text-base font-semibold text-secondary">
                    {value.address}
                  </span>
                </div>
              </div>
              <div className="flex w-3/4 flex-col">
                <span className="text-sm text-lightgray">Description</span>
                <span className="font-medium text-[#181818] lg:text-lg">
                  {value.description}
                </span>
              </div>
              <div className="flex flex-col items-start gap-y-2">
                <span className="text-sm text-lightgray">Catalog</span>
                <img
                  src={value.document}
                  className="w-[200px]"
                  alt="attachment"
                />
              </div>
            </div>
            <div className="flex flex-col w-1/2 gap-y-10 px-20 py-2 ">
              <div className="h-fit w-full px-4 border rounded-[5px] border-[#AEAEAE]">
                <div className="flex flex-col">
                  <div className="flex w-full py-2 justify-between">
                    <span className="text-sm font-normal text-lightgray">Join Date</span>
                    <span className="text-base font-semibold text-secondary">{value.join_date}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full py-2 justify-between">
                    <span className="text-sm font-normal text-lightgray">User ID</span>
                    <span className="text-base font-semibold text-secondary">{value.userId}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full py-2 justify-between">
                    <span className="text-sm font-normal text-lightgray">Phone number</span>
                    <span className="text-base font-semibold text-secondary">{value.phone}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full py-2 justify-between">
                    <span className="text-sm font-normal text-lightgray">Email</span>
                    <span className="text-base font-semibold text-secondary">{value.email}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full py-2 justify-between">
                    <span className="text-sm font-normal text-lightgray">Gender</span>
                    <span className="text-base font-semibold text-secondary">{value.gender}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full py-2 justify-between">
                    <span className="text-sm font-normal text-lightgray">Location</span>
                    <span className="text-base font-semibold text-secondary">{value.location}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full py-2 justify-between">
                    <span className="text-sm font-normal text-lightgray">Category</span>
                    <span className="text-base font-semibold text-secondary">{value.category}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full py-2 justify-between">
                    <span className="text-sm font-normal text-lightgray">Specialty</span>
                    <span className="text-base font-semibold text-secondary">{value.specialty}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full py-2 justify-between">
                    <span className="text-sm font-normal text-lightgray">Years of experience</span>
                    <span className="text-base font-semibold text-secondary">{value.exp}</span>
                  </div>
                  <hr className="w-full" color="#AEAEAE" />
                  <div className="flex w-full py-2 justify-between">
                    <span className="text-sm font-normal text-lightgray">Base price rate</span>
                    <span className="text-base font-semibold text-secondary">₦{value.base_price}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default VendorDetails;

import React from "react";
import { verificationLogo } from "../../../../../../../../../constants/images";
import {
  setShowApplyForm,
  setJobDetails,
  setShowApplication
} from "../../../../../../../../../features/overlay";
import { useDispatch } from "react-redux";

const SuccessModal = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setShowApplyForm(false));
  };
  const handleApplication = () => {
    dispatch(setShowApplyForm(false));
    dispatch(setJobDetails(false));
    dispatch(setShowApplication(true))
  };
  return (
    <div className="absolute -right-36 z-[999] max-h-screen w-[584px] overflow-y-auto bg-primary">
      <span
        className="absolute right-10 top-4 cursor-pointer text-2xl font-medium hover:scale-105"
        onClick={handleClose}
      >
        X
      </span>
      <div className="flex flex-col items-center justify-center gap-y-4 pt-20">
        <img src={verificationLogo} alt="" className="w-[10rem]" />
        <span className="text-[20px] font-semibold text-secondary">
          Application sent
        </span>
        <span className="text-center text-lg font-normal text-[#181818]">
          Congratulations, your application has been successfully submitted
        </span>
        <span
          className="cursor-pointer py-10 text-lightblue"
          onClick={handleApplication}
        >
          View application details
        </span>
      </div>
    </div>
  );
};

export default SuccessModal;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAgentDetails,
  setAgentId,
  setShowEditAgent,
} from "../../../../../../redux/slice/modalOverlay";

const AgentTable = () => {
  const agentDetails = useSelector((state) => state.modalOverlay.states.agentDetails);
  const [details, setDetails] = useState([]);
  const [showAction, setShowAction] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const dispatch = useDispatch();

  const handleDeleteAgent = (value) => {
    const updatedDetails = details.filter((item) => item.id !== value);
    dispatch(setAgentDetails(updatedDetails));
    setShowAction(false);
  };

  const handleEditAgent = (agentId) => {
    dispatch(setShowEditAgent(true));
    dispatch(setAgentId(agentId));
  };

  const handleAction = (agentId) => {
    setSelectedAgentId(agentId);
    setShowAction(!showAction);
  };

  useEffect(() => {
    setDetails(agentDetails);
  }, [agentDetails]);

  return (
    <div className="flex w-full flex-col">
      {details.length === 0 && (
        <span className="mx-auto text-center text-secondary lg:text-lg">No agents yet.</span>
      )}
      {details.length !== 0 && (
        <div className="grid w-full grid-cols-4 justify-items-center bg-[#E8E8E8] px-6 py-4 text-lg font-medium text-secondary">
          <span>Name</span>
          <span>Company name</span>
          <span>Number of referrals</span>
          <span>Action</span>
        </div>
      )}
      {details.length !== 0 &&
        details.map((value) => (
          <div className="relative" key={value.id}>
            <div className="relative transition-colors ease-in-out">
              <div className="grid w-full grid-cols-4 justify-items-center px-6 py-4">
                <span className="">{value.agent_details.agent_name}</span>
                <span className="">{value.agent_details.company_name}</span>
                <span className="">{value.agent_details?.referrals}</span>
                <span className="cursor-pointer" onClick={() => handleAction(value.id)}>
                  ...
                </span>
              </div>
              <hr color="#AEAEAE" className="w-full" />
            </div>
            {showAction && selectedAgentId === value.id && (
              <div className="absolute right-10 top-0 rounded-[5px] bg-primary py-1">
                <div className="flex w-full flex-col justify-start">
                  <span
                    className="w-full px-10 text-secondary hover:cursor-pointer hover:bg-[#eee]"
                    onClick={() => handleEditAgent(value.id)}
                  >
                    View
                  </span>
                  <span
                    className="w-full px-10 text-secondary hover:cursor-pointer hover:bg-[#eee]"
                    onClick={() => handleDeleteAgent(value.id)}
                  >
                    Delete
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default AgentTable;

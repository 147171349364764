import React, { useEffect, useState } from "react";
import ConsumeSide from "./_components/cards/consumerSide";
import VendorSide from "./_components/cards/vendorsSide";
import GivePartialAwardCard from "./_components/cards/givePartialAwardCard";
import GiveFullAward from "./_components/cards/giveFullAward";
import { disputeData } from "../../../dispute/data";
import { useSelector } from "react-redux";

const DisputeDetails = () => {
  const disputeId = useSelector((state) => state.modalOverlay.states.disputeId);
  const [id, setId] = useState(0);
  const [partialValue, setPartialValue] = useState({
    vendor: "",
    consumer: "",
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [partialError, setPartialError] = useState("");
  const [showDecision, setShowDecision] = useState(false);
  const [selectedAward, setSelectedAward] = useState(null);
  const res = disputeData[id];
  const handleSendClick = (e) => {
    e.preventDefault();
    if (selectedAward === "partial" && !partialValue.vendor && !partialValue.consumer) {
      setPartialError("Enter shared percentages");
    } else {
      setShowDecision(true);
    }
  };
  const handleChange = (e) => {
    setPartialValue({ ...partialValue, [e.target.name]: e.target.value });
  };

  const handleAwardChange = (awardType) => {
    setSelectedAward(awardType);
  };

  useEffect(() => {
    if (disputeId) {
      setId(disputeId);
    }
  }, [disputeId]);

  return (
    <div className="">
      <div className="flex items-center justify-between bg-white px-10 py-3 drop-shadow-md">
        <div className="text-[25px] font-medium text-[#002D3A]">Disputes</div>
        <div className="">
          <button className="rounded-[5px] bg-white  px-3 py-1 text-[#0096C1]">
            Make a decision
          </button>
        </div>
      </div>
      <div className="rounded-[17px] bg-white drop-shadow-md">
        <div className="flex items-center justify-between border-b-[0.5px] border-[#AEAEAE] px-5 py-6 text-[18px]">
          <div className="">
            <h3 className="text-[18px] text-[#5F5F5F]">Dispute ID</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">GYWDDR101</span>
          </div>
          <div className="">
            <h3 className="text-[18px] text-[#5F5F5F]">Project ID</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">{res.ProjectID}</span>
          </div>
          <div className="">
            <h3 className="text-[18px] text-[#5F5F5F]">Project Title</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">{res.ProjectTitle}</span>
          </div>
          <div className="">
            <h3 className="text-[18px] text-[#5F5F5F]">Date of Complain</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">{res.DateofComplain}</span>
          </div>
        </div>
        <div className="flex border-b-[0.5px] border-[#AEAEAE] px-5 py-3">
          <button
            className={`w-[50%] rounded-[5px] px-4 py-2 ${activeIndex === 0 ? "bg-[#0096C1] text-white" : "bg-white text-[#5F5F5F]"}`}
            onClick={() => setActiveIndex(0)}
          >
            Consumer side
          </button>
          <button
            className={`w-[50%] rounded-[5px] px-4 py-2 ${activeIndex === 1 ? "bg-[#0096C1] text-white" : "bg-white text-[#5F5F5F]"}`}
            onClick={() => setActiveIndex(1)}
          >
            Vendor side
          </button>
        </div>
        <div className="">{activeIndex === 0 && <ConsumeSide />}</div>
        <div className="">{activeIndex === 1 && <VendorSide />}</div>
      </div>
      <div className="my-7 rounded-[17px] bg-white px-5 py-8 drop-shadow-md">
        <h3 className="text-[20px] font-medium text-[#002D3A]">Resolution Decision</h3>
        {!showDecision && (
          <form action="">
            <div className="mt-4 flex gap-10">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="award"
                  value="consumer"
                  className=""
                  checked={selectedAward === "consumer"}
                  onChange={() => handleAwardChange("consumer")}
                />
                <label htmlFor="Give full award to consumer" className="text-[18px] font-normal ">
                  Give full award to consumer
                </label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="award"
                  value="vendor"
                  className=""
                  checked={selectedAward === "vendor"}
                  onChange={() => handleAwardChange("vendor")}
                />
                <label htmlFor="Give full award to vendor" className="text-[18px] font-normal ">
                  Give full award to vendor
                </label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="award"
                  value="partial"
                  className=""
                  checked={selectedAward === "partial"}
                  onChange={() => handleAwardChange("partial")}
                />
                <label htmlFor="Give partial award" className="text-[18px] font-normal ">
                  Give partial award
                </label>
              </div>
            </div>
            <hr color="#AEAEAE" className="mt-3 w-1/2" />
            {selectedAward === "partial" && (
              <>
                <div className="justify- flex w-1/2 text-lightgray">
                  <div className="flex items-center gap-x-2 py-3">
                    <span>To consumer:</span>
                    <input
                      name="consumer"
                      id="consumer"
                      onChange={handleChange}
                      type="text"
                      className="w-[10%] border border-[#5f5f5f] px-2 outline-none"
                    />
                    %
                  </div>
                  <div className="flex items-center gap-x-2 py-3">
                    <span>To vendor:</span>
                    <input
                      name="vendor"
                      id="vendor"
                      onChange={handleChange}
                      type="text"
                      className="w-[10%] border border-[#5f5f5f] px-2 outline-none"
                    />
                    %
                  </div>
                </div>
                {partialError !== "" && (
                  <span className="text-sm text-[#e42a2a]">{partialError}</span>
                )}
              </>
            )}

            <h3 className="mt-7 text-[20px]">Reason for resolution (optional)</h3>
            <textarea
              name="resolution"
              id="resolution"
              cols="70"
              rows="5"
              className="mt-1 rounded-[5px] border-[0.5px] border-[#181818] p-3 text-lightgray outline-none"
              placeholder=""
            ></textarea>
            <div className="mt-16">
              <button
                type="submit"
                disabled={!selectedAward}
                className="w-[35%] rounded-[5px] bg-[#0096C1] px-4 py-2 text-[18px] text-white"
                onClick={handleSendClick}
              >
                Send
              </button>
            </div>
          </form>
        )}
        {showDecision && selectedAward === "partial" && <GivePartialAwardCard />}
        {showDecision && selectedAward === "consumer" && <GiveFullAward />}
        {showDecision && selectedAward === "vendor" && <GivePartialAwardCard />}
      </div>
    </div>
  );
};

export default DisputeDetails;

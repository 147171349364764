import React from "react";

const ServiceRequestCard = ({ name, title, date }) => {
  return (
    <div className="lg:min-w-auto min-h-[101px] w-auto cursor-pointer rounded-[5px] border border-[#5F5F5F] transition-all ease-in-out hover:scale-105">
      <div className="flex flex-col gap-2  px-4 py-3">
        <div className="flex items-center justify-start space-x-2">
          <span className="text-sm text-lightgray">Request from:</span>
          <span className="text-wrap text-sm font-medium text-secondary">
            {name}
          </span>
        </div>
        <span className="text-lg font-medium text-[#181818]">{title}</span>
        <div className="flex items-center justify-start space-x-2">
          <span className="text-wrap text-xs font-normal text-lightgray">
            Date requested:
          </span>
          <span className="text-wrap text-xs font-medium">{date}</span>
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestCard;

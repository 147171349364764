import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const ProjectIcon = () => {
  const location = useLocation();
  const [fill, setFill] = useState();
  const [pathname, setPathname] = useState();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const path = pathname?.substring(pathname.lastIndexOf("/") + 1);
    if (path === "projects" && !isMobile) {
      setFill("#fff");
    }
   else if (path === "projects" && isMobile) {
      setFill("#0096C1");
    } else {
      setFill("#5F5F5F");
    }
  }, [pathname]);


  return (
    <svg
    width="22"
    height="20" 
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
    >


      <path d="M21 18H6C5.44772 18 5 18.4477 5 19C5 19.5523 5.44772 20 6 20H21V22H6C4.34315 22 3 20.6569 3 19V4C3 2.89543 3.89543 2 5 2H21V18ZM5 16.05C5.16156 16.0172 5.32877 16 5.5 16H19V4H5V16.05ZM16 9H8V7H16V9Z" />

    </svg>
  );
};

export default ProjectIcon;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobDetails } from "../../../../../data";
import {
  setJobDetails,
  setShowApplyForm,
} from "../../../../../../../features/overlay";
import { Button } from "../../../../../../../components/ui";
import {
  blueLove,
  left,
  loveoutline,
  star,
} from "../../../../../../../constants/images";
import DesktopForm from "../_components/form/desktop";
import SuccessModal from "../_components/success-modal/desktop";
import SaveIcon from "../_components/saveicon";

const JobDetailsDesktop = () => {
  const dispatch = useDispatch();
  const [showReviews, setShowReviews] = useState(false);
  const [save, setSave] = useState(true);
  const JobId = useSelector((state) => state?.overlay.displayStates.jobId);
  const showForm = useSelector(
    (state) => state?.overlay.displayStates.showApplyForm,
  );
  const [successful, setSuccessful] = useState(false);

  const sendSuccessful = (value) => {
    dispatch(setShowApplyForm(true));
    setSuccessful(value);
  };
  const handleReviews = () => {
    setShowReviews(!showReviews);
  };

  const handleSave = () => {
    setSave(!save);
  };
  const handleApply = () => {
    dispatch(setShowApplyForm(true));
  };

  const handleJobDetails = () => {
    dispatch(setJobDetails(false));
  };
  return (
    <div className="relative w-full">
      {showForm && (
        <div className="z-[999] hidden md:block">
          {!successful ? (
            <DesktopForm sendSuccessful={sendSuccessful} />
          ) : (
            <SuccessModal />
          )}
        </div>
      )}

      <div
        className={`fixed bottom-0 right-0 z-20 mx-2 flex h-[650px] w-[80%] justify-between rounded-tl-[50px] bg-primary px-16 pt-20`}
      >
        <span
          onClick={handleJobDetails}
          className="max- absolute right-10 top-4 cursor-pointer text-2xl font-medium hover:scale-105"
        >
          X
        </span>
        <div className="max-w-[50%]">
          <span className="text-[30px] font-semibold text-secondary">
            Job details
          </span>
          {jobDetails
            .filter((job) => job.id === JobId)
            .map((value) => (
              <div className="flex flex-col gap-4 ">
                <span className="text-[20px] font-semibold text-secondary">
                  {value.title}
                </span>
                <span className="text-sm font-normal text-lightgray">
                  Posted {value.post_duration} mins ago
                </span>
                <hr color="#AEAEAE" className="w-full" />
                <div className="flex flex-col">
                  <span className="text-sm font-normal text-lightgray">
                    Description
                  </span>
                  <span>{value.description}</span>
                </div>
                <hr color="#AEAEAE" className="w-full" />
                <div>
                  <span className="text-sm font-normal text-lightgray">
                    Job requirements
                  </span>
                  {value.requirements.map((requirement) => (
                    <div className="flex gap-1">
                      <span>{requirement.id + 1}.</span>
                      <span>{requirement.content}</span>
                    </div>
                  ))}
                </div>
                <hr color="#AEAEAE" className="w-full" />
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <span className="text-sm font-normal text-lightgray">
                      Date
                    </span>
                    <div className="flex gap-1">
                      {" "}
                      <span>From: </span>{" "}
                      <span className="text-base font-medium">
                        {value.start_date}
                      </span>
                    </div>
                    <div className="flex gap-1">
                      {" "}
                      <span>To: </span>{" "}
                      <span className="text-base font-medium">
                        {value.closing_date}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-normal text-lightgray">
                      Location
                    </span>
                    <span className="text-base font-medium">
                      {value.location}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-normal text-lightgray">
                      Budget
                    </span>
                    <span className="text-base font-medium">
                      {value.budget}
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {/* <hr className="h-full flex flex- items-center justify-center w-[29%] bg-[#AEAEAE] mx-auto" /> */}
        <div className="flex w-full max-w-[50%] flex-col items-center overflow-y-scroll pb-20">
          <div className="flex w-3/4 flex-col gap-4 pb-8">
            <Button
              backgroundColor="transparent"
              textColor="#0096C1"
              className="relative"
              onClick={handleSave}
            >
              <div className="absolute bottom-[0.97rem] left-[13rem]">
                {save ? <SaveIcon /> : <img src={blueLove} className="animate-zoom" alt="saved" />}
              </div>
              {save ? <span>Save</span> : <span>Saved</span> }
            </Button>
            <Button onClick={handleApply}>Apply now</Button>
          </div>
          <hr color="#AEAEAE" className="w-3/4" />
          {jobDetails
            .filter((job) => job.id === JobId)
            .map((value) => {
              return (
                <>
                  <div className="flex w-3/4 items-center gap-2 py-10">
                    <img src={value.image} alt="image" className="w-[15%]" />
                    <div className="flex flex-col">
                      <span className="text-lg font-semibold text-secondary">
                        {value.name}
                      </span>
                      <div className="flex items-center gap-2">
                        <img src={star} alt="star" />
                        <span className="text-sm font-semibold">
                          {value.rating}
                        </span>
                        <span className="text-sm font-normal">
                          ({value.count})
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr color="#AEAEAE" className="w-3/4 space-y-3" />
                  <div className="flex w-3/4 flex-col items-start pt-4">
                    <div className="flex w-full items-center justify-between">
                      <span className="text-base font-semibold text-secondary">
                        View reviews
                      </span>
                      <img
                        src={left}
                        className="hover:cursor-pointer"
                        alt="open"
                        onClick={handleReviews}
                      />
                    </div>

                    {showReviews && (
                      <div className="flex animate-slide_up flex-col gap-5 pt-10">
                        {value.reviews.map((reviews) => (
                          <div className="flex-col gap-1">
                            <div className="flex">
                              <span className="text-base font-medium text-[#181818]">
                                {reviews.title}
                              </span>
                            </div>
                            <span className="text-sm font-normal text-lightgray">
                              {reviews.date}
                            </span>
                            <div className="flex items-center gap-2">
                              <img src={star} alt="star" />
                              <span className="text-sm font-semibold">
                                {reviews.rating}
                              </span>
                              <span className="text-sm font-normal text-lightgray">
                                By Vendor:
                              </span>
                              <span className="text-sm font-normal text-lightgray">
                                {value.name}
                              </span>
                            </div>
                            <span className="text-sm font-normal text-[#181818]">
                              {reviews.content}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default JobDetailsDesktop;

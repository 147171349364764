import React from "react";
import Nav from "../shared/nav";
import SideBar from "../shared/sidebar";

const Container = ({ children }) => {
  return (
    <div className="">
        <Nav />

        
        <div className="flex w-full">
            <div className="w-[15%] lg:flex md:flex hidden z-50">
                <SideBar />
            </div>
            <div className="lg:w-[85%] md:w-[85%] w-full ">
                {children}
            </div>
        </div>
        <div className="fixed bottom-0 w-full  md:hidden z-50">
                <SideBar />
            </div>
    </div>
  );
};

export default Container;

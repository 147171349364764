import React from "react";
import SideBar from "../../_components/ui/sidebar";
import VendorDashboardProjectsDesktop from "./desktop";
import VendorDashboardProjectsMobile from "./mobile";
import Navbar from "../../_components/ui/navbar";

const VendorDashboardProjects = () => {
  return (
    <div>
      <Navbar />
      <SideBar />
      <VendorDashboardProjectsDesktop />
      <VendorDashboardProjectsMobile />
    </div>
  );
};

export default VendorDashboardProjects;

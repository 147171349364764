import React, { useState } from "react";
import { hamburger, logo, searchicon } from "../../../constants/images";
import { Link, NavLink } from "react-router-dom";
import { Button } from "../";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false); // initialize togglemenu state to keep track if mobile menu is open

  return (
    <>
      <div className="bg fixed top-0 z-50 flex h-12 w-full items-center justify-between bg-primary px-6 transition-all duration-300 ease-out md:top-0 md:h-14 md:gap-3 md:px-12 lg:h-20 lg:px-40 xl:gap-0">
        <Link to="/">
          <img src={logo} />
        </Link>
        <div className="hidden items-center justify-center font-normal text-lightgray md:flex md:space-x-10 md:text-xs lg:space-x-20 lg:text-lg">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about-us">About Us</NavLink>
          <NavLink to="/services">Services</NavLink>
          <NavLink to="/vendor">Be a Vendor</NavLink>
        </div>

        <div className="hidden items-center space-x-5 md:flex lg:space-x-10">
          <img src={searchicon} alt="search" />
          <Link to="/login"> 
          <Button
            children="Log in"
            backgroundColor={"transparent"}
            textColor={"#5F5F5F"}
            className={`text-nowrap rounded-[5px] font-semibold ease-in-out md:!px-[10px] md:!py-[8px] md:text-xs lg:!px-[20px] lg:!py-[10px] xl:text-lg `}
          />
          </Link>
          <Link to="/signup">
            <Button
              children="Sign up"
              backgroundColor={"#0096C1"}
              border="none"
              className={`text-nowrap rounded-[5px] font-semibold ease-in-out md:!px-[10px] md:!py-[8px] md:text-xs lg:!px-[20px] lg:!py-[10px] xl:text-lg `}
            />
          </Link>
        </div>

        <div className="block cursor-pointer md:hidden">
          <img
            src={hamburger}
            alt="hamburger"
            onClick={() => setToggleMenu(true)}
          />
        </div>
      </div>
      {toggleMenu && (
        <div className="animate-slideBottom fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center gap-20 overflow-hidden bg-primary px-6 pt-6 transition-all duration-150">
          <div className="flex w-full items-center justify-between">
            <img src="/logo.png" alt="gywde" />

            <span
              className="right-6  top-5 cursor-pointer text-2xl"
              onClick={() => setToggleMenu(false)}
            >
              X
            </span>
          </div>
          <ul className="w-full list-none">
            <li className="mt-12 cursor-pointer text-xl text-primary">
              <div className="flex items-center justify-center">
                <Link
                  to="/"
                  className=" text-lg font-normal text-lightgray"
                >
                  Home
                </Link>
              </div>
            </li>
            <li className="mt-12 cursor-pointer text-xl text-primary">
              <div className="flex items-center justify-center">
                <Link
                  to="/about-us"
                  className=" text-lg font-normal text-lightgray"
                >
                  About Us
                </Link>
              </div>
            </li>
            <li className="mt-12 cursor-pointer text-xl text-primary">
              <div className="flex items-center justify-center">
                <Link
                  to="/services"
                  className=" text-lg font-normal text-lightgray"
                >
                  Services
                </Link>
              </div>
            </li>
            <li className="mt-12 cursor-pointer text-xl text-primary">
              <div className="flex items-center justify-center">
                <Link
                  to="/vendor"
                  className=" text-lg font-normal text-lightgray"
                >
                  Be a Vendor
                </Link>
              </div>
            </li>
          </ul>
          <div className="mt-20 flex w-full flex-col items-center space-y-6 text-base">
            <Link to="/login" className="mx-auto w-[100%]">
              <Button
                className="w-full !text-base font-semibold  !text-lightgray hover:scale-95"
                children="Log in"
                backgroundColor={"transparent"}
                padding={"15px 30px"}
              />
            </Link>
            <Link to="/signup" className="mx-auto w-[100%]">
              <Button
                className="w-full !text-base font-semibold  text-primary hover:scale-95"
                children="Sign up"
                border={"none"}
                padding={"15px 30px"}
              />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;

import React from 'react'
import { useDispatch } from 'react-redux';
import { handleHomeSearchInput } from '../../../features/searchSlice';
import { searchicon } from '../../../constants/images';
import { Input } from '../../../components/ui';

const SearchInput = () => {
    const dispatch = useDispatch();
    const handleInputChange = (e) => {
      const searchParam = e.target.value;
      dispatch(handleHomeSearchInput(searchParam));
    };
  return (
    <div className=''>
        <form action="">
            <div className="relative flex">
              <Input
                type="search"
                className="min-w-[100%] rounded-[5px] px-10 py-2 "
                placeholder="Search for services...."
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />{" "}
              <img
                src={searchicon}
                alt=""
                className="absolute bottom-2 z-10 px-2"
              />
            </div>
          </form>
    </div>
  )
}

export default SearchInput

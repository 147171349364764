import React, { useState, useEffect, useRef } from "react";
import Cards from "./_components/cards";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { disputeData } from "../../../dispute/data";
import { useDispatch } from "react-redux";
import { setDisputeId, setShowDispute } from "../../../../redux/slice/modalOverlay";
const MainContent = () => {
  const dispatch = useDispatch()
  const [activeFilter, setActiveFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const filterRef = useRef();

  const handleFilterChange = (status) => {
    setActiveFilter(status);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveFilter(null);
    }
  };
  const handleDisputeClick = () => {
    dispatch(setDisputeId())
    dispatch(setShowDispute(true))
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredData =
    activeFilter === null
      ? disputeData
      : disputeData.filter((data) => data.Status === activeFilter);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const currentDataLength = currentData.length;
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const goToPreviousPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  const goToNextPage = () => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));

  // Calculate percentage values and counts for each status
  const unresolvedCount = disputeData.filter((data) => data.Status === "Unresolved").length;
  const resolvingCount = disputeData.filter((data) => data.Status === "Resolving").length;
  const resolvedCount = disputeData.filter((data) => data.Status === "Resolved").length;

  const unresolvedPercentage = (unresolvedCount / disputeData.length) * 100;
  const resolvingPercentage = (resolvingCount / disputeData.length) * 100;
  const resolvedPercentage = (resolvedCount / disputeData.length) * 100;

  return (
    <div className=" ">
      <div ref={filterRef} className="my-12 flex justify-around gap-10">
        <div
          className={`flex w-full cursor-pointer items-center justify-start rounded-[7px] px-7 py-3 drop-shadow-md ${
            activeFilter === "Unresolved" ? "bg-[#0096C1] text-white" : "bg-white text-[#002D3A]"
          }`}
          onClick={() => handleFilterChange("Unresolved")}
        >
          <div style={{ width: 100, height: 100 }}>
            <CircularProgressbar
              value={unresolvedPercentage}
              text={`${Math.round(unresolvedPercentage)}%`}
              styles={buildStyles({
                textColor: "#002D3A",
                pathColor: "#FFA000",
                trailColor: "#FFE5D3", // Light background color for the circle
              })}
            />
          </div>
          <div className="ml-3 flex flex-col">
            <span className="text-[40px] font-medium "> {unresolvedCount}</span>
            <span className="text-[18px] font-medium ">Unresolved Disputes</span>
          </div>
        </div>
        <div
          className={`flex w-full cursor-pointer items-center justify-start rounded-[7px] px-7 py-3 drop-shadow-md ${
            activeFilter === "Resolving" ? "bg-[#0096C1] text-white" : "bg-white text-[#002D3A]"
          }`}
          onClick={() => handleFilterChange("Resolving")}
        >
          <div style={{ width: 100, height: 100 }}>
            <CircularProgressbar
              value={resolvingPercentage}
              text={`${Math.round(resolvingPercentage)}%`}
              styles={buildStyles({
                textColor: "#002D3A",
                pathColor: "#623CEA",
                trailColor: "#DAD7FE", // Light background color for the circle
              })}
            />
          </div>

          <div className="ml-3 flex flex-col">
            <span className="text-[40px] font-medium "> {resolvingCount}</span>
            <span className="text-[18px] font-medium ">Resolving Disputes</span>
          </div>
        </div>
        <div
          className={`flex w-full cursor-pointer items-center justify-start rounded-[7px] px-7 py-3 drop-shadow-md ${
            activeFilter === "Resolved" ? "bg-[#0096C1] text-white" : "bg-white text-[#002D3A]"
          }`}
          onClick={() => handleFilterChange("Resolved")}
        >
          <div style={{ width: 100, height: 100 }}>
            <CircularProgressbar
              value={resolvedPercentage}
              text={`${Math.round(resolvedPercentage)}%`}
              styles={buildStyles({
                textColor: "#002D3A",
                pathColor: "#279F51",
                trailColor: "#E2FBD7", // Light background color for the circle
                background: {
                  fill: "#3e98c7",
                },
              })}
            />
          </div>
          <div className="ml-3 flex flex-col">
            <span className="text-[40px] font-medium "> {resolvedCount}</span>
            <span className="text-[18px] font-medium ">Resolved Disputes</span>
          </div>
        </div>
      </div>

      {activeFilter === null && (
        <div className=" mt-6 rounded-b-[15px] rounded-t-[15px] bg-white drop-shadow-md">
          <div className="rounded-t-[15px] bg-[#E8E8E8] px-5  py-3">
            <h3 className="text-[20px] font-medium text-[#002D3A]">All disputes</h3>
          </div>
          <div className="justify flex cursor-pointer border-b-[0.5px] border-[#AEAEAE] bg-white px-5 py-3 text-[18px] font-medium text-[#002D3A]">
            <div className="w-[38%]">Project Title</div>
            <div className="w-[15%]">Project ID</div>
            <div className="w-[17%]">Date of Complain</div>
            <div className="w-[12%]">Status</div>
          </div>

          <div className="flex flex-col">
            {currentData.map((data, index) => (
              <Cards
              onClick={handleDisputeClick}
                key={data.id}
                ProjectTitle={data.ProjectTitle}
                ProjectID={data.ProjectID}
                DateofComplain={data.DateofComplain}
                Status={data.Status}
              />
            ))}
          </div>

          <div className="mt-4 flex justify-between px-5 py-4">
            <div className="text-[18px] text-[#002D3A]">
              <span>Showing</span> <span>{currentDataLength}</span> <span>entries</span>
            </div>
            <div className="flex items-center">
              <button
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
                className={`mx-1 rounded border px-3 py-1 ${
                  currentPage === 1
                    ? "cursor-not-allowed bg-gray-300 text-gray-500"
                    : "bg-white text-blue-500"
                }`}
              >
                Previous
              </button>
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`mx-1 rounded border px-3 py-1 ${
                    currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-white text-blue-500"
                  }`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
                className={`mx-1 rounded border px-3 py-1 ${
                  currentPage === totalPages
                    ? "cursor-not-allowed bg-gray-300 text-gray-500"
                    : "bg-white text-blue-500"
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      {activeFilter !== null && (
        <div className="mt-6 rounded-b-[15px] rounded-t-[15px] bg-white drop-shadow-md">
          <div className="rounded-t-[15px] bg-[#E8E8E8] px-5  py-3">
            <h3 className="text-[20px] font-medium text-[#002D3A]">{activeFilter} disputes</h3>
          </div>
          <div className="justify flex cursor-pointer border-b-[0.5px] border-[#AEAEAE] bg-white px-5 py-3 text-[18px] font-medium text-[#002D3A]">
            <div className="w-[38%]">Project Title</div>
            <div className="w-[15%]">Project ID</div>
            <div className="w-[17%]">Date of Complain</div>
            <div className="w-[12%]">Status</div>
          </div>

          <div className="flex flex-col">
            {currentData.map((data, index) => (
              <Cards
                key={data.id}
                to={`/disputeReview/${index}`}
                ProjectTitle={data.ProjectTitle}
                ProjectID={data.ProjectID}
                DateofComplain={data.DateofComplain}
                Status={data.Status}
              />
            ))}
          </div>

          <div className="mt-4 flex justify-between px-5 py-4">
            <div className="text-[18px] text-[#002D3A]">
              <span>Showing</span> <span>{currentDataLength}</span> <span>entries</span>
            </div>
            <div className="flex items-center">
              <button
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
                className={`mx-1 rounded border px-3 py-1 ${
                  currentPage === 1
                    ? "cursor-not-allowed bg-gray-300 text-gray-500"
                    : "bg-white text-blue-500"
                }`}
              >
                Previous
              </button>
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`mx-1 rounded border px-3 py-1 ${
                    currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-white text-blue-500"
                  }`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
                className={`mx-1 rounded border px-3 py-1 ${
                  currentPage === totalPages
                    ? "cursor-not-allowed bg-gray-300 text-gray-500"
                    : "bg-white text-blue-500"
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainContent;

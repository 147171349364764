import React from "react";
import "react-chat-elements/dist/main.css";
import { ChatList } from "react-chat-elements";
import { detailedChatHistory } from "../../../vendor-dashboard/data";
import { useSelector } from "react-redux";

const ChatListSidebar = ({ onClick }) => {
  const overlayValue = useSelector(
    (state) => state?.overlay.displayStates.overlay,
  );
  // console.log(overlayValue)

  return (
    <div className="overflow-hidden relative">
       {overlayValue && (
        <div className="fixed top-0 right-0 left-0 z-50 block h-[160vh] w-full bg-black bg-opacity-25 md:hidden" />
      )}
      {detailedChatHistory.map((chat) => {
        const lastMessage = chat.messages[chat.messages.length - 1];
        const subtitle =
          lastMessage && lastMessage.position === "left"
            ? lastMessage.text
            : lastMessage && lastMessage.position === "right"
              ? lastMessage.text.length > 30
                ? lastMessage.text.substring(0, 30) + "..."
                : lastMessage.text
              : "";
        const unreadCount =
          lastMessage && lastMessage.position === "right" ? 0 : chat.unread;
        const dataSource = [
          {
            avatar: chat.avatar,
            alt: chat.alt,
            title: chat.title,
            subtitle: chat.subtitle !== undefined && subtitle,
            date: chat.date,
            ...(unreadCount > 0 && { unread: unreadCount }),
          },
        ];

        return (
          <div className="md:w-full w-[85%] md:pt-4 pt-3 mx-auto" key={chat.id} onClick={() => onClick(chat)}>
            <ChatList className="chat-list" dataSource={dataSource} />
            <hr className="w-3/4 mx-auto pt-2" />
          </div>
        );
      })}
    </div>
  );
};

export default ChatListSidebar;

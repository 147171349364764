import React from "react";
import { elevate, elevatebg, elevatebg1 } from "../../../../constants/images";
import { Button } from "../../../../components/ui";

const VendorBannerSection = () => {
  return (
    <div className="relative mb-10 flex h-[318px] w-full bg-[#fff] shadow-xl md:my-20 lg:my-40">
      <div className="absolute left-0">
        <img
          src={elevatebg1}
          alt="elevate"
          className="h-full w-[272.67px] md:w-[531.26px]"
        />
      </div>
      <div className="absolute bottom-0 left-0 lg:left-[20%]">
        <img
          src={elevate}
          alt=""
          className="lg:w-[333.5px} h-full w-[152px] md:h-[250px] md:w-[210px] lg:h-full"
        />
      </div>
      <div className="absolute bottom-0 right-0">
        <img
          src={elevatebg}
          alt="elevate"
          className="h-full w-[272.67px] md:w-[581.59px]"
        />
      </div>
      <div className="flex w-full items-center justify-center">
        <div className="absolute left-[40%] top-[4rem] flex flex-col items-start justify-center gap-8 md:gap-10">
          <span className="text-[20px] font-semibold text-secondary lg:text-[30px]">
            Ready to elevate your service business? <br /> Join GYWDE today!
          </span>
          <Button
            children="Sign up as a Vendor"
            className="w-fit"
            border={"none"}
          />
        </div>
      </div>
    </div>
  );
};

export default VendorBannerSection;

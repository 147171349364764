import React, { useState } from "react";
import { Layout } from "../../components";
import {
  AdvertsPage,
  BusinessCards,
  ComissionModal,
  DiscountsPage,
  VatModal,
  Navbar,
  AgentsPage,
} from "./_components";
import { useSelector } from "react-redux";
import {
  AddNewAdvertModal,
  DeleteAdvertModal,
  EditAdvertModal,
} from "./_components/adverts/_components";
import {
  AddNewDiscountModal,
  DeleteDiscountModal,
  EditDiscountModal,
} from "./_components/discounts/_components";
import { AddNewAgentModal, AgentDetails } from "./_components/agents/_components";

const BusinessSuite = () => {
  const editAdvert = useSelector((state) => state.modalOverlay.states.editAdvert);
  const showEditAgent = useSelector((state) => state.modalOverlay.states.showEditAgent);
  const deleteAdvert = useSelector((state) => state.modalOverlay.states.deleteAdvert);
  const deleteDiscount = useSelector((state) => state.modalOverlay.states.deleteDiscount);
  const addNewAdvert = useSelector((state) => state.modalOverlay.states.addNewAdvert);
  const addNewAgent = useSelector((state) => state.modalOverlay.states.addNewAgent);
  const addNewDiscount = useSelector((state) => state.modalOverlay.states.addNewDiscount);
  const editDiscount = useSelector((state) => state.modalOverlay.states.editDiscount);
  const [vatModal, setVatModal] = useState(false);
  const [comissionModal, setComissionModal] = useState(false);

  const showAgentModal = useSelector((state) => state.modalOverlay.states.showAgentModal);
  const showAdvertModal = useSelector((state) => state.modalOverlay.states.showAdvertModal);
  const showDiscountModal = useSelector((state) => state.modalOverlay.states.showDiscountModal);

  const handleVatModal = (value) => {
    setVatModal(value);
  };
  const handleComissionModal = (value) => {
    setComissionModal(value);
  };

  const handleCloseVat = () => {
    setVatModal(false);
    setComissionModal(false);
  };

  return (
    <Layout>
      {(vatModal ||
        comissionModal ||
        editAdvert ||
        editDiscount ||
        deleteAdvert ||
        deleteDiscount ||
        addNewAdvert ||
        addNewAgent ||
        addNewDiscount) && (
        <div className="absolute inset-0 z-50 h-[100vh] bg-black bg-opacity-25" />
      )}
      {vatModal && (
        <div className="absolute left-[40%] right-[50%] top-[30%] z-[999] w-[30%]">
          <VatModal handleClose={handleCloseVat} />
        </div>
      )}
      {comissionModal && (
        <div className="absolute left-[40%] right-[50%] top-[30%] z-[999] w-[30%]">
          <ComissionModal handleClose={handleCloseVat} />
        </div>
      )}
      {showAdvertModal && <AdvertsPage />}
      {showDiscountModal && <DiscountsPage />}

      {showAgentModal && !showEditAgent && <AgentsPage />}
      {editAdvert && (
        <div className="absolute left-[40%] right-[50%] top-[30%] z-[999] w-[30%]">
          <EditAdvertModal />
        </div>
      )}
      {showEditAgent && <AgentDetails />}
      {editDiscount && (
        <div className="absolute left-[40%] right-[50%] top-[10%] z-[999] w-[30%]">
          <EditDiscountModal />
        </div>
      )}
      {deleteAdvert && (
        <div className="absolute left-[40%] right-[50%] top-[30%] z-[999] w-[30%]">
          <DeleteAdvertModal />
        </div>
      )}
      {deleteDiscount && (
        <div className="absolute left-[40%] right-[50%] top-[30%] z-[999] w-[30%]">
          <DeleteDiscountModal />
        </div>
      )}
      {addNewAdvert && (
        <div className="absolute left-[40%] right-[50%] top-[30%] z-[999] w-[30%]">
          <AddNewAdvertModal />
        </div>
      )}
      {addNewAgent && (
        <div className="absolute left-[40%] right-[50%] top-[30%] z-[999] w-[30%]">
          <AddNewAgentModal />
        </div>
      )}
      {addNewDiscount && (
        <div className="absolute left-[40%] right-[50%] top-[10%] z-[999] w-[30%]">
          <AddNewDiscountModal />
        </div>
      )}
      {!(showAgentModal || showAdvertModal || showDiscountModal || showEditAgent) && (
        <>
          <Navbar />
          <BusinessCards showVatModal={handleVatModal} showComissionModal={handleComissionModal} />
        </>
      )}
    </Layout>
  );
};

export default BusinessSuite;

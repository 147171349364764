import React from "react";
import { time, location } from "../../../../../constants/images";
const SingleCardService = ({ companyName, monTOfri, satTOsun, address,title,service,yearsOfexperience,price }) => {
  return (
    <div className="mt-8">
      <h1 className="text-[20px] font-semibold leading-[23.46px] text-[#002D3A]">
        {companyName}
      </h1>
      <div className="mt-4">
        <div className="flex space-x-10">
          <div className="flex flex-col text-[#002D3A] ">
            <p className="text-[14px] font-medium text-[#002D3A]">
              Business hours
            </p>
            <div className="flex items-center space-x-3">
              <img src={time} alt="time" className="h-4 w-4" />
              <span className="text-[14px] font-normal">Mon - Fri:</span>{" "}
              <span className="text-[16px] font-semibold">{monTOfri}</span>
            </div>
            <div className="flex items-center space-x-3">
              <img src={time} alt="time" className="h-4 w-4" />
              <span className="text-[14px] font-normal">Sat - Sun:</span>{" "}
              <span className="text-[16px] font-semibold">{satTOsun}</span>
            </div>
          </div>
          <div className="text-[#002D3A]">
            <p className="text-[14px] font-medium text-[#002D3A]">Address</p>
            <div className="flex items-center space-x-3">
              <img src={location} alt="time" className="h-4 w-4" />{" "}
              <p className="text-[16px] font-semibold">{address}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-10 w-[65%] gap-4">
        <div className="flex items-center space-x-16 justify-between "><span className="text-[#5F5F5F] text-[14px] font-normal">Category</span> <span className="text-[#002D3A] text-[16px] font-medium">{title}</span></div>
        <div className="flex items-center space-x-16 justify-between "><span className="text-[#5F5F5F] text-[14px] font-normal">Specialty</span><span className="text-[#002D3A] text-[16px] font-medium">{service}</span></div>
        <div className="flex items-center space-x-16 justify-between "><span className="text-[#5F5F5F] text-[14px] font-normal">Years of experience</span><span className="text-[#002D3A] text-[16px] font-medium">{yearsOfexperience}</span></div>
        <div className="flex items-center space-x-16 justify-between "><span className="text-[#5F5F5F] text-[14px] font-normal">Base price rate</span><span className="text-[#002D3A] text-[16px] font-medium">₦{price}</span></div>
      </div>
    </div>
  );
};

export default SingleCardService;

import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch } from "react-redux";
import { setRateConsumer, setSaveRatings } from "../../../../../../features/overlay";
import { Button } from "../../../../../../components/ui";

const RatingForm = () => {
  const dispatch = useDispatch();
  const [communicationRating, setCommunicationRating] = useState("");
  const [timeRating, setTimeRating] = useState("");
  const [overall, setOverall] = useState(0);
  const [comment, setComment] = useState("");
  const [errorState, setErrorState] = useState({
    communicationError: "",
    timelinesError: "",
  });

  const calculateOverall = () => {
    const overallValue = (Number(communicationRating) + Number(timeRating)) / 2;
    setOverall(overallValue);
  };

  useEffect(() => {
    calculateOverall();
  }, [communicationRating, timeRating]);

  const validate = () => {
    let isError = false;
    const errors = {
      communicationError: "",
      timelinesError: "",
      commentError: "",
    };
    if (communicationRating === "") {
      isError = true;
      errors.communicationError = "Please leave a rating";
    }
    if (timeRating === "") {
      isError = true;
      errors.timelinesError = "Please leave a rating";
    }
    if (comment === "") {
      isError = true;
      errors.commentError = "Please leave a comment";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleClose = () => {
    dispatch(setRateConsumer(false));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validate();

    if (!error) {
      dispatch(setRateConsumer(false)); 
      dispatch(setSaveRatings(overall))

      // dispatch(formdata({ personal_details: details }));
    }
  };
  const CommRating = (newRating) => {
    setCommunicationRating(newRating);
  };
  const TimeRating = (newRating) => {
    setTimeRating(newRating);
  };
  const handleComment = (e) => {
    setComment(e.target.value);
  };
  return (
    <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white px-6 py-8 lg:w-[529px] lg:rounded-[5px] lg:px-0">
      <div
        className="absolute right-10 top-2 text-2xl hover:cursor-pointer"
        onClick={handleClose}
      >
        X
      </div>
      <div className="flex flex-col justify-start gap-y-4 lg:px-10 lg:py-8">
        <span className="text-start text-xl font-semibold text-secondary">
          Give ratings
        </span>
        <span className="text-lg font-medium text-secondary">
          Rate consumers on the following
        </span>
        <div className="flex items-center justify-between">
          <span className="font-medium text-lightgray">Communication</span>
          <div className="">
            <ReactStars
              count={5}
              name="communication"
              onChange={CommRating}
              size={24}
              activeColor="#FFE234"
            />
          </div>
        </div>{" "}
        <span className="text-sm text-[#e62e2e]">
          {errorState.communicationError}
        </span>
        <div className="flex items-center justify-between">
          <span className="font-medium text-lightgray">Timelines</span>
          <div className="">
            <ReactStars
              count={5}
              name="timelines"
              onChange={TimeRating}
              size={24}
              activeColor="#FFE234"
            />
          </div>
        </div>{" "}
        <span className="text-sm text-[#e62e2e]">
          {errorState.timelinesError}
        </span>
        <div className="flex items-center justify-between">
          <span className="font-semibold text-[#181818]">Overall rating</span>
          <div className="">
            <ReactStars
              key={overall}
              count={5}
              name="overall"
              edit={false}
              value={overall}
              size={24}
              activeColor="#FFE234"
            />
          </div>
        </div>
        <div className="mb-20 flex flex-col lg:mb-0">
          <label className="font-normal text-[#181818]" htmlFor=""></label>
          <textarea
            name="comment"
            id="comment"
            onChange={handleComment}
            cols="20"
            className="rounded-[5px] border border-[#181818] px-4 py-2"
            placeholder="Leave a comment"
            rows="5"
          />
          <span className="text-sm text-[#e62e2e]">
            {errorState.commentError}
          </span>
        </div>
        <Button className="pt-6" onClick={handleSubmit}>
          Submit review
        </Button>
      </div>
    </div>
  );
};

export default RatingForm;

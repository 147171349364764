import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handlePreviousPage = () => {
    if (!isFirstPage) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (!isLastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`mx-1 rounded px-3 py-1 ${
            i === currentPage ? "bg-lightblue text-white" : "bg-white border border-[#AEAEAE]"
          }`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>,
      );
    }

    return pageNumbers;
  };

  return (
    <div className="w-full flex items-center">
      <button
        className="mx-1 rounded px-2 py-1"
        onClick={handlePreviousPage}
        disabled={isFirstPage}
      >
        Previous
      </button>
      {renderPageNumbers()}
      <button
        className="mx-1 rounded px-2 py-1"
        onClick={handleNextPage}
        disabled={isLastPage}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;

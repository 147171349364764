import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const JobIcon = () => {
  const location = useLocation();
  const [fill, setFill] = useState();
  const [pathname, setPathname] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    setPathname(location.pathname);
  }, []);

  useEffect(() => {
    const path = pathname?.substring(pathname.lastIndexOf("/") + 1);
    if (path === "jobs" && !isMobile) {
      setFill("#fff");
    }
   else if (path === "jobs" && isMobile) {
      setFill("#0096C1");
    } else {
      setFill("#5F5F5F");
    }
  }, [pathname]);

  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.031 14.6168L20.3137 18.8995L18.8995 20.3137L14.6168 16.031C13.0769 17.263 11.124 18 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18 11.124 17.263 13.0769 16.031 14.6168ZM14.0247 13.8748C15.2475 12.6146 16 10.8956 16 9C16 5.1325 12.8675 2 9 2C5.1325 2 2 5.1325 2 9C2 12.8675 5.1325 16 9 16C10.8956 16 12.6146 15.2475 13.8748 14.0247L14.0247 13.8748Z"
      />
    </svg>
  );
};

export default JobIcon;

/* eslint-disable react/prop-types */

import { Link } from "react-router-dom"

const TableBody = ({project_title,project_id,date_of_complain,status,to,}) => {


    return (
      <tbody className=" bg-white border-b-[0.5px] border-[#AEAEAE] text-[18px] w-full">
        
        <td className="px-10 text-[18px]">{project_title}</td>
        <td className="px-10 text-[18px]">{project_id}</td>
        <td className="px-10 text-[18px]">{date_of_complain}</td>
        <td className={`px-10 capitalize text-[18px]`}>
          <p
            className={` 
            ${
              status === "unresolved"
                ? "text-[#FFA000] "
                : status === "resolving"
                ? "text-[#623CEA] "
                : "text-[#279F51] "
            } 
            `}
          >
            {status}
          </p>
        </td>
        <Link to={to}>
        <td className="py-[1rem] px-10">
          <button className="border-[1px] border-[#0096C1] px-2 py-1 rounded-[5px] text-[#0096C1] ">
            {status=== "resolved" ? "View ": "Review"} 
          </button>
        </td>
        </Link>
      </tbody>
    )
  }
  
  export default TableBody
  
import React, { useState } from "react";
import { addFile, addfile } from "../../../../../../constants/images";
import { Button } from "../../../../../../components/ui";
import { useDispatch } from "react-redux";
import { setOverlay } from "../../../../../../features/overlay";

const ProjectForm = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    note: "",
    file: "",
  });
  const [errorState, setErrorState] = useState({
    noteError: "",
    fileError: "",
  });

  const validate = () => {
    let isError = false;
    const errors = {
      noteError: "",
      fileError: "",
    };

    if (!details.note) {
      isError = true;
      errors.noteError = "Please add a note";
    }
    if (!details.file) {
      isError = true;
      errors.fileError = "Please upload a document";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validate();

    if (!error) {
      // dispatch(
      //   setApplicationForm({
      //     application_details: details,
      //     service_fee: servicefee,
      //     actual_amount: amount,
      //   }),
      // );
      // sendSuccessful(!successful);
      dispatch(setOverlay(false));
    }
  };

  const handleClose = () => {
    dispatch(setOverlay(false));
  };
  return (
    <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white px-4 py-8 lg:w-[529px] lg:rounded-[5px]">
      <div
        className="absolute right-10 top-2 text-2xl hover:cursor-pointer"
        onClick={handleClose}
      >
        X
      </div>
      <div className="flex flex-col justify-center gap-y-4 lg:px-10 lg:py-8">
        <span className="text-center text-xl font-semibold text-secondary">
          Submit project
        </span>

        <span className="text-base font-normal text-[#181818]" htmlFor="files">
          Upload documents/files (optional)
        </span>
        <div className="flex flex-col items-center justify-center gap-y-2 border border-dashed border-[#181818] py-6 lg:py-8">
          <label
            htmlFor="previous_work"
            className="mx-auto flex w-fit cursor-pointer items-center justify-center gap-2 bg-[#E6F5F9] px-3 py-4"
          >
            <img src={addfile} alt="add file" />
            <span className="text-sm text-lightblue">Add Files</span>
          </label>
          <span className="text-center text-sm text-lightgray">
            {details.file?.replace(/^.*[\\\/]/, "")}
          </span>
          <input
            type="file"
            name="file"
            id="previous_work"
            onChange={handleForm}
            className="hidden"
          />
          <span className="text-xs text-[#e62e2e]">{errorState.fileError}</span>
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="note"
            className="py-2 text-base font-normal text-[#181818]"
          >
            Add note
          </label>
          <textarea
            name="note"
            placeholder="Include a brief note about the project"
            id="note"
            cols="20"
            onChange={handleForm}
            rows="5"
            className="border border-[#181818] px-4 py-2 outline-none"
          ></textarea>
          <span className="text-xs text-[#e62e2e]">{errorState.noteError}</span>
        </div>
        <Button onClick={handleSubmit}>Submit project</Button>
      </div>
    </div>
  );
};

export default ProjectForm;

import React from "react";
import { Button } from "../../../../../../../components/ui";
import { datas } from "./stepSix";
import StepSixCard from "./StepSixCard";
const StepSix = ({ next }) => {
  const filterdData = datas.filter(item=>item.text!="Post a job")
  const handleSubmit = () => {
    next();
  };
  return (
    <div className="mx-auto rounded-[5px] bg-[#ffff] p-10 lg:w-[70%]">
      <h1 className="text-center text-[30px] font-semibold text-[#002D3A]">
        Tell us your interests
      </h1>
      <p className="my-5 text-center text-[18px] font-[400] text-[#181818]">
        What kind of service are you interested in?
      </p>
      <div className="grid grid-cols-2 gap-5 lg:grid-cols-4">
        {
        filterdData.map((data) => {
          return (
            <StepSixCard key={data.id} {...data} />
          );
        })
        }
      </div>
      <div className="flex justify-center">
        <Button
          children="Save and proceed"
          border="none"
          className="mt-7 w-[80%]"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default StepSix;

import React from "react";

const BannerSection = () => {
  return (
    <div className="py-20">
      <div className="bg-[#E6F5F9] w-full py-10">
        <div className="grid lg:grid-cols-4 grid-cols-2 gap-8">
          <div className="grid md:place-items-end place-items-center grid-flow-col">
            <div className="flex flex-col items-center">
              <span className="text-secondary lg:text-[50px] text-[30px] font-semibold">
                100+
              </span>
              <span className="text-secondary md:text-lg text-base font-normal">
                Available services
              </span>
            </div>
            <span className="bg-[#AEAEAE] md:inline hidden w-[1px] h-[50px] my-auto" />
          </div>

          <div className="grid md:place-items-end place-items-center grid-flow-col">
            <div className="flex flex-col items-center">
            <span className="text-secondary lg:text-[50px] text-[30px] font-semibold">
              50+
            </span>
            <span className="text-secondary md:text-lg text-base font-normal">
              Reliable vendors
            </span>
          </div>
          <span className="bg-[#AEAEAE] md:inline hidden  w-[1px] h-[50px] my-auto" />
          </div>
          
          <div className="grid md:place-items-end place-items-center grid-flow-col">
            <div className="flex flex-col items-center">
            <span className="text-secondary lg:text-[50px] text-[30px] font-semibold">
              100+
            </span>
            <span className="text-secondary md:text-lg text-base font-normal">
              Happy customers
            </span>
          </div>
          <span className="bg-[#AEAEAE] md:inline hidden w-[1px] h-[50px] my-auto" />
          </div>
          
          <div className="flex flex-col items-center">
            <span className="text-secondary lg:text-[50px] text-[30px] font-semibold">
              200+
            </span>
            <span className="text-secondary md:text-lg text-base font-normal">
              Jobs posted
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSection;

import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../../components/ui";
import { Link } from "react-router-dom";
import { Services } from "../../../../constants";
import Pagination from "../../../../components/ui/pagination";

const CategoriesSection = () => {
  const scrollRef = useRef(null);
  const [displayMobile, setDisplayMobile] = useState(screen.width <= 768);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicePerPage, setServicesPerPage] = useState(2);

  useEffect(() => {
    setDisplayMobile(screen.width <= 768);

    const handleResize = () => {
      setDisplayMobile(screen.width <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (displayMobile) {
      setServicesPerPage(2);
    } else {
      setServicesPerPage(Services.length);
    }
  }, [displayMobile, Services]);

  const nPages = Math.ceil(Services.length / servicePerPage);

  // pagination
  const lastServiceIndex = currentPage * servicePerPage;
  const firstServiceIndex = lastServiceIndex - servicePerPage;
  const currentService = Services.slice(firstServiceIndex, lastServiceIndex);

  return (
    <div className="mx-auto flex flex-col px-8 py-12 md:gap-5 lg:px-[9rem]  lg:py-40">
      <div className="flex w-full flex-col gap-2 md:flex-row md:items-center md:justify-between md:gap-0">
        <div className="flex flex-col gap-y-3">
          <div className="flex items-end">
            <hr color="#5f5f5f" className="w-[15%]" />
            <span className="text-sm font-normal capitalize text-lightgray lg:text-lg">
              Categories
            </span>
          </div>
          <span className="text-[25px] font-semibold text-secondary lg:w-[70%] lg:text-[40px]">
            Enjoy a diverse selection of Vendors
          </span>
        </div>
        <div className="text-base font-normal text-lightgray lg:w-[40%] lg:text-lg">
          Your all-in-one platform to discover reliable vendors at your
          convenience that cater to your every need whether online or
          physically.
        </div>
      </div>
      <div className="no-scrollbar flex w-full flex-row justify-between overflow-x-scroll py-10 transition-all ease-in md:overflow-hidden md:py-0">
        {currentService.map((service) => (
          <div
            key={service.id}
            className="group flex h-[214px] w-[170px] flex-col items-center justify-start border border-[#0096C1] p-3 transition-all ease-in hover:cursor-pointer hover:bg-lightblue"
          >
            <div className="ease flex w-full justify-center border border-[#0096C1] p-2 transition-all group-hover:bg-primary">
              <img src={service.image} alt="repair and maintenance" />
            </div>
            <span className="py-4 text-center font-semibold text-secondary group-hover:text-primary lg:text-base">
              {service.title}
            </span>
          </div>
        ))}
      </div>
      <div className="flex justify-center lg:hidden">
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className="flex items-center justify-center pt-10 md:items-end md:justify-end">
        <Link to="/services">
          <Button backgroundColor="transparent" textColor="#0096C1" width="fit">
            Explore all services
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default CategoriesSection;

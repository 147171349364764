// import React from 'react'
import { Button } from "../../../../../../../components/ui";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { formdata } from "../../../../../../../features/formSlice";

const StepThree = ({ next }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [counter, setCounter] = useState(60);
  const [resend, setResend] = useState(false);

  const handleOtp = (e) => {
    setOtp(e);
    if (otp.length < 6) {
      setErrorMessage(false);
      setShowError(true);
    }
  };

  const handleResend = () => {
    if (counter === 0) {
      setResend(true);
    }
  };

  const handleSubmit = () => {
    if (showError === false && errorMessage === "") {
      dispatch(formdata({ otp: otp }));
      next();
    }
  };

  const handleRestartCounter = () => {
    setResend(false);
    setCounter(60);
    setOtp("");
  };

  useEffect(() => {
    if (otp.length === 6) {
      setShowError(false);
      setErrorMessage("");
    }
  }, [otp]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      {resend && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-20 h-[100vh] bg-black bg-opacity-40" />
      )}
      {resend && (
        <div className="absolute md:left-[30.5%] left-[8%] right-0 top-[40%] z-50 h-[20vh] w-5/6 md:w-[40%] rounded-xl bg-primary animate-zoom">
          <span className="flex h-full flex-col items-center justify-center gap-2">
          Verification code has been resent{" "}
            <Button
              border="none"
              onClick={handleRestartCounter} 
              className="w-fit"
              children="Okay"
            />
          </span>
        </div>
      )}
      <div className="absolute left-0 right-0 top-24 md:top-[15rem] h-[50vh] md:px-20 lg:px-0">
        <div className="mx-auto md:w-full lg:w-1/2">
          <div className="rounded-[5px] bg-primary p-7 ">
            <div className="flex flex-col items-start md:items-center justify-center gap-6 md:gap-10">
              <h1 className="text-[30px] font-semibold text-[#002D3A]">
                Verify your email vendor
              </h1>
              <p className="md:text-center text-lg font-normal text-[#181818]">
                We have sent a verification code to the email you entered.{" "}
                <br /> Kindly enter the 6-digit code below
              </p>
              <form className="flex w-full flex-col md:items-center gap-8">
                <OtpInput
                  value={otp}
                  skipDefaultStyles={true}
                  inputStyle=" w-full h-[60px] text-center rounded-[5px] font-semibold text-[20px] border border-lightgray outline-none"
                  onChange={handleOtp}
                  containerStyle="gap-5 w-full md:w-3/4"
                  numInputs={6}
                  renderInput={(props) => <input {...props} required />}
                />
                {showError && (
                  <span className="h-[5px] text-sm text-[#da3939]">
                    {errorMessage}
                  </span>
                )}
                <span className="md:text-center text-base font-normal text-lightgray">
                  Didn’t receive code?{" "}
                  <span
                    className={`font-bold ${counter === 0 ? "text-lightblue hover:scale-105 hover:cursor-pointer" : " hover:cursor-wait"}`}
                    onClick={handleResend}
                  >
                    RESEND
                  </span>{" "}
                  {counter > 0 && <span>in {counter}s</span>}
                </span>
                <Button
                  children="Verify email address"
                  border="none"
                  type="submit"
                  onClick={handleSubmit}
                  className='md:static absolute top-[80vh] w-[90%] md:w-1/2'
                />
              </form>
              <Link className="md:mt-7 text-center text-[16px] font-[400] text-[#AEAEAE]">
                Use phone number instead
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepThree;

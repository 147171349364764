import React, { useState } from "react";
import { Link } from "react-router-dom";

const DisputeSmallCard = ({ ProjectTitle, ProjectID, DateofComplain, Status, to, onClick }) => {
  const getStatusColor = (Status) => {
    switch (Status) {
      case "Unresolved":
        return "text-[#FFA000]";
      case "Resolving":
        return "text-[#623CEA]";
      case "Resolved":
        return "text-[#279F51]";
      default:
        return "text-gray-500";
    }
  };
  return (
    <div className="flex items-center border-b-[0.5px] border-[#AEAEAE] bg-white px-5 py-3 text-[18px]">
      <div className="w-[38%]">{ProjectTitle}</div>
      <div className="w-[15%]">{ProjectID}</div>
      <div className="w-[17%]">{DateofComplain}</div>
      <div className={`${getStatusColor(Status)} w-[15%]`}>{Status}</div>
      <div className=" rounded-[5px] border-[1px] border-[#0096C1] px-2 py-1 text-[#0096C1]">
        <button onClick={onClick}>Review</button>
      </div>
    </div>
  );
};

export default DisputeSmallCard;

import React from "react";
import Container from "../container";
import { datas } from "../../signup/_components/steps/consumer/_components/steps/stepSix";
import DefaulDisplay from "./defaultDisplay";
import { filterIcon } from "../../../constants/images";
import { useSelector } from "react-redux";
import SearchCompnent from "../search";
import SearchInput from "../component";


const DashService = () => {


  
  const searchParams = useSelector((state) => state.search.homeSearchInput);
  return (
    <Container>
      <div className="mt-16 px-10 py-6">
        <div className="md:hidden">
        <div className="flex gap-3">
            <SearchInput />
            <div className="rounded-[5px] bg-[#0096C1] p-2">
              <img src={filterIcon} alt="" />
            </div>
          </div>
        </div>
        {searchParams === "" ? (
          <div className=" ">
            <h1 className="tetx-[#002D3A] text-[30px] font-semibold my-5">
              Services
            </h1>
            <div className="grid grid-cols-2 gap-5 lg:grid-cols-5">
              {datas.map((data) => {
                return <DefaulDisplay key={data.id}  {...data}  />;
              })}
            </div>
          </div>
        ) : (
          <SearchCompnent searchParams={searchParams} />
        )}
      </div>
    </Container>
  );
};

export default DashService;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { formdata } from "../../../../../../../../../features/formSlice";
import { Button } from "../../../../../../../../../components/ui";
import { Link, useNavigate } from "react-router-dom";
import { addfiles, userNotImage } from "../../../../../../../../../constants/images";

const CatalogAndPicture = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [details, setDetails] = useState({
    file: "",
    profile: "",
  });

  const handleFileUpload = (e) => {
    setSelectedFile(e.target.value);
  };
  const handleImageUpload = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (details) {
      dispatch(formdata({ id: 1, catalog_picture: details }));
      navigate("/signup/setup&complete");
    }
  };

  useEffect(() => {
    setDetails({ catalog: selectedFile, picture: profileImage });
  }, [selectedFile, profileImage]);

  return (
    <div className="h-screen w-[100%] bg-primary md:bg-bgImage">
      <div className="left-0 right-0 top-[6rem] h-[90vh] overflow-auto py-10 md:absolute md:px-20 md:py-0 lg:px-0">
        <div className=" w-full md:mx-auto lg:w-1/2">
          <div className="flex flex-col justify-center rounded-[5px] bg-primary p-7 md:px-10 md:py-10">
            <span className="mb-8 text-[25px] font-semibold text-secondary md:text-center md:text-[30px]">
              Set up your account
            </span>
            <span className="text-start text-lg font-semibold text-secondary md:text-[20px]">
              Update catalog and profile picture
            </span>
            <div className="flex flex-col justify-center gap-10 pt-6">
              <span className="text-start text-[#181818] lg:text-center">
                Upload a catalog of your previous work to attract more customers
              </span>
              <div className="flex flex-col items-center">
                <input
                  type="file"
                  name="addfiles"
                  id="addfiles"
                  onChange={handleFileUpload}
                  className="hidden"
                />
                <label
                  htmlFor="addfiles"
                  className="flex flex-col items-center gap-3 text-sm hover:cursor-pointer"
                >
                  <img src={addfiles} alt="Add files" />
                  Upload your files
                </label>
                <span className="text-sm text-lightgray">
                  {selectedFile?.replace(/^.*[\\\/]/, "")}
                </span>
              </div>

              <div className="flex flex-col space-y-4 lg:text-center">
                <span className="text-start lg:text-center">
                  Upload profile picture
                </span>
                <div className="mx-auto flex h-[11rem] w-[10rem] flex-col items-center justify-center gap-3 border-2 border-dotted border-[#002D3A]">
                  {profileImage ? (
                    <img
                      src={profileImage}
                      className="h-[120.67px] w-[120.67px] rounded-full object-cover"
                    />
                  ) : (
                    <img src={userNotImage} alt="upload image" />
                  )}

                  <input
                    type="file"
                    id="profileimg"
                    onChange={handleImageUpload}
                    className="hidden"
                  />
                  <label
                    htmlFor="profileimg"
                    className="cursor-pointer text-lightblue underline underline-offset-4"
                  >
                    Upload image
                  </label>
                </div>
              </div>
              <Button
                onClick={handleSubmit}
                children="Save and proceed"
                className="w-full"
                border="none"
              />
              <Link to='/signup/setup&complete' className="text-center text-lightgray">
                I'll do it later
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogAndPicture;
